<template>
  <div class="content" v-show="$route.meta.showfater">
    <!-- 轮播图 -->
    <div class="swiperr">
      <div class="swiper-item">
        <!-- 轮播图 -->
        <img :src="Bnners" alt="">
        <!-- 标题 -->
        <div class="killtitle">
          <div class="killtitle1">
            <div class='button' @click="shouquan()">
              <span>我要授权</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 授权搜索框 -->
    <div class="authot_score">
      <div class="authot_scoreq">
        <div class="search">
          <!-- 文本输入框表单 -->
          <input class='Searchqq' type="text" placeholder="请输入您需要查询的授权编号" v-model="number">
          <button @click="shouquanchaxun(number)">
            <span>授权查询</span>
          </button>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="authot_con">
      <div class="authot_con_con">
        <!-- 商品授权优势 -->
        <div class="authot_con_con1">
          <div class="authot_text">
            商品授权优势
          </div>
          <div class="authot_textt">
            授权将会给您带来什么？
          </div>
          <div class="authot_con_con2">
            <div class="authot_content" v-for="(item,index) in tabdata" :key="index">
              <div class="authot_img">
                <img :src="item.image" alt="">
              </div>
              <div class="authot_bt">
                {{item.name}}
              </div>
              <div class="authot_context">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
        <!-- 常见问题 -->
        <div class="authot_con_con3">
          <div class="authot_text">
            常见问题
          </div>
          <div class="authot_textt">
            授权问题集锦？
          </div>
          <div class="authot_con_con4">
            <div class="authot_content" v-for="(item,index) in chanjianwenti" :key="index">
              <div class="authot_num">
                0{{index+1}}
              </div>
              <div class="authot_bt">
                {{item.name}}
              </div>
              <div class="authot_context">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 授权弹窗 -->
    <el-dialog title="" v-model="dialogVisible" center width="85%" :before-close="handleClose">
      <div class="dialog_con">
        <div class="dialog_con_img" style="margin-bottom: 38px;">
          <img src="../../assets/shouquanchaxun/img5.png" alt="">
        </div>
        <div class="wangzhi" style="margin-bottom: 16px;">
          {{shouqa.empower_name}}
        </div>
        <div class="text" style="margin-bottom: 100px;">
          <span class="text1">获得以下产品授权</span>
          <span class="text2">【{{shouqa.goods_info.title}}】</span>
        </div>
        <div class="success" style="margin-bottom: 38px;">
          恭喜您成功授权省钱兄{{shouqa.goods_info.subtitle}}！
        </div>
        <div class="erweiimg" style="margin-bottom: 20px;">
          <img src="../../assets/shouquanchaxun/img6.png" alt="">
        </div>
        <div class="foot">
          官方客服二维码
        </div>
      </div>
    </el-dialog>
    <!-- 右侧固钉 -->
    <affix></affix>
  </div>
  <div class="otherpage">
    <router-view> </router-view>
  </div>

</template>

<script>
  import affix from '../../components/affix.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'

  export default {
    components: {
      affix,
    },
    data() {
      return {
        dialogVisible: false,
        number: '',
        tabdata: [],
        chanjianwenti: [],
        shouqa: '',
		Bnners:'',
      }
    },
    methods: {
      shouquan() {
		  // var userId = this.$cookie.get('userid')
		  var token = window.sessionStorage.getItem('token')
		  console.log('token',token,)
		  if(token){
			  this.$router.push({
			    name: 'mymoney',
			  })
		  }else{
			ElMessageBox.confirm(
			        	    '当前未登录，是否登录。',
			        	    '提示',
			        	    {
			        	      confirmButtonText: '去登录',
			        	      cancelButtonText: '取消',
			        	      type: 'warning',
			        	    }
			        	  ).then(()=>{
			        		  this.$router.push({
			        		    path: 'login',
			        		  })
			        	  }).catch(() => {
			        	  })  
		  }
        
      },

      async shouquanchaxun(number) {

        const {
          data: res
        } = await this.$http.post('index/user/user_impower', {
          empower_code: number
        })
        if (res.code == 1) {
          this.dialogVisible = true
          this.shouqa = res.data
        } else if (res.code == 0) {
          ElMessageBox.confirm(
        	    '当前未登录，是否登录。',
        	    '提示',
        	    {
        	      confirmButtonText: '去登录',
        	      cancelButtonText: '取消',
        	      type: 'warning',
        	    }
        	  ).then(()=>{
        		  this.$router.push({
        		    path: 'login',
        		  })
        	  }).catch(() => {
              this.images[0].active = false
        	  })
        }

      },
      async bychanjianwenti() {
        const {
          data: res
        } = await this.$http.post('index/product/empower_list')
        if (res.code = 1) {
          this.tabdata = res.data.empower_benefit //优势
          this.chanjianwenti = res.data.empower_question //常见问题
        }
      },
	  //头部组件
	  async byhead() {
	    const {
	      data: res
	    } = await this.$http.get('index/index/index_header')
	    if (res.code == 1) {
	  			if(res.data.top_navigation){
	  				this.Bnners = res.data.top_navigation[3].img
	  			}
	      
	    }
	  }
    },
    mounted() {
		this.byhead()
      this.bychanjianwenti()
    }
  }
</script>

<style lang="less" scoped>
  .content {
    background: #FFF;
  }

  .swiperr {
    width: 100%;
    height: 700px;

    .swiper-item {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .killtitle {
        position: absolute;
        top: 219px;
        left: 159px;

        .killtitle1 {
          .button {
            margin-top: 250px;
            width: 247px;
            height: 80px;
            background: #FFFFFF;
            border-radius: 40px;
            text-align: center;

            span {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #0D68FC;
              line-height: 2px;
              line-height: 80px;
            }
          }
        }
      }
    }
  }

  .authot_score {
    width: 100%;
    height: 137px;

    .authot_scoreq {
      margin: 0 auto;
      width: 1615px;
      height: 137px;
      position: relative;
      top: -68px;
    }

    /* 搜索栏盒子模型 */
    .search {
      float: left;
      margin-left: 65px;

      .Searchqq {
        background-image: url(../../assets/shouquanchaxun/search.png);
        /*设置小图标*/
        background-size: 42px 42px;
        /*小图标的大小*/
        background-position: 32px 46px;
        /*小图标在input的位置*/
        background-repeat: no-repeat;
        /*背景小图标不重复*/
      }
    }

    input[type=text]:focus {
      outline: none;
    }

    input::-webkit-input-placeholder {
      position: relative;
      left: 0px;
    }

    /* 搜索栏 输入框 */
    .search input {
      padding-left: 95px;
      float: left;
      border: none;
      width: 1120px;
      height: 135px;
      background: #FFFFFF;
      box-shadow: 0px 4px 14px 0px rgba(90, 99, 255, 0.11);
      border-radius: 20px 0 0 20px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      // color: #B3B3B3;
    }

    /* 搜索框按钮 */
    .search button {
      float: left;
      border: none;
      width: 257px;
      height: 137px;
      background: #0D68FC;
      border-radius: 0px 20px 20px 0px;
      box-shadow: 0px 4px 14px 0px rgba(90, 99, 255, 0.11);
      cursor: pointer;

      span {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

  }

  .authot_con {
    width: 100%;
    height: auto;

    .authot_con_con {
      width: 90%;
      height: 100%;
      margin: 0 auto;

      .authot_con_con1 {
        width: 100%;
        // 

        .authot_text {
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #464646;
          line-height: 2px;
          text-align: center;
        }

        .authot_textt {
          margin-top: 40px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #B3B3B3;
          line-height: 2px;
          text-align: center;
        }
      }

      .authot_con_con2 {
        width: 100%;
        margin-top: 73px;
        display: flex;
        height: 454px;
        justify-content: space-around;

        .authot_content {
          width: 375px;
          height: 454px;
          background: #F7FAFF;
          border-radius: 10px;


          .authot_img {
            margin-top: 72px;
            text-align: center;
          }

          .authot_bt {
            margin-top: 30px;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }

          .authot_context {
            margin: 30px 20px 0 20px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            padding: 20px;
          }
        }
      }

      .authot_con_con3 {
        width: 100%;
        margin-top: 200px;

        .authot_text {
          text-align: center;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #464646;
          line-height: 2px;
        }

        .authot_textt {
          text-align: center;
          margin-top: 40px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #B3B3B3;
          line-height: 2px;
        }
      }

      .authot_con_con4 {
        width: 100%;
        margin-top: 73px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-left: 96px;

        .authot_content {
          width: 33%;
          position: relative;
          height: 275px;

          .authot_num {
            font-size: 40px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #888889;
            line-height: 2px;
            opacity: 0.3;
            position: absolute;
            left: -50px;
            top: 20px;
          }

          .authot_bt {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            margin-bottom: 20px;
          }

          .authot_context {
            width: 370px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
          }
        }
      }

    }

  }

  .dialog_con {
    width: 100%;
    text-align: center;

    .wangzhi {
      font-size: 45px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

    .text {
      .text1 {
        font-size: 34px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }

      .text2 {
        font-size: 34px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0D68FC;
      }
    }

    .success {
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

    .foot {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
  }
</style>