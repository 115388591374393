<template>
  <div class="search" style="display: flex;">
    <div class="search-input" style="width: 80%;">
      <el-input placeholder="请输入产品名" v-model="sinput" @input="search" style="height: 36px;" />
    </div>
    <div class="search-button" @click="getList()">
      <img src="../assets/ruzhu/score.png" style="width: 17px; height: 17px;" />
    </div>
	<el-button size="mini" style="margin-left: 20px;color: #999;height: 36px;line-height: 39px;" @click="clear()">清空</el-button>
  </div>
  <div class="cont" v-if="showSearch">
    <el-col class="text">
      <el-form :model="queryParams" v-for="(item,index) in form" :key="index" ref="form" :inline="true">

        <div style="line-height: 20px;" @click="goto(item)" class="ewnben">
          <span v-html="brightenKeyword(item.title, sinput)"></span>
        </div>
      </el-form>
    </el-col>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showSearch: false,
        sinput: '',
        form: [],
        queryParams: {
          likeTitleOrContent: ''
        },
        loading: false
      }
    },
    methods: {
      brightenKeyword(contentText, searchText) {
        let wordsArray = contentText.split('');
        let res = '';
        for (var i = 0; i < wordsArray.length; i++) {
          if (searchText.includes(wordsArray[i])) {
            wordsArray[i] = "<span style='color: #0D68FC;'>" + wordsArray[i] + '</span>';
          }
        }
        res = wordsArray.join('');
        return res;
      },
      search() {
        if (this.sinput == '') {
          this.showSearch = false;
        } else {
          this.showSearch = true;
          this.getList()
        }
      },
	  clear(){
		  this.sinput = ''
		  this.showSearch = false;
		  this.getList()
	  },
      getList() {
        this.$http.post('/index/index/user_search', {
          title: this.sinput,
          num: 10,
          page: 1
        }).then(res => {
          if (res.data.code == 1) {
            if(res.data.data.total == 0){
              this.form = [
                {title:"暂无相关产品"}
              ]
            }else{
              this.form = res.data.data.data
            }
            console.log(this.form)
          }
        })
      },
      goto(item) {
       var itemcon = item.id
       this.$router.push({
         name: 'ProductIntroduction',
         query: {
           item: itemcon
         }
       })
        this.sinput = ''
        this.showSearch = false
      }
    }
  }
</script>
<style scoped lang="less">
  .search {
    width: 100%;
    position: relative;

    .search-button {
      position: absolute;
      top: 8px;
      left: 73%;
    }
  }

  .cont {
    width: 303px;
    height: auto;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    margin-top: 13px;


    .text {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      line-height: 2px;
    }

    .ewnben {
      width: 303px;
      white-space: nowrap; //不换行
      overflow: hidden; //超出部分隐藏
      text-overflow: ellipsis; //文本溢出显示省略号
      margin-bottom: 10px;
    }
  }
</style>