<template>
  <div class="content">
    <div class="team">
      <!-- 上部分 -->

      <div class="team_top">
        <div class="team_top3">
          <div class="team_top3_right">
            <div class="money">
              <div class="jinqiantext">邀请好友拿奖励</div>
              <div class="jinqian">{{number3}}<span>天</span></div>
              <div class="text" @click="invite()">
                <button class="button">
                  <span>立即邀请</span>
                </button>
              </div>
            </div>
          </div>
          <div class="team_top3_left">
            <img src="../../assets/my/teamimg3.png" alt="">
          </div>
        </div>
        <div class="team_top1">
          <div class="team_top1_right">
            <div class="money">
              <div class="jinqiantext">已邀请人数</div>
              <div class="jinqian">{{number1}}<span>人</span></div>
              <div class="text"></div>
            </div>
          </div>
          <div class="team_top1_left">
            <img src="../../assets/my/teamimg1.png" alt="">
          </div>
        </div>
        <div class="team_top2">
          <div class="team_top2_right">
            <div class="money">
              <div class="jinqiantext">累计收益总额</div>
              <div class="jinqian">{{number2}}<span>元</span></div>
              <div class="text"></div>
            </div>
          </div>
          <div class="team_top2_left">
            <img src="../../assets/my/teamimg2.png" alt="">
          </div>
        </div>

      </div>
      <!-- 下半部分 -->
      <div class="team_bottom">
        <div class="team_bottom_text">
          <span class="budeg"></span>
          <span class="budegtitle">邀请明细</span>
        </div>
        <div class="team_bottomcon">
          <el-table :data="tableData" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
            <el-table-column prop="id" label="序号" width="180" style="">
            </el-table-column>
            <el-table-column prop="name" label="邀请用户昵称">
              <template v-slot="scope">
                <span
                  style="font-size: 16px;display: inline-block;margin-left: 9px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">{{scope.row.lower_user_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="commission_money" label="收益金额">
              <template v-slot="scope">
                <span style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                  + {{scope.row.commission_money}}元
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="create_at" label="邀请时间">
            </el-table-column>
          </el-table>


          <div class="pages">
            <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
              :current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
          </div>
        </div>
      </div>
    </div>


    <!-- 邀请好友弹窗 -->
    <el-dialog v-model="dialogVisible" title="邀请好友" center width="30%" :before-close="handleClose" class="eldis">
      <div style="width: 100%; height: 100%; text-align: center;">
        <div>
          <img :src="qrCodeUrl" alt="" width="203" height="203">
        </div>
        <div style="font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">二维码分享</div>

      </div>

      <template #footer>
        <span class="dialog-footer">
          <div><button style="width: 187px;
          height: 46px;
          border: none;
          background: #0D68FC;
          border-radius: 23px;"><span style="font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;" @click="shareLink">链接分享</span></button></div>
        </span>
      </template>
    </el-dialog>

  </div>

</template>

<script>
  import QRCode from 'qrcode';
  export default {
    data() {
      return {
        qrcode_img: '',
        total: 6,
        pageSize: 3,
        currentPage: 1,
        number1: 0,
        number2: 0,
        number3: 0,
        dialogVisible: false,
        tableData: [],
        qrcode_url: '',
        qrCodeUrl: '',
      }
    },
    methods: {
      shareLink() {
        this.$notify.success({
          title: "成功",
          message: '复制链接成功',
          duration: 2500,
          offset: 50
        });
        const link = this.qrcode_url; // 将这里替换为你要分享的实际链接  
        let input = document.createElement("input");
        input.value = link;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      async invite() {
        const {
          data: res
        } = await this.$http.post('/index/user/test_qrcode')
        this.qrcode_url = res.data.qrcode_url
        if (this.qrcode_url) {
          this.geterimg()
        }
        this.dialogVisible = true
      },
      geterimg() {
        const qrCodeData = this.qrcode_url;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.info({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrl = url;
          }
        });
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.teamnum()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.teamnum()
      },
      async teamnum() {
        const {
          data: res
        } = await this.$http.post('index/user/mine_team_info', {
          num: this.pageSize,
          page: this.currentPage,
        })
        if (res.code == 1) {
          this.number1 = res.data.invitation_info.invitation_num
          this.number2 = res.data.invitation_info.invitation_money
          this.number3 = res.data.invitation_info.invitation_day
          this.tableData = res.data.invitation_list.data
          this.total = res.data.invitation_list.total
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      }
    },
    mounted() {
      this.teamnum()
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-table__empty-text {
   display: block;
   width: 200px;
   height: 371px;
   position: relative;
   padding-top: 148px;
   top: 80px;
   background: url('../../assets/dhpbjj.png') no-repeat;
   color: #909399;
   box-sizing: border-box;
  }

  /deep/.el-table tr {
    height: 50px;
  }

  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .team {
      width: calc(100% - 20px);
      min-height: 770px;
      background: #FFFFFF;
      border-radius: 20px;

      .team_top {
        width: 100%;
        height: 165px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        padding-top: 31px;

        .team_top1 {
          width: 504px;
          height: 151px;
          background: #F6F9FF;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .team_top1_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 22%;
              right: 86px;
            }
          }

          .team_top1_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              width: 100%;
              height: 100%;



              .jinqiantext {
                width: 82px;
                height: 16px;
                margin: 26px 30px 0px 54px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;


              }

              .jinqian {
                margin: 10px 30px 0px 52px;
                width: 119px;
                height: 27px;
                font-size: 35px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;

                span {
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }

              }

              .text {
                margin: 40px 30px 0px 54px;
                width: 50px;
                height: 8px;
                background: #8461F0;
              }
            }
          }
        }

        .team_top2 {
          width: 504px;
          height: 151px;
          background: #F6F9FF;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .team_top2_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 22%;
              right: 86px;
            }
          }

          .team_top2_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              width: 100%;
              height: 100%;



              .jinqiantext {
                width: 96px;
                height: 16px;
                margin: 26px 30px 0px 54px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;


              }

              .jinqian {
                margin: 10px 30px 0px 52px;
                width: 119px;
                height: 27px;
                font-size: 35px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;

                span {
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }

              }

              .text {
                margin: 40px 30px 0px 54px;
                width: 50px;
                height: 8px;
                background: #2276fd;
              }
            }
          }
        }

        .team_top3 {
          width: 504px;
          height: 151px;
          background: #F6F9FF;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .team_top3_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 22%;
              right: 86px;
            }
          }

          .team_top3_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              width: 100%;
              height: 100%;



              .jinqiantext {
                width: 120px;
                height: 16px;
                margin: 26px 30px 0px 54px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;


              }

              .jinqian {
                margin: 10px 30px 0px 52px;
                width: 119px;
                height: 27px;
                font-size: 35px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;

                span {
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }

              }

              .text {
                margin: 28px 30px 0px 54px;

                .button {
                  cursor: pointer;
                  width: 84px;
                  height: 30px;
                  background: #38d8cb;
                  border-radius: 4px;
                  border: none;

                  span {
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
      }

      .team_bottom {
        width: 95%;
        height: 500px;
        margin: 0 auto;
        margin-top: 20px;

        .team_bottom_text {
          width: 100%;
          height: 30px;
          display: flex;

          .budeg {
            margin-top: 4px;
            margin-right: 5px;
            display: inline-block;
            height: 15px;
            width: 4px;
            border-radius: 2px;
            background-color: #0D68FC;

          }

          .budegtitle {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #333333;
          }
        }

        .team_bottomcon {
          width: 100%;
          margin: 0 auto;
          margin-top: 15px;
          height: 368px;

        }
      }
    }

  }





  /deep/ .el-dialog {
    width: 325px;
    height: 425px;
    background: #FFFFFF;
    box-shadow: -1px 2px 6px 0px rgba(55, 86, 232, 0.16);
    border-radius: 16px;
  }



  /deep/.el-table__header th {
    height: 50px;
    background: #EEF0F6;
    border-radius: 0px 0px 0px 0px;
  }

  .pages {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>