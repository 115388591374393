<template>
  <div class="content" v-show="$route.meta.showfater">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item,index) in Bnners" :key="index">
          <div class="swiper-item">
            <img :src="item" />
            <div class="imgg">
             <!-- <img src="../../assets/kaiyuanchanping/kaiyuan.png" />
              <div class="text">官方正版授权，享受1V1服务支持，上手更快，商用无忧</div> -->
            </div>
            <div class="butt1" @click="kefuzixun()">
              <img src="../../assets/kaiyuanchanping/buttonkf.png" />
            </div>
            <scrollwarp></scrollwarp>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 派单池 -->
    <div class="type flex align-center justify-around">
      <div class="paidanchi1">
        <div class="img_one"></div>
        <div class="img_two"></div>
      </div>
      <div class="paidanchi2">
        <div class="text1">
          <span>派单池</span>
        </div>
        <div class="text2"></div>
        <div class="text3">
          <span>
            邀您一起超越无界
          </span>
        </div>
        <div class="text4"></div>
      </div>
      <div class="paidanchi3">
        <el-button @click="paidanchi()" class="buttonnn"><span>立即查看</span></el-button>
      </div>
    </div>
    <!-- 秒杀专区 -->
    <div class="title flex justify-center align-center">
      <div class="miaosha">
        <div class="miaosha1">
          <div class="miaosha1top"></div>
          <div class="miaosha1bottom">
            <span id="text">多款商品限时低价抢购</span>
          </div>
        </div>
        <div class="miaosha2">
          <div class="container">
            <div class="item">
              <p id="day">{{day}}<span>天</span></p>
            </div>
            <div class="item">
              <p id="hour">{{hr}}</p>
            </div>
            <b
              style="font-size: 37px;font-family: Source Han Sans CN;font-weight: 500;color: #FFFFFF;line-height: 56px;">:</b>
            <div class="item">
              <p id="min">{{min}}</p>
            </div>
            <div class="item">
              <p id="sec">{{sec}}</p>
            </div>
          </div>
        </div>
        <div class="miaosha3">
          <el-button class="button2" @click="joinmiaosha()"><span>进入专区</span></el-button>
        </div>
      </div>
    </div>
    <!-- 系统专区 -->
    <div class="xitong flex justify-center align-center">
      <div class="xitongconcent">
        <div class="xitong1" v-for="(item,index) in  xitong" :key="index">
          <div class="xitongimg">
            <img :src="item.cover_img" alt="" @click="skilldemo(item)">
          </div>
          <div class="xitongtext">
            <div class="title-xt">
              {{item.title}}
            </div>
            <div class="money-xt">
              <span class="money1">￥{{item.member_price}}</span>
              <span class="money2">￥{{item.original_price}}</span>
            </div>
            <div class="progress-xt">
              <div class="progress-xt1">
                <el-progress :percentage="50" status="exception"
                  color="linear-gradient(-90deg, #FF4566 19%, #FF8074 100%);" :text-inside="true" :show-text="false" />
              </div>
              <div class="progress-xt2">
                仅剩 {{ item.date}}
              </div>
            </div>
            <div class="button-xt">
              <el-button class="button3" @click="buy(item)"><span>立即抢购</span></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 全部产品 -->
    <div class="chanpin flex justify-center align-center">
      <div class="chanpinimg">
        <div class="chanpinimg2"></div>
        <div class="chanpinimgtext">
          所有产品均有演示，开源可二次开发
        </div>
      </div>

    </div>
    <!-- 产品内容 -->
    <div class="product flex justify-center align-center">
      <div class="allproduct">
        <!-- 级联选择 -->
        <div class="jilian">
          <div style="position: relative;display: inline-block;margin: 10px 0px;">
            <!-- @change="orderfenxi" -->
            <el-select :teleported="false" v-model="types" style="margin-left: 10px;" placeholder="最新上线"
              @change="shangxian">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
          <div style="position: relative;display: inline-block;margin: 10px 0px;">
            <el-select v-model="types1" style="margin-left: 10px;" placeholder="产品价格" :teleported="false"
              @change="byallchanpin">
              <el-option v-for="item in typeList1" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div style="position: relative;display: inline-block;margin: 10px 0px;">
            <el-select v-model="types2" style="margin-left: 10px;" placeholder="全部系列" :teleported="false"
              @change="byallchanpin">
              <el-option v-for="item in typeList2" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div style="position: relative;display: inline-block;margin: 10px 0px;">
            <el-select v-model="types3" style="margin-left: 10px;" placeholder="发布时间" :teleported="false"
              @change="byallchanpin">
              <el-option v-for="item in typeList3" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
			
          </div>
		  <el-button @click="clearBtn()" class="qingkong">清空选项</el-button>
        </div>
        <!-- 内容 -->
        <div class="productcentents">
          <div class="product1" v-for="(item,index) in product " :key="index">
            <div class="productimg">
              <img :src="item.image" alt="" @click="joinjieshao(item)">
            </div>
            <div class="productext" :style="colorDiv(item)" @click="divCLick(index)" style="z-index: 999;">
              <div class="producone">
                {{item.title}}
              </div>
              <div class="productwo">
                <div class="productwo_money">
                  ￥ <span style="font-size: 22px; font-weight: 600px;">{{item.money}}</span>
                </div>
                <div class="productwo_renqi">
                  <span><img src="../../assets/kaiyuanchanping/hot.png" alt=""> {{item.sales}}</span>
                </div>
              </div>
              <div class="producthree">
                <div class='button1' @click="buyone(item)">
                  <span>立即购买</span>
                </div>
                <div class='button2' @click="buytwo(item)">
                  <span>产品演示</span>
                </div>
                <div class='button3' @click="buythree(item)">
                  <span><img src="../../assets/kaiyuanchanping/kefu.png"
                      style="margin-top: 5px; margin-right: 10px; color: blue;">咨询</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看更多 -->
    <div class="more flex justify-center align-center">
      <div class="button4" @click="moreshop">
      </div>
    </div>
    <!-- 右侧固钉 -->
    <affix></affix>

  </div>
  <div class="otherpage">
    <router-view> </router-view>
  </div>
</template>

<script>
  import affix from '../../components/affix.vue'
  import scrollwarp from '../../components/scrollwarp.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
    name: "index",
    components: {
      affix,
      scrollwarp,

    },
    data() {
      return {
        currentIndex: 0,
        day: 0, //天
        hr: 0, //时
        min: 0, //分
        sec: 0, //秒
        isTextChanging: false,
        swiperIndex: 0,
        swiperPhone: require('../../assets/index/swiperPhone.png'),
        gg: '购买请联系客服，领取优惠卷！客服电话：13895585204',
        xitong: [],
        types: '',
        typeList: [{
          value: 'all',
          label: '最新'
        }, {
          value: 1,
          label: '热销'
        }, {
          value: 2,
          label: '好评'
        }],
        types1: '',
        typeList1: [{
          value: 'all',
          label: '全部'
        }, {
          value: 1,
          label: '5K以下'
        }, {
          value: 2,
          label: '5k-1w'
        }, {
          value: 3,
          label: '1w-2w'
        }, {
          value: 4,
          label: '2w-3w'
        }, {
          value: 5,
          label: '3w-4w'
        }, {
          value: 6,
          label: '4w以上'
        }],
        types2: '',
        typeList2: [],
        types3: '',
        typeList3: [{
            value: 'all',
            label: '全部'
          },
          {
            value: 1,
            label: '近一个月'
          }, {
            value: 2,
            label: '近三个月'
          }, {
            value: 3,
            label: '近六个月'
          }, {
            value: 4,
            label: '近一年'
          }
        ],
        isActive: false,
        product: [],
        purchasedProducts: [], //存储的商品
		Bnners:[],
      }
    },
	
    computed: {},
    methods: {
      shangxian(value) {
        // this.types = value
        this.byallchanpin()
      },
      // chanpinprice(value) {
      //   this.types1 = value
      //   this.byallchanpin()
      // },
      // allxilei(value) {
      //   this.types2 = value
      //   this.byallchanpin()
      // },
      // fabutime(value) {
      //   this.types3 = value
      //   this.byallchanpin()
      // },
	  // 清空选项
	  clearBtn(){
		  this.types = ''
		  this.types1 = ''
		  this.types2 = ''
		  this.types3 = ''
		  this.byallchanpin()
	  },

      //全部产品
      async byallchanpin() {
        const {
          data: res
        } = await this.$http.get('index/product/procduct_list', {
          params: {
            search_new: this.types,
            product_money: this.types1,
            goods_cate: this.types2,
            create_time: this.types3,
            num: 12,
            page: 1
          }
        })
        if (res.code == 1) {
          //全部产品
          var chanpin = res.data.data
          for (let i = 0; i < chanpin.length; i++) {
            chanpin[i].isActive = false
          }
          this.product = chanpin
        }

      },
      // //系统专区
      async bymiaosha() {
        const {
          data: res
        } = await this.$http.post('index/product/kill_goods')
        if (res.code == 1) {
          var xitonglist = res.data
          this.xitong = xitonglist.slice(0, 4)
		  if(this.xitong.length>0){
			  var times = this.xitong[0].end_time
			  this.hr = this.convertTimestamp(this.xitong[0].start_time-this.xitong[0].end_time)*1 
			  this.countdown(times)
			  this.countdownList(this.xitong);
			  
		  }
		  
        }
      },
      //仅剩时间
      convertTimestamp(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从0开始，需要加1
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
		// console.log('------',day,'===',hours)
        return `${hours}`;
      },

      kefuzixun() {
        var userid = this.$cookie.get('userid')
        this.$router.push({
          name: 'chatindex',
          params: {
            userid: userid,
          }
        })
      },
      selectSwiper(index) {
        this.swiperIndex = index
        this.classNames = 'animate__fadeInUp'
        this.isTextChanging = !this.isTextChanging;
        setTimeout(() => {
          this.swiperIndex = index
          this.isTextChanging = false;
        }, 100);
      },
      countdown(times) {
        // const end = Date.parse(new Date('2024-01-01 18:00:00'))
		const end = times*1000
        const now = Date.parse(new Date())
		// console.log((times)+';',end,'----',now)
        if (now >= end) {
          this.day = 0
          this.hr = 0
          this.min = 0
          this.sec = 0
          return
        }
        // 用结束时间减去当前时间获得倒计时时间戳
        const msec = end - now
        let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
        let hr = parseInt(msec / 1000 / 60 / 60 % 24) //算出小时数
        let min = parseInt(msec / 1000 / 60 % 60) //算出分钟数
        let sec = parseInt(msec / 1000 % 60) //算出秒数
        //给数据赋值
        this.day = day
        this.hr = hr > 9 ? hr : '0' + hr
        this.min = min > 9 ? min : '0' + min
        this.sec = sec > 9 ? sec : '0' + sec
		// console.log(day+' '+hr+':'+min+':'+sec)
        const that = this
		var times1 = times-1
        // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
        setTimeout(function() {
          that.countdown(times1)
        }, 1000)
      },
	  
	  countdownList(list) {
		  for(var i in list){
		  				  // console.log("-----",)
		  				 // this.xitong[i].hr =   this.convertTimestamp(this.xitong[i].start_time-this.xitong[i].end_time)*1 
		  				  var times2 = list[i].end_time
		  				  const end = times2*1000
		  				  const now = Date.parse(new Date())
		  				  // console.log((times)+';',end,'----',now)
		  				  if (now >= end) {
		  				    list[i].day = 0
		  				    list[i].hr = 0
		  				    list[i].min = 0
		  				    list[i].sec = 0
		  				    return
		  				  }
		  				  // 用结束时间减去当前时间获得倒计时时间戳
		  				  const msec = end - now
		  				  
		  				  let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
		  				  let hr = parseInt(msec / 1000 / 60 / 60 % 24) //算出小时数
		  				  let min = parseInt(msec / 1000 / 60 % 60) //算出分钟数
		  				  let sec = parseInt(msec / 1000 % 60) //算出秒数
		  				  
		  				  //给数据赋值
		  				  list[i].day = day
		  				  list[i].hr = hr > 9 ? hr : '0' + hr
		  				  list[i].min = min > 9 ? min : '0' + min
		  				  list[i].sec = sec > 9 ? sec : '0' + sec
		  				  // console.log("***************"+day+' '+hr+':'+min+':'+sec)
						  if(day>0){
							  list[i].date=day+'天'+hr+'小时'
						  }else{
							  if(hr>0){
								  list[i].date=hr+'小时'
							  }else{
								  list[i].date=min+'分'
							  }
							  
						  }
		  				  
		  				  
		  				  // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
		  				  // setTimeout(function() {
		  				  //   that.countdown(times1)
		  				  // }, 1000)
		  }
		  this.xtiong=list
	    var that=this;
	    // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
	    setTimeout(function() {
	      that.countdownList(list)
	    }, 1000)
	  },
	  
	  
	  
      //点击切换背景
      divCLick(index) {
        /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
        for (let i in this.product) {
          if (index == i) {
            this.product[i].isActive = !this.product[i].isActive
          } else this.product[i].isActive = false;
        }
      },
      colorDiv(item) {
        if (item.isActive == true) {
          return {
            'background-color': '#FFFFFF'
          }
        } else {
          return {
            'background-color': '#FAFAFA'
          }
        }
      },
      gotologin() {
        this.$router.push({
          name: 'login',
        })
      },
      paidanchi() {
        //要判断是否是vip 
        let userId = window.sessionStorage.getItem('token');
        // console.log(userId)
        if (userId == '' || userId == null) {
          ElMessageBox.confirm(
        	    '成为VIP可享受派单池服务！当前未登录，是否登录？',
        	    '提示',
        	    {
        	      confirmButtonText: '去登录',
        	      cancelButtonText: '取消',
        	      type: 'warning',
        	    }
        	  ).then(()=>{
        		  this.$router.push({
        		    path: 'login',
        		  })
        	  }).catch(() => {
              
        	  })
        } else {
          var vip = this.$cookie.get('is_vip_type')
          if (vip == 0 || vip == 1) {
            this.$notify.info({
              title: "官方提示",
              message: "尊敬的用户你还不是会员用户无法进行此操作,请开通会员在查看!",
              duration: 1500,
              offset: 50
            });
          } else if (vip == 2 || vip == 3 || vip == 4) {
            this.$router.push({
              name: 'paidan',
              query: {
                name: '这是派单页面',
              }
            })
          }
        }

      },
      joinmiaosha() {
        this.$router.push({
          name: 'flashKill',
          query: {
            name: '这是秒杀页面',
          }
        })
      },
      buy(item) {
        let userId = window.sessionStorage.getItem('token');
        if (userId == '' || userId == null) {
          ElMessageBox.confirm(
        	    '当前未登录，是否登录。',
        	    '提示',
        	    {
        	      confirmButtonText: '去登录',
        	      cancelButtonText: '取消',
        	      type: 'warning',
        	    }
        	  ).then(()=>{
        		  this.$router.push({
        		    path: 'login',
        		  })
        	  }).catch(() => {
              
        	  })
        } else {
          this.purchasedProducts.push(item);
          let jsonString = JSON.stringify(this.purchasedProducts);
          window.localStorage.setItem('purchasedProducts', jsonString)
          window.localStorage.setItem('buy_type', 2)
          this.$router.push({
            name: 'empower',
            query: {
              buy_type: 2,
              name: '/my/empower'
            }
          })
        }
      },
      buyone(item) {
        let userId = window.sessionStorage.getItem('token');
        if (userId == '' || userId == null) {
          ElMessageBox.confirm(
        	    '当前未登录，是否登录。',
        	    '提示',
        	    {
        	      confirmButtonText: '去登录',
        	      cancelButtonText: '取消',
        	      type: 'warning',
        	    }
        	  ).then(()=>{
        		  this.$router.push({
        		    path: 'login',
        		  })
        	  }).catch(() => {
              
        	  })
        } else {
          this.purchasedProducts.push(item);
          let jsonString = JSON.stringify(this.purchasedProducts);
          window.localStorage.setItem('purchasedProducts', jsonString)
          window.localStorage.setItem('buy_type', 1)
          this.$router.push({
            name: 'empower',
            query: {
              buy_type: 1,
              name: '/my/empower'
            }
          })
        }
      },
      buytwo(item) {
        this.$router.push({
          path: 'demoCenter',
          query: {
            goodsId: item.id
          }
        })
      },
      buythree(item) {

        var itemcon = item.id
        this.$router.push({
          path: '/chatindex',
          query: {
            item: itemcon
          }
        })

      },
      //进入产品介绍页面
      joinjieshao(item) {
        this.purchasedProducts.push(item);
        let jsonString = JSON.stringify(this.purchasedProducts);
        window.localStorage.setItem('purchasedProducts', jsonString)
        window.localStorage.setItem('buy_type', 1)
        var itemcon = item.id
        this.$router.push({
          name: 'ProductIntroduction',
          query: {
            item: itemcon
          }
        })
      },
      skilldemo(item) {
        this.purchasedProducts.push(item);
        let jsonString = JSON.stringify(this.purchasedProducts);
        window.localStorage.setItem('purchasedProducts', jsonString)
        window.localStorage.setItem('buy_type', 2)
        var itemcon = item.sec_kill_id
        this.$router.push({
          name: 'skillProductIntroduction',
          query: {
            item: itemcon
          }
        })
      },
      moreshop() {
        this.$router.push({
          name: 'moreshop',
          query: {
            item: '商品库'
          }
        })
      },
      async byshopplist() {
		  this.typeList2 = [
			  {
			      id: 'all',
			      name: '全部'
			    }
		  ]
        const {
          data: res
        } = await this.$http.get('index/product/get_goods_cate_list')
		
		  if(res.data.length>0){
			  for(var i in res.data){
				  this.typeList2.push(res.data[i])
			  }
		  }
      },
	  //头部组件
	  async byhead() {
	    const {
	      data: res
	    } = await this.$http.get('index/index/index_header')
	    if (res.code == 1) {
			if(res.data.top_navigation){
				this.Bnners = res.data.top_navigation[0].img.split(",")
			}
	      
	    }
	  }
    },
    mounted() {
      this.byhead()
      const format = (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`)
      this.byshopplist()
      this.byallchanpin()
      this.bymiaosha()
	  // this.countdown()
    }
  }
</script>

<style lang="less" scoped>
  .content {
    background: #EFF7FF;
  }

  .swiper {
    width: 100%;
    height: 700px;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .imgg {
        position: absolute;
        top: 200px;
        left: 100px;

        img {
          width: 913px;
          height: 96px;
        }
      }

      .text {
        margin-top: 40px;
        margin-left: 10px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0060FB;
        line-height: 2px;
      }

      .butt1 {
        position: absolute;
        top: 473px;
        left: 132px;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  .type {
    width: 100%;
    height: 132px;
    background-image: url('../../assets/kaiyuanchanping/jiedanchi.png');
    display: flex;
    position: relative;

    .paidanchi1 {
      width: 25%;
      height: 100%;
      position: relative;

      .img_one {
        width: 190px;
        height: 151px;
        position: absolute;
        left: 120px;
        bottom: -20px;
        background-image: url('../../assets/kaiyuanchanping/line.png');
      }

      .img_two {
        width: 134px;
        height: 57px;
        position: absolute;
        left: 225px;
        top: 57px;
        background-image: url('../../assets/kaiyuanchanping/jiantou.png');
      }
    }

    .paidanchi2 {
      flex: 1;
      height: 100%;

      .text1 {
        span {
          font-size: 60px;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #000000;
          line-height: 125px;
          position: absolute;
          left: 608px;
        }
      }

      .text2 {
        position: absolute;
        left: 805px;
        top: 55px;
        width: 4px;
        height: 24px;
        background: #000000;
        opacity: 0.5;
        border-radius: 1px;
      }

      .text3 {
        span {
          font-size: 30px;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
          left: 832px;
          top: 43px;
        }
      }

      .text4 {
        background-image: url('../../assets/kaiyuanchanping/VIP.png');
        width: 120px;
        height: 39px;
        position: absolute;
        left: 1092px;
        top: 45px;
      }
    }

    .paidanchi3 {
      width: 18%;
      height: 100%;
      position: absolute;
      left: 1534px;
      top: 35px;

      .buttonnn {
        width: 200px;
        height: 62px;
        background: #136DFF;
        border-radius: 31px;

        span {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }

    }
  }

  .title {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 83px;
    margin-bottom: 60px;

    .miaosha {
      width: 1663px;
      height: 190px;
      background-image: url('../../assets/kaiyuanchanping/miaosha.png');
      display: flex;
      position: relative;

      .miaosha1 {
        width: 330px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .miaosha1top {
          width: 254px;
          height: 51px;
          background-image: url('../../assets/kaiyuanchanping/miaoshawenzi.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          top: 48px;
          left: 56px;
        }

        .miaosha1bottom {
          position: absolute;
          top: 115px;
          left: 56px;

          #text {
            width: 257px;
            height: 26px;
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 2px;
          }
        }
      }

      .miaosha2 {
        flex: 1;

        .container {
          width: 400px;
          height: 56px;
          position: absolute;
          top: 69px;
          left: 373px;
          display: flex;
          justify-content: space-between;

          .item {
            width: 91px;
            height: 62px;
            background: #FFFFFF;
            border-radius: 28px;
          }

          #day {
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #FF0000;
            text-align: center;
            margin-top: 5px;
          }

          #hour {
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #FF0000;
            text-align: center;
            margin-top: 5px;

          }

          #min {
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #FF0000;
            text-align: center;
            margin-top: 5px;

          }

          #sec {
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #FF0000;
            text-align: center;
            margin-top: 5px;

          }
        }
      }

      .miaosha3 {
        width: 330px;
        height: 100%;
        position: absolute;
        top: 57px;
        right: -87px;

        .button2 {
          width: 200px;
          height: 76px;
          background: #FFFFFF;
          border-radius: 38px;

          span {
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FF1541;
          }
        }
      }
    }

  }

  .xitong {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    background: #EFF7FF;
    margin-bottom: 30px;

    .xitongconcent {
      width: 87%;
      height: auto;
      display: flex;
      justify-content: space-between;
    }

    .xitong1 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .xitongimg {
        width: 100%;
        height: 242px;

        img {
          width: 350px;
          height: 280px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .xitongtext {
        width: 350px;
        height: 205px;
        background-color: #FFFFFF;
        position: relative;
        border-radius: 0px 0px 16px 16px;

        .title-xt {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000024;
          line-height: 36px;
          margin-top: 10px;
          margin-left: 18px;
        }

        .money-xt {
          margin-left: 18px;
          margin-bottom: 10px;
          margin-top: 22px;

          .money1 {
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FF011B;
            line-height: 2px;
          }

          .money2 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #B3B3B3;
            line-height: 2px;
            text-decoration: line-through;
            margin-left: 33px;
          }
        }

        .progress-xt {
          margin-left: 18px;
          width: 100%;
          height: 6px;
          display: flex;

          .progress-xt1 {
            width: 57%;
          }

          .progress-xt2 {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #B3B3B3;
            line-height: 8px;
            margin-left: 37px;
          }
        }

        .button-xt {
          position: absolute;
          top: 135px;
          left: 54px;

          .button3 {
            width: 221px;
            height: 54px;
            background: linear-gradient(-270deg, #FF795F 0%, #FF537D 98%);
            border-radius: 27px;

            span {
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 2px;
            }
          }
        }
      }
    }

    .demo-progress .el-progress--line {
      margin-bottom: 15px;
      width: 350px;
    }
  }

  .chanpin {
    width: 100%;
    height: 262px;
    flex-wrap: wrap;
    margin-top: 34px;
    margin-bottom: 83px;
    background: #EFF7FF;
    margin: 0 auto;

    .chanpinimg {
      width: 1663px;
      height: 190px;
      background-image: url('../../assets/kaiyuanchanping/chanpin.png');
      position: relative;
    }

    .chanpinimg2 {
      width: 254px;
      height: 51px;
      background-image: url('../../assets/kaiyuanchanping/allchanpin.png');
      position: absolute;
      left: 57px;
      top: 48px;
    }

    .chanpinimgtext {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      position: absolute;
      left: 54px;
      top: 115px;
    }
  }

  .product {
    width: 100%;
    margin-top: 34px;
    margin-bottom: 58px;
    background: #EFF7FF;

    .allproduct {
      width: 87%;
      height: 100%;
      margin: 0 auto;
    }

    .jilian {
      width: 680px;
      display: flex;
      justify-content: space-between;
      margin-left: -8px;
    }

    .productcentents {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: -12px;

      .product1 {
        height: 100%;
        margin-top: 40px;
        margin-right: 63px;

        .productimg {
          width: 350px;
          height: 280px;


          img {
            width: 350px;
            height: 280px;
            border-radius: 8px;

          }
        }

        .productext {
          width: 350px;
          height: 153px;
          background: #FFFFFF;
          border-radius: 0px 0px 16px 16px;


          .producone {
            padding-top: 10px;
            font-size: 23px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #000024;
            line-height: 40px;
            margin-left: 18px;
            white-space: nowrap;
            /* 禁止文字换行 */
            overflow: hidden;
            /* 隐藏超出容器部分的文字 */
            text-overflow: ellipsis;
            /* 当文字超出容器宽度时显示省略号 */


          }

          .productwo {
            margin-left: 18px;

            .productwo_money {
              float: left;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FF011B;
              line-height: 32px;
            }

            .productwo_renqi {
              float: right;
              margin-right: 19px;

              span {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                line-height: 2px;
              }
            }
          }

          .producthree {
            margin-left: 18px;
            width: 100%;
            text-align: center;
            display: inline-flex;
            margin-top: 20px;

            .button1 {
              margin-right: 10px;
              width: 90px;
              height: 32px;
              background: linear-gradient(90deg, #0D68FC 0%, #2597FF 100%);

              span {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 32px;

              }
            }

            .button2 {
              margin-right: 65px;
              width: 90px;
              height: 32px;
              background: #EBF1FF;

              span {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #1A6AFF;
                line-height: 32px;
              }
            }

            .button3 {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .more {
    width: 100%;
    padding: 34px 0;
    background: #EFF7FF;

    .button4 {
      width: 252px;
      height: 78px;
      background-image: url('../../assets/kaiyuanchanping/chakanmore.png');
    }
  }

  /deep/ .el-select .el-input__inner {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1A6AFF;
    line-height: 2px;
  }

  /deep/.el-input__wrapper {
    width: 237px;
    height: 48px;
    background: #F6F9FF;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
  }

  /deep/.el-select .el-input .el-select__caret.el-icon {
    font-size: 20px;
  }

  /deep/.el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #0D68FC;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin: 10px 0;

  }

  /deep/.el-select-dropdown__item {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666;
    margin: 10px 0;
  }
  .qingkong {
  	background: #e8ebee;
  	height: 52px;
  	position: relative;
  	top: 10px;
  	font-size: 18px;
  	margin-left: 20px;
  	line-height: 52px;
  	
  	border-radius: 10px;
  	border: none;
  	color: #999;
  }
</style>