<template>
  <div class="content">
    <div class="top"></div>
    <div class="middle">
      <div class="middlr_text">
        {{content.name}}
      </div>
      <div class="line"></div>
      <div class="middle_con">
        <div class="middle_con_top">
          <div v-html="content.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        concent: {},
        goods_cate_id: '',
        content: {},
      }
    },
    methods: {
      //接受传的参数
      getParams() {
        this.concent = JSON.parse(this.$route.query.name)
        console.log(this.concent, 22222222)
        this.goods_cate_id = this.concent

      },
      //商品详情
      async bydoucument() {
        const {
          data: res
        } = await this.$http.post('index/product/document_info', {
          doc_id: this.goods_cate_id
        })
        if (res.code == 1) {
          this.content = res.data
          console.log(this.concent, '111111111111')
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
        }
      }
    },
    created() {
      this.getParams()
      this.bydoucument()
    }
  }
</script>

<style scoped lang="less">
  .content {
    width: 100%;
    background: #EFF7FF;
    height: 2714px;

    .top {
      width: 100%;
      height: 96px;
      background-color: black;
    }
  }

  .middle {
    width: 1620px;
    height: 2520px;
    background: #FFFFFF;
    margin: 0 auto;
    position: relative;
    top: 35px;

    .middlr_text {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #343434;
      position: absolute;
      top: 87px;
      left: 57px;
    }

    .line {
      width: 1549px;
      height: 1px;
      border: 1px solid #5F8BF5;
      position: absolute;
      top: 135px;
      left: 35px;
    }

    .middle_con {
      width: 80%;
      height: 80%;
      position: absolute;
      top: 170px;
      left: 139px;

      .middle_con_top {
        width: 1475px;
        height: 696px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #343434;
      }
    }
  }
</style>