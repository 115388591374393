<template>
	<div class="cont" ref="imgBox">
		<div class="chatTitle">
			<div class='chatTitleq'>
				<div class="text1">入驻规则</div>
			</div>
			<div class='chatTitleb' @click="over">
				<div class='over'><img src="../../src/assets/affix/suoxiao.png" style="width: 25px ;height: 25px;">
				</div>
			</div>
		</div>
		<div v-html="fuwutioakuamg" v-loading="loading" style="padding: 30px 20px;width: 95%;height: 71%;overflow: auto;"></div>

		<div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 15px;margin-bottom:20px">
			<el-button @click="over()">取 消</el-button>
			<el-button type="primary" @click="ruzhiBtn()">去入驻</el-button>
		</div>

	</div>
</template>
<script>
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		data() {
			return {
				dialogVisible: true,
				loading: false,
				fuwutioakuamg: '',
			};
		},
		methods: {
			// 获取入驻规则
			ruzu() {
				this.loading = true
				this.$http.get('/index/common/sys_common?type_id=246').then(res => {

					this.fuwutioakuamg = res.data.data.value
					this.loading = false
				});
			},
			ruzhiBtn() {
				let isLogin = window.sessionStorage.getItem('token');
				if (!isLogin) {
					ElMessageBox.confirm(
						'当前未登录，是否登录。',
						'提示', {
							confirmButtonText: '去登录',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).then(() => {
						this.$router.push({
							path: 'login',
						})
					}).catch(() => {})
				} else {
					this.$router.push({
						name: 'Settlein',
						query: {
							name: '这是入驻页面',
						}
					})
				}
			},
			over() {
				this.$parent.gzshow = false
				this.$parent.images[0].active = false
			},
		},
		mounted() {
			this.ruzu()
		},
	}
</script>
<style scoped lang="less">
	.cont {
		border-radius: 20px 20px 0px 0px;
		height: 84vh;
		width: 900px;
		position: fixed;
		top: 10%;
		left: 25%;
		z-index: 999;
		background: #FFFFFF;
		background-repeat: no-repeat;
		


		.touxing {
			width: 56px;
			height: 56px;
			border-radius: 50%;
			position: absolute;
			top: 402px;
			left: 211px;
		}

		.neirong {
			width: 312px;
			height: 180px;
			position: absolute;
			top: 470px;
			left: 90px;
			align-items: center;

			.num {
				text-align: center;
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.box_img {
				text-align: center;

				img {
					width: 160px;
					height: 160px;
				}
			}
		}

		.anniu {
			width: 313px;
			height: 48px;
			justify-content: space-around;
			position: absolute;
			top: 715px;
			left: 90px;
			display: flex;
			flex-wrap: nowrap;

			.anniu1 {
				width: 110px;
				height: 40px;
				background: #FFFFFF;
				border: 1px solid #FFFFFF;
				border-radius: 8px;
				text-align: center;

				text {
					line-height: 40px;
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FD4F4A;
				}
			}

			.anniu2 {
				width: 110px;
				height: 40px;
				background: #FFFFFF;
				border: 1px solid #FFFFFF;
				border-radius: 8px;
				text-align: center;

				text {
					line-height: 40px;
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FD4F4A;
				}
			}
		}
	}
	.chatTitle {
	  // width: 500px;
	  height: 82px;
	  background: #0D68FC;
	  border-radius: 20px 20px 0px 0px;
	  display: flex;
	  flex-wrap: nowrap;
	position: relative;
	  .chatTitleq {
	    width: 100%;
	    height: 100%;
	text-align: center;
	    .text1 {
	      font-size: 18px;
	      font-family: Source Han Sans CN;
	      font-weight: 400;
	      color: #FFFFFF;
	      margin: 30px 0 0 30px;
	    }
	
	    
	  }
	
	  .chatTitleb {
	    // width: 50%;
	    height: 100%;
	    z-index: 999;
		position: absolute;
		
	    cursor: pointer;
	
	    .over {
	      position: relative;
	
	      img {
	        position: absolute;
	        top: 30px;
	        left: 860px;
	      }
	    }
	  }
	
	}
</style>