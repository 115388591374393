<template>
  <div class="content">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <div class="search-left">
            <div class="search-left-item">
              所属产品
              <el-select size="default" style="margin-left: 10px;width: 150px;" v-model="productvalue"
                @change="suoshuproduct" class="m-2 input__wrapper" placeholder="Select">
                <el-option v-for="item in product" :key="item.value" :label="item.label" :value="item.id" />
              </el-select>
            </div>
            <div class="search-left-item">
              订单状态
              <el-select size="default" style="margin-left: 10px;width: 150px;" v-model="optionsvalue" @change="dingdan"
                class="m-2 input__wrapper" placeholder="Select">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.id" />
              </el-select>
            </div>
            <div class="search-left-item">
              产品名称
              <el-input v-model="orderName" style="margin-left: 10px;width: 150px;" placeholder="请输入产品名称 " />
            </div>
            <div class="search-left-item">
              订单编号
              <el-input v-model="orderNumber" style="margin-left: 10px;width: 150px;" placeholder="请输入订单编号" />
            </div>
            <div class="search-left-item">
              <el-button type="primary" style="width: 110px;" color="#0D68FC" @click="chaxun()">查询</el-button>
            </div>
            <div class="search-left-item">
              <el-button type="primary" style="width: 110px;" color="#0D68FC" @click="chongzhi()">重置</el-button>
            </div>

          </div>
          <div class="search-right" @click="shopp">
            去商场逛逛
            <el-icon size="14" style="margin-left: 6px;" color="#0D68FC">
              <ArrowRightBold />
            </el-icon>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- tabs -->
    <el-row>
      <el-col :span="24">
        <div class="tabs">
          <div v-for="(item, index) in tabs" :key="index" @click="selectCurrent(index,item)"
            :class="tabsCurrent == index ? 'tabsActive' : ''" class="tabs-item">
            {{ item.name }}
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- table -->
    <el-row>
      <el-col :span="24">
        <div class="tables">
          <!-- 表头 -->
          <div class="tables-head">
            <div class="tables-head-item" v-for="(item, index) in tables" :key="index">
              {{ item }}
            </div>
          </div>
          <!-- 表数据 -->
          <div class="tables-list">
            <div class="tables-list-item" v-for="(item, index) in tableData" :key="index">
              <div class="tables-list-itemt">
                <div class="tables-list-itemt-l">
                  <text>{{item.create_time}}</text>
                  <text>订单号：{{item.order_num}}</text>
                  <text style="margin-left: 5px;">
                    <div class="fuzhuanniu" @click="fuzhi(item.order_num)">
                      <img src="../../assets/fz.png" alt="" />
                    </div>
                  </text>
                </div>
              </div>
              <div class="tables-list-itemb">
                <!-- 订单详情 -->
                <div class="tables-list-items">
                  <div class="tables-list-items-l">
                    <img :src="item.goods_info.image" />
                  </div>
                  <div class="tables-list-items-r">
                    <div class="tables-list-items-r-t" style="width: 299px;">
                      <b style="font-size: 20px;"> {{item.goods_info.title}}</b>
                    </div>
                    <div class="tables-list-items-r-n" style="font-size: 16px;">
                      月销量 {{item.goods_info.sales}}
                    </div>
                  </div>
                </div>
                <!-- 单价(元) -->
                <div class="tables-list-items" style="font-size: 20px;">
                  {{item.goods_money}}
                </div>
                <!-- 数量 -->
                <div class="tables-list-items" style="font-size: 20px;">
                  {{item.goods_num}}
                </div>
                <!-- 实付款 -->
                <div class="tables-list-items" style="font-weight: bold;">
                  ¥{{item.pay_money}}
                </div>
                <div class="tables-list-items">
                  <div class="tables-list-items-status" style="font-size: 20px;">
                    <span v-if="item.status==1">待付款</span>
                    <span v-if="item.status==2">已付款</span>
                    <span v-if="item.status==3">已发货</span>
                    <span v-if="item.status==4">待评价</span>
                    <span v-if="item.status==5">已评价</span>
                    <span v-if="item.status==6">退款中</span>
                    <span v-if="item.status==7">已退款</span>
                    <span v-if="item.status==8">拒绝退款</span>
                    <span v-if="item.status==9">已取消</span>
                  </div>
                  <div class="tables-list-items-order" @click="push(item)">
                    订单详情
                  </div>
                </div>
                <div class="tables-list-items buttondisplay">
                  <div
                    v-if="item.status==1 || item.status==2 ||  item.status==3||item.status==4 || item.status==5 ||  item.status==6 ">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC" @click="kefu(item)">联系客服</el-button>
                  </div>
                  <div v-if=" item.status==3 ">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC" @click="sure(item)">确认收货</el-button>
                  </div>
                  <div v-if="item.status==9 ||  item.status==5 || item.status==4  ">
                    <el-button type="danger" style="width: 70px;" @click="updelete(item)">删除订单</el-button>
                  </div>
                  <div v-if="item.status==5 || item.status==4  ">
                    <el-button type="primary" style="width: 70px;" @click="openfapiao(item)">开发票</el-button>
                  </div>
                  <div v-if="item.status==4">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC" @click="huifu(item)">去评价</el-button>
                  </div>
                  <div v-if="item.status==1">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC"
                      @click="buyzhifu(item)">去支付</el-button>
                  </div>
                  <div v-if="(item.status == 4 || item.status == 5) && (item.is_accredit == '')">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC"
                      @click="shouquan(item)">获取授权</el-button>
                  </div>
                  <div v-if="(item.status == 4 || item.status == 5) && (item.is_accredit != '')">
                    <el-button type="primary" style="width: 70px;" color="#0D68FC" @click="dowmxiazai(item)">
                      <span v-if="item.is_accredit==1">审核中</span>
                      <span v-if="item.is_accredit==2">下载证书</span>
                      <span v-if="item.is_accredit==3">拒绝</span>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 站位图 -->
    <div v-if="!tableData.length" style="text-align: center; position: absolute; top: 160px;left: 777px;">
      <img src="../../assets/dhpbj.png" alt="" />
      <div style="margin: 10px 0 20px 0;">暂无数据</div>
    </div>
    <!-- 分页 -->
    <el-row v-else>
      <el-col :span="24">
        <div class="pages">
          <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
            :current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="dialogVisible" title="评价" center>
      <div style="margin-bottom: 10px;">
        <span style="width: 200px;display: inline-block;text-align: right;">评价：</span>
        <el-input style="width:50%;" v-model="shopReplyMessage" type="textarea" placeholder="请输入评价内容"></el-input>
      </div>
      <div class="demo-rate-block">
        <span style="width: 200px;display: inline-block;text-align: right;">评分:</span>
        <el-rate v-model="score" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="refuseto()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 授权弹窗 -->
    <el-dialog v-model="dialogVisiblesq" title="授权" center>
      <div style="margin-bottom: 10px; text-align: center;">
        <div style="margin-right: 20px;width: 180px;display: inline-block;text-align: right;">公司名称:</div>
        <el-input style="width:50%;" v-model="company_name" type="text" placeholder="请输入公司名称"></el-input>
      </div>
      <div style="margin-bottom: 10px; text-align: center;">
        <div style="margin-right: 20px;width: 180px;display: inline-block;text-align: right;">法人姓名:</div>
        <el-input style="width:50%;" v-model="user_name" type="text" placeholder="请输入法人姓名"></el-input>
      </div>
      <div style="margin-bottom: 10px; text-align: center;">
        <div style="margin-right: 20px;width: 180px;display: inline-block;text-align: right;">法人身份证号码:</div>
        <el-input style="width:50%;" v-model="card_id" type="text" placeholder="请输入法人身份证号码"></el-input>
      </div>
      <div style="margin-bottom: 10px; text-align: center;">
        <div style="margin-right: 20px;width: 180px;display: inline-block;text-align: right;">社会信用代码:</div>
        <el-input style="width:50%;" v-model="social_code" type="text" placeholder="请输入社会信用代码"></el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" color="#0D68FC" @click="refusetosq()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'haed',
    data() {
      return {
        dialogVisiblesq: false,
        company_name: '',
        card_id: '',
        user_name: '',
        social_code: '',
        total: 6,
        pageSize: 4,
        currentPage: 1,
        productvalue: 'all',
        product: [{
          id: 'all',
          value: '全部',
        }],
        optionsvalue: 'all',
        options: [{
            id: 'all',
            label: '全部',
          }, {
            id: 1,
            label: '待付款'
          },

          {
            id: 2,
            label: '已付款'
          }, {
            id: 3,
            label: '已发货'
          }, {
            id: 4,
            label: '已完成'
          }, {
            id: 5,
            label: '已评价'
          }, {
            id: 6,
            label: '退款中'
          }, {
            id: 7,
            label: '已退款'
          }, {
            id: 8,
            label: '拒绝退款'
          }, {
            id: 9,
            label: '已取消'
          }
        ],
        orderName: '', //订单名称
        orderNumber: '',
        tabs: [{
          id: 'all',
          name: '全部订单'
        }, {
          id: 1,
          name: '待付款'
        }, {
          id: 2,
          name: '已付款'
        }, {
          id: 3,
          name: '已发货'
        }, {
          id: 4,
          name: '待评价'
        }, {
          id: 5,
          name: '已评价'
        }], //类型切换菜单
        tabsCurrent: 0, //tabs索引
        tableData: [], //表单数据
        tables: ['订单详情', '单价(元)', '数量', '实付款', '交易状态', '交易操作'],
        dialogVisible: false,
        order_id: '',
        score: '',
        shopReplyMessage: '',
        purchasedProductss: [],
      }
    },

    methods: {
      async byshopplist() {
        const {
          data: res
        } = await this.$http.get('index/product/get_goods_cate_list')
        var arrNew = []
        arrNew = res.data.map((item) => {
          return {
            id: item.id,
            label: item.name
          }
        })
        let arre = []
        arre = arrNew
        const newObject = {
          id: 'all',
          label: '全部系列'
        }
        arre.unshift(newObject)
        this.product = arre
        console.log(this.product)
      },
      fuzhi(item) {
        this.$notify.success({
          title: "成功",
          message: '复制订单号成功',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = item;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      shopp() {
        this.$router.push({
          path: '/products',
          query: {
            name: '这是开源产品页面',
          }
        })
      },
      chaxun() {
        this.budingdan()
      },
      chongzhi() {
        this.productvalue = 'all'
        this.optionsvalue = 'all'
        this.orderName = ''
        this.orderNumber = ''
        this.budingdan()
      },
      selectCurrent(index, item) {
        this.optionsvalue = item.id
        console.log(this.optionsvalue)
        this.budingdan()
        this.tabsCurrent = index
      },
      suoshuproduct(value) {
        this.productvalue = value
        this.budingdan()
      },
      dingdan(value) {
        this.optionsvalue = value
        this.budingdan()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.budingdan()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.budingdan()
      },
      async budingdan() {
        const {
          data: res
        } = await this.$http.post('index/order/mine_order_list', {
          goods_cate_id: this.productvalue,
          status: this.optionsvalue,
          order_num: this.orderNumber,
          goods_name: this.orderName,
          num: this.pageSize,
          page: this.currentPage
        })
        if (res.code == 1) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      },
      push(item) {
        if (this.status == 1) {
          this.$notify.info({
            title: "提示",
            message: "你还未支付改商品,请先支付完成后才能才看",
            duration: 1500,
            offset: 50
          });
          return
        }
        this.$router.push({
          name: 'OrderDetails',
          query: {
            item: item.id
          }
        })

      },
      kefu(item) {
        var userid = this.$cookie.get('userid')
        this.$router.push({
          name: 'chatindex',
          params: {
            userid: userid,
          }
        })
      },
      sure(item) {
        this.$confirm(`确定收货吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('index/order/user_confirm_receipt', {
            order_id: item.id
          }).then((res) => {
            this.$notify.success({
              title: "收货提示",
              message: '收货成功',
              duration: 2500,
              offset: 50
            });
            this.budingdan()
          })
        }).catch(() => {})
      },
      openfapiao(item) {
        this.$http.post('/index/order/user_open_invoice', {

          order_id: item.id

        }).then(res => {
          if (res.data.code == 1) {
            this.$notify.success({
              title: '提示',
              duration: 1800,
              message: '审核中...',
            })
            this.budingdan()
          }
        })
      },
      updelete(item) {
        this.$confirm(`确定删除此条订单?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('index/order/del_order', {
            order_id: item.id
          }).then((res) => {
            this.$notify.success({
              title: "删除提示",
              message: '删除成功',
              duration: 2500,
              offset: 50
            });
            this.budingdan()
          })
        }).catch(() => {})
      },
      huifu(item) {
        this.shopReplyMessage = ''
        this.order_id = item.id
        this.dialogVisible = true
      },
      refuseto() {
        if (this.shopReplyMessage == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入回复内容',
            type: 'warning'
          })
          return
        }
        this.$http.post('index/order/user_evaluate_order', {
          order_id: this.order_id,
          content: this.shopReplyMessage,
          score: this.score,
          user_lng: this.$cookie.get('longitude'),
          user_lat: this.$cookie.get('latitude')
        }).then((res) => {
          if (res.data.code == 1) {
            this.$notify.success({
              title: '提示',
              duration: 1800,
              message: '评价成功',
            })
            this.budingdan()
            this.dialogVisible = false
          }
        })
      },
      shouquan(item) {
        this.company_name = ''
        this.card_id = ''
        this.user_name = ''
        this.social_code = ''
        this.dialogVisiblesq = true
        this.order_id = item.id
      },
      dowmxiazai(item) {
        if (item.is_accredit == 3) {
          this.$notify.warning({
            title: '提示',
            duration: 1800,
            message: '请联系客服'
          })
          return
        }
        this.$http.post('/index/empower/user_down_cert', {
          order_id: item.id,
        }).then((res) => {
          if (res.data.code == 1) {
            this.$notify.success({
              title: '提示',
              duration: 1800,
              message: res.data.msg,
            })
          } else {
            this.$notify.warning({
              title: '提示',
              duration: 1800,
              message: res.data.msg,
            })
          }
        })
      },
      refusetosq() {
        if (this.company_name == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入公司名称',
            type: 'warning'
          })
          return
        }
        if (this.card_id == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入法人身份证号',
            type: 'warning'
          })
          return
        }
        //身份证号正则表达式
        var cardidd = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (!cardidd.test(this.card_id)) {
          this.$notify({
            title: '错误',
            duration: 3000,
            message: '身份证号码格式不正确',
            type: 'error'
          });
          return;
        }
        if (this.user_name == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入法人姓名',
            type: 'warning'
          })
          return
        }
        if (this.social_code == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入社会信用代码',
            type: 'warning'
          })
          return
        }
        // 社会信用代码正则表达式
        var socialCodeRegex = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
        if (!socialCodeRegex.test(this.social_code)) {
          this.$notify({
            title: '错误',
            duration: 3000,
            message: '社会信用代码格式不正确',
            type: 'error'
          });
          return;
        }
        this.$http.post('/index/empower/user_apply_certy', {
          order_id: this.order_id,
          company_name: this.company_name,
          card_id: this.card_id,
          user_name: this.user_name,
          social_code: this.social_code,
        }).then((res) => {
          if (res.data.code == 1) {
            this.$notify.success({
              title: '提示',
              duration: 1800,
              message: '获取授权成功',
            })
          }
          this.budingdan()
          this.dialogVisiblesq = false
        })
      },
      //待付款
      buyzhifu(item) {
        this.purchasedProductss.push(item);
        let jsonString = JSON.stringify(this.purchasedProductss);
        window.localStorage.setItem('purchasedProducts', jsonString)
        this.$router.push({
          name: 'empower',
          query: {
            buy_type: 1,
          }
        })
      }
    },
    mounted() {
      this.budingdan()
      this.byshopplist()
    },
  }
</script>
<style lang="less" scoped>
  .el-input__wrapper {
    background-color: #EEF0F6 !important;
  }

  .el-input__wrapper:focus {
    border: none !important;
  }

  .number {
    background-color: #ffffff !important;
    border: 2px solid #F6F5F7 !important;
    color: #333333 !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 4px !important;
  }

  .btn-prev {
    width: 50px !important;
    height: 50px !important;
    border-radius: 4px !important;
  }

  .btn-next {
    width: 50px !important;
    height: 50px !important;
    border-radius: 4px !important;
  }

  .el-pager {
    .is-active {
      background-color: #0D68FC !important;
      color: #ffffff !important;
    }
  }
</style>
<style lang="less" scoped>
  .content {
    padding: 30px;
  }

  .search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-left {
      display: flex;
      align-items: center;
      color: #333333;
    }

    .search-left-item {
      display: flex;
      align-items: center;
      margin-right: 50px;
      white-space: nowrap;
    }

    .search-right {
      color: #0D68FC;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .tabsActive {
    color: #0D68FC !important;
    border-image: linear-gradient(90deg, #2679FF 0%, #61B4FF 100%) 1 !important;
  }

  .tabs {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;

    .tabs-item:hover {
      color: #0D68FC;
      border-image: linear-gradient(90deg, #2679FF 0%, #61B4FF 100%) 1;
    }

    .tabs-item {
      margin-right: 70px;
      color: #666666;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      border-bottom: 4px solid;
      border-color: #ffffff;
      padding-bottom: 4px;
      transition: color 0.5s ease;
      transition: border-image 0.5s ease;
    }
  }

  .tables {
    width: 100%;
    margin-top: 20px;

    // border: 1px solid #ebeef5;
    .tables-head {
      width: 100%;
      height: 30px;
      background-color: #EEF0F6;
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      .tables-head-item {
        width: calc((100% - 500px) / 5);
      }

      .tables-head-item:nth-of-type(1) {
        width: 460px;
        padding: 0 20px;

      }

      .tables-head-item:nth-of-type(6) {
        // padding-right: 20px;
      }

    }

    .tables-list {
      width: 100%;

      .tables-list-item {
        width: 100%;
        border: 1px solid #ebeef5;
        margin-bottom: 20px;
      }

      .tables-list-itemt {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ebeef5;

        .tables-list-itemt-l {
          display: flex;
          align-items: center;
          line-height: 40px;

          text:nth-of-type(1) {
            padding-left: 20px;
            margin-right: 30px;
            color: #666666;
            font-size: 16px;
            font-weight: 500;
          }

          text:nth-of-type(2) {
            color: #666666;
            font-size: 14px;
          }
        }
      }

      .tables-list-itemb {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .tables-list-items {
        width: calc((100% - 500px) / 5);
        // padding: 10px 20px;
      }

      .tables-list-items-status {
        color: #0D68FC;
        font-size: 14px;
      }

      .tables-list-items-order {
        color: #333333;
        font-size: 14px;
        margin-top: 16px;
        cursor: pointer;
      }

      .tables-list-items:nth-of-type(1) {
        width: 460px;
        display: flex;
        align-items: flex-start;
        padding: 10px 20px;

        .tables-list-items-l {
          img {
            width: 100px;
            height: auto;
          }
        }

        .tables-list-items-r {
          margin-left: 10px;
        }

        .tables-list-items-r-t {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
        }

        .tables-list-items-r-n {
          color: #666666;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }

  .pages {
    margin-top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttondisplay {
    display: flex;
    flex-wrap: wrap;

    button {
      margin: 5px;
    }
  }

  .fuzhuanniu {
    width: 30px;
    height: 20px;
    text-align: center;

    img {
      margin-left: 5px;
      display: block;
      width: 20px;
      height: 20px;

    }
  }
</style>