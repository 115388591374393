<template>
  <div class="qiyewenhaun">
    <div class="qiyewenhuan_card">
      <el-carousel :loop="true" :autoplay="true" class="carousel" indicator-position="outside" height="650px">
        <el-carousel-item class="el-car-item" v-for="(list, index) in qiyewenhaunist" :key="index">
          <div v-for="(imgList,index1) in list" :key="index1" class="divSrc">
            <div class="title">
              <div class="title_top" :class="['carousel-item', { 'active': index1 === activeIndex }]"
                @mouseenter="activeIndex = index1" @mouseleave="activeIndex=-1">
                <img class="img" :src="imgList.image" />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

</template>

<script>
  import Swiper from 'swiper';

  export default {
    name: "HomeView",
    data() {
      return {
        qiyewenhaunist: [],
        activeIndex: -1,
      };
    },
    mounted() {
      this.byqiyewenhua()
    },
    methods: {
      async byqiyewenhua() {
        const {
          data: res
        } = await this.$http.get('index/index/index_title_love_road')
        if (res.code == 1) {
          this.qiyewenhaunist = res.data.index_content_list
          this.lovename = res.data.title
          this.lovenameN = res.data.description
        } else {
          this.$notify.error({
            title: "页面提示",
            message: "接口报错请重新登录",
            duration: 1500,
            offset: 50
          });
        }
        let newDataList = [];
        let current = 0
        if (this.qiyewenhaunist && this.qiyewenhaunist.length > 0) {
          for (let i = 0; i <= this.qiyewenhaunist.length - 1; i++) {
            if (i % 4 !== 0 || i === 0) { //数据处理成几张展示
              if (!newDataList[current]) {
                newDataList.push([this.qiyewenhaunist[i]])
              } else {
                newDataList[current].push(this.qiyewenhaunist[i])
              }
            } else {
              current++
              newDataList.push([this.qiyewenhaunist[i]])
            }
          }
        }
        this.qiyewenhaunist = [...newDataList]
      }
    }
  };
</script>

<style lang="less" scoped>
  .qiyewenhaun {
    width: 100%;
    height: 650px;
    margin-top: 30px;

    .qiyewenhuan_card {
      width: 100%;
      height: 650px;
      margin: 0 auto;
      margin-top: 50px;

      .carousel {
        width: 100%;
        height: 650px;

        .el-car-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .divSrc {
            width: 400px;
            height: 584px;

            .title {
              // flex: 1;
              margin: 0;

              .title_top {
                img {
                  width: 400px;
                  height: 584px;
                  margin-top: 35px;
                  transition: 300ms;
                  transform: scale(0.8);
                  box-shadow: 0 0px 40px rgba(0, 0, 0, .1);
                  border-radius: 4px;
                  cursor: pointer;
                }
              }

              .carousel-item {
                padding: 10px;
                transition: transform 0.3s ease;
              }

              .carousel-item.active {
                img {
                  transform: scale(1.2);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
</style>