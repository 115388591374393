<template>
  <div class="myuser">
    <div class="boxuser flex justify-center">
      <div class="box-c">
        <el-row>
          <el-col :span="3">
            <el-menu background-color="#F6F9FF" active-text-color="#0D68FC" :default-openeds="openeds"
              class="el-menu-vertical-demo" :collapse="isCollapse">
              <el-sub-menu :index="index" v-for="(item,index) in list" :key="index">
                <template #title>
                  <img :src="item.imgae" class="left-icon" alt="">
                  <span class="left-icon-title">{{item.name}}</span>
                </template>
                <el-menu-item @click="clickMenuItem(ite.router)" :index="ite.id+'-'+index" style="margin-left: 10px;"
                  v-for="(ite,ind) in item.childer" :key="ind"
                  :style="{ color: xxx==ite.router ? '#0D68FC' : '#301223' }">
                  {{ite.name}}
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
          </el-col>
          <el-col :span="21">
            <div class="right flex justify-end">
              <view class="right-box">
                <!-- 此处放路由标签 -->
                <router-view></router-view>
              </view>
            </div>

          </el-col>
        </el-row>
      </div>

    </div>
    <!-- <div class="mydata">
			<myData></myData>
		</div> -->
  </div>
</template>

<script>
  import myData from '../../components/Userdata.vue'
  export default {
    components: {
      myData
    },
    name: 'my',
    data() {
      return {
        xxx: '',
        router: '',
        openeds: [0, 1, 2, 3],
        isCollapse: false,
        list: [{
          id: 1,
          name: '产品授权',
          imgae: require("../../assets/index/cpsq.png"),
          childer: [{
            id: 1,
            name: '授权列表',
            router: '/my/Authorization',
          }, {
            id: 2,
            name: '购买产品',
            router: '/my/empower', //路由路径
          }]
        }, {
          id: 2,
          name: '订单中心',
          imgae: require('../../assets/index/dz.png'),
          childer: [{
            id: 1,
            name: '我的订单',
            router: '/my/order',
          }]
        }, {
          id: 3,
          name: '资产管理',
          imgae: require('../../assets/index/zc.png'),
          childer: [{
            id: 1,
            name: '我的余额',
            router: '/my/mymoney',
          }, {
            id: 2,
            name: '我的优惠劵',
            router: '/my/coupon',
          }, {
            id: 3,
            name: '分销管理',
            router: '/my/distribution',
          }, {
            id: 4,
            name: '我的团队',
            router: '/my/team',
          }]
        }, {
          id: 4,
          name: '信息管理',
          imgae: require('../../assets/index/xx.png'),
          childer: [{
              id: 1,
              name: '个人资料',
              router: '/my/user',
            },
            //      {
            // 	id: 2,
            // 	name: '发票管理',
            // 	router: '/my/invoice',
            // }, 
            {
              id: 3,
              name: '开票记录',
              router: '/my/Invoicing',
            }
          ]
        }],

      }
    },
    mounted() {
      let wWidth = window.innerWidth
      if (wWidth < Number(480)) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
      this.xxx = this.$route.query.name
      console.log(this.xxx)
    },
    beforeUpdate() {
      this.xxx = this.$route.query.name
      // console.log( this.xxx)
    },
    methods: {
      clickMenuItem(e) {
        this.xxx = e
        console.log(e)
        this.$router.push({
          path: e,
          query: {
            name: e,
          }
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .color1 {
    color: #0D68FC;
    /* 你想要的第一种颜色 */
  }

  .color2 {
    color: #333;
    /* 你想要的第二种颜色 */
  }

  /deep/.el-menu {
    border: none !important;
  }

  /deep/.el-menu-item:hover {
    background-color: #E7F0FF !important;
  }

  .myuser {
    background: #F6F9FF;
    color: #333333;
    // height: 100vh;
    // position: relative;
    // top: 70px;
    // margin-top: 75px;
    padding-top: 100px;

  }

  .boxuser {
    padding-top: 20px;

    .box-c {
      width: calc(100% - 60px);
    }

    .left-icon {
      width: 16px;
      height: auto;
      margin-right: 12px;
    }

    .left-icon-title {
      font-size: 20px;
      font-weight: 900;
    }

    .right {
      width: 100%;
      // height: 800px;
      min-height: 800px;

      .right-box {
        width: calc(100% - 20px);
        // height: 800px;
        min-height: 800px;
        background-color: #ffffff;
        border-radius: 20px;
      }
    }
  }


  .mydata {
    margin-top: 55px;
  }
</style>