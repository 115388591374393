<template>
  <div class="content">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <div class="swiper-item">
          <img src="../../assets/kaiyuanchanping/banner1.png" alt="">
          <div class="killtitle">
            限时秒杀！
            <div class="killtitle1">
              低价买好物，限时不限量
            </div>
          </div>
          <div>
            <el-popover :visible="visible" placement="right" :width="470" :offset='100'
              style="position: absolute;top:200px">
              <img src="../../assets/kaiyuanchanping/chacah.png" style="float: right;width: 13px;height:13px;"
                @click="visible = false">
              <p
                style="font-size: 24px;text-align: center;font-family: Source Han Sans CN;font-weight: 500;color: #333333;">
                邀请好友</p>
              <img :src="qrCodeUrl" style="width: 306px;height: 306px; margin: 20px 74px;;">
              <div style="text-align: center; margin: 0 auto">
                <el-button type="primary" style="width: 137px;height: 46px;background: #0D68FC;border-radius: 23px;"
                  @click="shareLink">
                  链接分享</el-button>
              </div>
              <template #reference>
                <el-button @click="invite()" class="button1"><span>立即分享</span></el-button>
              </template>
            </el-popover>
          </div>
        </div>
      </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="xitong flex justify-center align-center">
      <div class="xitongconcent">
        <div class="xitong1" v-for="(item,index) in  xitong" :key="index">
          <div class="xitongimg">
            <img :src="item.cover_img" alt="" @click="skilldemo(item)">
          </div>
          <div class="xitongtext">
            <div class="title-xt">
              {{item.title}}
            </div>
            <div class="money-xt">
              <span class="money1">￥{{item.member_price}}</span>
              <span class="money2">￥{{item.original_price}}</span>
            </div>
            <div class="progress-xt">
              <div class="progress-xt1">
                <el-progress :percentage="50" status="exception"
                  color="linear-gradient(-90deg, #FF4566 19%, #FF8074 100%);" :text-inside="true" :show-text="false" />
              </div>
              <div class="progress-xt2">
                仅剩  {{ item.date}}
              </div>
            </div>
            <div class="button-xt">
              <el-button class="button3" @click="buy(item)"><span>立即抢购</span></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <affix></affix>
</template>

<script>
  import affix from '../../components/affix.vue'
  import QRCode from 'qrcode';
  export default {
    name: "flashKill",
    components: {
      affix
    },
    data() {
      return {
        visible: false,
        isTextChanging: false,
        swiperIndex: 0,
        swiperPhone: require('../../assets/index/swiperPhone.png'),
        gg: '',
        xitong: [],
        purchasedProducts: [],
        invitation_code: '',
        qrcode_url: '',
        qrCodeUrl: ''
      }
    },
    methods: {
      async invite() {
        const {
          data: res
        } = await this.$http.post('/index/user/test_qrcode')
        this.invitation_code = res.data.invitation_code
        this.qrcode_url = res.data.qrcode_url
        if (this.qrcode_url) {
          this.geterimg()
        }
        this.visible = true
      },
      geterimg() {
        const qrCodeData = this.qrcode_url;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "官方提示",
              message: "二维码生成有误,请联系客服",
              duration: 1500,
              offset: 50
            });
          } else {
            this.qrCodeUrl = url;
          }
        });
      },
      async shareLink() {
		  const text = this.qrcode_url
		  const textarea = document.createElement('textarea')
		  textarea.value = text;
		  document.body.appendChild(textarea)
		  textarea.select();
		  try{
		  	const success = document.execCommand('copy');
		  	if(success){
		  		this.$notify({
		  			title: "提示",
		  			message: "复制成功",
		  			duration: 1500,
		  			type: 'success',
		  		});
		  	}else{
		  		this.$notify({
		  			title: "提示",
		  			message: "复制失败",
		  			duration: 1500,
		  			type: 'warning',
		  		});
		  	}
		  }catch(e){
		  	this.$notify({
		  		title: "提示",
		  		message: "复制失败",
		  		duration: 1500,
		  		type: 'warning',
		  	});
		  }
		  
		  document.body.removeChild(textarea)
        // try {
        //   const link = this.qrcode_url; // 将这里替换为你要分享的实际链接  
        //   await navigator.clipboard.writeText(link);
        //   this.$notify.success({
        //     title: "官方提示",
        //     message: "链接已复制到剪贴板，可以粘贴到其他地方进行分享",
        //     duration: 1500,
        //     offset: 50
        //   });
        // } catch (error) {
        //   this.$notify.error({
        //     title: "官方提示",
        //     message: "无法复制链接到剪贴板，请手动复制链接进行分享",
        //     duration: 1500,
        //     offset: 50
        //   });
        // }
      },
      selectSwiper(index) {
        this.swiperIndex = index
        // this.classNames = 'animate__fadeInUp'
        this.isTextChanging = !this.isTextChanging;
        setTimeout(() => {
          // this.swiperIndex = index
          // this.isTextChanging = false;
        }, 100);
      },
      //点击切换背景
      divCLick(index) {
        for (let i in this.product) {
          if (index == i) {
            this.product[i].isActive = !this.product[i].isActive
          } else this.product[i].isActive = false;
        }
      },
      colorDiv(item) {
        if (item.isActive == true) {
          return {
            'background-color': '#FFFFFF'
          }
        } else {
          return {
            'background-color': '#FAFAFA'
          }
        }
      },
      //进入产品介绍页面
      skilldemo(item) {
        this.purchasedProducts.push(item);
        let jsonString = JSON.stringify(this.purchasedProducts);
        window.localStorage.setItem('purchasedProducts', jsonString)
        window.localStorage.setItem('buy_type', 2)
        var itemcon = item.sec_kill_id
        this.$router.push({
          name: 'skillProductIntroduction',
          query: {
            item: itemcon
          }
        })
      },
      buy(item) {
        let userId = window.sessionStorage.getItem('token');
        if (userId == '' || userId == null) {
          this.$notify.info({
            title: "提示",
            message: '请进行登录',
            duration: 3500,
            offset: 50
          });
          return;
        } else {
          this.purchasedProducts.push(item);
          let jsonString = JSON.stringify(this.purchasedProducts);
          window.localStorage.setItem('purchasedProducts', jsonString)
          this.$router.push({
            name: 'empower',
            query: {
              buy_type: 2,
              name: '/my/empower'
            }
          })
        }
      },
      //系统专区
      async bymiaosha() {
        const {
          data: res
        } = await this.$http.post('index/product/kill_goods_list', {
          num: 10,
          page: 1
        })
        if (res.code == 1) {
          var xitonglist = res.data.data
          for (let i = 0; i < xitonglist.length; i++) {
            xitonglist[i].isActive = false
          }
          this.xitong = xitonglist
		  if(this.xitong.length>0){
		  			  // var times = this.xitong[0].end_time
		  			  // this.hr = this.convertTimestamp(this.xitong[0].start_time-this.xitong[0].end_time)*1 
		  			  // this.countdown(times)
		  			  this.countdownList(this.xitong);
		  			  
		  }
          // console.log(this.xitong)
        }
      },
      //仅剩时间
      convertTimestamp(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从0开始，需要加1
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return ` ${hours}`;
      },
	countdownList(list) {
			  for(var i in list){
			  				  // console.log("-----",)
			  				 // this.xitong[i].hr =   this.convertTimestamp(this.xitong[i].start_time-this.xitong[i].end_time)*1 
			  				  var times2 = list[i].end_time
			  				  const end = times2*1000
			  				  const now = Date.parse(new Date())
			  				  // console.log((times)+';',end,'----',now)
			  				  if (now >= end) {
			  				    list[i].day = 0
			  				    list[i].hr = 0
			  				    list[i].min = 0
			  				    list[i].sec = 0
			  				    return
			  				  }
			  				  // 用结束时间减去当前时间获得倒计时时间戳
			  				  const msec = end - now
			  				  
			  				  let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
			  				  let hr = parseInt(msec / 1000 / 60 / 60 % 24) //算出小时数
			  				  let min = parseInt(msec / 1000 / 60 % 60) //算出分钟数
			  				  let sec = parseInt(msec / 1000 % 60) //算出秒数
			  				  
			  				  //给数据赋值
			  				  list[i].day = day
			  				  list[i].hr = hr > 9 ? hr : '0' + hr
			  				  list[i].min = min > 9 ? min : '0' + min
			  				  list[i].sec = sec > 9 ? sec : '0' + sec
			  				  // console.log("***************"+day+' '+hr+':'+min+':'+sec)
							  if(day>0){
								  list[i].date=day+'天'+hr+'小时'
							  }else{
								  if(hr>0){
									  list[i].date=hr+'小时'
								  }else{
									  list[i].date=min+'分'
								  }
								  
							  }
			  				  
			  				  
			  				  // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
			  				  // setTimeout(function() {
			  				  //   that.countdown(times1)
			  				  // }, 1000)
			  }
			  this.xtiong=list
	  var that=this;
	  // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
	  setTimeout(function() {
	    that.countdownList(list)
	  }, 1000)
	},

    },
    mounted() {
      this.bymiaosha()
      const format = (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`)
    },
  }
</script>

<style lang="less" scoped>
  .content {
    background: #EFF7FF;
  }

  .swiper {
    width: 100%;
    height: 1400rpx;
    position: relative;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 8px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 100rpx;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .killtitle {
        position: absolute;
        top: 171px;
        left: 163px;
        font-size: 150px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #EAF2FF;
        background: linear-gradient(-90deg, #0055FF 0.634765625%, #3E81FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .killtitle1 {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2973FB;
        }
      }

      .button1 {
        position: absolute;
        top: 450px;
        left: 136px;
        width: 224px;
        height: 80px;
        background: #FFFFFF;
        border: 2rpx solid #63FFFD;
        border-radius: 40px;
        text-align: center;

        span {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          line-height: 80px;
        }
      }
    }
  }

  .xitong {
    width: 100%;
    height: 500px;
    flex-wrap: wrap;
    margin-top: 10px;
    // margin-bottom: 103px;
    background: #EFF7FF;

    .xitongconcent {
      width: 87%;
      height: 500px;
      display: flex;
      justify-content: space-between;
    }

    .xitong1 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .xitongimg {
        width: 100%;
        height: 242px;

        img {
          width: 350px;
          height: 280px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .xitongtext {
        width: 350px;
        height: 230px;
        background-color: #FFFFFF;
        position: relative;
        border-radius: 0px 0px 16px 16px;

        .title-xt {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000024;
          line-height: 36px;
          margin-top: 10px;
          margin-left: 18px;
        }

        .money-xt {
          margin-left: 18px;
          margin-bottom: 10px;
          margin-top: 22px;

          .money1 {
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FF011B;
            line-height: 2px;
          }

          .money2 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #B3B3B3;
            line-height: 2px;
            text-decoration: line-through;
            margin-left: 33px;
          }
        }

        .progress-xt {
          margin-left: 18px;
          width: 100%;
          height: 6px;
          display: flex;

          .progress-xt1 {
            width: 57%;
          }

          .progress-xt2 {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #B3B3B3;
            line-height: 8px;
            margin-left: 37px;
          }
        }

        .button-xt {
          position: absolute;
          top: 150px;
          left: 54px;

          .button3 {
            width: 221px;
            height: 54px;
            background: linear-gradient(-270deg, #FF795F 0%, #FF537D 98%);
            border-radius: 27px;

            span {
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 2px;
            }
          }
        }
      }
    }

    .demo-progress .el-progress--line {
      margin-bottom: 15px;
      width: 350px;
    }
  }
</style>