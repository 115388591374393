<template>
  <div class="content">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
        <div class="swiper-item">
          <!-- 轮播图 -->
          <img src="../assets/ruzhu/banner.png" alt="">
          <!-- 标题 -->
          <div class="killtitle">
            <img src="../assets/ruzhu/title.png" alt="">
            <div style="font-size: 30px; color: aliceblue; margin-top: 30px; font-family: Source Han Sans CN ; font-weight: 900;
              margin-left: 20px;">
              新商激励力度升级 &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 日进斗金不是梦
            </div>
            <div class="killtitle1" @click="ruzhuxiz()">
              <div class='button'>
                <span>了解详情</span>
              </div>
            </div>
          </div>
        </div>
        <!-- </el-carousel-item> -->
      </el-carousel>
    </div>
    <div class="ruzhucont">
      <div class="ruzhu_con" v-if="company_status==0">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
          label-position="left">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 635px;"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号" style="width: 635px;">
            </el-input>
            <span class="yzm" v-if="countdown==0" @click="getcoden">{{msg}}</span>
			<span class="yzm" v-else >{{msg}}</span>
          </el-form-item>
          <el-form-item label="验证码" prop="yzm">
            <el-input v-model="ruleForm.yzm" placeholder="请输入验证码" style="width: 635px;"></el-input>
          </el-form-item>
          <el-form-item label="企业名称" prop="qyname">
            <el-input v-model="ruleForm.qyname" placeholder="请输入企业名称" style="width: 635px;"></el-input>
            <!--  <sapn @click="open()" class="yzm">打开地图</sapn> -->
          </el-form-item>
          <el-form-item label="所属行业" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择选项" :teleported="false" style="width: 655px;">
              <!-- <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option> -->
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 160px; ">
            <el-button @click="submitForm('ruleForm')"
              style="width: 238px;height: 74px;background: #0D68FC;border-radius: 12px;">
              <span
                style="font-size: 28px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">立即入驻</span></el-button>
          </el-form-item>
        </el-form>
      </div>
	  <div class="ruzhu_con" v-if="company_status==1">
	    <el-form :model="ruleData" ref="ruleForm" label-width="120px" class="demo-ruleForm"
	      label-position="left">
	      <el-form-item label="姓名">
	        <el-input v-model="ruleData.name" placeholder="请输入姓名" disabled style="width: 635px;"></el-input>
	      </el-form-item>
	      <el-form-item label="手机号">
	        <el-input v-model="ruleData.mobile" placeholder="请输入手机号" disabled style="width: 635px;">
	        </el-input>
	        
	      </el-form-item>
	      
	      <el-form-item label="企业名称">
	        <el-input v-model="ruleData.company_name" placeholder="请输入企业名称" disabled style="width: 635px;"></el-input>
	      </el-form-item>
	      <el-form-item label="所属行业">
			   <el-input v-model="ruleData.regionName" placeholder="请输入企业名称" disabled style="width: 635px;"></el-input>
	      </el-form-item>
	      <el-form-item style="margin-left: 160px; ">
	        <el-button @click="buyzixun()"
	          style="width: 238px;height: 74px;background: #0D68FC;border-radius: 12px;">
	          <span
	            style="font-size: 28px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">联系客服</span></el-button>
	      </el-form-item>
	    </el-form>
	  </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="" width="30%" center>
      <div class="daio_img">
        <img src="../assets/ruzhu/img1.png" alt="" srcset="">
      </div>
      <div class='text1'>您已提交资料，审核中</div>
      <div class='text2'>审核将在1-3个工作日完成</div>
      <div class='text3' @click="gotoshop()">去商城逛逛</div>
      <template #footer>
        <span class="dialog-footer">
        </span>
      </template>
    </el-dialog>
    <!-- <locmap ref="childRef" @childEvent="handleChildEvent"></locmap> -->
    <el-dialog v-model="dialogVisible" title="入驻细则" width="30%" :before-close="handleClose" center>
      <div v-html="fuwutioakuamg"></div>

    </el-dialog>
  </div>

</template>

<script>
  import {
    ElNotification
  } from 'element-plus'
  import locmap from '../components/map/map.vue'
  export default {
    components: {
      locmap
    },

    data() {
      //验证手机号
      var checkephone = (rule, value, callback) => {
        const phone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (phone.test(value)) {
          return callback()
        }
        callback(new Error('请输入正确的手机号'))
      };
      return {
        fuwutioakuamg: '',
        dialogVisible: false,
        timer: null,
        msg: '获取验证码',
        countdown: 0,
        options: [{
          value: 1,
          label: '个人',
        }, {
          value: 2,
          label: '商户',
        }, {
          value: 3,
          label: '自营',
        }],
        centerDialogVisible: false,
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          phone: '',
          yzm: '',
          qyname: '',
          region: ''
        },
        rules: {
          name: [{
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }, ],
          phone: [{
              //表单验证
              required: true,
              message: '请输入手机号',
              //触发规则
              trigger: 'blur'
            },
            {
              validator: checkephone,
              trigger: 'blur'
            }
          ],
          yzm: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }, ],
          qyname: [{
            required: true,
            message: '请输入企业名称',
            trigger: 'blur'
          }],
          region: [{
            required: true,
            message: '请选择所属行业',
            trigger: 'blur'
          }],
        },
		ruleData:{},
		company_status:0,
      };
    },
    created() {
	this.user()
    },
    methods: {
      ruzhuxiz() {
        this.dialogVisible = true
        this.ruzu()
      },
      ruzu() {
        this.$http.get('/index/common/sys_common?type_id=246').then(res => {

          this.fuwutioakuamg = res.data.data.value

        });
      },
      handleChildEvent(value) {
        // 处理子组件传递的值
        this.ruleForm.qyname = value;
      },
      open() {
        this.$refs.childRef.show();
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/index/company_join', {
              name: this.ruleForm.name,
              mobile: this.ruleForm.phone,
              code: this.ruleForm.yzm,
              company_name: this.ruleForm.qyname,
              industry: this.ruleForm.region,
              province: this.$cookie.get('province') || "",
              city: this.$cookie.get('city') || "",
              area: this.$cookie.get('area') || "",
              longitude: this.$cookie.get('longitude') || "",
              latitude: this.$cookie.get('latitude') || "",

            }).then((res) => {
              console.log(res);
              if (res.data.code == 1) {
                this.centerDialogVisible = true
                setTimeout((res) => {
                  this.centerDialogVisible = false
                  this.ruleForm.name = ''
                  this.ruleForm.phone = ''
                  this.ruleForm.yzm = ''
                  this.ruleForm.qyname = ''
                  this.ruleForm.region = ''
                }, 1500)
              } else {
                this.$notify.error({
                  title: "入驻提示",
                  message: res.data.msg,
                  duration: 3500,
                  offset: 50
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //表单校检
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      getcoden() {
        if (this.ruleForm.phone == '') {
          this.$notify.info({
            title: "验证码",
            message: '请先输入手机号再获取验证码',
            duration: 3500,
            offset: 50
          });
          return;
        }
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.$http.post('/index/sms/send', {
          mobile: this.ruleForm.phone
        }).then(res => {
          if (res.data.code == 1) {
			  this.countdown = 60
            let timer = setInterval(() => {
              this.msg = '剩余(' + --this.countdown + ')秒';
              if (this.countdown <= 0) {
                clearInterval(timer);
                this.msg = '获取验证码';
                this.registercode = '';
              }
            }, 1000);
            this.timer = timer;
          } else {
            this.$notify.error({
              title: "验证码",
              message: '发送验证码失败' + res.data.msg,
              duration: 3500,
              offset: 50
            });
			 this.countdown = 0
          }
        })
      },
      gotoshop() {
        this.$router.push({
          name: 'products',
        })
      },
	  async user() {
	    const {
	      data: res
	    } = await this.$http.post('index/user/user_center')
	    console.log(res)
		if(res.data.company_status!=0){
			if(res.data.company_info){
				if(res.data.company_info.industry=='1'){
					res.data.company_info.regionName = '个人'
				}
				if(res.data.company_info.industry=='2'){
					res.data.company_info.regionName = '商户'
				}
				if(res.data.company_info.industry=='3'){
					res.data.company_info.regionName = '自营'
				}
			}
			this.ruleData = res.data.company_info
			this.company_status = res.data.company_status
			
		}else{
			this.ruleData = {}
			this.company_status = res.data.company_status
		}
	    
	    
	  },
	  buyzixun() {
	  	this.$router.push({
	  	  path: '/chatindex',
	  	})
	  },
    }
  }
</script>


<style lang="less" scoped>
  .content {
    background: #EFF7FF;
  }

  .swiper {
    width: 100%;
    height: 700px;
    position: relative;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .killtitle {
        position: absolute;
        top: 219px;
        left: 118px;

        .killtitle1 {
          .button {
            margin-top: 83px;
            width: 247px;
            height: 80px;
            background: #FFFFFF;
            border-radius: 40px;
            text-align: center;

            span {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #0D68FC;
              line-height: 2px;
              line-height: 80px;
            }
          }
        }
      }

      .button1 {
        position: absolute;
        top: 450px;
        left: 138px;
        width: 229px;
        height: 80px;
        background: #FFFFFF;
        border: 1px solid #63FFFD;
        border-radius: 40px;
        text-align: center;

        span {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          line-height: 80px;
        }
      }
    }
  }

  .ruzhucont {
    width: 100%;
    height: 780px;
    background-color: #EFF7FF;

    .ruzhu_con {
      margin: 0 auto;
      width: 80%;
      height: 718px;
      background: #FFFFFF;
      border-radius: 30px;
      margin-top: 50px;
    }
  }

  .demo-ruleForm {
    position: relative;
    top: 97px;
    left: 365px;
  }

  /deep/.el-form-item {
    margin-bottom: 40px;
  }

  /deep/.el-input__wrapper {
    box-shadow: none;
  }

  /deep/.el-input__inner {
    width: 400px;
    font-size: 20px;
    padding-left: 15px;
    height: 60px;
    border: 1px solid #0D68FC;
    border-radius: 12px;
  }

  /deep/.el-form-item__label {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 64px;
  }

  /deep/.el-form-item__error {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: red;
  }

  /deep/.el-form-item__content {
    margin: 0;
  }

  /deep/.el-dialog--center {
    width: 50%;
    align-items: center;
  }

  /deep/.el-select:hover:not(.el-select--disabled) .el-input__wrapper {
    box-shadow: 0 0 0 1px #ffffff inset !important;
  }

  /deep/.el-form-item.is-error .el-input__wrapper {
    box-shadow: 0 0 0 1px #ffffff inset !important;
  }

  .daio_img {
    margin-left: 39%;
    margin-bottom: 30px;
  }

  .text1 {
    width: 100%;
    height: 39px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 2px;
    text-align: center;
    margin-bottom: 20px;
  }

  .text2 {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    height: 25px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 2px;
  }

  .text3 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0D68FC;
    line-height: 2px;
    cursor: pointer;
  }

  .yzm {
    top: 9px;
    width: 90px;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0D68FC;
    line-height: 2px;
    position: relative;
    right: 118px;
    cursor: pointer;
  }

  /deep/.el-select .el-input .el-select__caret.el-icon {
    position: relative;
    height: inherit;
    z-index: 2;
    left: -36px;
  }
  /deep/.el-input.is-disabled .el-input__wrapper{
	  box-shadow:none;
  }
</style>