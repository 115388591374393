<template>
  <div v-html="apply"></div>
</template>
<script>
  export default {
    name: 'apply',
    data() {
      return {
        apply: ''
      }
    },
    created() {
      let form = this.$route.query.htmls //接收表单内容
      this.apply = form
      console.log(form)
      this.$nextTick(() => {
        document.forms[0].submit(); //自动执行表单提交事件
      })
    }
  }
</script>

<style>
</style>