<template>
  <div class="Serve">
    <div class="top"></div>
    <div class="Serve_Serve">
      <!-- 顶部标签栏 -->
      <div class="chatTitle">
        <div class='chatTitleq'>
          <div class="text1">省钱兄官方智能客服</div>
          <div class="text2">为您解答...</div>
        </div>
        <div class='chatTitleb' @click="over">
          <div class='over'><img src="../../src/assets/affix/suoxiao.png" style="width: 25px ;height: 25px;"> </div>
        </div>
      </div>
      <div class="serve_main" ref="list">
        <div class="chatBoxtishi">
          <div class='chatBoxtishi1'>
            <img src="../../src/assets/affix/gantan.png" alt="">
          </div>
          <div class='chatBoxtishi2'>
            <div class='chatBoxtishi2_1'>
              官方提醒: 请勿轻信不明跳转链接、二维码支付及其他联
              系方式如脱离省钱兄平台交易，支付打款。 资金安全、
              纠纷处理、完善交付将均不受保障!
            </div>
          </div>
        </div>
        <div class="other_user">
          <div class="user_main" v-if='conlust'>
            <div class="user_message" style="display: flex; flex-wrap: wrap;margin-left: 15px;">
              <div v-for="(item,index) in conlust" :key="index"
                style="width: 100%; margin-bottom: 10px; color: #0D68FC; cursor: pointer;" @click="wenda(item)">
                {{ item.problem }}

              </div>
            </div>
          </div>
        </div>
        <div class="message_warp" v-for="(item, index) in messageList" :key="index" style="padding-bottom: 20px;">
          <!-- 客服信息 -->
          <other-chat v-if="item.user == 'other'" :time="item.time" :avatar="item.avatar" :con="item.con"></other-chat>
          <!-- 我的信息 -->
          <my-chat v-else :time="item.time" :avatar="item.avatar" :con="item"></my-chat>
        </div>

      </div>
      <div class="center_wrapper_right" style="border-top: 1px solid #e5e5e5;">
        <div class="icon-list">

          <Emoji ref="getEmoji" @click="changeText" @changeTextZ="changeTexts" style="margin-top:30px;"></Emoji>
          <!-- 上传文件 -->
          <div class="icon-item">
            <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img src="../../src/assets/affix/img.png" alt="">
            </el-upload>
          </div>
        </div>
        <el-input type="textarea" id="textarea" :rows="2" placeholder="请输入内容" v-model="sendVal"
          @keyup.enter.native="send(1)" style="height: 100px; margin-top: -20px;"></el-input>
        <div style="position: absolute; right: 6%; top:128px;">
          <el-button style="width: 79px;height: 33px;font-size: 12px; line-height: 33px;" type="primary" color="#2276fd"
            v-if="sendVal" @click="send(1)">发送
          </el-button>
          <el-button style="width: 79px;height: 33px;font-size: 12px;line-height: 33px;" type="primary" v-else disabled
            @click="send(1)">发送
          </el-button>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  import OtherChat from "./OtherChat";
  import MyChat from "./MyChat";
  import Emoji from "./emoji";
  export default {
    name: "Serve",
    components: {
      OtherChat,
      MyChat,
      Emoji
    },
    data() {
      return {
        messageList: [],
        // 输入框
        sendVal: "",
        concent: '',
        currentDateTime: '',
        userid: '',
        chat_id: '',
        goosdidd: '',
        conlust: [],
        kefu_first_answer: '',
      };
    },
    // computed: {
    //   ...mapState(["userInfo"]),
    // },
    beforeCreate() {
      let now = new Date();
      let month = now.getMonth() + 1; // 月份是从0开始的，所以需要加1
      let date = now.getDate();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      this.time = month + '-' + date + ' ' + hours + ':' + minutes;
    },
    mounted() {
      let randNum = Math.floor(Math.random() * 10) + 1 + '' + new Date().getTime()
      let is_rand = localStorage.getItem('randNum');
      if(!is_rand){
        localStorage.setItem('randNum',randNum); 
      }
	  this.getMessageList();
      this.initMessage();
      this.chat_idd()
      this.getquestion();
      
    },
    methods: {
		getMessageList(){
			let data = {
				rand_num:localStorage.getItem('randNum'),
				num:10,
				page:1,
				search:'',
			}
			this.$http.post('/index/chatroom/customer_service_record',data).then(res => {
			  if (res.data.code == 1) {
				// console.log(this)
				// console.log(res.data.data.data)
			   this.messageList = res.data.data.data.map((item)=>{
				   return  {
					   ...item,  
					   user: item.send_type == 1 ? "user" : "other",   // send_type 1
					   con: item.send_type == 1 ? { con: item.content, type: item.type} : item.content,
					}
			   })
			  }
			})
		},
      getquestion() {
        this.$http.post('/index/common/kefu_config').then(res => {
          if (res.data.code == 1) {
            this.conlust = res.data.data.content_list
            this.kefu_first_answer = res.data.data.kefu_first_answer
          }
        })
      },
      wenda(item) {
        let id = this.$cookie.get('userid')
		    let randNum = localStorage.getItem('randNum')
        let problem = item.problem
        let daan = item.answer
        this.$http.post('index/chatroom/user_send_msgs', {
          content: problem,
		      rand_num:randNum,
          type: 7, //1文字 2图片 3语音  4.表情包  5.商品咨询  6.订单信息  7.快捷问答
          chat_id: this.chat_id,
          voice_length: '', //语音长度
          answer_id: item.id, //快捷问答id
          goods_id: '', //商品id
          order_id: '', //订单id
        }).then((res) => {
          if (res.code = 1) {
            var item = {
              avatar: this.$cookie.get('avatar'),
              time: this.time,
              con: problem,
              user: "my",
              type: 7, //1文字 2图片 3语音  4.表情包  5.商品咨询  6.订单信息  7.快捷问答
            };
            var itemm = {
              avatar: "https://bucket-ans.oss-cn-hangzhou.aliyuncs.com/2.jpg",
              time: this.time,
              con: daan,
              user: "other",
              type: 7, //1文字 2图片 3语音  4.表情包  5.商品咨询  6.订单信息  7.快捷问答
            };
            this.messageList.push(item);
            this.messageList.push(itemm);
            this.sendVal = "";
            this.$nextTick(() => {
              this.$refs.list.scrollTo(0, this.$refs.list.scrollHeight);
            });
          }
        })
        setTimeout(() => {
          // this.messageList.push(other);
          this.$nextTick(() => {
            this.$refs.list.scrollTo(0, this.$refs.list.scrollHeight);
          });
        }, 2000);
      },
      chat_idd() {
        let id = this.$cookie.get('userid')
        if (id) {
          this.$http.post('index/chatroom/return_chat_id', {
            user_id: id,
			      rand_num: localStorage.getItem('randNum')
          }).then(res => {
            if (res.data.code == 0) {
              this.$notify.info({
                title: "聊天室",
                message: res.data.msg,
                duration: 3500,
                offset: 50
              });
            } else {
              this.chat_id = res.data.data.chat_info.chat_id
              window.sessionStorage.setItem('chat_id', this.chat_id)
			  console.log('chatid---',this.chat_id)
            }
          })
        }
      },
      //上传图片
      handleAvatarSuccess(file) {
        this.sendVal = file.data;
        let id = this.$cookie.get('userid')
        let randNum = localStorage.getItem('randNum')
        this.send(2)
      },
      //表情弹框
      changeText() {
        var textArea = document.getElementById('textarea');
        let content = this.$refs.getEmoji.emojiItem;
        
      },
      // 发送表情
      changeTexts(data){
        console.log('-----',data)
        this.sendVal = data;
        this.send(4)
      },
      initMessage() {
        var other = {
          avatar: "https://bucket-ans.oss-cn-hangzhou.aliyuncs.com/2.jpg",
          time: this.time,
          con: '尊敬的客户您好，我是您的专属客服，很高兴能够帮助到您，欢迎向我提问！',
          user: "other",
        };
        setTimeout(() => {
          this.messageList.push(other);
        }, 1000);
      },
      // 发送消息
      send(index) {
        let id = this.$cookie.get('userid')
        let randNum = localStorage.getItem('randNum')
        this.$http.post('index/chatroom/user_send_msgs', {
            content: this.sendVal,
            type: index, //1文字 2图片 3语音  4.表情包  5.商品咨询  6.订单信息  7.快捷问答
            chat_id: this.chat_id,
            rand_num:randNum,
            voice_length: '', //语音长度
            answer_id: '', //快捷问答id
            goods_id: '', //商品id
            order_id: '', //订单id
          }).then((res) => {
            if (res.code = 1) {
              var item = {
                avatar: this.avatrt,
                time: this.time,
                content: this.sendVal,
                user: "my",
                type: index, //1文字 2图片 3语音  4.表情包  5.商品咨询  6.订单信息  7.快捷问答
              };
			  console.log('-------item',item)
              this.messageList.push(item);
              this.sendVal = "";
              this.$nextTick(() => {
                this.$refs.list.scrollTo(0, this.$refs.list.scrollHeight);
              });
            }
          })
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.list.scrollTo(0, this.$refs.list.scrollHeight);
            });
          }, 2000);

      },
      
      over() {
        this.$parent.chatapp = false
        this.$parent.kefua = true
        this.$parent.kefu1 = false
        this.$parent.textColor2 = '#666666'
        this.$parent.images[2].active = false
      },
      
    },
  };
</script>

<style scoped lang="less">
  /deep/.el-textarea__inner {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 39px;

  }

  .Serve {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 500px;
    position: fixed;
    top: 13%;
    left: 68%;
    z-index: 999;
  }

  .chatTitle {
    width: 500px;
    height: 82px;
    background: #0D68FC;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-wrap: nowrap;

    .chatTitleq {
      width: 50%;
      height: 100%;

      .text1 {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 10px 0 0 30px;
      }

      .text2 {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 10px 0 0 30px;
      }
    }

    .chatTitleb {
      width: 50%;
      height: 100%;
      z-index: 999;
      cursor: pointer;

      .over {
        position: relative;

        img {
          position: absolute;
          top: 30px;
          left: 205px;
        }
      }
    }

  }

  .ser_img {
    width: 100%;
  }

  .serve_main {
    flex: 1;
    padding: 0 5px;
    height: 450px;
    overflow-y: scroll;
    background: #FFFFFF;

    .chatBoxtishi {
      width: 440px;
      padding: 0;
      background: #EEF4FF;
      border: 1px solid #0D68FC;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .chatBoxtishi1 {
        width: 70px;
        margin: 0;

        img {
          display: block;
          margin: 40px auto;
        }

      }

      .chatBoxtishi2 {
        flex: 1;
        margin-right: 13px;

        .chatBoxtishi2_1 {
          width: 100%;
          margin: 0 auto;
          margin-top: 4px;
          font-size: 16px;
          margin-top: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          line-height: 27px;
        }
      }
    }
  }

  .chatBottom {
    width: 500px;
    border-top: 1px solid #D6D6D6;
    z-index: 999;

    .chatBottom_chatBottom {
      width: 500px;
      height: 100px;
      background: #fff;
      border-radius: 0 0 20px 20px;
      display: flex;
      flex-wrap: wrap;

      .input {
        width: 500px;
        height: 75px;
        border: none;
        outline: none;
        padding-left: 15px;


      }

      .chatBottom_bottom {
        width: 500px;
        height: 50px;
        display: flex;
        flex-wrap: nowrap;

        .chatBottom_bottom1 {
          width: 50px;
          margin: 10px 20px;
        }

        .chatBottom_bottom2 {
          width: 50px;
          margin: -20px 0px 10px -35px;
        }

        .chatBottom_bottom3 {
          flex: 1;
          margin-left: 278px;
          margin-top: -32px;

          .buttonfs {
            width: 80px;
            height: 40px;
            background: #0D68FC;
            border-radius: 6px;
            border: none;


            span {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .center_wrapper_right {
    flex: 4;
    background-color: #ffffff;
    padding: 20px 20px;
    position: relative;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }

  .center-item-info_wrapper {
    margin: 0px 16px;
  }

  .center-item-tip {
    display: flex;
    align-items: center;
  }

  .error-tip>img {
    width: 20px;
    margin: 0 10px;
  }

  .center_wrapper_center_item .center-item-info {
    background: #ffffff;
    border-radius: 3px;
  }

  .center_wrapper_center_item_reserve .center-item-info {
    background: #9eea6a;
    border-radius: 3px;
  }

  .center-item-info {
    max-width: 336px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 22px;
    padding: 16px;
  }

  .center-item-time {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #909090;
    line-height: 22px;
    margin-top: 12px;
  }

  /* 反向样式开始*/
  .center_wrapper_center_item_reserve {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 32px;
  }

  .center_wrapper_center_item_reserve .center-item-time {
    text-align: right;
  }

  .center_wrapper_center_item_reserve {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 32px;
  }

  .center_wrapper_center_item_reserve .center-item-tip {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .center_wrapper_center_item_reserve .error-tip>img {
    width: 20px;
    margin: 0 10px;
  }

  /* 反向样式结束*/

  /* 右边样式 */
  .right_whole_wrapper {
    background: #fff;
  }

  .right_wrapper {
    flex: 1;
  }

  .right_wrapper_center {
    border: none;
    color: #909090;
  }

  .center-item-name {
    font-size: 12px;
  }

  // .center-item-info {
  //   word-wrap: bre省钱兄-word;
  //   word-bre省钱兄: bre省钱兄-all;
  // }

  .center-item-info_img {
    width: 150px;
    /* height: 100px; */
  }

  .center-item-info_img>img {
    width: 100%;
    height: 100%;
  }

  /* 几个图标 */
  .icon-list {
    display: flex;
  }

  .icon-list>div:not(:first-child) {
    margin-left: 15px;
  }

  .icon-item-img {
    width: 25px;
  }

  /* 修改组件样式 */
  .whole-wrapper>.el-input__inner {
    border-radius: 5px;
  }

  .whole-wrapper>.el-textarea__inner {
    min-height: 80px;
    resize: none;
    border: none;
    background: #ffffff;
    padding: 5px 0px;
  }

  .whole-wrapper>.el-button--primary {
    width: 100px;
    height: 40px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .loading {
    text-align: center;
    color: #909090;
  }

  .loading span {
    margin-left: 10px;
  }

  .center_wrapper_center_item_reserve,
  .center_wrapper_center_item {
    position: relative;
  }

  .center_wrapper_center_item_reserve .center-item-info::after {
    content: "";
    position: absolute;
    right: 55px;
    top: 12px;
    width: 0;
    height: 0;
    border: 5px solid #9eea6a;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  .center_wrapper_center_item .center-item-info::after {
    content: "";
    position: absolute;
    left: 55px;
    top: 12px;
    width: 0;
    height: 0;
    border: 5px solid #ffffff;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  .other_user {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .user_main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .user_message {
    padding: 10px 25px;
    background: #FBFCFF;
    border: 1px solid #D7E4FB;
    border-radius: 10px;
    max-width: 60%;
    line-height: 34px;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
</style>