<template>
	<div class="content">
		<!-- 轮播图 -->
		<div class="swiper">
			<el-carousel height="700px" indicator-position="none" arrow="never">
				<el-carousel-item  v-for="(item,index) in Bnners" :key="index">
					<div class="swiper-item">
						<img :src="item" />
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 内容 -->
		<div class="centent ">
			<el-tabs v-model="activeChannel" type="border-card" @tab-click="handleChannelClick">
				<div v-for="(item, index) in channelTabs" :key="index">
					<el-tab-pane :label="item.name" :name="index+''" >
						<div class="ceentet_bord">
							<div class="text_card" v-for="(ite,ind) in item.goods_list" :key="ind"
								 :class="{'bg':isbg==ind}">
								<div @click="centen(ite,ind)">
									<div class="text_card_img" >
										<img :src="ite.icon" alt="" srcset="" width="10" height="20">
									</div>
									<div class="text_card_text" :class="{'text12':isbg1==ind}">
										{{ ite.title }}
									</div>
								</div>
								
								<!-- 系列详情 -->
								<div class="tab_content">
									<div class="tab_content_con">
										<div class="tab_content_con_left">
											<div class="tab_content_con_leftimg" v-if="concent.goods_banner">
												<el-carousel height="512px" arrow="never">
													<el-carousel-item v-for="itemq in concent.goods_banner.split(',')"
														:key="item">
														<img :src="itemq" alt=""
															style="width: 225px;height: 439px;padding-top: 20px;">
													</el-carousel-item>
												</el-carousel>
											</div>
										</div>
										<div class="tab_content_con_right">
											<div class="text">
												{{ concent.cert_name }}
											</div>
											<div class="changtab">
												<div v-for="(itcon,inde) in concent.goods_show_list" :key='inde'>
													<div class="tab">
														<div class="tab-item" :class="{ active: currentTab === inde }"
															@click="switchTab(inde)">
															{{itcon.title}}
														</div>
													</div>
													<div class="contenttt" style="margin-top: 30px;">
														<div v-show="currentTab ===inde"
															style="position: absolute; top: 20%;left: 48%;transform: translate(-58%, 51%);  padding: 20px;">
															<img :src="itcon.image" alt="" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</div>
			</el-tabs>


		</div>
		<!-- 右侧固钉 -->
		<affix></affix>
	</div>
</template>

<script>
	import affix from '../../components/affix.vue'

	export default {
		name: "index",
		components: {
			affix,

		},
		data() {
			return {
				isbg: 0,
				isbg1: 0,
				currentTab: 0,
				swiperPhone: require('../../assets/democenter/logo1.png'),
				gg: '购买请联系客服，领取优惠卷！客服电话：13895585204',
				listQuery: {
					app_name: ''
				},
				goodsshowlist: [],
				activeChannel: '0',
				channelTabs: [],
				concent: {},
				goodsId: '', //商品id
				Bnners:[],
			};
		},
		computed: {},
		methods: {
			async bychannelTabs() {
				const {
					data: res
				} = await this.$http.post('index/product/product_show_list')
				if (res.code == 1) {
					this.channelTabs = res.data
					if (this.channelTabs.length > 0) {
						if (this.channelTabs[0].goods_list.length > 0) {
							this.concent = this.channelTabs[0].goods_list[0]
						}
					}
					console.log("-------", this.channelTabs)
					//        if (this.goodsId!='') {
					//          let index1 = -1
					//          let index2 = -1
					//          this.channelTabs.forEach((item, index) => {


					//            item.goods_list.forEach((ite, ind) => {
					//              if (ite.id == this.goodsId) {
					//                index1 = index
					//                index2 = ind
					//              }
					// 	if(ind==0){
					// 			this.isbg = index1
					// 			this.isbg1 = index2		
					// 			  console.log('this.isbg:'+this.isbg,)
					// 			  console.log('this.isbg1:'+this.isbg1,)
					// 	}
					//            })

					//          })


					//          this.activeChannel = index1 + ''
					//          this.concent = this.channelTabs[index1].goods_list[index2]

					//        } else {
					// if(this.channelTabs.length>0){
					//  if(this.channelTabs[0].goods_list.length>0){
					//   this.concent = this.channelTabs[0].goods_list[0]
					//  }
					// }
					// }

				}
			},
			handleChannelClick(tab) {
				console.log('--tab:' + JSON.stringify(tab.props)   )
				
				console.log('--this.isbg1:' + this.isbg1, )
				this.listQuery.app_name = tab.props.label
				this.isbg1 = 0
				this.isbg = 0
				for(var i in this.channelTabs){
					if(this.channelTabs[i].name==tab.props.label){
						var goodsList = this.channelTabs[i].goods_list
						if(this.channelTabs[i].goods_list.length>0){
							this.concent = this.channelTabs[i].goods_list[0]
							console.log("点击商品获取数据-------", this.concent)
						}
					}
				}
				
			},
			centen(item, index) {
				this.isbg = index
				this.isbg1 = index
				if (item) {
					this.concent = item
				}
				console.log('----this.isbg下标:' + this.isbg, )
			},
			switchTab(index) {
				this.currentTab = index;
			},
			//头部组件
			async byhead() {
			  const {
			    data: res
			  } = await this.$http.get('index/index/index_header')
			  if (res.code == 1) {
						if(res.data.top_navigation){
							this.Bnners = res.data.top_navigation[1].img.split(",")
						}
			    
			  }
			}
		},
		created() {
			this.byhead()
			this.bychannelTabs()
		},
		mounted() {
			if (this.$route.query.goodsId) {
				this.goodsId = this.$route.query.goodsId
			} else {
				this.goodsId = ''
			}
			console.log(this.$route.query.goodsId, 'goodsId', this.goodsId, )

		}
	}
</script>

<style lang="less" scoped>
	.bg {
		width: 10%;
		height: 90%;
		background: linear-gradient(0deg, #E9F0FF 0%, #E7F0FF 100%);
		border-radius: 8px;
		text-align: center;
		margin-top: 10px;
		//超出一行省略号
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.text12 {
		width: 100%;
		color: #0D68FC;
		//超出一行省略号
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.content {
		background: #EFF7FF;

		width: 100%;
	}

	.swiper {
		width: 100%;
		height: 700px;

		.swiper-item {
			width: 100%;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.swiper-item-gg {
				position: absolute;
				bottom: 20px;
				left: 50%;
				transform: translate(-50%, 0);
				width: 80%;
				height: 80px;
				background: rgba(255, 255, 255, 0.7);
				border-radius: 16px;
				padding: 0 50px;
				color: #0D68FC;
				font-size: 22px;

				img {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
			}
		}
	}

	.centent {
		width: 100%;
		height: 100%;
		position: relative;

		.ceentet_bord {
			width: 100%;
			height: auto;
			overflow: auto;
			white-space: nowrap;
			background: #FFFFFF;
			border-radius: 0px 0px 18px 18px;
		}

		.text_card {
			display: inline-block;
			margin-left: 50px;
			width: 10%;
			text-align: center;

			.text_card_img {
				width: 100%;
				height: 50%;
				text-align: center;
				margin-bottom: 22px;

				img {
					margin-top: 30px;
					width: 48px;
					height: 48px;
				}
			}

			.text_card_text {
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				height: 50%;
				font-size: 22px;
				font-family: Source Han Sans CN;
				font-weight: 400;
			}
		}

		.tab_content {
			width: 100%;
			height: 743px;
			position: absolute;
			top: 200px;
			left: -5px;

			.tab_content_con {
				margin: 0 auto;
				width: 80%;
				height: 100%;
				background: #FFFFFF;
				border-radius: 36px;
				display: flex;

				.tab_content_con_left {
					width: 40%;
					height: 100%;

					.tab_content_con_leftimg {
						width: 257px;
						height: 512px;
						background-image: url('../../assets/democenter/phonee.png');
						background-repeat: no-repeat;
						background-size: 100% 94%;
						margin: 131px 0 0 268px;

					}

					img {
						margin: 0px 0 0 -3px;
					}
				}

				.tab_content_con_right {
					width: 50%;
					height: 100%;

					.text {
						font-size: 40px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #333333;
						line-height: 2px;
						margin-top: 159px;
						margin-bottom: 52px;
					}

					.changtab {
						display: flex;
						justify-content: space-between;
						align-content: center;
						flex-wrap: nowrap;
						flex-direction: row;

						.tab {
							display: flex;
						}

						.tab-item {
							flex: 1;
							text-align: center;
							margin-bottom: 30px;
							font-size: 21px;
							font-family: Source Han Sans CN;
							font-weight: bold;
						}

						.tab-item.active {
							color: #0D68FC;
							border-bottom: 1px solid #0D68FC;
							margin-bottom: 10px;
						}

						.content {}
					}
				}
			}
		}
	}

	/deep/.el-tabs--border-card>.el-tabs__content {
		padding: 0;
		width: 100%;
		height: 990px;
	}

	/deep/ .el-tabs__item {
		width: 269px;
		height: 97px;
		font-size: 24px;
		font-family: Source Han Sans CN;
		font-weight: 500;
	}

	/deep/.el-tabs__nav-prev {
		left: 8;
		transform: rotate(360deg);
		z-index: 9;
	}

	/deep/.el-tabs__nav-next {
		margin-top: 20px;
		transform: rotate(180deg);
		z-index: 9;
	}

	/deep/.el-tabs__nav-next .el-icon svg {
		transform: rotate(180deg);
	}

	/deep/.el-icon {
		width: 37px;
		height: 37px;
		margin-top: 20px;
		background-color: #efefef;
		border-radius: 0 50px 50px 0;
	}

	/deep/.el-icon svg {
		width: 20px;
		height: 20px;
		color: #cccccc;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		background-color: #F6F9FF;
	}

	/deep/.el-tabs {
		--el-tabs-header-height: 0px;
		background-color: #F6F9FF;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		width: 269px;
		height: 97px;
		background: #FFFFFF;
		border-radius: 14px 14px 0px 0px;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item {
		background-color: #F6F9FF;
		border: none;
	}

	/deep/.el-tabs__nav-wrap.is-scrollable {
		padding: 0;
	}

	.ceentet_bord::-webkit-scrollbar {
		height: 10px;
	}

	.ceentet_bord::-webkit-scrollbar-thumb {
		background-color: rgba(100, 100, 100, .5);
		border: 3px solid transparent;
		border-radius: 9px;
		background-clip: content-box;

	}

	.ceentet_bord::-webkit-scrollbar-track {
		background-color: #efefef;
		width: 3px;
		height: 5px;
	}
</style>