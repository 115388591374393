<template>
  <div class="sq">
    <div class="sqbox">
      <p>授权记录</p>
      <div class="rir">授权区域名和IP地址后，部署运行的系统内才可使用在线更新和更新包下载功能</div>

      <div class="tab">
        <el-table :data="tableData" style="width: 100%" empty-text="暂无数据"
          :header-cell-style="{background:'#EEF0F6',color:'#333333'}">
          <el-table-column prop="goods_info.title" label="授权产品" width="250">
          </el-table-column>
          <el-table-column prop="empower_num" label="授权编号" />
          <el-table-column prop="empower_time" label="授权日期" />
          <el-table-column label="操作" width="200">
            <template template #default="scope">
              <el-button type="primary" @click="fuzhi(scope.row.empower_num)">复制授权编号</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pages">
        <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
          :current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Authorization',
    data() {
      return {
        total: 6,
        pageSize: 3,
        currentPage: 1,
        tableData: [{
          //移除数据
          selected: false,
        }, ],
        selectedItems: []
      }
    },
    methods: {
      fuzhi(item) {
        this.$notify.success({
          title: "成功",
          message: '复制订单号成功',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = item;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      handleCheck(row) {
        console.log(row)
        if (row.selected) {
          this.selectedItems.push(row);
        } else {
          const index = this.selectedItems.findIndex(item => item === row);
          if (index !== -1) {
            this.selectedItems.splice(index, 1); // 从数组中移除选中的项
          }
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.byempower()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.byempower()
      },
      async byempower() {
        const {
          data: res
        } = await this.$http.post('index/user/user_empower_record', {
          num: this.pageSize,
          page: this.currentPage
        })
        if (res.code == 1) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      }
    },
    mounted() {
      this.byempower()
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-table__empty-text {
    display: block;
    width: 501px;
    height: 530px;
    position: relative;
    top: -117px;
    padding-top: 448px;
    background: url('../../assets/dhpbj.png') no-repeat;
    color: #909399;
    box-sizing: border-box;
  }

  .pages {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sqbox {
    margin: 40px 18px 30px;

    p {
      font-weight: bold;
      margin: 0;
    }

    .rir {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #777777;
      margin-top: 4px;
    }

    .tab {
      margin-top: 30px;
    }
  }

  /deep/ .el-table tbody tr:hover>td {
    background-color: #FFFFFF !important //修改成自己想要的颜色即可
  }

  /deep/.el-pagination.is-background .el-pager li.is-active {
    background-color: #0D68FC !important //修改成自己想要的颜色即可
  }

  /deep/.el-pagination.is-background .el-pager li {
    background-color: #FFFFFF !important; //修改成自己想要的颜色即可
    border: 1px solid #F6F5F7;
  }

  /deep/.el-pagination button {
    background-color: #FFFFFF !important; //修改成自己想要的颜色即可
    border: 1px solid #F6F5F7;
  }

  /deep/.el-table__empty-block {
    min-height: 120px;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }

  /deep/.el-table tr {
    height: 50px;
  }
</style>