<template>
  <div class="content">
    <div class="user">
      <div class="user_user">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <div class="userbox">
              <div class="touxing" style="margin:14px 0">
                <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
                  :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <el-avatar :size="100" :src="circleUrl" />
                </el-upload>
              </div>
              <div class="xiugai">
                点击头像进行修改
              </div>
              <div class="nicheng" style="margin:14px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                  :label-position="left">
                  <el-form-item label="昵称:" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="dianhua" style="margin:14px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                  :label-position="left">
                  <el-form-item label="电话:" prop="daihua" v-if="ruleForm.phone==null">
                    <el-input v-model="ruleForm.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="电话:" prop="daihua" v-else-if='ruleForm.phone !==null'>
                    <el-input v-model="ruleForm.phone" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="电话:" prop="daihua" v-else>
                    <el-input v-model="ruleForm.phone"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="xinbie" style="margin:14px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                  :label-position="left">
                  <el-form-item label="性别:" prop="sex">
                    <el-radio-group v-model="ruleForm.sex" @change="change">
                      <el-radio label="1">男</el-radio>
                      <el-radio label="0">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
              <div class="email" style="margin:14px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                  :label-position="left">
                  <el-form-item prop="email" label="邮箱:" :rules="[
                        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                      ]">
                    <el-input v-model="ruleForm.email"></el-input>
                  </el-form-item>
                </el-form>
                <div style="color: red; margin-left: 101px; font-size: 15px;position: absolute;top:384px;">
                  购买产品统一邮箱发送，请务必核对邮箱地址</div>
              </div>
              <div class="bianhao" style="margin: 35px 0px 0px 48px;">
                <span class="name">编号:&nbsp;</span>{{ordernum}}
              </div>
              <div class="dengji" style="margin: 6px 0px 14px 48px;">
                <span class="name">等级:&nbsp;</span>
                <span v-if="dengji==0">普通用户</span>
                <span v-if="dengji==1">免费体验</span>
                <span v-if="dengji==2">vip用户</span>
                <span v-if="dengji==3">svip用户</span>
                <span v-if="dengji==4">超级svip用户</span>
              </div>
              <div class="bianhao" style="margin: 0px 0px 0px 48px;">
                <span class="name" v-if="time">会员到期时间:&nbsp;{{time}}</span>
                <span class="name" v-else>会员到期时间:&nbsp;您还不是会员请<span style="color: cornflowerblue;" @click="goto">
                    开通会员</span>获取更多权益</span>
              </div>
            </div>
          </el-tab-pane>
          <div class="button" style="margin-top: 130px;">
            <button class="butt" @click="submitForm('ruleForm')">
              <span>保存</span>
            </button>
          </div>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ordernum: '',
        dengji: 1,
        activeName: 'first',
        time: '',
        circleUrl: require('../../assets/my/user.png'),
        ruleForm: {
          name: '',
          sex: '',
          email: '',
          phone: '',
        },
        sex: '',
        rules: {
          name: [{
            required: true,
            message: '请输入用户昵称',
            trigger: 'blur'
          }, ],
          sex: [{
            required: true,
            message: '请选择用户性别',
            trigger: 'change'
          }],
          phone: [{
              required: true,
              message: '请输入电话号码',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 5,
              message: '长度在11个字符',
              trigger: 'blur'
            }
          ]
        },
      }
    },
    methods: {
      change(e) {
        this.ruleForm.sex = e
      },
      handleAvatarSuccess(file) {
        console.log(file)
        this.skuImg = file.data;
        this.circleUrl = this.skuImg
      },
      goto() {
        this.$router.push({
          path: '/hy',
          query: {
            name: '这是会员页面',
          }
        })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.circleUrl == '') {
              this.$notify.error({
                title: "个人信息",
                message: '头像不能为空',
                duration: 3500,
                offset: 50
              });
              return
            }
            if (this.ruleForm.name == '') {
              this.$notify.error({
                title: "个人信息",
                message: '用户昵称不能为空',
                duration: 3500,
                offset: 50
              });
              return
            }
            if (this.ruleForm.sex == '') {
              this.$notify.error({
                title: "个人信息",
                message: '请选择性别',
                duration: 3500,
                offset: 50
              });
              return
            }
            if (this.ruleForm.phone == '') {
              this.$notify.error({
                title: "个人信息",
                message: '请输入电话号码',
                duration: 3500,
                offset: 50
              });
              return
            }
            if (this.ruleForm.email == '') {
              this.$notify.error({
                title: "个人信息",
                message: '用户邮箱不能为空',
                duration: 3500,
                offset: 50
              });
              return
            }
            this.$http.post('index/user/edit_user_info', {
              avatar: this.circleUrl,
              nickname: this.ruleForm.name,
              age: '',
              gender: this.ruleForm.sex,
              mobile: this.ruleForm.phone,
              email: this.ruleForm.email,
            }).then((res) => {
              console.log(res);
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "个人信息",
                  message: '修改成功',
                  duration: 3500,
                  offset: 50
                });
                this.$cookie.set('phone', this.ruleForm.phone)
                this.user();
              } else {
                this.$notify.error({
                  title: "个人信息",
                  message: res.msg,
                  duration: 3500,
                  offset: 50
                });
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //个人中心
      async user() {
        const {
          data: res
        } = await this.$http.post('index/user/user_center')
        console.log(res)
        //头像
        this.circleUrl = res.data.avatar
        this.$cookie.set('avatar', this.circleUrl)
		window.sessionStorage.setItem('avatar', this.circleUrl);
        this.ruleForm.name = res.data.nickname
        this.ruleForm.sex = res.data.gender.toString()
        // console.log(this.ruleForm.sex)
        this.ruleForm.email = res.data.email
        this.ordernum = res.data.serial_number
        this.dengji = res.data.is_vip_type
        //是否vip
        this.$cookie.set('is_vip_type', res.data.is_vip_type)
        this.time = res.data.vip_end_time
        //是否vip
        if (this.time) {
          this.$cookie.set('is_vip_time', res.data.vip_end_time)
        }
      }
    },
    created() {
      let token = window.sessionStorage.getItem('token')
      if (token !== '') {
        this.user()
      }
      //等级
      //电话
      this.ruleForm.phone = this.$cookie.get('phone')
    },
  }
</script>

<style scoped lang="less">
  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .user {
      width: calc(100% - 20px);
      min-height: 750px;
      background: #FFFFFF;
      border-radius: 20px;

      .user_user {
        width: 97%;
        height: 740px;
        margin: 0 auto;
        padding-top: 20px;

        .userbox {
          width: 400px;
          height: 410px;
          margin: 0 auto;
          margin-top: 15px;

          .touxing {
            width: 100%;
            height: auto;
            text-align: center;
          }

          .xiugai {
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
            cursor: pointer;
            margin-bottom: 29px;
          }

          .nicheng {
            width: 100%;
            height: 40px;


          }

          .xinbie {
            width: 100%;
            height: 40px;


          }

          .email {
            width: 100%;
            height: 40px;

            margin-bottom: 25px;
          }

          .bianhao {
            width: 100%;
            height: 40px;
            margin-left: 60px;
            margin-bottom: 5px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .name {
              font-size: 16px;
              font-family: Source Han Sans CN;
              color: #333333;
              line-height: 2px;
            }
          }

          .dengji {
            width: 100%;
            height: 25px;
            margin-bottom: 25px;
            margin-left: 60px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .name {
              font-size: 16px;
              font-family: Source Han Sans CN;
              color: #333333;
              line-height: 2px;
            }
          }

        }
      }
    }
  }

  .button {
    width: 100%;
    height: auto;
    text-align: center;

    .butt {
      width: 120px;
      border: navajowhite;
      height: 44px;
      background: #0D68FC;
      border-radius: 5px;

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  /deep/.el-form-item__label {
    font-size: 16px;
    font-family: Source Han Sans CN;
    color: #333333;
    line-height: 2px;
    padding-top: 15px;
  }
</style>