<template>
  <div class="content">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
        <div class="swiper-item">
          <!-- 轮播图 -->
          <img src="../../assets/kaiyuanchanping/paidanchi/banner.png" alt="">
          <!-- 标题 -->
          <div class="killtitle">
            <img src="../../assets/kaiyuanchanping/paidanchi/title.png" alt="">
            <div class="killtitle1">
              <div class='button'>
                <span>时刻与用户保持沟通,高效创作</span>
              </div>
            </div>
          </div>
        </div>
        <!-- </el-carousel-item> -->
      </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="product flex justify-center align-center">
      <div class="allproduct">
        <div class="shaixuan" style="display:flex; width: 210px; justify-content: space-between;">
          <span @click="zonhepaixu(1)" :class="{activeColor:colorIndex===1}">综合排序</span> |
          <span  style="width: 38px;">距离
            <div class="juli" >
              <i class="top" v-if="juliimg" @click="upjuli()">
                <img src="../../assets/kaiyuanchanping/paidanchi/top.png" />
              </i>
              <i class="top" v-if="juliimg1">
                <img src="../../assets/kaiyuanchanping/paidanchi/top_blue.png" alt="" width="10" height="6">
              </i>
              <i class="down" v-if="downjuliimg" @click="downjuli()">
                <img src="../../assets/kaiyuanchanping/paidanchi/down.png" alt="">
              </i>
              <i class="down" v-if="downjuliimg1">
                <img src="../../assets/kaiyuanchanping/paidanchi/down_blue.png" width="10" height="6">
              </i>
            </div>
          </span>
          <span>|</span>
          <span>价格
            <div class="price">
              <i class="top1" v-if="upchangeimg" @click="upchange1()">
                <img src="../../assets/kaiyuanchanping/paidanchi/top.png" />
              </i>
              <i class="top1" v-if="upchangeimg1">
                <img src="../../assets/kaiyuanchanping/paidanchi/top_blue.png" alt="" width="10" height="6">
              </i>
              <i class="down1" v-if="downchangeimg" @click="downchangee()">
                <img src="../../assets/kaiyuanchanping/paidanchi/down.png" alt="">
              </i>
              <i class="down1" v-if="downchangeimg1">
                <img src="../../assets/kaiyuanchanping/paidanchi/down_blue.png" width="10" height="6">
              </i>
            </div>
          </span>
        </div>
        <div class="productcentents">
          <div class="product1" v-for="(item,index) in product " :key="index" @click="joincontent(item)">
            <div class="productimg">
              <img :src="item.image" alt="" style="width: 394px;">
            </div>
            <div class="productext" :style="colorDiv(item)" @click="divCLick(index)" style="z-index: 999;">
              <div class="producone">
                {{item.title}}
              </div>
              <div class="productwo">
                <span>{{item.subtitle}}</span>
              </div>
              <div class="progress-xt">
                <div class="progress-xt1" v-for="(item, index) in lab" :key="index">

                  <el-tag :style="{ background: randomColor }" style="color: #333;">{{item}}</el-tag>
                </div>
              </div>
              <el-divider />
              <div class="divider">
                <div class="address">
                  <img src="../../assets/kaiyuanchanping/paidanchi/address.png">
                  <span style="margin-left: 5px; margin-bottom: 20px;">{{item.city}}</span><span
                    style="margin-left:5px; margin-bottom: 20px;"> {{item.km}}</span>
                </div>
                <div class="money">
                  <span class="renmingbi">¥</span><span class="money1">{{item.money}}</span><span
                    class="renmingbi">起</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    
  </div>
  <affix></affix>
</template>

<script>
  import affix from '../../components/affix.vue'
  export default {
    name: "flashKill",
    components: {
      affix
    },
    data() {
      return {
        colorIndex: -1,
        visible: false,
        upchangeimg1: false,
        upchangeimg: true,
        downchangeimg: true,
        downchangeimg1: false,
        juliimg: true,
        juliimg1: false,
        downjuliimg: true,
        downjuliimg1: false,
        typearry: 'all',
        product: [

        ],
        randomColor: '',
        longitude: null,
        latitude: null,
        lab: []
      }
    },
    methods: {
      //点击切换背景
      divCLick(index) {
        /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
        for (let i in this.product) {
          if (index == i) {
            this.product[i].isActive = !this.product[i].isActive
          } else this.product[i].isActive = false;
        }
      },
      colorDiv(item) {
        if (item.isActive == true) {
          return {
            'background-color': '#FFFFFF'
          }
        } else {
          return {
            'background-color': '#FAFAFA'
          }
        }
      },
      getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 20)];
        }
        return color;
      },
      coloediv(typr) {
        if (this.typr == 1) {
          return {
            'background-color': 'blue'
          }
        } else {
          return {
            'background-color': '#FFFFFF'
          }
        }
      },
      //综合排序
      zonhepaixu(index) {
        this.colorIndex = index;
        this.juliimg = true
        this.juliimg1 = false
        this.downjuliimg = true
        this.downjuliimg1 = false
        this.upchangeimg = true
        this.upchangeimg1 = false
        this.downchangeimg = true
        this.downchangeimg1 = false
        this.typearry = 'all'
        this.bypaidanchi()
      },
      //距离升序
      upjuli() {
        this.juliimg = false
        this.juliimg1 = true
        this.downjuliimg = true
        this.downjuliimg1 = false
        this.product.sort(this.sortMethodjuli('km'))
        this.typearry = 1
        this.bypaidanchi()
      },
      sortMethodjuli(property) {
        return function(a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      },

      //距离降序
      downjuli() {
        this.downjuliimg = false
        this.downjuliimg1 = true
        this.juliimg = true
        this.juliimg1 = false
        this.product.sort(this.sortMethodjuliq('km'))
        this.typearry = 2
        this.bypaidanchi()
      },
      sortMethodjuliq(property) {
        return function(a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      },
      //价格升序降序
      upchange1() {
        this.upchangeimg = false
        this.upchangeimg1 = true
        this.downchangeimg = true
        this.downchangeimg1 = false
        this.product.sort(this.sortMethods('money1'))
        this.typearry = 3
        this.bypaidanchi()
      },
      sortMethods(property) {
        return function(a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      },
      //价格降序
      downchangee() {
        this.upchangeimg = true
        this.upchangeimg1 = false
        this.downchangeimg = false
        this.downchangeimg1 = true
        this.product.sort(this.sortMethodsd('money1'))
        this.typearry = 4
        this.bypaidanchi()
      },
      sortMethodsd(property) {
        return function(a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value1 - value2
        }
      },
      //进入商品详情
      joincontent(item) {
        var itemcon = JSON.stringify(item.id)
        // console.log(item)
        this.$router.push({
          name: 'dispatchdetails',
          query: {
            item: itemcon
          }
        })
      },
      async bypaidanchi() {
        const {
          data: res
        } = await this.$http.get('index/empower/receive_tank_list', {
          params: {
            longitude: this.$cookie.get('longitude'),
            latitude: this.$cookie.get('latitude'),
            type: this.typearry,
            num: 10,
            page: 1,
            result:'',
          }
        })
        if (res.code == 0) {
          this.product = res.data.data
          this.product.forEach((item) => {
            this.lab = item.lable.split(",");
            console.log(this.lab)
          })
        }
      }
    },
    mounted() {
      this.randomColor = this.getRandomColor();
      this.bypaidanchi()
      const format = (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`)

    },
  }
</script>

<style lang="less" scoped>
  .content {
    background: #EFF7FF;
  }

  .swiper {
    width: 100%;
    height: 700px;
    position: relative;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .killtitle {
        position: absolute;
        top: 219px;
        left: 118px;

        .killtitle1 {
          .button {
            margin-top: 83px;
            width: 576px;
            height: 80px;
            background: #0D68FC;
            border-radius: 40px;
            text-align: center;

            span {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 80px;
            }
          }
        }
      }

      .button1 {
        position: absolute;
        top: 450px;
        left: 138px;
        width: 229px;
        height: 80px;
        background: #FFFFFF;
        border: 1px solid #63FFFD;
        border-radius: 40px;
        text-align: center;

        span {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          line-height: 80px;
        }
      }
    }
  }

  .product {
    width: 100%;
    padding-bottom: 58px;
    margin-top: 34px;
    background: #EFF7FF;

    .allproduct {
      width: 87%;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }

    .shaixuan {
      .activeColor {
        color: #0D68FC;
      }

      .juli {
        width: 10px;
        height: 30px;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: -10px;
        left: 135px;

        .top {
          width: 100%;
          height: 50%;
        }

        .down {
          width: 100%;
          height: 50%;
        }
      }


      .price {
        width: 10px;
        height: 30px;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: -10px;
        left: 212px;

        .top1 {
          width: 100%;
          height: 50%;
        }

        .down1 {
          width: 100%;
          height: 50%;
        }
      }

    }

    .productcentents {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .product1 {
        height: 100%;
        margin-top: 40px;
        margin-right: 23px;

        .productimg {
          width: 394px;
          height: 223px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

          }
        }

        .productext {
          width: 394px;
          height:185px;
          background: #FFFFFF;
          border-radius: 0px 0px 16px 16px;

          .producone {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 50px;
            margin-left: 18px;
            
          }

          .productwo {
            margin-left: 18px;
            

            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 2px;
            }
          }

          .progress-xt {
            width: 100%;
            margin: 10px 7px;

            .progress-xt1 {
              margin-left: 10px;
              flex-wrap: wrap;
              display: inline-block;
            }
          }
        }

        .divider {
          width: 100%;

          .address {
            float: left;
            margin: -10px 0 0 17px;

            img {
              width: 16px;
              height: 16px;
            }

            span {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;

            }
          }

          .money {
            float: right;
            margin: -10px 20px 0 0;

            .renmingbi {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FF0000;
            }

            .money1 {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FF0000;
            }
          }
        }
      }
    }
  }

  .more {
    width: 100%;
    padding: 34px 0;
    background: #EFF7FF;

    .button4 {
      width: 252px;
      height: 78px;
      background-image: url('../../assets/kaiyuanchanping/chakanmore.png');
    }
  }
</style>