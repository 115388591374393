<template>
	<div class="content">
		<div style="text-align: center;">
			<el-row>
				<el-col :span="24">
					<img src="../../assets/image/payok.png" alt="" srcset="" style="width: 180px;">
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<h1>支付成功</h1>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<h4>您已支付成功，快去看看授权商品吧</h4>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<div class="btn">
						<div class="btn1" @click="goUser">查看商品</div>
						<div class="btn2">继续逛逛</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: '/payment',
		data() {
			return {

			}
		},
		mounted() {
			
		},
		methods:{
			goUser(){
				this.$router.push({
					path: '/my',
					query: {
						name: '这是用户中心路由',
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		// text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;

		h4 {
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #999999;
			margin: 10px 0;
		}

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 120px;

			.btn1 {
				width: 200px;
				height:64px;
				background: #0D68FC;
				border-radius: 6px;
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.btn2 {
				width: 200px;
				height:64px;
				border: 1px solid #0D68FC;
				border-radius: 6px;
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #0D68FC;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 180px;

			}
		}
	}
</style>