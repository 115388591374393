<template>
  <div class="userbox">
    <el-row>
      <el-col :span="one">
        <div class="flex align-center justify-center">
          <div class="box1">
            <img src="../../src/assets/image/keji.png" alt="" srcset="">
            <div class="three">高新科技企业认证</div>
          </div>
        </div>
      </el-col>
      <el-col :span="one">
        <div class="flex align-center justify-center">
          <div class="box1">
            <img src="../../src/assets/image/renzhng.png" alt="" srcset="">
            <div class="three">SOC2认证</div>
          </div>
        </div>
      </el-col>
      <el-col :span="one">
        <div class="flex align-center justify-center">
          <div class="box1">
            <img src="../../src/assets/image/ios.png" alt="" srcset="">
            <div class="three">ISO 27001认证</div>
          </div>
        </div>
      </el-col>
      <el-col :span="one">
        <div class="flex align-center justify-center">
          <div class="box1">
            <img src="../../src/assets/image/ios.png" alt="" srcset="">
            <div class="three">双软企业认证</div>
          </div>
        </div>
      </el-col>
      <el-col :span="one">
        <div class="flex align-center justify-center">
          <div class="box1">
            <img src="../../src/assets/image/sq.png" alt="" srcset="">
            <div class="three">双软企业认证</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'kj',
    data() {
      return {
        one: 4
      }
    },
    mounted() {
      if (window.innerWidth > Number(1200)) {
        this.one = 4
      } else if (window.innerWidth >= Number(992) && window.innerWidth <= Number(1200)) {
        this.one = 8
      } else if (window.innerWidth >= Number(768) && window.innerWidth < Number(992)) {
        this.one = 8
      } else if (window.innerWidth >= Number(480) || window.innerWidth < Number(768)) {
        this.one = 12
      } else if (window.innerWidth < Number(480)) {
        this.one = 24
      }
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  /deep/.el-row{
    justify-content: center;
  }
  .userbox {
    background: #FFFFFF;
    // padding: 55px 50px 0px 50px;
    color: #999999;
  }

  .box1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 55px;

    .three {
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      margin-left: 15px;
    }
  }

  /*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .box1 {
      margin-bottom: 45px;
    }

  }

  /*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
  @media (min-width: 768px) and (max-width: 991px) {
    .userbox {
      padding: 0 !important;
    }
  }

  /*在 480 和 767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {
    .userbox {
      padding: 55px 0 0 !important;
    }
  }

  /*在小于 480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {}

  .userbox {
    padding: 55px 0 0 !important;
  }
</style>