<template>
	<div class="content">
		<div class="footone">
			<div class='footone_wai' v-for="(item,index) in projects" :key="index">
				<div class='footontop'>
					<div class="one">{{item.name}}</div>
					<div class="two">{{item.description}}</div>
				</div>
				<div class="footonebottom">
					<span class='footonebottom_one'>
						{{item.remark}}
					</span>
					<span class='footonebottom_two'>
						+
					</span>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="foottwo">
			<div class='foottwo_wai' v-for="(item,index) in renzheng" :key="index">
				<div class='foottwotop'>
					<img :src="item.img" class="one" alt="" srcset="">
					<div class="tow">
						{{item.name}}
					</div>
					<!-- <span class="two">{{item.name}}</span> -->
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="footthree">
			<!-- <div class='footthree_wai1'>
        <div class="flex align-center justify-left">
          <img src="../assets/index/footone.png" class="loimg" alt="" srcset="">
          <div class="tit">资深专家全程咨询</div>
        </div>
        <div class="flex align-center justify-left">
          <img src="../assets/index/foottwo.png" class="loimg" alt="" srcset="">
          <div class="tit">1V1大客户专享服务</div>
        </div>
        <div class="flex align-center justify-left">
          <img src="../assets/index/footthree.png" class="loimg" alt="" srcset="">
          <div class="tit">可靠保障承诺</div>
        </div>
      </div> -->
			<div class='footthree_wai2'>
				<el-row class="botfootpc">
					<el-col :span="6" v-for="(item,index) in tablist" :key="index">
						<ul>
							<ol>
								<a :href="item.urls">
									{{item.name}}
								</a>
							</ol>
							<!-- <li v-for="(ite,ind) in item.children" :key="ind">
                <a :href="ite.urls">
                  {{ite.name}}
                </a>
              </li> -->
						</ul>
					</el-col>
				</el-row>
				<el-row class="botfootpc">
					<el-col :span="2">
					</el-col>
					<el-col :span="4">
						<ul>
							<ol style="font-size: 16px;">{{tablist1.name}}
								<img src="../assets/index/phone.png">
								{{phone}}
							</ol>
						</ul>
					</el-col>
					<el-col :span="8">
						<ul>
							<ol style="font-size: 16px;">
								<img src="../assets/index/address.png">
								{{adress}}
							</ol>
						</ul>
					</el-col>
					<el-col :span="8">
						<ul>
							<ol style="font-size: 16px;">
								<img src="../assets/index/email.png">
								{{email}}
							</ol>
						</ul>
					</el-col>
					<el-col :span="2">
					</el-col>
				</el-row>
				<el-row class="botfootpc">
					<el-col :span="4">
					</el-col>
					<el-col :span="4">
						<ul>
							<ol style="font-size: 16px;    position: relative;">
								<img src="../assets/index/footone.png" class="loimg" style="width: 36px;height: 36px;"
									alt="" srcset="">
								<span style="position: absolute;top: 10px;left: 50px;">资深专家全程咨询</span>

							</ol>
						</ul>
					</el-col>
					<el-col :span="4">
						<ul>
							<ol style="font-size: 16px;    position: relative;">
								<img src="../assets/index/footthree.png" class="loimg" style="width: 36px;height: 36px;"
									alt="" srcset="">
								<span style="position: absolute;top: 10px;left: 50px;">可靠保障承诺</span>

							</ol>
						</ul>
					</el-col>
					<el-col :span="8">
						<ul>
							<ol style="font-size: 16px;    position: relative;">
								<img src="../assets/index/foottwo.png" class="loimg" style="width: 36px;height: 36px;"
									alt="" srcset="">
								<span style="position: absolute;top: 10px;left: 50px;">1V1大客户专享服务</span>

							</ol>

						</ul>
					</el-col>
					<el-col :span="4">
					</el-col>
				</el-row>
			</div>
			<!-- <div class='footthree_wai3'>
        <el-row class="botfootpc">
          <el-col :span="24">
            <ul>
              <ol>{{tablist1.name}}</ol>
              <li>
                <img src="../assets/index/phone.png">
                {{phone}}
              </li>
              <li> <img src="../assets/index/address.png">
                {{adress}}
              </li>
              <li>
                <img src="../assets/index/email.png">
                {{email}}
              </li>
            </ul>
          </el-col>
        </el-row>
      </div> -->
			<div class='footthree_wai4'>
				<div class="lianxi">
					<img :src="phoneimg" style="width: 150px; height: 150px;" />
				</div>
				<div class="text">
					微信扫一扫
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="footfour">
			<div class="four_tetx">
				COPYRIGHT © 西安省钱兄网络科技有限公司. 版权所有. 陕ICP备19023892号-2
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'footer',
		props: {
			msg: String,
		},
		data() {
			return {
				projects: [],
				huoban: [],
				renzheng: [],
				Received: 50,
				satisfaction: 2000,
				employees: 20,
				tablist: [],
				tablist1: [],
				phone: '',
				adress: '',
				email: '',
				phoneimg: '',
			}
		},
		mounted() {
			this.byfoot()
			this.byxitong()

			this.byxitongNN()
		},
		methods: {
			//底部导航
			async byfoot() {
				const {
					data: res
				} = await this.$http.get('index/index/index_footer')
				if (res.code == 1) {
					console.log(res)
					this.tablist = res.data.slice(0, 4)
					this.tablist1 = res.data[5]
					this.phone = this.tablist1.children[0].alias_name

					this.adress = this.tablist1.children[1].alias_name
					this.email = this.tablist1.children[2].alias_name
					this.phoneimg = this.tablist1.children[3].alias_name
				}
			},
			//系统配置
			// api/index/index/index_title_cooperate
			// 传参：type:6.合作伙伴 7. 交付配置  8.认证配置
			async byxitongNN() {
				const {
					data: res
				} = await this.$http.get('/index/index/index_title_cooperate', {
					params: {
						'type': 7
					}
				})
				if (res.code == 1) {
					this.projects = res.data
				}
			},
			async byxitong() {
				const {
					data: res
				} = await this.$http.get('/index/index/index_title_cooperate', {
					params: {
						'type': 8
					}
				})
				if (res.code == 1) {
					this.renzheng = res.data
				}
			},
			//合作公司


		}
	}
</script>

<style lang="less" scoped>
	a {
		text-decoration: none;
		color: #FFFFFF;

	}

	.content {
		width: 100%;
		background: #2E3033;
	}

	.line {
		width: 100%;
		height: 1px;
		background: #ffff;
		margin: 0;
	}

	// 在电脑1200px大
	@media (min-width: 1200px) {
		.footone {
			width: 100%;
			height: 220px;
			background: #16151D;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.footone_wai {
				width: 25%;
				height: 100%;
				text-align: center;

				.footontop {
					width: 80%;
					height: auto;

					.one {
						font-size: 25px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 50px;
					}

					.two {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 10px;
					}
				}

				.footonebottom {
					width: 80%;
					height: auto;
					// margin-top: 30px;
					margin-left: -2px;
					position: relative;

					.footonebottom_one {
						font-size: 60px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}

					.footonebottom_two {
						position: absolute;
						top: 10px;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
					}
				}
			}
		}
	}

	/*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
	@media (min-width: 992px) and (max-width: 1199px) {
		.footone {
			width: 100%;
			height: auto;
			background: #16151D;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.footone_wai {
				width: 50%;
				height: 100%;
				text-align: center;

				.footontop {
					width: 80%;
					height: auto;

					.one {
						font-size: 25px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 50px;
					}

					.two {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 10px;
					}
				}

				.footonebottom {
					width: 80%;
					height: auto;
					// margin-top: 30px;
					margin-left: -2px;
					position: relative;

					.footonebottom_one {
						font-size: 60px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}

					.footonebottom_two {
						position: absolute;
						top: 10px;
						font-size: 10px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
					}
				}
			}
		}
	}

	/*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
	@media (min-width: 768px) and (max-width: 991px) {
		.footone {
			width: 100%;
			height: auto;
			background: #16151D;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.footone_wai {
				width: 50%;
				height: 100%;
				text-align: center;

				.footontop {
					width: 80%;
					height: auto;

					.one {
						font-size: 25px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 50px;
					}

					.two {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 10px;
					}
				}

				.footonebottom {
					width: 80%;
					height: auto;
					// margin-top: 30px;
					margin-left: -2px;
					position: relative;

					.footonebottom_one {
						font-size: 60px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}

					.footonebottom_two {
						position: absolute;
						top: 10px;
						font-size: 10px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
					}
				}
			}
		}
	}

	// 在电脑1200px大
	@media (min-width: 1200px) {
		.foottwo {
			width: 100%;
			height: 170px;
			background: #16151D;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;

			.foottwo_wai {
				width: 20%;
				text-align: center;

				.foottwotop {
					width: 80%;
					height: auto;
					margin: 0 auto;

					.tow {
						margin-top: 10px;
						font-size: 18px;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	/*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
	@media (min-width: 992px) and (max-width: 1199px) {
		.foottwo {
			width: 100%;
			height: auto;
			background: #16151D;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.foottwo_wai {
				width: 33%;
				height: 130px;
				text-align: center;

				.foottwotop {
					width: 80%;
					height: auto;
					margin: 0 auto;
					position: relative;

					.one {
						position: absolute;
						top: 55px;
						left: 55px;
					}

					.two {
						width: 200px;
						height: 21px;
						position: absolute;
						top: 72px;
						left: 134px;
						font-size: 22px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	/*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
	@media (min-width: 768px) and (max-width: 991px) {
		.foottwo {
			width: 100%;
			height: auto;
			background: #16151D;
			display: inline-flex;
			flex-wrap: wrap;


			.foottwo_wai {
				width: 63%;
				height: 130px;
				position: relative;
				left: 37%;

				.foottwotop {
					width: 80%;
					height: auto;

					.one {
						position: absolute;
						top: 55px;
						left: 55px;
					}

					.two {
						width: 295px;
						height: 21px;
						position: absolute;
						top: 72px;
						left: 134px;
						font-size: 22px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	@media (min-width: 1200px) {
		.footthree {
			width: 100%;
			height: 268px;
			background-color: #16151D;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.footthree_wai1 {
				width: 12%;
				line-height: 64px;
				margin-left: 5%;

				.loimg {
					width: 34px;
					height: 34px;
					margin-top: 20px;
					margin-right: 14px;
				}

				.tit {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.footthree_wai2 {
				width: 80%;
				height: 100%;
				margin-left: 5%;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						// margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai3 {
				width: 20%;
				height: 100%;
				margin-left: 77px;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						// margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai4 {
				width: 15%;
				height: 100%;

				.lianxi {
					margin-top: 44px;
					margin-left: 30px;
				}

				.text {
					margin-top: 15px;
					width: 212px;
					height: 14px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}

	}

	@media (min-width: 992px) and (max-width: 1199px) {
		.footthree {
			width: 100%;
			height: 268px;
			background-color: #16151D;
			display: flex;
			flex-wrap: wrap;

			.footthree_wai1 {
				width: 100%;
				line-height: 64px;
				display: flex;
				justify-content: space-around;

				.loimg {
					width: 34px;
					height: 34px;
					margin-top: 20px;
					margin-right: 14px;
				}

				.tit {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.footthree_wai2 {
				width: 64%;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 201px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai3 {
				width: 30%;
				margin-left: 77px;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 11px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai4 {
				width: 100%;
				text-align: center;

				.lianxi {
					align-items: center;
				}

				.text {
					margin-top: 15px;
					width: 100%;
					height: 14px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 991px) {
		.footthree {
			width: 100%;
			height: 268px;
			background-color: #16151D;
			display: flex;
			flex-wrap: wrap;

			.footthree_wai1 {
				width: 100%;
				line-height: 64px;
				display: flex;
				justify-content: space-around;

				.loimg {
					width: 34px;
					height: 34px;
					margin-top: 20px;
					margin-right: 14px;
				}

				.tit {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.footthree_wai2 {
				width: 64%;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 201px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai3 {
				width: 30%;
				margin-left: 77px;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 11px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai4 {
				width: 100%;
				text-align: center;

				.lianxi {
					align-items: center;
				}

				.text {
					margin-top: 15px;
					width: 100%;
					height: 14px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}
	}

	@media (max-width:768px) {
		.footone {
			width: 100%;
			height: 220px;
			background: #16151D;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.footone_wai {
				width: 25%;
				height: 100%;
				text-align: center;

				.footontop {
					width: 80%;
					height: auto;

					.one {
						font-size: 25px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 50px;
					}

					.two {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 10px;
					}
				}

				.footonebottom {
					width: 80%;
					height: auto;
					// margin-top: 30px;
					margin-left: -2px;
					position: relative;

					.footonebottom_one {
						font-size: 60px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}

					.footonebottom_two {
						position: absolute;
						top: 10px;
						font-size: 10px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
					}
				}
			}
		}

		.foottwo {
			width: 100%;
			height: auto;
			background: #16151D;
			display: inline-flex;
			flex-wrap: wrap;


			.foottwo_wai {
				width: 63%;
				height: 130px;
				position: relative;
				left: 37%;

				.foottwotop {
					width: 80%;
					height: auto;

					.one {
						position: absolute;
						top: 55px;
						left: 55px;
					}

					.two {
						width: 295px;
						height: 21px;
						position: absolute;
						top: 72px;
						left: 134px;
						font-size: 22px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}

		.footthree {
			width: 100%;
			height: 268px;
			background-color: #16151D;
			display: flex;
			flex-wrap: wrap;

			.footthree_wai1 {
				width: 100%;
				line-height: 64px;
				display: flex;
				justify-content: space-around;

				.loimg {
					width: 34px;
					height: 34px;
					margin-top: 20px;
					margin-right: 14px;
				}

				.tit {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.footthree_wai2 {
				width: 64%;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 201px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai3 {
				width: 30%;
				margin-left: 77px;

				.botfootpc {
					color: #FFFFFF;
					margin-top: 19px;
					justify-content: space-between;
					margin-left: 11px;

					ol {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						padding: 0;
						margin-bottom: 40px;
					}

					li {
						list-style-type: none;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 12px;
					}
				}
			}

			.footthree_wai4 {
				width: 100%;
				text-align: center;

				.lianxi {
					align-items: center;
				}

				.text {
					margin-top: 15px;
					width: 100%;
					height: 14px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}
	}

	.footfour {
		width: 100%;
		height: 80px;
		background-color: #16151D;
		text-align: center;

		.four_tetx {
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			padding-top: 36px;
			color: #FFFFFF;
		}
	}
</style>