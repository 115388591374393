<template>
	<div class="register">
		<div class="img_box"></div>
		<div class="box">
			<div class="box_left">
				<img src="../../assets/logo.png" />
				<div class="tit">移动端领域多端技术源码服务解决商</div>
			</div>
			<div class="boxlogin">
				<!-- 登录 -->
				<div class="login" v-if="loginshow">
					<div class="logtit">
						<div class="">{{loginname}}</div>
						<div class="line"></div>
					</div>
					<div style="position: relative;margin-top: 50px;" v-if="islogin">
						<div class="logintab">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane label="密码登录" name="first">
									<div>
										<div class="mobeboxk " style="margin-top:25px;">
											<el-input v-model="loginuser" placeholder="请输入手机号" />
										</div>
										<div class="mobeboxk " style="margin-top:20px;">
											<el-input v-model="loginpassword" placeholder="请输入登录密码" type="password"
												show-password />
										</div>
										<div class="zhuce" @click="login()">登录</div>
										<div class="flex align-center justify-between"
											style="color: #999999;font-size: 14px;margin-top: 10px;">
											<div @click="openmimaa">忘记密码 </div>
											<div @click="openzc">快速注册 </div>
										</div>
										<div style="text-align: center;" @click="opencode">
											<div style="color: #999999;font-size: 14px;margin: 10px 0;">第三方账户登录</div>
											<img src="../../assets/login/wx.png" style="width: 30px;" />
										</div>

										<div
											style="text-align: center;color: #999999;font-size: 14px;margin-top: 30px;">
											————
											西安省钱兄网络科技有限公司 ————</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="验证码登录" name="second">
									<div>
										<div class="mobeboxk">
											<el-input v-model="phoneuser" placeholder="请输入手机号" />
										</div>
										<div class="inputbox justify-between">
											<!-- <div class="boxname">验证码</div> -->
											<div class="boxks">
												<el-input v-model="registercode" placeholder="请输入验证码" />
											</div>
											<div class="yzmbtn" v-if="msg=='获取验证码'" @click="getcodeb">{{msg}}</div>
											<div class="yzmbtn" v-else>{{msg}}</div>
										</div>
										<div class="zhuce" @click="phonelogin">登录</div>
										<div class="flex align-center justify-between"
											style="color: #999999;font-size: 14px;margin-top: 10px;">
											<div @click="openmimaa">忘记密码 </div>
											<div @click="openzc">快速注册 </div>
										</div>
										<div style="text-align: center;" @click="opencode">
											<div style="color: #999999;font-size: 14px;margin: 10px 0;">第三方账户登录</div>
											<img src="../../assets/login/wx.png" style="width: 30px;" />
										</div>
										<div
											style="text-align: center;color: #999999;font-size: 14px;margin-top: 30px;">
											————
											西安省钱兄网络科技有限公司 ————</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<div class="logma" @click="opencode">
							<img src="../../assets/login/ma.png" />
						</div>
					</div>
					<!-- 找回密码 -->
					<div class="logintab " style="margin-top: 50px;" v-if="ispassword">
						<el-tabs v-model="activeName" class="demo-tabs">
							<el-tab-pane label="找回密码" name="first">
								<div>
									<div class="flex align-center justify-between">
										<div style="font-size: 14px;">请填写您需要找回的手机号</div>
									</div>
									<div class="mobeboxk " style="margin-top: 20px;">
										<el-input v-model="yz" placeholder="请输入手机号" />
									</div>
									<div class="zhuce" @click="openphone()">下一步</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
					<!-- 安全验证 -->
					<div style="margin-top: 50px;position: relative;" v-if="isphoneyz">
						<div class="logintab ">
							<el-tabs v-model="activeName" class="demo-tabs">
								<el-tab-pane label="安全验证" name="first">
									<div>
										<div style="color: #999999;font-size: 14px;">
											您的帐号可能存在安全风险，为了确保为您本人操作，请先 进行安全验证。
										</div>
										<div class="inputbox ">
											<div class="boxname">验证方式</div>
											<div class="boxks">
												<el-input v-model="yz" placeholder="请输入手机号" />
											</div>
										</div>
										<div class="inputbox ">
											<div class="boxname" style="width: 21%;">验证码</div>
											<div class="boxks">
												<el-input v-model="registercode" placeholder="请输入验证码" />
											</div>
											<div class="yzmbtn" v-if="msg=='获取验证码'" @click="getcoden">{{msg}}</div>
											<div class="yzmbtn" v-else>{{msg}}</div>
										</div>
										<div class="zhuce" @click="openpass">下一步</div>

									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<div class="logclose" @click="gobackphone">
							<img src="../../assets/login/colse.png" style="width: 16px;" />
						</div>
					</div>
					<!-- 找回密码 -->
					<div style="margin-top: 50px;position: relative;" v-if="isphonepass">
						<div class="logintab ">
							<el-tabs v-model="activeName" class="demo-tabs">
								<el-tab-pane label="找回密码" name="first">
									<!-- 设置密码 -->
									<div v-if="setmima">
										<div style="color: #999999;font-size: 14px;">
											您正在找回的账号是：{{yz}}
										</div>
										<div class="inputbox">
											<div class="boxname">新密码</div>
											<div class="boxk">
												<el-input v-model="newpassword" placeholder="请输入新密码" type="password"
													show-password />
												<div class="huit">
													至少包含一个字母/数字/标点符号，不允许 有空格、中文</div>
											</div>
										</div>
										<div class="inputbox " style="margin-top: 50px;">
											<div class="boxname">确认密码</div>
											<div class="boxk">
												<el-input v-model="newpwd" placeholder="请输入确认密码" type="password"
													show-password  /> <!-- @blur="checkInput1" -->
											</div>
										</div>
										<div class="zhuce " style="margin-top:35px;" @click="openmimadl">确定</div>
										<div class="zhuce" @click="open" style="background-color: #ccc;">返回上一步</div>
									</div>
									<!-- 设置成功 密码登录 -->
									<div v-if="mimadl">
										<div class="flex align-center justify-center" style="margin-top: 25px;">
											<img src="../../assets/login/mmaok.png" alt="" srcset=""
												style="width: 25px;">
											<div style="color: #333333;font-size: 14px;margin-left: 5px;">
												恭喜！您的账号重置密码成功
											</div>
										</div>
										<div class="zhuce " style="margin-top:35px;" @click="gobackphone">确定</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
				</div>
				<!-- 注册 -->
				<div class="login" v-if="zcshow">
					<div class="logtit">
						<div class="">注册</div>
						<div class="line"></div>
					</div>
					<div style="position: relative;">

						<div class="logintab">
							<el-tabs v-model="activeName" class="demo-tabs">
								<el-tab-pane label="快速注册" name="first">
									<div>
										<div class="inputbox">
											<div class="boxname">用户名</div>
											<div class="boxk">
												<el-input v-model="registerusername" placeholder="请设置用户名" />
											</div>
										</div>
										<div class="inputbox">
											<div class="boxname">手机号</div>
											<div class="boxk">
												<el-input v-model="registermobile" placeholder="可用于登录或找回密码" />
											</div>
										</div>
										<div class="inputbox">
											<div class="boxname">密 码</div>
											<div class="boxk">
												<el-input v-model="registerpassword" placeholder="请设置登录密码"
													type="password" show-password />
												<div class="huit">
													至少包含一个字母/数字/标点符号，不允许 有空格、中文。</div>
											</div>
										</div>
										<div class="inputbox " style="margin-top: 50px;">
											<div class="boxname">邮箱</div>
											<div class="boxk">
												<el-input v-model=" registeremail" placeholder="请输入常用邮箱地址" />
											</div>
										</div>
										<div class="inputbox justify-between">
											<div class="boxname">验证码</div>
											<div class="boxks">
												<el-input v-model="registercode" placeholder="请输入验证码" />
											</div>
											<div class="yzmbtn" v-if="msg=='获取验证码'" @click="getcode">{{msg}}</div>
											<div class="yzmbtn" v-else>{{msg}}</div>
										</div>
										<div class="zhuce" @click="registerr">注册</div>
										<div style="text-align: center;margin-top: 10px;">
											<el-checkbox v-model="registerchecked2"
												:change='handleCheckAllChange(registerchecked2)'>
												<div style="color: #999999;font-size: 12px;">
													我仔细阅读并同意网站 <text style="color: #0D68FC;" @click="fuuw">服务条款</text> 与
													<text style="color: #0D68FC;" @click="mianze">免责申明</text>
												</div>
											</el-checkbox>

										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<div class="log" @click="opendl">已有账户? <span>登录</span> </div>
					</div>
				</div>

				<!-- 扫码登录 -->
				<div class="login" v-if="codeshow">
					<div class="logtit">
						<div style="font-size: 18px;">手机扫码，安全登录</div>
						<div class="line"></div>
					</div>
					<div style="position: relative;margin-top:80px;">
						<div class="logpcma" @click="openpclogin">
							<img src="../../assets/login/pcmx.png" style="width: 90px;" />
						</div>
						<div class="logintab">
							<div style="text-align: center;margin-top:60px;">
								<!-- 扫码登录 -->
								<img :src="saomacord" alt="" srcset="" style="width: 150px;height: 150px;">
							</div>
							<div class="flex align-center justify-center" style="margin-top: 5px;">
								<img src="../../assets/login/saosao.png" style="width: 20px;height: 19px;" />
								<div style="font-size: 15px;margin-left: 8px;">打开 微信 扫一扫登录</div>
							</div>

							<div class="flex align-center justify-between"
								style="color: #999999;font-size: 14px;margin-top: 20px;padding: 0 50px;">
								<div @click="openmima">密码登录 </div>
								<div @click="openzc">快速注册 </div>
							</div>
							<div style="text-align: center;color: #999999;font-size: 14px;margin-top: 30px;">
								————
								西安省钱兄网络科技有限公司 ————</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<el-dialog v-model="dialogVisible" title="服务条款" width="30%" :before-close="handleClose" center>
		<div v-html="fuwutioakuamg"></div>
	</el-dialog>
	<el-dialog v-model="dialogVisiblev" title="免责申明" width="30%" :before-close="handleClose" center>
		<div v-html="mainzeshengming"></div>
	</el-dialog>
</template>


<script>
	import axios from 'axios';
	import {
		ElMessage
	} from 'element-plus'
	export default {
		name: "login",
		data() {
			return {
				intervalId: '',
				loginname: '登录',
				fuwutioakuamg: '',
				mainzeshengming: '',
				dialogVisible: false,
				dialogVisiblev: false,
				zcshow: false, //注册
				loginshow: true, //登录
				islogin: true, //账号登录
				ispassword: false, //密码找回
				isphoneyz: false, //安全验证
				isphonepass: false, //找回密码
				setmima: false, //设置密码
				mimadl: false, //密码登录
				codeshow: false, //扫码登录
				name: '',
				saomacord: '',
				fullWidth: window.innerWidth,
				fullHeight: window.innerWidth,
				activeName: 'first',
				input: '',
				yz: '',
				invitation_code: '',
				//注册组件
				registerusername: '',
				registermobile: '',
				registerpassword: '',
				registeremail: '',
				registercode: '',
				timer: null,
				msg: '获取验证码',
				countdown: 60,
				registerchecked2: false,
				//登录组件
				loginuser: '',
				loginpassword: '',
				//手机验证码登录
				phoneuser: '',
				phonecode: '',
				newpassword: '',
				newpwd: '',
				captcha: ''
			}
		},
		mounted() {
			this.name = this.$route.query.name;
		},
		methods: {
			fuuw() {
				this.dialogVisible = !this.dialogVisible
				this.fuuww()
			},
			fuuww() {
				this.$http.get('/index/common/sys_common?type_id=246').then(res => {

					this.fuwutioakuamg = res.data.data.value

				});
			},
			mianze() {
				this.dialogVisiblev = !this.dialogVisiblev
				this.mianzee()
			},
			mianzee() {
				this.$http.get('/index/common/sys_common?type_id=247').then(res => {

					this.mainzeshengming = res.data.data.value

				});
			},
			//注册接口
			async registerr() {
				if (this.registerusername == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入用户名",
						duration: 1500,
						offset: 50
					});
					return
				}
				if (this.registermobile == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入注册手机号",
						duration: 1500,
						offset: 50
					});
					return
				}
				if (this.registerpassword == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入注册密码",
						duration: 1500,
						offset: 50
					});
					return
				}
				// 检查密码是否满足其他条件
				var reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{4,}$/;
				if (!reg.test(this.registerpassword)) {
					this.$notify.error({
						title: "注册提示",
						message: "密码设置不正确",
						duration: 1500,
						offset: 50
					});
					return;
				}
				if (this.registeremail == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入注册邮箱",
						duration: 1500,
						offset: 50
					});
					return;
				} else {
					var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
					if (!reg.test(this.registeremail)) {
						this.$notify.error({
							title: "注册提示",
							message: "请输入正确的注册邮箱",
							duration: 1500,
							offset: 50
						});
						return;
					}
				}
				if (this.registercode == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入验证码",
						duration: 1500,
						offset: 50
					});
					return
				}
				if (this.registerchecked2 == false) {
					this.$notify.error({
						title: "注册提示",
						message: "请勾选服务条款",
						duration: 1500,
						offset: 50
					});
					return
				}
				const {
					data: res
				} = await this.$http.post('index/user/register', {
					username: this.registerusername,
					password: this.registerpassword,
					mobile: this.registermobile,
					email: this.registeremail,
					code: this.registercode,
					invitation_code: this.invitation_code || ''
				})
				if (res.code == 1) {
					ElMessage({
						message: '注册成功',
						type: 'success',
					})
					//token
					window.sessionStorage.setItem('token', res.data.userinfo.token)
					this.$cookie.set('token', res.data.userinfo.token)
					//头像
					this.$cookie.set('avatar', res.data.userinfo.avatar)
					window.sessionStorage.setItem('avatar', res.data.userinfo.avatar)
					//用户id
					this.$cookie.set('userid', res.data.userinfo.id)
					//昵称
					this.$cookie.set('username', res.data.userinfo.username)
					//性别
					this.$cookie.set('gender', res.data.userinfo.gender)
					//邮箱
					this.$cookie.set('email', res.data.userinfo.email)
					//编号
					this.$cookie.set('serialnumber', res.data.userinfo.serial_number)
					//电话
					this.$cookie.set('phone', res.data.userinfo.mobile)
					this.$cookie.set('is_vip_type', res.data.userinfo.is_vip_type)
					window.sessionStorage.setItem('is_vip_type', res.data.userinfo.is_vip_type)
					this.$router.push({
						path: '/',
					})
					this.registerusername = ''
					this.registerpassword = ''
					this.registermobile = ''
					this.registeremail = ''
					this.registercode = ''
				} else if (res.code == 0) {
					ElMessage({
						message: res.msg,
						type: 'warning',
					})
				}
			},
			//登录接口
			async login() {
				if (this.loginuser == '') {
					this.$notify.error({
						title: "登录提示",
						message: "请输入手机号",
						duration: 1500,
						offset: 50
					});
					return
				}
				if (this.loginpassword == '') {
					this.$notify.error({
						title: "登录提示",
						message: "请输入登录密码",
						duration: 1500,
						offset: 50
					});
					return
				}
				const {
					data: res
				} = await this.$http.post('index/user/mobile_pass_login', {
					mobile: this.loginuser,
					password: this.loginpassword
				})
				if (res.code == 0) {
					ElMessage({
						message: res.msg,
						type: 'warning',
						duration: 1500,
					})
					this.loginuser = ''
					this.loginpassword = ''
				} else {
					ElMessage({
						message: '登录成功',
						type: 'success',
						duration: 1500,
					})
					//token
					window.sessionStorage.setItem('token', res.data.userinfo.token)
					this.$cookie.set('token', res.data.userinfo.token)
					//头像
					this.$cookie.set('avatar', res.data.userinfo.avatar)
					window.sessionStorage.setItem('avatar', res.data.userinfo.avatar)
					//用户id
					this.$cookie.set('userid', res.data.userinfo.id)
					//昵称
					this.$cookie.set('username', res.data.userinfo.username)
					//性别
					this.$cookie.set('gender', res.data.userinfo.gender)
					//邮箱
					this.$cookie.set('email', res.data.userinfo.email)
					//编号
					this.$cookie.set('serialnumber', res.data.userinfo.serial_number)
					//电话
					this.$cookie.set('phone', res.data.userinfo.mobile)
					this.$cookie.set('is_vip_type', res.data.userinfo.is_vip_type)
					window.sessionStorage.setItem('is_vip_type', res.data.userinfo.is_vip_type)
					this.$router.push({
						path: '/',
					})
				}
			},
			//手机验证码登录
			async phonelogin() {
				if (this.phoneuser == '') {
					this.$notify.error({
						title: "登录提示",
						message: "请输入手机号",
						duration: 1500,
						offset: 50
					});
					return
				}
				if (this.registercode == '') {
					this.$notify.error({
						title: "注册提示",
						message: "请输入验证码",
						duration: 1500,
						offset: 50
					});
					return
				}
				const {
					data: res
				} = await this.$http.post('index/user/mobilelogin', {
					mobile: this.phoneuser,
					captcha: this.registercode,
					invitation_code: this.invitation_code
				})
				if (res.code == 0) {
					ElMessage({
						message: res.msg,
						type: 'warning',
						duration: 1500,
					})
					this.phoneuser = ''
					this.phonecode = ''
				} else {
					ElMessage({
						message: '登录成功',
						type: 'success',
						duration: 1500,
					})
					//token
					window.sessionStorage.setItem('token', res.data.userinfo.token)
					this.$cookie.set('token', res.data.userinfo.token)
					//头像
					this.$cookie.set('avatar', res.data.userinfo.avatar)
					window.sessionStorage.setItem('avatar', res.data.userinfo.avatar)
					//昵称
					this.$cookie.set('username', res.data.userinfo.username)
					//性别
					this.$cookie.set('gender', res.data.userinfo.gender)
					//邮箱
					this.$cookie.set('email', res.data.userinfo.email)
					//编号
					this.$cookie.set('serialnumber', res.data.userinfo.serial_number)
					//等级
					this.$cookie.set('level_cn', res.data.userinfo.level_cn)
					this.$cookie.set('is_vip_type', res.data.userinfo.is_vip_type)
					window.sessionStorage.setItem('is_vip_type', res.data.userinfo.is_vip_type)
					this.$router.push({
						path: '/',
					})
				}
			},
			//获取验证码
			getcode() {


				if (this.registermobile == '') {
					this.$notify.info({
						title: "验证码",
						message: '请先输入手机号再获取验证码',
						duration: 3500,
						offset: 50
					});
					return;
				}
				if (this.timer) {
					// clearInterval(this.timer);
					// this.timer = null;
					return;
				}
				this.$http.post('/index/sms/send', {
					mobile: this.registermobile
				}).then(res => {

					if (res.data.code == 1) {
						this.$message({
							message: '发送成功',
							type: 'success'
						});
						this.daojishi()
						return
						let timer = setInterval(() => {
							this.msg = '剩余(' + --this.countdown + ')秒';
							if (this.countdown <= 0) {
								clearInterval(timer);
								this.msg = '获取验证码';
								this.registercode = '';
								this.timer = null;
								this.countdown = 60
							}
						}, 1000);
						this.timer = timer;
					} else {
						this.$notify.error({
							title: "验证码",
							message: '发送验证码失败' + res.data.msg,
							duration: 3500,
							offset: 50
						});
					}
				})
			},
			// 倒计时
			daojishi() {
				let timer = setInterval(() => {
					this.msg = '剩余(' + --this.countdown + ')秒';
					if (this.countdown <= 0) {
						clearInterval(this.timer);
						this.msg = '获取验证码';
						this.registercode = '';
						this.timer = null;
						this.countdown = 60
					}
				}, 1000);
			},
			getcodeb() {
				if (this.phoneuser == '') {
					this.$notify.info({
						title: "验证码",
						message: '请先输入手机号再获取验证码',
						duration: 3500,
						offset: 50
					});
					return;
				}
				if (this.timer) {
					// clearInterval(this.timer);
					// this.timer = null;
					return;
				}
				this.$http.post('/index/sms/send', {
					mobile: this.phoneuser
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: '发送成功',
							type: 'success'
						});
						this.daojishi()
						return
						// let timer = setInterval(() => {
						//   this.msg = '剩余(' + --this.countdown + ')秒';
						//   if (this.countdown <= 0) {
						//     clearInterval(timer);
						//     this.msg = '获取验证码';
						//     this.registercode = '';
						//     this.timer = null;
						//     this.countdown = 60
						//   }
						// }, 1000);
						// this.timer = timer;
					} else {
						this.$notify.error({
							title: "验证码",
							message: '发送验证码失败' + res.data.msg,
							duration: 3500,
							offset: 50
						});
					}
				})
			},
			getcoden() {

				if (this.yz == '') {
					this.$notify.info({
						title: "验证码",
						message: '请先输入手机号再获取验证码',
						duration: 3500,
						offset: 50
					});
					return;
				}
				if (this.timer) {
					// clearInterval(this.timer);
					// this.timer = null;
					return;
				}
				this.$http.post('/index/sms/send', {
					mobile: this.yz
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: '发送成功',
							type: 'success'
						});
						this.daojishi()
						return
						//        let timer = setInterval(() => {
						// this.countdown = 60
						//          this.msg = '剩余(' + --this.countdown + ')秒';
						//          if (this.countdown <= 0) {
						//            clearInterval(timer);
						//            this.msg = '获取验证码';
						//            this.registercode = '';
						//            this.timer = null;

						//          }
						//        }, 1000);
						//        this.timer = timer;
					} else {
						this.$notify.error({
							title: "验证码",
							message: '发送验证码失败' + res.data.msg,
							duration: 3500,
							offset: 50
						});
					}
				})
			},
			openmimaa() { //忘记密码
				this.loginname = '找回密码'
				this.activeName = 'first'
				this.loginshow = true
				this.islogin = false
				this.ispassword = true
				this.codeshow = false
			},
			openzc() { //打开注册
				this.activeName = 'first'
				this.loginshow = false
				this.zcshow = true
				this.codeshow = false
				this.msg = '获取验证码'
				clearInterval(this.timer);
			},
			opendl() { //返回登录
				this.activeName = 'first'
				this.loginshow = true
				this.zcshow = false
				this.islogin = true
				this.ispassword = false
			},
			openmima() { //找回密码
				this.activeName = 'first'
				this.loginshow = true
				this.islogin = true
				this.ispassword = false
				this.codeshow = false
				this.msg = '获取验证码'
				clearInterval(this.timer);
			},
			openphone() { //安全验证
				if (this.yz == '') {
					this.$notify.error({
						title: "提示",
						message: '手机号不能为空',
						duration: 3500,
						offset: 50
					});
					return
				} else {
					var reg = /^1[3-9]\d{9}$/;
					if (!reg.test(this.yz)) {
						this.$notify.error({
							title: "提示",
							message: "请输入正确的手机号",
							duration: 1500,
							offset: 50
						});
						return;
					}
				}
				this.activeName = 'first'
				this.loginshow = true
				this.islogin = false
				this.ispassword = false
				this.isphoneyz = true
			},
			gobackphone() { //关闭验证  返回手机登录
				this.activeName = 'first'
				this.loginshow = true
				this.islogin = true
				this.ispassword = false
				this.isphoneyz = false

				this.isphonepass = false
				this.msg = '获取验证码'
				clearInterval(this.timer);
			},
			// 返回上一步
			open() {
				this.activeName = 'first'
				this.loginshow = true
				this.islogin = false
				this.ispassword = false
				this.isphoneyz = true
				this.isphonepass = false
				this.msg = '获取验证码'
			},
			openpass() { //找回密码
				if (this.registercode == '') {
					this.$notify.error({
						title: "提示",
						message: '验证码不能为空',
						duration: 3500,
						offset: 50
					});
					return
				}
				this.activeName = 'first'
				this.loginshow = true
				this.isphoneyz = false
				this.isphonepass = true
				this.setmima = true
				this.mimadl = false
			},
			checkInput1() {
				// 检查两次输入的密码是否一致  
				if (this.newpassword !== this.newpwd) {
					this.$notify.error({
						title: "找回密码提示",
						message: '两次密码不一致请重新输入',
						duration: 3500,
						offset: 50
					});
					return;
				}
				// 检查密码是否满足其他条件  
				var reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{4,}$/;
				if (!reg.test(this.newpassword)) {
					this.$notify.error({
						title: "找回密码提示",
						message: "密码设置不正确",
						duration: 1500,
						offset: 50
					});
					return;
				}
				// 所有检查都通过，显示成功消息  
				// this.$notify.success({
				// 	title: "找回密码提示",
				// 	message: '密码设置成功',
				// 	duration: 3500,
				// 	offset: 50
				// });
			},
			async openmimadl() { //密码设置ok 登录
				if (this.newpassword == '') {
					this.$notify.error({
						title: "找回密码提示",
						message: '请输入设置密码',
						duration: 3500,
						offset: 50
					});
					return
				}
				if (this.newpwd == '') {
					this.$notify.error({
						title: "找回密码提示",
						message: '请输入设置密码',
						duration: 3500,
						offset: 50
					});
					return
				}
				// 检查两次输入的密码是否一致
				if (this.newpassword !== this.newpwd) {
					this.$notify.error({
						title: "找回密码提示",
						message: '两次密码不一致请重新输入',
						duration: 3500,
						offset: 50
					});
					return;
				}
				const {
					data: res
				} = await this.$http.post('index/user/resetpwd', {
					mobile: this.yz,
					newpassword: this.newpassword,
					newpwd: this.newpwd,
					captcha: this.registercode
				})
				console.log(res)
				if (res.code == 1) {
					this.$message({
						title: "提示",
						message: '操作成功',
						type: 'success'
					});
					this.yz = ''
					this.newpassword = ''
					this.newpwd = ''
					this.captcha = ''
					this.setmima = false
					this.mimadl = true
					return

				} else {
					this.$message({
						title: "验证提示",
						message: res.msg,
						type: 'warning',
					});
					return
					this.zcshow = false //注册
					this.loginshow = true //登录
					this.islogin = true //账号登录
					this.ispassword = false //密码找回
					this.isphoneyz = false //安全验证
					this.isphonepass = false //找回密码
					this.setmima = false //设置密码
					this.mimadl = false //密码登录
					this.codeshow = false //扫码登录
				}
			},
			opencode() { //扫码
				this.islogin = false
				this.loginshow = false
				this.codeshow = true
				this.callApi()
			},
			async callApi() {
				const response = await axios.get('https://ymshopapi.xianmaxiong.com/api/index/wxlogin/qrcode');
				if (response.status === 200) {
					this.saomacord = response.data.data.qrcode;
					this.scene_id = response.data.data.scene_id;
					if (this.scene_id) {
						this.intervalId = setInterval(this.repeatedApiCall, 200);
					}
				}
			},
			repeatedApiCall() {
				this.$http.post('/index/wxlogin/find_user_scene', {
						scene_id: this.scene_id
					})
					.then((res) => {
						if (res.data.code == 1) {
							clearInterval(this.intervalId);
							console.log(res.data.data);
							// token  
							window.sessionStorage.setItem('token', res.data.data.token);
							this.$cookie.set('token', res.data.data.token);
							// 头像  
							this.$cookie.set('avatar', res.data.data.avatar);
							window.sessionStorage.setItem('avatar', res.data.data.avatar);
							// 昵称  
							this.$cookie.set('username', res.data.data.username);
							// 性别  
							this.$cookie.set('gender', res.data.data.gender);
							// 邮箱  
							this.$cookie.set('email', res.data.data.email);
							// 编号  
							this.$cookie.set('serialnumber', res.data.data.serial_number);
							// 等级  
							this.$cookie.set('level_cn', res.data.data.level_cn);
							this.$cookie.set('is_vip_type', res.data.data.is_vip_type);
							window.sessionStorage.setItem('is_vip_type', res.data.data.is_vip_type);
							this.$router.push({
								path: '/'
							});
						}
					});
			},

			openpclogin() { //打开pc端登录
				this.islogin = true
				this.loginshow = true
				this.codeshow = false
			},
			handleClick(tab, event) {
				console.log(tab, event)
			},
			goBack() {
				this.$router.go(-1)
			},
			// /定义数据获取方法
			handleResize() {
				this.fullWidth = window.innerWidth
				this.fullHeight = window.innerHeight
			},
			handleCheckAllChange(val) {
				this.registerchecked2 = val
				console.log(this.registerchecked2, ' this.registerchecked2 this.registerchecked2')
			},
		},
		created() {
			window.addEventListener('resize', this.handleResize)
		},
		beforeUnmount() {
			window.removeEventListener('resize', this.handleResize)
		}
	}
</script>

<style lang="less" scoped>
	@import '../../assets/css/login.css';

	/deep/.el-input__suffix-inner {
		background-color: #F5F6FA !important;
	}

	.register {
		width: 100%;
		height: 100vh;
		//固定外层盒子，不受其他盒子影响
		//放在最底层
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;

		.img_box {
			//这里外层盒子已经固定定位了，其实可以不加定位
			width: 100%;
			height: 1920px;
			position: absolute;
			// background-image: url(./assets/ash98-n8ely.jpg);
			background-image: url('../../assets/login/loginbg.png');
			background-size: cover;
			background-repeat: no-repeat;
			//保证盒子始终在窗口中心
			background-position: center center;
			//避免窗口周围留白
			z-index: -1;
		}
	}

	.log {
		position: absolute;
		top: 10px;
		right: 0;
		z-index: 9;
		font-size: 13px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999;

		span {
			color: #0D68FC;
		}
	}

	.logma {
		position: absolute;
		top: -10px;
		right: 0;

		img {
			width: 40px;
		}
	}

	.logpcma {
		position: absolute;
		top: -70px;
		right: -20px;
	}

	/deep/.el-tabs__item.is-active {
		color: #333333 !important;
		font-size: 15px !important;
		font-weight: bold !important;
	}

	/deep/.el-input__wrapper {
		padding: 0 !important;
	}

	/deep/.el-tabs__item {
		color: #999999 !important;
	}

	/deep/.el-input__inner {
		color: #333333 !important;
	}
</style>