<template>
  <div class="content">
    <!-- 上部分 -->
    <div class="balance_top">
      <div class="balance_toptitle">
        <span class="budeg"></span>
        <span class="budegtitle">账户余额</span>
      </div>
      <div class="balance_topnumber">
        <div style="width: 200px; text-align: center;">
          <div style="font-size: 16px;font-family: Source Han Sans CN;color: #666666;">当前余额</div>
          <div style="font-size: 24px;font-weight: bold;color: #0D68FC;">{{number1}}元</div>
        </div>
        <div style="display: inline-flex; margin-top: 10px;">
          <div
            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin: 3px 10px  0 0;">
            可用余额</div>
          <div style="font-size: 20px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;">{{number2}}元
          </div>
        </div>
        <div style="display: inline-flex;margin-top: 10px;">
          <div
            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin: 3px 10px  0 0;">
            可提现余额</div>
          <div style="font-size: 20px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;">{{number3}}元
          </div>
        </div>
        <div class="button3" @click="chongzhi()" style="margin-top: -14px;">
          <text>充值</text>
        </div>
        <div class="button4" @click="txmney()"  style="margin-left: -126px;margin-top: -14px;">
          <text>提现</text>
        </div>

      </div>
    </div>
    <!-- 下半部分 -->
    <div class="balance_bottom">
      <div class="balance_bottomtitle">
        <div style="float: left; display: flex;font-size: 16px;font-family: Source Han Sans CN;color: #999999; ">
          <div style="margin-right:29px;">
            <div :class="current==1?'select':''" @click="confirm(1)">交易明细</div>
            <div :class="current==1?'hengxian':''"></div>
          </div>
          <div style="margin-right:29px;">
            <div :class="current==2?'select':''" @click="confirm(2)">提现记录</div>
            <div :class="current==2?'hengxian1':''"></div>
          </div>
          <div>
            <div :class="current==3?'select':''" @click="confirm(3)">充值明细</div>
            <div :class="current==3?'hengxian2':''"></div>
          </div>
        </div>
        <div style="float: right;" v-if="current==1">
          <div class="authot_score">
            <div class="authot_scoreq">
              <div class="search">
                <input class='Searchqq' type="text" placeholder="请输入订单编号" v-model="number">
                <button @click="shouquanchaxun(number)" class="button1">
                  <img src="../../assets/my/score.png" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="balance_bottomcon" v-if="current==1">
        <el-table :data="tableData" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
          <el-table-column prop="id" label="序号">
          </el-table-column>
          <el-table-column prop="create_time" label="时间">
          </el-table-column>
          <el-table-column prop="ordername" label="订单名称">
            <template v-slot="scope">
              <span>{{scope.row.goods_info.title}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_num" label="订单编号">
            <template v-slot="scope">
              <text>{{scope.row.order_num}}
                <text class="fuzhuanniu" @click="fuzhi(scope.row.order_num)">
                  <img src="../../assets/fz.png" alt="" />
                </text>
              </text>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="金额（元）">
            <template v-slot="scope">
              <span>{{scope.row.pay_money}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template v-slot="scope">
              <span v-if="scope.row.status==1">待付款</span>
              <span v-if="scope.row.status==2">已付款</span>
              <span v-if="scope.row.status==3">已发货</span>
              <span v-if="scope.row.status==4">待评价</span>
              <span v-if="scope.row.status==5">已评价/已完成 </span>
              <span v-if="scope.row.status==6">退款中</span>
              <span v-if="scope.row.status==7">已退款</span>
              <span v-if="scope.row.status==8">拒绝退款</span>
              <span v-if="scope.row.status==9">已取消</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template v-slot="scope">
              <img src="../../assets/my/delete.png" alt="" width="12" height="12" @click="shopdeletes(scope.row)">
            </template>
          </el-table-column>
        </el-table>


        <div class="pages">
          <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
            v-model:current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
        </div>
      </div>
      <div class="balance_bottomcon" v-if="current==2">
        <el-table :data="tableDataN" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
          <el-table-column prop="ali_order_num" label="交易订单号">
          </el-table-column>
          <el-table-column prop="order_num" label="提现订单号">
            <template v-slot="scope">
              <text>{{scope.row.order_number}}
                <text class="fuzhuanniu" @click="fuzhi(scope.row.order_number)">
                  <img src="../../assets/fz.png" alt="" />
                </text>
              </text>
            </template>
          </el-table-column>
          <el-table-column prop="pay_type" label="提现类型">
            <template v-slot="scope">
              <span v-if="scope.row.pay_type==1">银行卡</span>
              <span v-if="scope.row.pay_type==2">支付宝</span>
              <span v-if="scope.row.pay_type==3">微信</span>
            </template>
          </el-table-column>
          <el-table-column prop="pay_type" label="提现信息" width="250">
            <template v-slot="scope">
              <span v-if="scope.row.pay_type==1">
                <span>
                  <div> 银行卡号:{{scope.row.bank_number}}</div>
                  <div>开户行:{{scope.row.bank_name}}</div>
                  <div>预留电话:{{scope.row.bank_phone}}</div>
                  <div>预留姓名:{{scope.row.bank_username}}</div>
                </span>
              </span>
              <span v-if="scope.row.pay_type==2">
                <span>
                  <div>支付宝账号:{{scope.row.zhifubao}}</div>
                  <div>支付宝名称:{{scope.row.zhifubao_name}}</div>
                </span>
              </span>
              <span v-if="scope.row.pay_type==3">
                <span>
                  <div> <img :src="scope.row.wx_img" alt="" /></div>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="提现金额">
            <template v-slot="scope">
              <span>￥{{scope.row.money}}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_at" label="提现时间">
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template v-slot="scope">
              <span v-if="scope.row.state==-1">退款</span>
              <span v-if="scope.row.state==1">已到账</span>
              <span v-if="scope.row.state==0">代转账</span>
            </template>
          </el-table-column>
          <el-table-column prop="cash_type" label="转账状态">
            <template v-slot="scope">
              <span v-if="scope.row.cash_type==2">线下转账</span>
              <span v-if="scope.row.cash_type==1">线上转账</span>
            </template>
          </el-table-column>
          <el-table-column prop="refund" label="拒绝原因">
          </el-table-column>
          <el-table-column prop="out_at" label="转账/拒绝时间">
          </el-table-column>
          <el-table-column prop="create_at" label="时间">
          </el-table-column>
        </el-table>

        <div class="pages">
          <el-pagination background :total="total" v-model:page-size="pageSizeN" @size-change="handleSizeChangeN"
            v-model:current-page="currentPage" @current-change="handleCurrentChangeN" layout=" prev, pager, next" />
        </div>
      </div>
      <div class="balance_bottomcon" v-if="current==3">
        <el-table :data="tableDataNN" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
          <el-table-column prop="id" label="充值Id" width="80">
          </el-table-column>
          <el-table-column prop="order_num" label="订单号" width="240">
            <template v-slot="scope">
              <text>{{scope.row.order_num}}
                <text class="fuzhuanniu" @click="fuzhi(scope.row.order_num)">
                  <img src="../../assets/fz.png" alt="" />
                </text>
              </text>
            </template>
          </el-table-column>
          <el-table-column prop="trade_no" label="交易订单号">
          </el-table-column>
          <el-table-column prop="type" label="支付类型">
            <template v-slot="scope">
              <span v-if="scope.row.type==1">微信app</span>
              <span v-if="scope.row.type==2">微信公众号支付</span>
              <span v-if="scope.row.type==3">微信小程序支付</span>
              <span v-if="scope.row.type==4">支付宝app</span>
              <span v-if="scope.row.type==5">支付宝H5</span>
            </template>
          </el-table-column>
          <el-table-column prop="classify" label="分类">
            <template v-slot="scope">
              <span v-if="scope.row.classify==1">支付订单</span>
              <span v-if="scope.row.classify==2">充值</span>
              <span v-if="scope.row.classify==3">商户缴纳保证金</span>
              <span v-if="scope.row.classify==4">购买会员</span>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="充值金额">
            <template v-slot="scope">
              <span>￥{{scope.row.money}}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="pay_time" label="充值时间">
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template v-slot="scope">
              <span v-if="scope.row.state==0">待支付</span>
              <span v-if="scope.row.state==1">成功</span>
              <span v-if="scope.row.state==2">失败</span>
            </template>
          </el-table-column>
        </el-table>


        <div class="pages">
          <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChangeNN"
            v-model:current-page="currentPage" @current-change="handleCurrentChangeNN" layout=" prev, pager, next" />
        </div>
      </div>
    </div>
    <el-dialog v-model="tixianshow">
      <div class="distribution">
        <div class="distribution_bottom">
          <div class="tixianmoney">
            <span class="text" style="margin-right:52px;">提现金额</span>
            <span>
              <input type="text" class="inputt" placeholder="请输入提现金额" v-model="numbermoney">
            </span>
            <span>
              <button class="button" @click="alltixian(user_money)">
                <span>
                  全部提现
                </span>
              </button>
            </span>
            <div class="tishi">手续费:{{tixian_money*100}}%，最低提现:{{min_tixian_money}}元，最高提现:{{max_tixian_money}}元</div>
          </div>
          <div class="zhanghaoleixing">
            <span class="text" style="margin-right: 52px;">账号类型</span>
            <span class="paybox">
              <div class="payxbox" v-for="(item,index) in openList" @click="selectWayy(item.id)"
                :class="openWay==item.id?'active':''">
                <img :src="item.imgae" style="width: 20px;height: 20px;" />
                <div class="name">{{item.name}}</div>
                <div class="xz" v-if="openWay==item.id">
                  <img src="../../assets/image/payxz.png" style="width: 22px;height: 22px;" />
                </div>
              </div>
            </span>
          </div>
          <div v-if="zhanghaoid==1">
            <div class="zhifubao">
              <span class="text">银行预留电话</span>
              <span>
                <input type="text" class="inputt" placeholder="请输入银行预留电话" v-model="yinhangtenp">
              </span>
            </div>
            <div class="zhifubao">
              <span class="text">银行预留姓名</span>
              <span>
                <input type="text" class="inputt" placeholder="请输入银行预留姓名" v-model="yinhangname">
              </span>
            </div>
            <div class="zhifubao">
              <span class="text" style="margin-right:35px;">银行卡账号</span>
              <span>
                <input type="text" class="inputt" placeholder="请输入银行卡账号" v-model="banknumber">
              </span>
            </div>
            <div class="zhifubaoname">
              <span class="text" style="margin-right:66px;">开户行</span>
              <span>
                <input type="text" class="inputt" placeholder="例:中国建设银行" v-model="bankname">
              </span>

            </div>
          </div>
          <div v-if="zhanghaoid==2">
            <div class="xukezheng">
              <div class="namee">微信二维码收款</div>
              <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
                :show-file-list="false" :on-success="handleAvatarSuccess">
                <img v-if="wx_img" :src="wx_img" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                  <div
                    style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #0D68FC;line-height: 3px;">
                    微信二维码收款
                  </div>
                </el-icon>
              </el-upload>
            </div>
          </div>
          <div v-if="zhanghaoid==3">
            <div class="zhifubao">
              <span class="text" style="margin-right:35px;">支付宝账号</span>
              <span>
                <input type="text" class="inputt" placeholder="请输入收款账号" v-model="zhifubaonumber">
              </span>
            </div>
            <div class="zhifubaoname">
              <span class="text" style="margin-right:35px;">支付宝名称</span>
              <span>
                <input type="text" class="inputt" placeholder="例：张三" v-model="zhifubaonanme">
              </span>

            </div>
          </div>
          <div class="buttonnn">
            <button class="button1" @click="lijitixian()">
              <span>立即提现</span>
            </button>
            <div class="invite" @click="invitefrineds()">邀请更多好友</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 邀请好友弹窗 -->
    <el-dialog v-model="dialogVisible" title="邀请好友" center width="30%" :before-close="handleClose" class="eldis">
      <div style="width: 100%; height: 100%; text-align: center;">
        <div>

          <img :src="qrCodeUrl" alt="" width="203" height="203">
        </div>
        <div style="font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">二维码分享</div>

      </div>

      <template #footer>
        <span class="dialog-footer">
          <div @click="share(qrcode_img)"><button style="width: 187px;
          height: 46px;
          border: none;
          background: #0D68FC;
          border-radius: 23px;"><span style="font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;">链接分享</span></button></div>
        </span>
      </template>
    </el-dialog>
    <!-- 充值弹窗 -->
    <el-dialog v-model="czshow" style="width: 1069px; height: auto;">
      <div class="distributionN">
        <div class="distribution_bottom">
          <div class="tixianmoney" style="display: flex;flex-wrap: wrap; justify-content: flex-start;">
            <div class="text">充值金额</div>
            <div v-for="(item,index) in calistlist" :key="index">
              <div style="width: 150px;
              margin: 20px 10px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #C2D9FE;
              border-radius: 4px;" @click="jinmoney(item)" :class="openWayN==item.id?'activeN':''">

                {{item.price}}
              </div>
            </div>
          </div>
          <div class="tishi" style="margin: 20px 0;">实际到账：{{jeomey}}元</div>
          <div class="zhanghaoleixing">
            <span class="text">账号类型</span>
            <span class="paybox">
              <div class="payxbox" v-for="(item,index) in openListN" @click="selectWay(item.id)"
                :class="openWay==item.id?'active':''">
                <img :src="item.imgae" style="width: 20px;height: 20px;" />
                <div class="name">{{item.name}}</div>
                <div class="xz" v-if="openWay==item.id">
                  <img src="../../assets/image/payxz.png" style="width: 22px;height: 22px;" />
                </div>
              </div>
            </span>
          </div>
        </div>
        <div v-if="qrCodeUrll" style="width: 100%;text-align: center;">
          <img :src="qrCodeUrll" alt="">
        </div>
        <div class="buttonnn" style="margin-top: 38px;">
          <button class="button1" @click="surecz()">
            <span>确认充值</span>
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import html2canvas from "html2canvas";
  import {
    ElMessage
  } from 'element-plus'
  export default {
    data() {
      return {
        qrCodeUrll: '',
        tixianshow: false,
        czshow: false,
        current: 1,
        total: 6,
        pageSize: 6,
        pageSizeN: 4,
        currentPage: 1,
        number: '',
        number1: 0,
        number2: 0,
        number3: 0,
        tableData: [],
        tableDataN: [],
        tableDataNN: [],
        invitation_money: '',
        invitation_num: '',
        max_tixian_money: '',
        min_tixian_money: '',
        tixian_money: '',
        user_money: '',
        zhanghaoid: 1,
        qrcode_img: '',
        qrCodeUrl: '',
        numbermoney: '',
        yinhangtenp: '',
        yinhangname: '',
        banknumber: '',
        bankname: '',
        wx_img: '',
        zhifubaonumber: '',
        zhifubaonanme: '',
        openList: [{
          id: 1,
          name: '银行卡',
          imgae: require("../../assets/my/corde.png"),
        }, {
          id: 2,
          name: '微信支付',
          imgae: require("../../assets/image/wx.png"),
        }, {
          id: 3,
          name: '支付宝支付',
          imgae: require("../../assets/image/zfb.png"),
        }],
        openListN: [{
          id: 1,
          name: '微信支付',
          imgae: require("../../assets/image/wx.png"),
        }, {
          id: 2,
          name: '支付宝支付',
          imgae: require("../../assets/image/zfb.png"),
        }],
        openWay: 1,
        dialogVisible: false,
        dialogVisiblecontent: true,
        dialogVisibletixian: false,
        calistlist: [],
        pay_classifyid: '',
        jeomey: '',
        openWayN: '',
        randomNumber: '',
      }
    },
    methods: {
      share(item) {
        this.$notify.success({
          title: "成功",
          message: '复制链接成功,可以去进行粘贴分享拉！',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = item;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      fuzhi(item) {
        this.$notify.success({
          title: "成功",
          message: '复制订单编号号成功',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = item;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      confirm(e) {
        this.page = 1
        this.current = e;
        if (this.current == 1) {
          this.bymoney()
        } else if (this.current == 2) {
          this.tixian()
        } else if (this.current == 3) {
          this.czming()
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.bymoney()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.bymoney()
      },
      handleSizeChangeN(val) {
        this.pageSize = val
        this.tixian()
      },
      handleCurrentChangeN(val) {
        this.currentPage = val
        this.tixian()
      },
      handleSizeChangeNN(val) {
        this.pageSizeN = val
        this.czming()
      },
      handleCurrentChangeNN(val) {
        this.currentPage = val
        this.czming()
      },
      shouquanchaxun(num) {
        this.number = num
        this.bymoney()
      },
      shopdeletes(row) {
        return this.$confirm(`确定删除此条信息?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http.post('index/user/del_order_details', {
            order_id: row.id
          }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.bymoney();
          }).catch((error) => {
            this.$message({
              message: '删除失败',
              type: 'error'
            });
            console.error(error); // 打印错误以进行调试  
          });
        });
      },
      async bymoney() {
        const {
          data: res
        } = await this.$http.post('index/user/mine_balance_list', {
          search_order: this.number,
          num: this.pageSize,
          page: this.currentPage
        })
        if (res.code == 1) {
          //交易明细
          this.tableData = res.data.order_list.data
          //账户余额

          if (res.data.user_info.money < 0) {
            this.$notify.info({
              title: "消息提示",
              message: '账户余额不足,请充值',
              duration: 2500,
              offset: 50
            });
            this.number1 = 0
            this.number2 = 0
            this.number3 = 0
          } else {
            this.number1 = res.data.user_info.money
            this.number2 = res.data.user_info.money
            this.number3 = res.data.user_info.money
          }

          this.total = res.data.order_list.total
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      },
      async tixian() {
        const {
          data: res
        } = await this.$http.post('index/cashout/cashout_wallet', {
          num: this.pageSizeN,
          page: this.currentPage
        })
        if (res.code == 1) {
          this.tableDataN = res.data.data
          this.total = res.data.total
        }
      },
      async czming() {
        const {
          data: res
        } = await this.$http.post('/index/cashout/user_recharge_list', {
          num: this.pageSize,
          page: this.currentPage
        })
        if (res.code == 1) {
          this.tableDataNN = res.data.data
          this.total = res.data.total
        }
      },
      chongzhi() {
        this.czshow = true

      },
      txmney() {
        this.tixianshow = true
      },
      handleAvatarSuccess(file) {
        console.log(file)
        this.wx_img = file.data;
      },
      alltixian(e) {
        // console.log(e)
        this.numbermoney = e
      },
      selectWay(index) {
        this.openWay = index
      },
      selectWayy(index) {
        this.openWay = index
        this.zhanghaoid = index
      },
      lijitixian() {
        if (this.numbermoney == '') {
          this.$notify.error({
            title: "提现提示",
            message: "请输入提现金额",
            duration: 1500,
            offset: 50
          });
          return
        }
        if (this.openWay == '') {
          this.$notify.error({
            title: "提现提示",
            message: "请选这账号类型",
            duration: 1500,
            offset: 150
          });
          return
        }
        if (this.openWay == 1) {
          if (this.yinhangtenp == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入银行预留电话",
              duration: 1500,
              offset: 50
            });
            return
          }
          if (this.yinhangname == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入银行预留姓名",
              duration: 1500,
              offset: 50
            });
            return
          }
          if (this.banknumber == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入银行卡账号",
              duration: 1500,
              offset: 50
            });
            return
          }
          if (this.bankname == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入开户行",
              duration: 1500,
              offset: 50
            });
            return
          }
          this.$http.post('index/user/user_withdrawal', {
            money: this.numbermoney, //提现金额
            cash_type: this.openWay, //1.支付宝  2.微信  3.银行卡
            zhifubao: this.zhifubaonumber, //支付宝账号
            zhifubao_name: this.zhifubaonanme, //支付宝名称
            wx_img: this.wx_img, //微信收款码
            bank_number: this.banknumber, //银行卡号
            bank_name: this.bankname, //开户行
            bank_phone: this.yinhangtenp, //银行预留电话
            bank_username: this.yinhangname, //银行预留姓名
          }).then((res) => {
            if (res.data.code == 1) {
              this.$notify.success({
                title: "提现提示",
                message: '提交成功',
                duration: 4500,
                offset: 50
              });
              this.tixianshow = false
              this.withdrawal_info()
            } else if (res.data.code == 0) {
              this.$notify.error({
                title: "提现提示",
                message: res.data.msg,
                duration: 4500,
                offset: 50
              });
            }
          })
        } else if (this.openWay == 2) {
          if (this.wx_img == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请上传微信收款码",
              duration: 1500,
              offset: 250
            });
            return
          }
          this.$http.post('index/user/user_withdrawal', {
            money: this.numbermoney, //提现金额
            cash_type: this.openWay, //1.支付宝  2.微信  3.银行卡
            zhifubao: this.zhifubaonumber, //支付宝账号
            zhifubao_name: this.zhifubaonanme, //支付宝名称
            wx_img: this.wx_img, //微信收款码
            bank_number: this.banknumber, //银行卡号
            bank_name: this.bankname, //开户行
            bank_phone: this.yinhangtenp, //银行预留电话
            bank_username: this.yinhangname, //银行预留姓名
          }).then((res) => {
            if (res.data.code == 1) {
              this.$notify.success({
                title: "提现提示",
                message: '提交成功',
                duration: 4500,
                offset: 50
              });
              this.tixianshow = false
              this.withdrawal_info()
            } else if (res.data.code == 0) {
              this.$notify.error({
                title: "提现提示",
                message: res.data.msg,
                duration: 4500,
                offset: 50
              });
            }
          })
        } else if (this.openWay == 3) {
          if (this.zhifubaonumber == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入支付宝账号",
              duration: 1500,
              offset: 250
            });
            return;
          }
          if (this.zhifubaonanme == '') {
            this.$notify.error({
              title: "提现提示",
              message: "请输入支付宝户名",
              duration: 1500,
              offset: 350
            });
            return;
          }
          this.$http.post('index/user/user_withdrawal', {
            money: this.numbermoney, //提现金额  
            cash_type: this.openWay, //1.支付宝  2.微信  3.银行卡  
            zhifubao: this.zhifubaonumber, //支付宝账号  
            zhifubao_name: this.zhifubaonanme, //支付宝名称  
            wx_img: this.wx_img, //微信收款码  
            bank_number: this.banknumber, //银行卡号  
            bank_name: this.bankname, //开户行  
            bank_phone: this.yinhangtenp, //银行预留电话  
            bank_username: this.yinhangname, //银行预留姓名  
          }).then((res) => {
            if (res.data.code == 1) {
              this.$notify.success({
                title: "提现提示",
                message: '提交成功',
                duration: 4500,
                offset: 50
              });
              this.tixianshow = false;
              this.withdrawal_info()
            } else if (res.data.code == 0) {
              this.$notify.error({
                title: "提现提示",
                message: res.data.msg,
                duration: 4500,
                offset: 50
              });
            }
          })
        }
      },
      async invitefrineds() {
        const {
          data: res
        } = await this.$http.post('index/user/test_qrcode')
        if (res.code == 0) {
          this.qrcode_img = res.data.qrcode_url
          console.log(this.qrcode_img)
          if (this.qrcode_img) {
            this.geterimg()
          }
        }
        this.dialogVisible = true
      },

      geterimg() {
        const qrCodeData = this.qrcode_img;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrl = url;
          }
        });
      },
      async withdrawal_info() {
        const {
          data: res
        } = await this.$http.post('index/user/user_withdrawal_info')
        if (res.code == 1) {
          this.invitation_money = res.data.invitation_money
          this.invitation_num = res.data.invitation_num
          this.max_tixian_money = res.data.max_tixian_money
          this.min_tixian_money = res.data.min_tixian_money
          this.tixian_money = res.data.tixian_money
          this.user_money = res.data.user_money
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      },
      //立即充值
      jinmoney(item) {
        this.pay_classifyid = item.id
        this.jeomey = item.price
        this.openWayN = item.id
      },
      getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
          const value = object[key];
          if (Array.isArray(value)) {
            value.forEach((subValue, i) => {
              formData.append(key + `[${i}]`, subValue)
            })
          } else {
            formData.append(key, object[key])
          }
        })
        return formData;
      },
      async surecz() {
        if (this.pay_classifyid == '') {
          ElMessage({
            message: '请选择充值金额',
            type: 'warning',
          })
          return
        }
        //微信支付
        if (this.openWay == 1) {
          this.randomNumber = Math.floor(Math.random() * 100) + 1;
          let data = {
            pay_type: 'wechat',
            pay_classify_id: this.pay_classifyid,
            method: 'scan',
            pay_ident: this.randomNumber, //随机数
          }
          const {
            data: res
          } = await this.$http.post('index/cashout/user_recharge', this.getFormData(data))
          if (res.code == 1) {
            this.payUrl = res.data.code_url
            if (this.payUrl) {
              this.geterimgg()
            }
            console.log(this.weixin)
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
          //支付充值
        } else if (this.openWay == 2) {
          let data = {
            pay_type: 'alipay',
            pay_classify_id: this.pay_classifyid,
            method: 'wap'
          }
          const {
            data: res
          } = await this.$http.post('index/cashout/user_recharge', this.getFormData(data))
          if (res.code == 1) {
            this.payUrl = res.data
            let routerData = this.$router.resolve({
              path: '/payGateWay',
              query: {
                htmls: this.payUrl
              }
            });
            window.open(routerData.href, '_blank');
            localStorage.clear();

          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })

          }
        }
      },
      geterimgg() {
        const qrCodeData = this.payUrl;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrll = url;
            if (this.qrCodeUrll) {
              this.intervalId = setInterval(this.repeatedApiCall, 2000);
            }
          }
        });
      },
      repeatedApiCall() {
        this.$http.post('/index/cashout/pay_details_foreach', {
            type: 1,
            pay_ident: this.randomNumber
          })
          .then((res) => {
            if (res.data.code == 1) {
              clearInterval(this.intervalId);
              this.$notify.success({
                title: "提示",
                message: '会员充值成功',
                duration: 2500,
                offset: 50
              });
              this.czshow = false
            }
          });
      },
      //充值列表 
      async czlist() {
        const {
          data: res
        } = await this.$http.get('index/usersign/change_money')
        if (res.code == 1) {
          this.calistlist = res.data
        }
      }
    },
    mounted() {
      this.bymoney()
      this.withdrawal_info()
      this.czlist()

    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-table__empty-text {
    display: block;
    width: 200px;
    height: 371px;
    position: relative;
    padding-top: 148px;
    top: 80px;
    background: url('../../assets/dhpbjj.png') no-repeat;
    color: #909399;
    box-sizing: border-box;
  }

  /deep/.el-table tr {
    height: 60px;
  }

  .select {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  .hengxian {
    width: 13px;
    height: 3px;
    background: #0D68FC;
    border-radius: 2px;
    position: absolute;
    top: 26px;
    left: 25px;
  }

  .hengxian1 {
    width: 13px;
    height: 3px;
    background: #0D68FC;
    border-radius: 2px;
    position: absolute;
    top: 26px;
    left: 118px;
  }

  .hengxian2 {
    width: 13px;
    height: 3px;
    background: #0D68FC;
    border-radius: 2px;
    position: absolute;
    top: 26px;
    left: 212px;
  }

  /deep/.el-input__inner {
    padding-left: 15px;
  }

  .el-col {
    text-align: center;
  }

  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .balance_top {
      width: calc(100% - 20px);
      min-height: 200px;
      background: #FFFFFF;
      border-radius: 20px;

      .balance_toptitle {
        width: 100%;
        height: 30px;
        display: flex;
        position: relative;
        top: 20px;

        .budeg {
          position: absolute;
          top: 13px;
          left: 38px;
          height: 15px;
          width: 4px;
          border-radius: 2px;
          background-color: #0D68FC;
        }

        .budegtitle {
          position: absolute;
          top: 20px;
          left: 52px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 900;
          color: #333333;
          line-height: 2px;
        }

        .chongzhi {
          cursor: pointer;

          img {
            position: absolute;
            top: 14px;
            left: 124px;
            width: 13px;
            height: 14px;
          }

          .tixian {
            position: absolute;
            top: 11px;
            left: 140px;
            height: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
          }
        }
      }

      .balance_topnumber {
        height: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: space-around;

        .button3 {
          width: 171px;
          height: 60px;
          background: #0D68FC;
          border-radius: 8px;
          text-align: center;

          text {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 60px;

          }
        }

        .button4 {
          width: 171px;
          height: 60px;
          background: #FFFFFF;
          border: 1px solid #0D68FC;
          border-radius: 8px;
          text-align: center;

          text {
            line-height: 60px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
          }
        }
      }
    }

    .balance_bottom {
      width: calc(100% - 20px);
      min-height: 600px;
      background: #FFFFFF;
      border-radius: 20px;
      margin-top: 30px;

      .balance_bottomtitle {
        width: 100%;
        height: 30px;
        position: relative;
        left: 50px;
        top: 32px;

        .budeg {
          position: absolute;
          top: 39px;
          left: 32px;
          height: 13px;
          border: 3px solid #0D68FC;
        }

        .budegtitle {
          position: absolute;
          top: 48px;
          left: 52px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 900;
          color: #333333;
          line-height: 2px;
        }

        .authot_score {
          margin-top: -2px;
          margin-right: 133px;

          .Searchqq {
            width: 155px;
            height: 30px;
            border: 1px solid #2469FF;
            opacity: 0.3;
            margin: 0;
            padding: 0;
            padding-left: 15px;
            border-radius: 4px 0px 0px 4px;

          }

          .button1 {
            position: absolute;
            top: -2px;
            width: 32px;
            height: 31.6px;
            background: #0D68FC;
            border-radius: 0px 4px 4px 0px;
            border: none;

            img {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 23px;
              height: 23px;

            }
          }
        }
      }

      .balance_bottomcon {
        width: 94%;
        margin: 0 auto;
        margin-top: 49px;
        height: 368px;
      }
    }
  }

  .pages {
    margin-top: 27px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  /deep/.el-statistic__head {
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 2px;
  }

  /deep/.el-statistic__content {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0D68FC;
    line-height: 2px;
  }

  /deep/.el-table__header th {
    height: 50px;
    background: #EEF0F6;
    border-radius: 0px 0px 0px 0px;
  }

  .content {
    width: 100%;
    min-height: 820px;
    background-color: #F6F9FF;

    .distribution {
      width: 100%;
      height: 90%;
      background: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 35px;
      margin-top: -42px;

      .distribution_bottom {
        width: 96%;
        height: auto;
        margin: 0 auto;
        margin-top: 60px;

        .tixianmoney {
          width: 100%;
          height: auto;
          position: relative;
          margin-bottom: 45px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 20px;
          }

          .inputt {
            width: 1190px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;
          }

          .button {
            position: absolute;
            top: 3px;
            right: 5px;
            width: 94px;
            height: 42px;
            background: #E5EFFF;
            border: 1px solid #0D68FC;
            border-radius: 4px;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
              line-height: 2px;
            }
          }

          .tishi {
            margin-left: 123px;
            margin-top: 18px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #939598;
            line-height: 2px;

            .jilu {
              cursor: pointer;
              display: inline-block;
              margin-left: 11px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
              line-height: 18px;
            }
          }

        }

        .zhanghaoleixing {
          width: 100%;
          height: auto;
          display: flex;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 20px;
          }

          .paybox {
            display: flex;
            align-items: center;

            .payxbox {
              width: 162px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #C2D9FE;
              border-radius: 4px;
              margin-right: 30px;
              position: relative;

              .name {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #818285;
                margin-left: 12px;
              }

              .xz {
                position: absolute;
                top: -6px;
                right: -2px;
              }
            }

            .active {
              border: 1px solid #0D68FC;
            }
          }
        }

        .zhifubao {
          width: 100%;
          height: auto;
          margin-top: 35px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 20px;
          }

          .inputt {
            width: 1230px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;

          }
        }

        .zhifubaoname {
          width: 100%;
          height: auto;
          margin-top: 45px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 3px;
          }

          .inputt {
            width: 1230px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;
          }

          .tishi {
            margin-left: 94px;
            margin-top: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #939598;
            line-height: 2px;
          }
        }

        .buttonnn {
          width: 100%;
          height: auto;
          text-align: center;
          margin-top: 73px;

          .button1 {
            width: 682px;
            height: 54px;
            background: #0D68FC;
            border-radius: 8px;
            border: none;
            cursor: pointer;

            span {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 2px;
            }
          }

          .invite {
            margin-top: 20px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
            line-height: 2px;
          }
        }

      }
    }

    .distributionN {
      width: 1069px;
      height: auto;
      border-radius: 24px;
      padding-bottom: 35px;

      .distribution_bottom {
        width: 96%;
        height: auto;
        margin: 0 auto;

        .tixianmoney {
          width: 100%;
          height: auto;
          position: relative;

          .text {
            width: 100%;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-right: 20px;
          }

          .inputt {
            width: 850px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;
          }

          .button {
            position: absolute;
            top: 3px;
            right: -16px;
            width: 94px;
            height: 42px;
            background: #E5EFFF;
            border: 1px solid #0D68FC;
            border-radius: 4px;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
              line-height: 2px;
            }
          }

          .tishi {
            margin-left: 96px;
            margin-top: 18px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #939598;
            line-height: 2px;

            .jilu {
              cursor: pointer;
              display: inline-block;
              margin-left: 11px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
              line-height: 18px;
            }
          }

          .activeN {
            background-color: #C2D9FE;
          }
        }

        .zhanghaoleixing {
          width: 100%;
          height: auto;
          display: flex;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 20px;
          }

          .paybox {
            display: flex;
            align-items: center;

            .payxbox {
              width: 162px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #C2D9FE;
              border-radius: 4px;
              margin-right: 30px;
              position: relative;

              .name {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #818285;
                margin-left: 12px;
              }

              .xz {
                position: absolute;
                top: -6px;
                right: -2px;
              }
            }

            .active {
              border: 1px solid #0D68FC;
            }
          }
        }

        .zhifubao {
          width: 100%;
          height: auto;
          margin-top: 35px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 20px;
          }

          .inputt {
            width: 1230px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;

          }
        }

        .zhifubaoname {
          width: 100%;
          height: auto;
          margin-top: 45px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 47px;
            margin-right: 3px;
          }

          .inputt {
            width: 1230px;
            height: 46px;
            border: 1px solid #2469FF;
            border-radius: 8px;
            padding-left: 15px;
          }

          .tishi {
            margin-left: 94px;
            margin-top: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #939598;
            line-height: 2px;
          }
        }
      }

      .buttonnn {
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: 13px;

        .button1 {
          width: 682px;
          height: 54px;
          background: #0D68FC;
          border-radius: 8px;
          border: none;
          cursor: pointer;

          span {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2px;
          }
        }

        .invite {
          margin-top: 20px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          line-height: 2px;
        }
      }
    }

  }

  .tixian {
    width: 80%;
    height: 80%;
    position: absolute;
    z-index: 999;

    .tixian_top {
      text-align: center;
      margin: 0 auto;
      width: 1032px;
      height: 439px;
      background: #0D68FC;
      border-radius: 20px 20px 0px 0px;

      .img {
        padding-top: 106px;

        img {
          width: 52px;
          height: 52px;
        }
      }

      .text {
        padding-top: 23px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 2px;
      }

      .text_text {
        width: 100%;
        height: 30px;
        position: relative;

        .text_text1 {
          position: absolute;
          left: 322px;
          top: 95px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 2px;
        }

        .text_text2 {
          .money {
            position: absolute;
            font-size: 28px;
            left: 440px;
            top: 70px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2px;
          }

          .jaintou {
            position: absolute;
            top: 64px;
            left: 379px;

            img {
              width: 263px;
              height: 61px;
            }

          }

          .arrive {
            position: absolute;
            left: 419px;
            top: 115px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2px;
          }

        }

        .zhifubaozhanghu {
          position: absolute;
          left: 632px;
          top: 84px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 2px;
        }
      }

    }

    .tixianbottom {
      width: 1032px;
      height: 457px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(13, 104, 252, 0.11);
      border-radius: 0px 0px 20px 20px;
      margin: 0 auto;
      position: relative;

      .tixianbottom_bord {
        width: 830px;
        height: 213px;
        border: 1px solid #2469FF;
        border-radius: 4px 0px 0px 0px;
        position: absolute;
        top: 58px;
        left: 101px;
        display: flex;

        .tixianbottom_bord1 {
          width: 50%;
          height: 100%;
          padding-left: 64px;
          line-height: 70px;

          .text {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }

          .money {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;

            .money1 {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
            }
          }

          .time {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }
        }

        .tixianbottom_bord2 {
          width: 50%;
          height: 100%;
          padding-left: 64px;
          line-height: 70px;

          .text {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }

          .money {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;

            .money1 {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0D68FC;
            }
          }

          .time {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }
        }


      }

      .butt {
        width: 100%;
        text-align: center;
        position: relative;
        bottom: -338px;

        .buttona {
          width: 190px;
          height: 63px;
          background: #0D68FC;
          border: 1px solid #2469FF;
          border-radius: 4px;

          span {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 2px;
          }
        }
      }
    }

  }

  /deep/ .el-dialog {
    width: 80%;
    height: auto;
    background: #FFFFFF;
    box-shadow: -1px 2px 6px 0px rgba(55, 86, 232, 0.16);
    border-radius: 16px;
  }

  /deep/ .el-table__header th {
    height: 50px;
    background: #EEF0F6;
    border-radius: 0px 0px 0px 0px;
  }

  .tixianjilu {
    width: calc(100% - 20px);
    min-height: 770px;
    background: #FFFFFF;
    border-radius: 20px;

    .tixianjilu_con {
      width: 98%;
      height: 100%;
      margin: 0 auto;

      .text {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 900;
        color: #333333;
        padding-top: 40px;
      }

      .line {
        width: 100%;
        height: 1px;
        border: 1px solid #719EFF;
        opacity: 0.1;
        margin-top: 50px;

      }

      .tixianform {
        width: 100%;
        height: 600px;

      }
    }
  }



  .pages {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .xukezheng {
    width: 300px;
    height: 172px;
    margin-top: 45px;

    .namee {
      margin-bottom: 10px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 47px;
    }

    .avatar-uploader {
      width: 300px;
      height: 172px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 300px;
      /* height: 172px; */
      line-height: 41px;
      text-align: center;
      margin-top: 61px;
      display: block;
    }

    .avatar {
      width: 300px;
      height: 172px;
      display: block;
    }
  }

  .fuzhuanniu {
    width: 30px;
    height: 20px;

    img {
      width: 20px;
      height: 20px;
    }
  }
</style>