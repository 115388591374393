<template>
  <div class="content" v-show="$route.meta.showfater">
    <!-- l轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item,index) in Bnners" :key="index">
          <div class="swiper-item">
            <img  :src="item" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="centent">
      <div class="centent_con">
        <div class="con_text">
          开源文档
        </div>
        <div class="con_more">
          <div class="con_more_left" @click="bank()">
            全部系列
          </div>
          <div class="con_more_con">
            <div class="yinchang">
              <div :class="flag ? 'externalDiv': ''">
                <div ref="insideDiv">
                  <section class="sectionon" v-for="(item,index) in  tab" :key="index" :class="{'bg':isbg==index}"
                    :style="item.isActive == true ? { backgroundColor: '#EAF4FF' } : { backgroundColor: '#F7F7F7' }"
                    @click="tabchang(index,item)">
                    <span>{{item.name}}</span>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="con_more_right">
            <span v-if="flag" @click="flag = !flag" id="more">
              更多
              <img src="../../assets/kaiyuanwendan/downjian.png" width="15" height="15">
            </span>
            <span v-if="!flag" @click="flag = !flag" id="more">
              收起
              <img src="../../assets/kaiyuanwendan/topjian.png" width="15" height="15">
            </span>
          </div>
        </div>
        <div class="coin_tab">
          <div class="coin_tab_tab" v-for="(item,index) in commentData " :key="index" :class="{'bigbg':iscon==index}"
            @click="cointab(index,item)">
            <div class="coin_tab_tab_left">
              <div class="vip_con" v-if="item.type==0||item.type==1">
                <img src="../../assets/kaiyuanwendan/free.png" alt="" srcset="">
              </div>
              <div class="vip_con" v-if="item.type==2||item.type==3||item.type==4">
                <img src="../../assets/kaiyuanwendan/vip.png" alt="" srcset="">
              </div>
              <div class="text_con" :class="{'bigtext':iscon==index}">
                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 333px;">
                  {{item.name}}
                </div>
              </div>
              <div class="con_con" :class="{'bigtext':iscon==index}"
                style="font-size: 20px; font-weight: 800; padding-top: 10px;">
                {{item.goods_cate_name}}
              </div>
            </div>
            <div class="coin_tab_tab_right">
              <img :src="item.image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" title="开源文档查看" width="60%" :before-close="handleClose" center>
      <div class="title_dialog">
        {{titlename}}
      </div>
      <div class="tiyan_dialog">
        全平台体验
      </div>
      <div class="button_dialog">
        <div class="button_dialog1">
          <el-button plain>管理后台演示</el-button>
        </div>
        <div class="button_dialog2">
          <el-button plain>商家端小程序演示</el-button>
        </div>
        <div class="button_dialog3">
          <el-button plain>接单端</el-button>
        </div>
        <div class="button_dialog4">
          <el-button plain>接单端小程序演示</el-button>
        </div>
      </div>
      <div class="jishu_dialog">
        <div class="textjishu">技术栈</div>
        <div class='tetx_jisuh'>后台服务 springboot+mybatisplus+mysql</div>
        <div class='tetx_jisuh'>用户端 uniapp（vue语法）</div>
        <div class='tetx_jisuh'>管理后台 vue+elementUi</div>
      </div>
      <div class="jishu_dialog1">
        <div class="textjishu">服务说明</div>
        <div class='tetx_jisuh'>销售价格均提供技术文档，资料准备文档，部署文档，业务咨询免费！本店保证保障源码可用性！</div>
      </div>
      <div class="jishu_dialog2">
        <div class="textjishu">授权源码</div>
        <div class='tetx_jisuh'>uni-app用户端（适配微信公众号+微信小程序+android+ios APP）、uni-app接单端（适配微信公众号+微信小程序+android+ios
          APP）、vue管理平台、java后台服务。</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="footter">
            <div class="button_img" @click="ioinvip()">
              <!--  -->
            </div>
          </div>

          <div class="button_text">
            支付即视为您已阅读并同意<span style="color: #0D68FC ; font-size: 18px;">授权许可协议</span>
          </div>
        </span>
      </template>
    </el-dialog>
    <!-- 右侧固钉 -->
    <affix></affix>
  </div>
  <div class="otherpage">
    <router-view> </router-view>
  </div>
</template>

<script>
  import affix from '../../components/affix.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
    components: {
      affix,
    },
    data() {
      return {
        dialogVisible: false,
        dialogFormVisible: false,
        color: '',
        isbg: -1,
        iscon: -1,
        isTextChanging: false,
        swiperIndex: 0,
        width: 0, // 记录一行数据有多高
        flag: true, // 展示样式
        commentData: [],
        tab: [],
        titlename: '',
        cateid: 'all',
        is_vip_type: '',
		Bnners:[],
      }
    },
    methods: {
      //获取内容
      async bytabchang() {
        const {
          data: res
        } = await this.$http.post('index/product/product_document', {
          cate_id: this.cateid,
          num: 10,
          page: 1
        })
        if (res.code == 1) {
          this.commentData = res.data.data
        }

      },
      //获取分类
      async byfenlie() {
        const {
          data: res
        } = await this.$http.post('index/product/get_goods_cate_list')
        if (res.code == 1) {
          var tab = res.data
          for (let i = 0; i < this.tab.length; i++) {
            this.tab[i].isActive = false
          }
          this.tab = tab

        }
      },
      bank() {
        this.cateid = 'all'
        this.isbg = -1
        this.bytabchang()
        this.byfenlie()
      },
      tabchang(index, item) {
        console.log(index, item)
        this.cateid = item.id
        this.bytabchang()
        // this.commentData = item.commentData
        //改变字的颜色
        this.isbg = index
        //改变背景色
        for (let i in this.tab) {
          if (index == i) {
            this.tab[i].isActive = true
          } else {
            this.tab[i].isActive = false;
          }
        }
      },
      cointab(index, item) {
        let userId = window.sessionStorage.getItem('token')
        if (userId == '' || userId == null) {
			console.log('userId',userId,'-----',item.type)
          if(item.type != 1){
            ElMessageBox.confirm(
        	    '当前未登录，是否登录。',
        	    '提示',
        	    {
        	      confirmButtonText: '去登录',
        	      cancelButtonText: '取消',
        	      type: 'warning',
        	    }
        	  ).then(()=>{
        		  this.$router.push({
        		    path: 'login',
        		  })
        	  }).catch(() => {
              
        	  })
          }else{
           this.dialogFormVisible = true
          }
        } else {
          this.iscon = index
          if (this.is_vip_type == 2 || this.is_vip_type == 3 || this.is_vip_type == 4 || item.type == 1) {
            var itemcon = JSON.stringify(item)
            this.$router.push({
              name: 'Vipfree',
              query: {
                name: itemcon,
              }
            })
          } else if (this.is_vip_type == 0 || this.is_vip_type == 1) {
            this.dialogFormVisible = true
            this.titlename = item.name
          }
        }
      },
      gotologin() {
        this.$router.push({
          name: 'login',
        })
      },
      ioinvip() {
        this.$router.push({
          name: 'vipmoney',
          query: {
            name: '',
          }
        })
      },
	  //头部组件
	  async byhead() {
	    const {
	      data: res
	    } = await this.$http.get('index/index/index_header')
	    if (res.code == 1) {
	  			if(res.data.top_navigation){
	  				this.Bnners = res.data.top_navigation[2].img.split(",")
	  			}
	      
	    }
	  }
    },
    mounted() {
      this.byfenlie()
      this.bytabchang()
      this.$nextTick(() => {
        this.width = this.$refs.insideDiv.clientHeight
      })
      window.onresize = () => (() => {
        this.width = this.$refs.insideDiv.clientHeight // 放大或者缩小, 对应的更多、收起是否显示
      })()
    },
    created() {
	this.byhead()
      this.is_vip_type = this.$cookie.get('is_vip_type')
      // this.commentData= = JSON.parse(JSON.stringify(CommentData.comment.data));
      // console.log(this.commentData, '111')
    }

  }
</script>

<style lang="less" scoped>
  .footter .button_img:hover {
    background-image: url('../../assets/kaiyuanwendan/img9.png');
    background-repeat: 100% 100%;
    background-repeat: no-repeat;
  }

  .footter {
    width: 100%;
    height: 90px;

    .button_img {
      margin: 0px 283px;
      width: 100%;
      height: 120px;
      background-image: url('../../assets/kaiyuanwendan/img9_1.png');
      background-repeat: 100% 100%;
      background-repeat: no-repeat;
    }
  }


  .customWidth2 {
    width: 69% !important;
  }

  .bg {
    width: 129px;
    height: 50px;
    background-color: #EAF4FF;
    border-radius: 6px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0D68FC;
  }

  .bigbg {
    width: 129px;
    height: 50px;
    background-color: #EAF4FF;
    border-radius: 6px;

  }

  .bigtext {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0D68FC;

  }

  .content {
    background: #EFF7FF;
  }

  .externalDiv {
    height: 59px;
    overflow: hidden;
  }

  .swiper {
    width: 100%;
    height: 700px;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  .centent {
    width: 100%;
    min-height: 1093px;
    background: #FFFFFF;

    .centent_con {
      width: 95%;
      height: 100%;
      margin: 0 auto;

      .con_text {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        text-align: center;
        padding-top: 82px;
      }

      .con_more {
        width: 100%;
        display: flex;

        .con_more_left {
          float: left;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          line-height: 100px;
        }

        .con_more_con {
          width: 85%;

          .yinchang {
            width: 100%;
            position: relative;
            margin-top: 26px;

            .sectionon {
              display: inline-block;
              width: 170px;
              height: 50px;
              background-color: #F7F7F7;
              border-radius: 6px;
              text-align: center;
              margin-left: 10px;
              line-height: 50px;
              padding-left: 10px;
              margin-bottom: 10px
            }
          }
        }

        .con_more_right {
          margin-top: 26px;
          float: right;
          width: 124px;
          height: 50px;
          background: #F7F7F7;
          border-radius: 6px;

          #more {
            line-height: 50px;
            position: relative;
            margin-left: 36px;
            img {
              position: absolute;
              left: 38px;
              top: 2px;
            }
          }
        }
      }

      .coin_tab {
        width: 100%;
        height: auto;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .coin_tab_tab:hover {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0D68FC;
          background-color: #EAF4FF;
        }

        .coin_tab_tab {
          width: 30%;
		      min-width: 500px;
          height: 195px;
          border: 1px solid #0D68FC;
          border-radius: 12px;
          display: flex;
          margin: 20px 10px;
		      padding: 20px;

          .coin_tab_tab_left {
            width: 70%;

            .vip_con {
              // margin-top: 36px;
              // margin-left: 30px;
              margin-bottom: 10px;

            }

            .text_con {
              width: 90%;
              // margin-left: 29px;
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
            }

            .con_con {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 333px;
              // margin-left: 30px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
          }

          .coin_tab_tab_right {
            flex: 1;

            img {
              width: 106px;
              height: 106px;
              margin-top: 38px;
              margin-left: 18px;
            }
          }
        }
      }
    }
  }

  .title_dialog {
    width: auto;
    height: 29px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 2px;
    margin-bottom: 20px;
  }

  .tiyan_dialog {

    height: 27px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 2px;
  }

  .button_dialog {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .jishu_dialog {
    width: 100%;
    height: 160px;
    margin-top: 60px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  .jishu_dialog1 {
    width: 100%;
    height: 90px;
    margin-top: 20px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  .jishu_dialog2 {
    width: 100%;
    height: 90px;
    margin-top: 20px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  // /deep/.el-overlay-dialog {
  //   position: fixed;
  //   top: 302px;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   overflow: auto;
  // }

  /deep/.el-dialog__title {
    color: #0D68FC;
    font-size: 25px;
    font-weight: 800;
  }

  /deep/.el-button.is-plain {
    width: 230px;
    height: 73px;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
  }

  /deep/.el-button>span {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }

  /deep/.el-dialog__footer {
    padding-top: 40px;
  }
</style>