<template>
	<div class="content">
		<!-- 轮播图 -->
		<div class="swiper">
			<el-carousel height="620px" indicator-position="none" arrow="never">
				<el-carousel-item v-for="item in banner" :key="item">
					<div class="swiper-item">
						<img :src="item.img" />
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 公告 -->
		<div class="swiper" style="height:71px">
			<scrollwarp></scrollwarp>
		</div>

		<!-- 类型 -->
		<div class="type flex align-center justify-around">
			<div class="type-item flex align-center" v-for="(item,index) in configure " :key="index">
				<img :src="item.img" alt="" srcset="">
				<div class="type-item-txt">
					<div class="type-item-txt-title">
						{{item.name}}
					</div>
					<div class="type-item-txt-tx">
						{{item.description}}
					</div>
				</div>
			</div>
		</div>
		<!-- 关于我们 -->
		<div class="title flex justify-center align-center">
			<div style="font-size: 50px; font-family: yanshi;">
				<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
-webkit-text-fill-color: transparent;">
					{{aboutwear}}
				</span>
			</div>
			<div class="title-tx">
				{{aboutwearN}}
			</div>
		</div>
		<!-- 公司介绍 -->
		<div class="introduce flex justify-center align-center">
			<div class="introduce-box flex justify-center align-center">
				<div class="introduce-box-c flex justify-between align-center">
					<div class="introduce-box-c-vido">
						<video id="video" controls :src="video"></video>
					</div>
					<div class="introduce-box-c-txt">
						<div class="introduce-box-c-txt-t flex align-center">
							<div class="introduce-box-c-txt-t-js flex align-center"
								:class="{'bg-blue': isBlue,'text-red':istop}">
								<img src="../../assets/index/gsjs.png" alt="" v-if="imgw">
								<img src="../../assets/index/gsjs/ywfw.png" alt="" v-if="imgb">
								<span @click="gongsi">公司介绍</span>
							</div>
							<div class="introduce-box-c-txt-t-fw flex align-center justify-end"
								:class="{'bg-blue1': isBlue1,'text-red1':istop1}">
								<img src="../../assets/index/ywjl.png" v-if="imgc">
								<img src="../../assets/index/gsjs/gsjs.png" v-if="imgd">
								<span @click="yewu">业务范围</span>
							</div>
						</div>

						<div v-if="gongsijieshao">
							<div class="introduce-box-c-txt-n">
								{{title}}
							</div>

							<div class="introduce-box-c-txt-x">
								{{description}}
							</div>
							<div class="introduce-box-c-txt-i flex">
								<el-icon color="#0D68FC" style="margin-top: 5px;"><Select /></el-icon>
								<div style="margin-left: 12px;">
									{{content}}
								</div>
							</div>
						</div>
						<div class="introduce-box-c-txt-i" v-if="guanyuthem">
							<div class="introduce-box-c-txt-n" style="margin-bottom:10px ;">
								{{title1}}
							</div>
							<div>
								{{content1}}
							</div>
						</div>
					</div>

				</div>
				<div class="introduce-left">
					<img src="../../assets/index/inleft.png" alt="" srcset="">
				</div>
				<div class="introduce-right">
					<img src="../../assets/index/inright.png" alt="" srcset="">
				</div>
			</div>
		</div>
		<!-- 产品介绍 -->
		<div class="product flex justify-center align-center">
			<div class="product-box flex">
				<div class="product-box-l">
					<div class="product-box-l-c flex justify-center align-center">
						<div class="product-box-l-c-box">
							<div class="product-box-l-c-box-item flex align-center justify-center"
								:class="swiperIndex == index?'product-box-l-c-box-item-active':''"
								v-for="(item,index) in swiperList" :key="index" @click="selectSwiper(index)">
								<img class="product-box-l-c-box-item-img_s" v-if="swiperIndex == index"
									:src="item.subtheme" :class="[swiperIndex == index ? 'active' : 'default']">
								<img v-else class="product-box-l-c-box-item-img_s" :src="item.theme"
									:class="[swiperIndex == index ? 'active' : 'default']">
								{{item.lable}}
							</div>
						</div>
					</div>
				</div>
				<div class="product-box-r">
					<div class="product-box-r-top">
						<div class="product-box-r-top-box">
							<div style="font-size: 50px; font-family: yanshi;">
								<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
             -webkit-text-fill-color: transparent;">
									{{chanpin}}
								</span>
							</div>

							<div class="product-box-r-top-name">
								{{chanpinN}}
							</div>
						</div>

					</div>

					<block v-for="(item,index) in swiperList" :key="index">
						<div class="product-box-r-btn" v-if="swiperIndex == index">
							<div class="product-box-r-btn-l">
								<div class="animate__animated" :class="swiperIndex==index?'animate__fadeInUp':''">
									<div class="product-box-r-btn-l-title flex align-center">
										<div class="product-box-r-btn-l-title-t">
											{{item.title}}
										</div>
										<div>
											{{item.subtitle}}
										</div>

									</div>
									<div class="product-box-r-btn-l-name">
										{{item.title_name}}
									</div>
								</div>
								<div>
									<div class="product-box-r-btn-l-type">
										{{item.content.attrName}}
									</div>
									<ul class="product-box-r-btn-l-ul">
										<li :class="swiperIndex == index?'animate__fadeInUp product-box-r-btn-l-item animate__animated':'product-box-r-btn-l-item animate__animated'"
											v-for="(list,listid) in item.content.attrValue" :key="listid">
											{{list.value}}
										</li>
									</ul>
								</div>

								<div class="product-box-r-btn-l-btn" @click="gotolist(item)">
									<p>了解详情</p>
								</div>
							</div>
							<div class="product-box-r-btn-phone">
								<img :src="item.image" class="animate__animated"
									:class="swiperIndex == index?'animate__fadeInUp':''" alt="" srcset="">
							</div>
							<div class="product-box-r-btn-line">
								<div class="product-box-r-btn-line-start">
									0{{index+1}}
								</div>
								<div class="product-box-r-btn-lines">
									<div class="product-box-r-btn-linesss"
										:style="{height:((230 / 6) * +(swiperIndex+1))+'px'}">

									</div>

								</div>

								<div class="product-box-r-btn-line-end">
									0{{swiperList.length}}
								</div>
								<div @click="swiperIndex>0?editSwiper('jian'):''"
									:style="swiperIndex>0?'border-color:#ffffff':'border-color:rgba(255,255,255,0.6)'"
									class="product-box-r-btn-line-up flex align-center justify-center">
									<el-icon size="28" :color="swiperIndex>0?'#ffffff':'rgba(255,255,255,0.6)'">
										<CaretTop />
									</el-icon>
								</div>
								<div @click="swiperIndex<5?editSwiper('add'):''"
									:style="swiperIndex<5?'border-color:#ffffff':'border-color:rgba(255,255,255,0.6)'"
									class="product-box-r-btn-line-down flex align-center justify-center">
									<el-icon size="28" :color="swiperIndex<5?'#ffffff':'rgba(255,255,255,0.6)'">
										<CaretBottom />
									</el-icon>
								</div>
							</div>
						</div>
					</block>

				</div>
			</div>
		</div>
		<!-- 客户案例 -->
		<div class="kehu">
			<div class="kehu-title">
				<div style="font-size: 50px; font-size: '微软雅黑';">
					<span style="color: #fff;">
						{{listename}}
					</span>
				</div>
			</div>
			<div class="kehu-card">
				<swiper :slides-per-view="4.5" :loop="true" :space-between="200" fl @swiper="onSwiper"
					@slideChange="onSlideChange" ref="cardShow">
					<swiper-slide v-for="(imgList,index1) in kehuList" :key="index1" class="divSrc2">
						<div class="divSrc">
							<img class="imgTop" :src="imgList.image" />
							<div class="title">
								<div class="title_top">
									<img class="img" :src="imgList.theme" />
								</div>
								<div class="title_bottom">
									{{imgList.description}}
								</div>
							</div>
							<div class="score" style="margin-left:26px;">
								<el-rate v-model="imgList.score" disabled />
							</div>
						</div>
					</swiper-slide>
					<!-- <swiper-slide>Slide 2</swiper-slide>
					<swiper-slide>Slide 3</swiper-slide>
					... -->
				</swiper>
				<!-- <el-carousel :loop="true" :autoplay="true" class="carousel" indicator-position="none" ref="cardShow">
          <el-carousel-item class="el-car-item" v-for="(list, index) in kehuList" :key="index">
            <div v-for="(imgList,index1) in list" :key="index1" class="divSrc">
              <img class="img" :src="imgList.image" />
              <div class="title">
                <div class="title_top">
                  <img class="img" :src="imgList.theme" />
                </div>
                <div class="title_bottom">
                  {{imgList.description}}
                </div>
              </div>
              <div class="score" style="margin-left:26px;">
                <el-rate v-model="imgList.score" disabled />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel> -->
				<div class="next">
					<img src="../../assets/index/zuhua.png" @click="arrowClick1(1)" class="zuo" />
					<img src="../../assets/index/youhua.png" @click="arrowClickP(2)" class="you" />
				</div>
			</div>
		</div>
		<!-- 企业资质与荣誉 -->
		<div class="qiye">
			<div class="qiye_title">
				<div class="qiye_img">

					<div style="font-size: 50px; font-family: yanshi;">
						<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;">
							{{qiyename}}
						</span>
					</div>
					<div class="qiye_img_text">{{qiyenameN}}</div>
				</div>
			</div>
			<div class="qiye_lunbo">
				<el-carousel :interval="5000" type="card" height="461px">
					<el-carousel-item v-for="(item,index) in qiyeluno" :key="index">
						<img :src="item.image" alt="" width="600" height="460">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 我们的合作伙伴 -->
		<div class="huoban">
			<div class="huoban_title">
				<div class="huoban_img">
					<div style="font-size: 50px; font-family: yanshi;">
						<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;">
							{{huoban}}
						</span>
					</div>
					<div class="huoban_img_text">{{huobanN}}</div>
				</div>
			</div>
			<div class="huoban_lunbo">
				<el-carousel :loop="true" :autoplay="true" class="carousel" indicator-position="none">
					<el-carousel-item class="el-car-item" v-for="(list, index) in dataList" :key="index">
						<div v-for="(imgList,index1) in list" :key="index1" class="divSrc">
							<div
								style='width: 296px;height: 197px;background: #EFF7FF;border-radius: 8px;margin-top: 52px;margin-left: 20px; '>
								<img class="img" :src="imgList.image" />
							</div>
							<div class="title" style="padding: 20px;">
								<div class="title_top">
									{{imgList.title}}
								</div>
								<div class="radios">
									<ul>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div class="title_bottom">
									{{imgList.description}}
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 核心团队成员 -->
		<div class="team">
			<div class="team-title">
				<div class="team_img">
					<div style="font-size: 50px; font-family: yanshi;">
						<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;">
							{{teamname}}
						</span>
					</div>
					<div class="team_img_text">{{ teamnameV }}</div>
				</div>
			</div>
			<div class="team-card">
				<el-carousel :loop="false" :autoplay="false" class="carousel" indicator-position="none" ref="cardShoww">
					<el-carousel-item class="el-car-item" v-for="(list, index) in teamList" :key="index">
						<div class="zuo">
							<img src="../../assets/index/youjiantou.png" @click="arrowClick2(1)" />
						</div>
						<div v-for="(imgList,index1) in list" :key="index1" class="divSrc">
							<div class="teamtitle">
								{{imgList.title}}
							</div>
							<div class="title">
								<div class="title_top">
									<img class="img" :src="imgList.image" style="border-radius: 10px;" />
								</div>
								<div class="title_bottom">
									{{imgList.description}}
								</div>
							</div>
							<div class="bage"></div>
						</div>
						<div class="you">
							<img src="../../assets/index/zuojiantou.png" @click="arrowClick3(2)" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 我们的爱心之路 -->
		<div class="lovely">
			<div class="lovely_title">
				<div class="lovely_img">

					<div style="font-size: 50px; font-family: yanshi;">
						<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
           -webkit-text-fill-color: transparent;">
							{{lovename}}
						</span>
					</div>
				</div>
				<div class="lovelytitle">
					{{lovenameN}}
				</div>
			</div>
			<div class="lovely_card">
				<loveimg></loveimg>
			</div>
		</div>
		<!-- 企业文化与发展历程 -->
		<div class="qiyewenhaun">
			<div class="qiyewenhaun_title">
				<div class="qiyewenhaun_img">

					<div style="font-size: 50px; font-family: yanshi;">
						<span style="background: linear-gradient(to bottom, #2597ff, #0d68fc);-webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;">
							{{qiyewenhaunn}}
						</span>
					</div>
				</div>
				<div class="qiyewenhauntitle">
					{{qiyewenhaunNn}}
				</div>
			</div>
			<div class="qiyewenhuan_card">
				<Company></Company>
			</div>
			<!-- 上层 -->
			<div class="qiyewenhuan_card1">
				<marqueey></marqueey>
			</div>
			<!-- 下层 -->
			<div class="qiyewenhuan_card2">
				<marqueeright></marqueeright>
			</div>
		</div>
		<!-- 固定组件 -->
		<affix></affix>
		<!--登录弹窗 -->
		<el-dialog v-model="dialogVisible" title="登录提示" width="30%" :before-close="handleClose">
			<span>尊敬的用户，你还为登录，请登录后在进行查看进行</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="gotologin()">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import affix from '../../components/affix.vue'
	import loveimg from './lovelylunbo.vue'
	import marqueey from './marquee.vue'
	import marqueeright from './marqueeright.vue'
	import Company from './Company.vue'
	import scrollwarp from '../../components/scrollwarp.vue'

	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/swiper-bundle.css';

	export default {
		components: {
			affix,
			loveimg,
			marqueey,
			marqueeright,
			Company,
			scrollwarp,
			Swiper,
			SwiperSlide,
		},
		name: "index",
		data() {
			return {
				dialogVisible: false,
				imgw: true,
				imgb: false,
				imgc: true,
				qiyewenhaun: '',
				qiyewenhaunN: '',
				imgd: false,
				value1: null,
				gongsijieshao: true,
				guanyuthem: false,
				dataList: [],
				huoban: '',
				huobanN: '',
				teamList: [],
				teamname: '',
				teamnameV: '',
				kehuList: [],
				qiyewenhaunist: [],
				qiyename: '',
				qiyenameN: '',
				qiyeluno: [],
				isTextChanging: false,
				swiperIndex: 0,
				swiperList: [],
				chanpin: '',
				chanpinN: '',
				lovename: '',
				lovenameN: '',
				swiperPhone: require('../../assets/index/swiperPhone.png'),
				gg: '截至今日已交付2000+!截至今日已交付2000+!截至今日已交付2000+!',
				leftVal: 0, // 轮播图盒子的偏移值
				flag: true, // 用来节流防止重复点击
				start: null, // 自动执行下一张定的时器
				imgWidth: 334, // 在这里填写你需要的图片宽度
				ition: 0.8, // 设置轮播图过度时间
				imgShow: 0, // 表示当前显示的图片索引
				banner: [],
				company_list: [],
				configure: [],
				isBlue: true,
				istop: true,
				isBlue1: false,
				istop1: false,
				title: [],
				description: [],
				content: [],
				video: '',
				contentattrValue: [],
				aboutwear: '',
				aboutwearN: '',
				mySwiper: null, //swiper实例
			}
		},
		// setup() {
		// 	const onSwiper = (swiper) => {
		// 		console.log(swiper);
		// 		this.mySwiper = swiper
		// 	};
		// 	const onSlideChange = () => {
		// 		console.log('slide change');
		// 	};
		// 	return {
		// 		onSwiper,
		// 		onSlideChange,
		// 	};
		// },
		mounted() {
			this.byEvents();
			this.bykehu();
			this.byteam();
			this.byqiye();
			this.byindex()
			this.byintroduce()
			this.byswiperList()
			this.byqiyewenhua()
			this.byteama()
			this.byintroduceN()
		},

		methods: {
			onSwiper(swiper) {
				this.mySwiper = swiper
			},
			async byteama() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_corporate_culture')

				if (res.code == 1) {
					this.qiyewenhaunn = res.data.title

					this.qiyewenhaunNn = res.data.description
				}
			},
			async byqiyewenhua() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_love_road')
				if (res.code == 1) {
					this.lovename = res.data.title
					this.lovenameN = res.data.description
				}
			},
			//跳转商品列表
			gotolist(row) {
				console.log('-----------', row)
				this.$router.push({
					name: 'ProductIntroduction', //moreshop
					query: {
						item: row.id
					}
				})
				// ?item=78
			},
			gotologin() {
				this.$router.push({
					name: 'login',
				})
			},
			//切换
			gongsi() {
				this.isBlue = true
				this.istop = true
				this.isBlue1 = false
				this.istop1 = false
				this.imgw = true
				this.imgb = false
				this.imgd = false
				this.imgc = true
				this.gongsijieshao = true
				this.guanyuthem = false
			},
			yewu() {
				this.imgw = false
				this.imgb = true
				this.imgc = false
				this.imgd = true
				this.isBlue = false
				this.istop = false
				this.isBlue1 = true
				this.istop1 = true
				this.gongsijieshao = false
				this.guanyuthem = true
			},
			// 增加/减少
			editSwiper(type) {
				if (type == 'add') { //增加
					this.swiperIndex += 1
				} else { //减少
					this.swiperIndex -= 1
				}
			},
			//切换
			selectSwiper(index) {
				this.swiperIndex = index
			},
			//切换
			arrowClick(val) {
				if (val == 1) {
					this.$refs.cardShow.prev()
				}
			},
			arrowClick1(val) {
				console.log(this.mySwiper, '111111111111111111111111111')
				this.mySwiper.slideNext()
			},
			arrowClickP() {
				this.mySwiper.slidePrev()
			},
			arrowClick2(val) {
				if (val === 1) {
					this.$refs.cardShoww.prev()
				}
			},
			arrowClick3(val) {
				if (val === 2) {
					this.$refs.cardShoww.next()
				}
			},
			async byteam() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_team')
				if (res.code == 1) {
					this.teamname = res.data.title
					this.teamnameV = res.data.description
					this.teamList = res.data.index_content_list
				}

				let newDataList = [];
				let current = 0
				if (this.teamList && this.teamList.length > 0) {
					for (let i = 0; i <= this.teamList.length - 1; i++) {
						if (i % 5 !== 0 || i === 0) { //数据处理成几张展示
							if (!newDataList[current]) {
								newDataList.push([this.teamList[i]])
							} else {
								newDataList[current].push(this.teamList[i])
							}
						} else {
							current++
							newDataList.push([this.teamList[i]])
						}
					}
				}
				this.teamList = [...newDataList]
			},
			async bykehu() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_say')
				if (res.code == 1) {
					this.kehuList = res.data.index_content_list
					this.listename = res.data.title
					for (let i = 0; i < this.kehuList.length; i++) {
						this.kehuList[i].score = 5
					}
				}
				// let newDataList = [];
				// let current = 0
				// if (this.kehuList && this.kehuList.length > 0) {
				// 	for (let i = 0; i <= this.kehuList.length - 1; i++) {
				// 		if (i % 4 !== 0 || i === 0) { //数据处理成几张展示
				// 			if (!newDataList[current]) {
				// 				newDataList.push([this.kehuList[i]])
				// 			} else {
				// 				newDataList[current].push(this.kehuList[i])
				// 			}
				// 		} else {
				// 			current++
				// 			newDataList.push([this.kehuList[i]])
				// 		}
				// 	}
				// }
				// this.kehuList = [...newDataList]
			},
			async byEvents() {
				const {
					data: res
				} = await this.$http.get('/index/index/index_title_partner', {
					params: {
						'type': 6
					}
				})
				if (res.code == 1) {
					this.dataList = res.data.index_content_list
					let newDataList = [];
					let current = 0
					if (this.dataList && this.dataList.length > 0) {
						for (let i = 0; i <= this.dataList.length - 1; i++) {
							if (i % 3 !== 0 || i === 0) { //数据处理成几张展示
								if (!newDataList[current]) {
									newDataList.push([this.dataList[i]])
								} else {
									newDataList[current].push(this.dataList[i])
								}
							} else {
								current++
								newDataList.push([this.dataList[i]])
							}
						}
					}
					this.dataList = [...newDataList]
					this.huoban = res.data.title
					this.huobanN = res.data.description
				}
			},
			async byqiye() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_honor')
				if (res.code == 1) {
					this.qiyename = res.data.title
					this.qiyenameN = res.data.description
					this.qiyeluno = res.data.index_content_list
				}
			},
			//首页
			async byindex() {
				const {
					data: res
				} = await this.$http.get('index/index/gateway_index')
				if (res.code == 1) {
					this.banner = res.data.banner //首页轮播图
					this.configure = res.data.configure //金刚区
				}
			},
			//关于我们
			async byintroduce() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_about')
				if (res.code == 1) {
					this.aboutwear = res.data.title
					this.aboutwearN = res.data.description
					this.introduce = res.data.index_content_list
					this.title = this.introduce[0].title
					this.description = this.introduce[0].description
					this.content = this.introduce[0].content
					this.content1 = this.introduce[1].content
					this.title1 = this.introduce[1].title
				}
			},
			//视频
			async byintroduceN() {
				const {
					data: res
				} = await this.$http.get('/index/index/index_title_cooperate', {
					params: {
						'type': 10
					}
				})
				if (res.code == 1) {
					this.video = res.data[0].img
				}
			},
			//产品介绍
			async byswiperList() {
				const {
					data: res
				} = await this.$http.get('index/index/index_title_product')
				let arr = res.data.index_content_list
				arr.map(item => {
					if (item.content) {
						item.content = JSON.parse(item.content)
					}
				})
				this.swiperList = arr
				this.chanpin = res.data.title
				this.chanpinN = res.data.description
			}
		}
	}
</script>

<style lang="less" scoped>
	:deep(.swiper-slide-active) {
		margin-left: -200px;
	}

	.bg-blue {
		background-image: url('../../assets/index/gsjs/leftblue.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.text-red {
		color: #fff;
		font-size: 14px;
	}

	.bg-blue1 {
		background-image: url('../../assets/index/gsjs/right_blue.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.text-red1 {
		color: #fff;
		font-size: 14px;
	}

	.active {
		filter: drop-shadow(0 0 #0D68FC);
	}

	.default {
		color: #fff;
	}

	.content {
		background: #EFF7FF;
	}

	.swiper {
		width: 100%;
		height: 620px;

		.swiper-item {
			width: 100%;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.swiper-item-gg {
				position: absolute;
				bottom: 20px;
				left: 50%;
				transform: translate(-50%, 0);
				width: 85%;
				height: 70px;
				background: rgba(255, 255, 255, 0.7);
				border-radius: 16px;
				padding: 0 50px;
				color: #0D68FC;
				font-size: 22px;

				img {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
			}
		}
	}

	.type {
		width: 100%;
		height: 185px;
		background: #FFFFFF;
		border-radius: 0px 0px 32px 32px;

		.type-item {
			width: 240px;
			height: 88px;

			img {
				width: 88px;
				height: 88px;
			}

			.type-item-txt {
				width: 120px;
				margin-left: 20px;

				.type-item-txt-title {
					width: 200px;
					color: #333333;
					font-size: 22px;
					font-weight: 500;
				}

				.type-item-txt-tx {
					width: 143px;
					margin-top: 6px;
					color: #666666;
					font-size: 16px;
				}
			}
		}
	}

	.title {
		width: 100%;
		flex-wrap: wrap;
		margin-top: 60px;

		img {
			width: 179px;
			height: 37px;
		}

		.title-tx {
			width: 100%;
			text-align: center;
			margin-top: 15px;
			font-size: 18px;
			color: #666666;
			font-weight: 400;
			font-style: italic;
		}
	}

	.introduce {
		width: 100%;
		margin-top: 60px;
		height: 500px;

		.introduce-box {
			width: 1250px;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.7);
			border-radius: 4px;
			position: relative;

			.introduce-box-c {
				width: calc(100% - 80px);
				height: calc(100% - 80px);

			}

			.introduce-box-cc {
				width: 100%;
				height: 100%;
			}

			.introduce-box-c-vido {
				width: 767px;
				height: 100%;

				#video {
					width: 100%;
					height: 100%;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			.introduce-box-c-txt {
				width: calc(100% - 787px);
				height: 100%;
				// background-color: red;
			}

			.introduce-box-c-txt-tt {
				width: 280px;
				height: 35px;
				border: #0D68FC 1px solid;
				border-radius: 4px;
				position: relative;
				left: 875px;
				top: 50px;

				.introduce-box-c-txt-t-js {
					width: 140px;
					height: 35px;
					border-radius: 4px;

					img {
						width: 15px;
						height: 15px;
						margin-left: 15px;
					}

					span {
						// color:  #0D68FC;
						// font-size: 14px;
						margin-left: 5px;
					}

				}

				.introduce-box-c-txt-t-fw {
					width: 140px;
					height: 35px;
					margin-left: 0px;
					position: relative;

					img {
						position: absolute;
						top: 11px;
						left: 42px;
						width: 15px;
						height: 15px;
					}

					span {
						position: absolute;
						top: 50%;
						right: 21px;
						transform: translate(0, -50%);

						font-size: 14px;
					}
				}
			}

			.introduce-box-c-txtb {
				width: 83%;
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374258;
				margin: 0 auto;
				margin-top: 15px;
				text-indent: 4ch;
				line-height: 36px;

			}

			.introduce-box-c-txt-t {
				width: 280px;
				height: 35px;
				border: #0D68FC 1px solid;
				border-radius: 4px;

				.introduce-box-c-txt-t-js {
					width: 140px;
					height: 35px;
					border-radius: 4px;

					img {
						width: 15px;
						height: 15px;
						margin-left: 15px;
					}

					span {
						// color:  #0D68FC;
						// font-size: 14px;
						margin-left: 5px;
					}

				}

				.introduce-box-c-txt-t-fw {
					width: 140px;
					height: 35px;
					margin-left: 0px;
					position: relative;

					img {
						position: absolute;
						top: 11px;
						left: 42px;
						width: 15px;
						height: 15px;
					}

					span {
						position: absolute;
						top: 50%;
						right: 21px;
						transform: translate(0, -50%);

						font-size: 14px;
					}
				}
			}

			.introduce-box-c-txt-n {
				width: 100%;
				margin-top: 40px;
				color: #333333;
				font-size: 22px;
				font-weight: 500;
			}

			.introduce-box-c-txt-x {
				margin-top: 24px;
				color: #374258;
				font-size: 14px;
				font-weight: 400;
			}

			.introduce-box-c-txt-i {
				margin-top: 20px;
				color: #374258;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.introduce-left {
			position: absolute;
			top: 50px;
			left: -220px;

			img {
				width: 220px;
				height: 255px;

			}
		}

		.introduce-right {
			position: absolute;
			bottom: 78px;
			right: -200px;

			// z-index: 1;
			img {
				width: 350px;
				height: 260px;

			}
		}
	}

	.product-box-l-c-box-item-active {
		color: #0D68FC !important;
		font-weight: 600 !important;
	}

	.product {
		width: 100%;
		height: 840px;
		margin-top: 60px;

		.product-box {
			width: 1633px;
			height: 100%;
			flex-wrap: wrap;
		}

		.product-box-l {
			width: 222px;
			// height: 744px;
			height: 100%;
			display: flex;
			align-items: flex-end;

			.product-box-l-c {
				width: 100%;
				height: 650px;
			}

			.product-box-l-c-box {
				width: 80%;
				height: 80%;
				display: flex;
				justify-content: center;
				align-content: space-between;
				flex-wrap: wrap;
			}

			.product-box-l-c-box-item {
				width: 100%;
				text-align: center;
				color: #999999;
				font-size: 24px;
				font-weight: 400;
				transition: color 0.5s ease;

				.product-box-l-c-box-item-img_s {
					width: 27px;
					height: 27px;
					margin-right: 16px;
				}

				.product-box-l-c-box-items {
					width: 27px;
					height: 27px;
					position: relative;
					margin-right: 16px;


					.product-box-l-c-box-item-img_,
					.product-box-l-c-box-item-img {
						width: 27px;
						height: 27px;
						position: absolute;
						top: 0;
						left: 0;
						transition: opacity 0.5s ease;
					}
				}

				.product-box-l-c-box-item-img_ {
					opacity: 0;
				}

			}

			.product-box-l-c-box-item:hover {
				color: #0D68FC;
				cursor: pointer;

				.product-box-l-c-box-item-img_ {
					opacity: 1;
				}

				.product-box-l-c-box-item-img {
					opacity: 0;
				}
			}
		}

		.product-box-r {
			width: 1411px;
			height: 100%;
		}

		.product-box-r-top {
			width: 100%;
			height: 189px;
			position: relative;

			.product-box-r-top-box {
				position: absolute;
				bottom: 15px;
				left: 20px;
			}

			img {
				width: 180px;
				height: 37px;
			}

			.product-box-r-top-name {
				color: #666666;
				font-size: 18px;
				margin-top: 10px;
			}
		}

		.product-box-r-btn {
			width: 100%;
			height: 650px;
			background-color: green;
			border-radius: 40px;
			background-image: url('../../assets/index/swiperBg.png');
			position: relative;

			.product-box-r-btn-l {
				position: absolute;
				left: 80px;
				top: 50px;
				color: #FFFFFF;
			}

			.product-box-r-btn-l-title {
				font-size: 44px;

				.product-box-r-btn-l-title-t {
					font-weight: bold;
				}
			}

			.product-box-r-btn-l-name {
				font-size: 22px;
				font-weight: 400;
				margin-top: 16px;
			}

			.product-box-r-btn-l-type {
				margin-top: 70px;
				color: #FFFFFF;
				font-size: 26px;
				font-weight: bold;
			}

			.product-box-r-btn-l-ul {
				margin: 0;
				padding: 20px;
			}

			.product-box-r-btn-l-item {
				font-size: 19px;
				font-weight: 400;
				margin-bottom: 28px;
			}

			.product-box-r-btn-l-btn:hover::after {
				height: 100%;
			}

			.product-box-r-btn-l-btn::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 0;
				background-color: white;
				bottom: 0;
				transition: height 0.5s ease;
			}

			.product-box-r-btn-l-btn:hover p {
				color: #0D68FC !important;
			}

			.product-box-r-btn-l-btn {
				width: 200px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #FFFFFF;
				border-radius: 8px;
				cursor: pointer;
				position: relative;
				overflow: hidden;

				p {
					position: relative;
					z-index: 1;
					color: white;
				}

			}

			.product-box-r-btn-phone {
				position: absolute;
				width: 368px;
				height: 840px;
				right: 250px;
				top: -189px;
				overflow: hidden;

				// background-color: red;
				img {
					width: 100%;
					height: 730px;
					position: absolute;
					top: 0;
				}
			}

			.product-box-r-btn-line {
				position: absolute;
				width: 53px;
				top: 100px;
				right: 110px;

				.product-box-r-btn-lines {
					width: 2px;
					height: 230px;
					background: rgba(255, 255, 255, 0.6);
					margin: 24px 0;
					margin-left: 25px;
					position: relative;

					.product-box-r-btn-linesss {
						width: 100%;
						height: calc(230px / 6);
						position: absolute;
						top: 0;
						left: 0;
						background-color: #ffffff;
					}
				}

				.product-box-r-btn-line-start {
					color: #E5EFFF;
					font-size: 21px;
					width: 100%;
					text-align: center;
				}

				.product-box-r-btn-line-end {
					color: #E5EFFF;
					font-size: 21px;
					width: 100%;
					text-align: center;
				}

				.product-box-r-btn-line-up {
					width: 53px;
					height: 53px;
					border: 1px solid #ffffff;
					border-radius: 50%;
					margin-top: 30px;
					cursor: pointer;
				}

				.product-box-r-btn-line-down {
					width: 53px;
					height: 53px;
					border: 1px solid #ffffff;
					border-radius: 50%;
					margin-top: 24px;
					cursor: pointer;
				}
			}
		}

	}

	.kehu {
		width: 100%;
		height: 960px;
		margin-top: 60px;
		background-image: url(../../assets/index/kehuBg.png);
		background-size: cover;
		background-repeat: no-repeat;

		.kehu-title {
			width: 100%;
			text-align: center;
			font-size: 44px;
			color: #FFFFFF;
			font-weight: bold;
			padding-top: 45px;
		}

		.kehu-card {
			width: 100%;
			height: 590px;
			margin-top: 63px;

			/deep/.el-carousel__arrow--right {
				display: none;
			}

			/deep/.el-carousel__arrow--left {
				display: none;
			}

			.carousel {
				width: 100%;
				height: 590px;

				.el-car-item {
					width: 100%;
					height: 590px;
					display: flex;
					justify-content: space-around;
					flex-direction: row;
					flex-wrap: nowrap;



					.divSrc {
						width: 443px;
						height: 590px;
						background: #FFFFFF;
						border-radius: 24px;

						.img {
							width: 389px;
							height: 217px;
							margin: 20px 20px 0 30px;
						}

						.title {
							flex: 1;
							margin: 0;

							.title_top {
								img {
									width: 154px;
									height: 67px;

								}
							}
						}

						.title_bottom {
							margin-left: 28px;
							width: 84%;
							height: 108px;
							font-size: 17px;
							font-family: Source Han Sans CN;
							font-weight: 300;
							color: #999999;
							line-height: 35px;
						}
					}

					.score {
						width: 100%;
						height: 90px;
						margin-top: 70px;
						margin-left: 10px;
					}
				}
			}

			.el-car-item {
				width: 100%;
				height: 590px;
				display: flex;

				.img {
					width: 284px;
					height: 184px;
					margin-right: 20px;
					cursor: pointer;
				}
			}
		}

		.next {
			width: 100%;
			text-align: center;
			margin-top: 88px;

			.zuo {
				margin-right: 20px;
			}

			.you {}
		}
	}

	.qiye {
		width: 100%;
		height: 550px;
		margin-top: 60px;

		.qiye_title {
			width: 100%;
			height: auto;

			.qiye_img {
				text-align: center;
			}

			.qiye_img_text {
				text-align: center;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
				margin-top: 20px;
			}
		}

		.qiye_lunbo {
			width: 100%;
			height: 100px;
			margin-top: 57px;
			text-align: center;
		}
	}

	.huoban {
		width: 100%;
		height: 500px;
		margin-top: 112px;

		.huoban_title {
			width: 100%;
			height: auto;

			.huoban_img {
				text-align: center;
			}

			.huoban_img_text {
				text-align: center;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
				margin-top: 20px;
			}
		}

		.huoban_lunbo {
			width: 100%;
			height: 100px;
			margin-top: 57px;
			// text-align: center;

			.carousel {
				width: 100%;
				height: 420px;

				.el-car-item {
					width: 90%;
					height: 420px;
					display: flex;

					.divSrc {
						width: 760px;
						height: 305px;
						background: #FFFFFF;
						border-radius: 10px;
						margin-left: 145px;
						display: flex;
						justify-content: space-between;

						.img {
							width: 100%;
							height: 100%;

						}

						.title {
							flex: 1;
							text-overflow: ellipsis;

							.title_top {
								font-size: 24px;
								font-family: Source Han Sans CN;
								font-weight: 500;
								color: #333333;
								margin-top: -32px;
								margin-left: 12px;
							}
						}

						.radios {
							ul {
								margin: 0;
								padding-left: 4px;
								text-decoration: none;

								li {
									margin-left: 10px;
									width: 4px;
									height: 4px;
									background: #FCD680;
									border-radius: 50%;
									display: inline-block;
								}

							}
						}

						.title_bottom {
							margin-left: 12px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							color: #999999;
							line-height: 30px;
						}
					}
				}
			}

			.el-car-item {
				width: 100%;
				display: flex;

				.img {
					width: 284px;
					height: 184px;
					margin-right: 20px;
					cursor: pointer;
				}
			}
		}
	}

	.team {
		width: 100%;
		height: 600px;

		.team-title {
			width: 100%;
			padding-top: 25px;
			text-align: center;

			.huoban_img {
				text-align: center;
			}

			.huoban_img_text {
				text-align: center;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
				margin-top: 20px;
			}
		}

		.team-card {
			width: 100%;
			height: 590px;
			margin-top: 50px;

			/deep/.el-carousel__arrow--right {
				display: none;
			}

			/deep/.el-carousel__arrow--left {
				display: none;
			}

			.carousel {
				width: 100%;
				height: 410px;

				.el-car-item {
					width: 96%;
					display: flex;
					justify-content: space-around;
					flex-direction: row;
					flex-wrap: wrap;
					overflow: hidden;
					margin-left: 29px;

					.divSrc {
						width: 300px;
						height: 420px;
						background: #E4EDFF;
						border-radius: 10px;

						.imgTop {
							width: 100%;

						}

						.teamtitle {
							margin: 71px 183px 0px 30px;
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #333333;
							line-height: 3px;
							width: 100%;
						}

						.title {
							flex: 1;
							margin: 0;

							.title_top {
								img {
									width: 156px;
									height: 184px;
									margin-top: 35px;
									margin-left: 31px;
								}
							}
						}

						.title_bottom {
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							line-height: 3px;
							margin-left: 30px;
							margin-top: 20px;
						}
					}

					.score {
						width: 100%;
						height: 90px;
						margin-top: 70px;
						margin-left: 10px;
					}
				}
			}

			.bage {
				width: 30px;
				height: 4px;
				background: #0D68FC;
				margin-top: 50px;
				margin-left: 30px;
			}

			.el-car-item {
				width: 100%;
				height: 590px;
				display: flex;

				.img {
					width: 284px;
					height: 184px;
					margin-right: 20px;
					cursor: pointer;
				}
			}
		}


		.zuo {
			margin-top: 200px;
		}

		.you {
			margin-top: 200px;
		}

	}

	.lovely {
		width: 100%;
		height: 750px;
		margin-top: 35px;

		.lovely_title {
			width: 100%;
			height: auto;
			text-align: center;

			.lovely_img {
				text-align: center;
				margin-bottom: 12px;
			}

			.lovelytitle {
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}

		.lovely_card {
			width: 86%;
			height: 500px;
			margin: 0 auto;
		}

	}

	.qiyewenhaun {
		width: 100%;
		height: 550px;
		margin: 60px 0 111px 0;


		.qiyewenhaun_title {
			width: 100%;
			height: auto;
			text-align: center;

			.qiyewenhaun_img {
				text-align: center;
				margin-bottom: 12px;
			}

			.qiyewenhauntitle {
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}

		/* 图片容器样式 */
		.SwiperBox {
			position: relative;
			width: 100%;
			height: 300px;
			box-sizing: border-box;
			cursor: pointer;
			overflow: hidden;
		}

		.imgBox {
			position: absolute;
			top: 0px;
			left: 0px;
			min-width: 500px;
			height: 300px;
			display: flex;
			justify-content: flex-start;
		}

		/* 图片默认样式 */
		.Swiper-item {
			flex-shrink: 0;
			width: 322px;
			height: 330px;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}

		.Swiper-item img {
			width: 322px;
			height: 330px;
		}

		/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
		.leftBtn,
		.rightBtn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(109, 109, 109, 0.445);
			color: #fff;
			border-radius: 50%;
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
		}

		.leftBtn {
			left: 10px;
		}

		.rightBtn {
			right: 10px;
		}


		#app {
			width: 100%;
			padding: 120px;
			display: flex;
			justify-content: center;
			padding: 33px 0px;
		}

		.qiyewenhuan_card {
			width: 100%;
			margin-top: 50px;
		}

		.qiyewenhuan_card1 {
			width: 100%;
			height: 100px;
			margin-top: 1px;
		}

		.qiyewenhuan_card2 {
			width: 100%;
			height: 100px;
		}

	}

	.divSrc2 {

		// background: #fff;
		//     padding: 26px;
		//     border-radius: 18px;
		.divSrc {
			width: 443px;
			height: 590px;
			background: #FFFFFF;
			border-radius: 24px;

			.imgTop {
				width: 90%;
				padding: 5%;
			}

			.img {
				width: 389px;
				height: 217px;
				margin: 20px 20px 0 30px;
			}

			.title {
				flex: 1;
				margin: 0;

				.title_top {
					img {
						width: 154px;
						height: 67px;

					}
				}
			}

			.title_bottom {
				margin-left: 28px;
				width: 84%;
				height: 108px;
				font-size: 17px;
				font-family: Source Han Sans CN;
				font-weight: 300;
				color: #999999;
				line-height: 35px;
			}
		}

		.score {
			width: 100%;
			height: 90px;
			margin-top: 70px;
			margin-left: 10px;
		}
	}
</style>