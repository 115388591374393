<!--评论模块-->
<template>
  <div class="pingjia_hera2">
    <span class="moren" @click="dianjichange(1)" :class="{'text-red':isRed}">默认排序</span>
    <span class="shu"></span>
    <span class="zuixin" @click="dianjichange(2)" :class="{'text-red':isRedd}">最新排序</span>
  </div>
  <div class="container">
    <div class="comment" v-for="(item, key) in comments" v-show="key<num">
      <!-- {{item}} -->
      <div class="info">
        <img class="avatar" v-if="item.user_info" :src="item.user_info.avatar" width="70" height="70" />
		<img class="avatar" v-else src="../assets/heard.png" width="70" height="70" />
        <div class="right">
          <div class="name" v-if="item.user_info">{{item.user_info.nickname}} </div>
          <div class='vip'>
            <div v-if="item.vip==1">
              <img src="../../src/assets/svip.png" alt="" width="93" height="30">
            </div>
            <div v-else style="font-size: 22px; position: relative;top:-12px">普通用户</div>
          </div>
          <div class="rate">
            <el-rate v-model="item.score" />
          </div>
        </div>
      </div>
      <div class="content">{{item.content}}</div>
      <div class="date">{{item.createtime}}
        <span style="margin-left: 30px;">
          {{item.address}}
        </span>
      </div>
      <el-divider />
      <div class="reply">
        <div class="item" v-for="reply in item.reply">
          <div v-if="reply">
            <div class="reply-content">
              <span class="from-name">{{reply.fromName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回复:</span>
              <div class="from-content">{{reply.content}}</div>
            </div>
            <div class="reply-bottom">
              <span>{{reply.date}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 站位图 -->
    <div v-if="!comments.length" style="text-align: center; margin: 0 auto;width: 100%;height: 300px;">
      <img src="../assets/dhpbjj.png" alt="" style="padding-left: 317px;padding-top: 44px;"/>
    </div>
  </div>
  <div class="buttonmore"  v-if="comments.length">
    <button class="buttonmore1" @click="lookmore">
      <span @click="showMore">{{txt}}</span>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      goosid: {
        type: Array,
        required: true,
      }
    },
    components: {},
    data() {
      return {
        comments: [],
        inputComment: '',
        showItemId: '',
        value1: 3,
        isShow: true,
        txt: '显示全部',
        num: 2,
        isRedd: false,
        isRed: true,
        type: 2,
      }
    },
    computed: {},

    methods: {

      dianjichange(index) {
        if (index == 1) {
          this.isRed = true
          this.isRedd = false
          this.type = 1
          this.bycomments()
        } else if (index == 2) {
          this.isRedd = true
          this.isRed = false
          this.type = 2
          this.bycomments()
        }
      },
      showMore() {
        this.isShow = !this.isShow;
        this.num = this.isShow ? 2 : this.comments.length;
        this.txt = this.isShow ? '显示全部' : '收起'
      },
      async bycomments() {
        const {
          data: res
        } = await this.$http.post('index/product/goods_evaluate', {
          goods_id: this.goosid,
          sort: this.type,
          num: 10,
          page: 1,
        })
        if (res.code == 1) {
          this.comments = res.data.data
          this.comments.map(item => {
            console.log(item)
            if (item.user_lat || item.user_lng) {
              let lat = item.user_lat
              let lng = item.user_lng
            }
          })
        }
      },
    },
    mounted() {
      this.bycomments()
    }
  }
</script>

<style scoped lang="less">
  .text-red {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 42px;
    background: linear-gradient(-90deg, #0055FF 0.634765625%, #3E81FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    .comment {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 35px;
      border-bottom: 1px solid #F2F6FC;
      margin-left: 37px;

      .info {
        display: flex;
        align-items: center;

        .avatar {
          border-radius: 50%;
        }

        .right {
          width: 400px;
          height: 50px;
          display: flex;
          margin-left: 10px;

          .name {
            width: 95px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 50px;
            margin-left: 10px;

          }

          .vip {
            width: 100px;
            line-height: 72px;
          }

          .rate {
            width: 100px;
            line-height: 56px;
          }
        }
      }

      .content {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 29px;
        padding: 10px 86px;
      }

      .date {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 42px;
        margin-left: 87px;
      }

      .reply {
        margin: 10px 0;

        .item {
          margin: 0 10px;
          padding: 10px 0;
          border-bottom: 1px dashed #EBEEF5;

          .reply-content {
            align-items: center;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;

            .from-name {
              display: inline-block;
              color: #409EFF;
              margin-bottom: 20px;
            }

            .from-content {
              margin-bottom: 20px;
            }
          }

          .reply-bottom {
            display: flex;
            align-items: center;
            margin-top: 6px;
            font-size: 24px;
            color: #909399;
          }
        }
      }
    }
  }

  .buttonmore {
    width: 100%;
    height: 200px;

    .buttonmore1 {
      width: 241px;
      height: 64px;
      border: 1px solid #0D68FC;
      border-radius: 32px;
      margin: 67px 700px;

      span {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0D68FC;
        line-height: 42px;
      }
    }
  }

  .pingjia_hera2 {
    float: right;
    margin-top: 18px;

    .moren {
      width: 111px;
      height: 27px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 42px;
    }

    .shu {
      display: inline-block;
      border: 2px solid #B3B3B3;
      height: 18px;
      margin: 0 20px;
    }

    .zuixin {
      width: 111px;
      height: 27px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 42px;
      margin-right: 51px;
    }
  }

  /deep/ .el-rate .el-rate__icon.is-active {
    color: red;
  }
</style>