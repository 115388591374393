<template>
  <div class="cont">
    <div class="contlist" ref='contlist'>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="con">
              {{item.name}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        scrollW: 0, //记录滚动到哪了
      }
    },
    methods: {
      //循环滚动
      scroll() {
        var that = this;
        var target = this.$refs.contlist;
        var initRight = 0;
        if (this.scrollW < 0) {
          initRight = this.scrollW * (-1)
        }
        //定时器
        this.scrollTime = setInterval(function() {
          initRight++;
          if (initRight >= target.offsetWidth / 4) {
            initRight = 0;
          }
          target.style.right = '-' + initRight + 'px';
        }, 16)
      },
      async byindex() {
        const {
          data: res
        } = await this.$http.get('index/index/gateway_index')
        if (res.code == 1) {
          this.list = res.data.company_list //金刚区
        }
      }
    },
    mounted() {
      this.scroll()
      this.byindex()
    }
  }
</script>
<style type="text/css" scoped>
  ul,
  li,
  em {
    margin: 0;
    padding: 0;
  }

  .cont {
    height: 100px;
    color: #fff;
    overflow: hidden;
    position: relative;
  }

  .contlist {
    position: absolute;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
  }

  .contlist ul {
    display: flex;
    flex-direction: row;
  }

  .contlist ul li {
    width: 306px;
    height: 100px;
    background: #FFFFFF;
    
    display: flex;
    flex-direction: row;
  }

  .contlist ul li:nth-of-type(odd) {
    width: 306px;
    height: 100px;
    background: #EFF7FF;
  }

  .con {
    width: 100%;
    height: 100px;
    margin: 38px auto;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #0D68FC;
    font-size: 18px;
    font-weight: 900;
  }
</style>