<template>
  <div class="content">
    <div class="invoice">
      <div class="invoice_invoice" v-if="dialogVisible">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="发票申请" name="first">
            <div class="application">
              <div class="application_top">
                <span class="shuxian"></span>
                开票信息
              </div>
              <div class="application_con">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                  <div class="application_con_first">
                    <div class="nashuiren"><!-- *纳税人识别号 -->
                      <el-form-item label="纳税人识别号:" prop="nashuiren">
                        <el-input v-model="ruleForm.nashuiren" style="width: 632px;" maxlength="15"
                          show-word-limit></el-input>
                      </el-form-item>
                    </div>
                    <div class="yinhang"><!-- *银行账号 -->
                      <el-form-item label="银行账号:" prop="yinhang">
                        <el-input v-model="ruleForm.yinhang" style="width: 632px;"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="application_con_two">
                    <div class="kaihu"><!-- *开户银行 -->
                      <el-form-item label="开户银行账号:" prop="kaihu">
                        <el-input v-model="ruleForm.kaihu" style="width: 632px;"></el-input>
                      </el-form-item>
                    </div>
                    <div class="kaipiao"><!-- *开票电话 -->
                      <el-form-item label="开票电话:" prop="kaipiao">
                        <el-input v-model="ruleForm.kaipiao" style="width: 632px;"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="application_con_three">
                    <div class="adress"><!-- **开户地址 -->
                      <el-form-item label="开户地址:" prop="adress">
                        <el-input v-model="ruleForm.adress" style="width: 96%; margin-left: 24px;"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
                <div style="margin-left: 32px;margin-top: 10px;" v-if="type==0">
                  <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
                    @click="submitForm('ruleForm')">
                    <span
                      style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">提交</span>
                  </el-button>
                </div>
                <div style="margin-left: 32px;margin-top: 10px;" v-if="type==1">
                  <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
                    @click="submitFormxiugai('ruleForm')">
                    <span
                      style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">修改提交</span>
                  </el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="专票资质管理" name="second">
            <div v-if="type==1">你以提交普票发票申请无法进行专票申请若想进行申请联系客服</div>
            <div v-else>
              <div class="management">
                <div class="management_top">
                  <span class="shuxian"></span>
                  基本信息
                  <span class="biaoshi"><span class="biaoshi_biaoshi">!</span></span>
                  <span class="text">您还未提交专票资质</span>
                </div>
                <div class="management_con">
                  <el-form :model="ruleFormlist" :rules="ruleslist" ref="ruleFormlist" class="demo-ruleForm"
                    :label-position="labelPosition">
                    <div class="management_con_first">
                      <div class="gongsi"><!-- *公司全称* -->
                        <el-form-item label="公司全称" prop="gongsi">
                          <el-input v-model="ruleFormlist.gongsi" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                      <div class="shuiwu"><!-- *税务登记号* -->
                        <el-form-item label="税务登记号" prop="shuiwu">
                          <el-input v-model="ruleFormlist.shuiwu" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="management_con_two">
                      <div class="address"><!-- *公司注册地址** -->
                        <el-form-item label="公司注册地址" prop="address">
                          <el-input v-model="ruleFormlist.address" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                      <div class="phone"><!-- *开票电话** -->
                        <el-form-item label="开票电话" prop="phone">
                          <el-input v-model="ruleFormlist.phone" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="management_con_three">
                      <div class="kaihu"><!-- *基本开户银行*** -->
                        <el-form-item label="基本开户银行" prop="kaihu">
                          <el-input v-model="ruleFormlist.kaihu" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                      <div class="kaihunumber"><!-- *基本开户银行账号*** -->
                        <el-form-item label="基本开户银行账号" prop="kaihunumber">
                          <el-input v-model="ruleFormlist.kaihunumber" style="width: 632px;"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="management_con_four">
                      <div class="name"><!-- *基本开户名**** -->
                        <el-form-item label="基本开户名" prop="name">
                          <el-input v-model="ruleFormlist.name" style="width: 1399px;"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="management_con_five">
                      <div class="businesslicense"><!-- *营业执照***** -->
                        <div class="namee">营业执照</div>
                        <el-upload class="avatar-uploader"
                          action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload" :show-file-list="false"
                          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                          <img v-if="ruleFormlist.imageUrl" :src="ruleFormlist.imageUrl" class="avatar" />
                          <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                            <div
                              style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #0D68FC;line-height: 3px;">
                              上传营业执照</div>
                          </el-icon>
                        </el-upload>
                      </div>
                      <div class="nashuishuoming"><!-- *一般纳税人证明****** -->
                        <div class="namee">一般纳税人证明</div>
                        <el-upload class="avatar-uploader"
                          action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload" :show-file-list="false"
                          :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1">
                          <img v-if="ruleFormlist.imageUrl1" :src="ruleFormlist.imageUrl1" class="avatar" />
                          <el-icon v-else class="avatar-uploader-icon">

                            <Plus />
                            <div
                              style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #0D68FC;line-height: 3px;">
                              上传纳税人证书</div>
                          </el-icon>
                        </el-upload>
                      </div>
                      <div class="xukezheng"><!-- *银行开户许可证****** -->
                        <div class="namee">银行开户许可证</div>
                        <el-upload class="avatar-uploader"
                          action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload" :show-file-list="false"
                          :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2">
                          <img v-if="ruleFormlist.imageUrl2" :src="ruleFormlist.imageUrl2" class="avatar" />
                          <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                            <div
                              style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #0D68FC;line-height: 3px;">
                              银行开户许可证</div>
                          </el-icon>
                        </el-upload>
                      </div>
                    </div>
                    <div style="position: relative;top:-6px;left: 38px;">图片仅支持JPG、jPEG、PNG,且不超过5MB</div>
                  </el-form>
                </div>
              </div>
              <div style="position: relative;top:-61px;left:1380px;" v-if="type==0">
                <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
                  @click="submitFormlist('ruleFormlist')"><span
                    style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">提交</span></el-button>
              </div>
              <div style="position: relative;top:-61px;left:1380px;" v-if="type==2">
                <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
                  @click="submitFormlistxiugai('ruleFormlist')"><span
                    style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">修改提交</span></el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="invoice_invoice" v-if="dialogVisible1">
        <div class="shengqingzhong">
          <span class="fanhui" @click="fanhui()">
            <img src="../../assets/my/fanhui.png">
          </span>
          <span class="shengqing">申请中</span>
          <sapn class="time">
            该订单还有{{ days }}天 {{ hours }}时 {{ minutes }}分 {{ seconds }}秒处理时间
            <el-tooltip :content="content" placement="bottom" effect="light" raw-content>
              <img src="../../assets/my/imig11.png" class="imig">
            </el-tooltip>
          </sapn>
        </div>
        <el-divider></el-divider>
        <div class="fpje">
          纳税人识别号: <span style="margin-left: 4px;">{{ruleForm.nashuiren}}</span>
        </div>
        <div class="fplx">
          发票类型: <span v-if="type==1">普票</span>
          <span v-else>专票</span>
        </div>
        <div class="fplx">
          银行账号: <span>{{ruleForm.yinhang}}</span>
        </div>
        <div class="sqrq">
          开户银行账号:<span>{{ruleForm.kaihu}}</span>
        </div>
        <div class="fptt">
          开票电话:<span>{{ruleForm.kaipiao}}</span>
        </div>
        <div class="gfsh">
          开户地址:<span>{{ruleForm.adress}}</span>
        </div>
        <div class="fpcz">
          发票操作:<span style="color: #0D68FC;" @click="xiugaishengqing()">修改申请</span>
        </div>
        <el-divider></el-divider>
        <div>
          您还可以：<span style="color: #0D68FC;" @click="lookhistory">查看申请历史</span>
        </div>
      </div>
      <div class="invoice_invoice" v-if="dialogVisible2">
        <div class="shengqingzhong">
          <span class="fanhui" @click="fanhui()">
            <img src="../../assets/my/fanhui.png">
          </span>
          <span class="shengqing">申请中</span>
          <sapn class="time">
            该订单还有{{ daysLeft }}天 {{ hoursLeft }}时 {{ minutesLeft }}分 {{ secondsLeft }}秒处理时间
            <el-tooltip :content="content" placement="bottom" effect="light" raw-content>
              <img src="../../assets/my/imig11.png" class="imig">
            </el-tooltip>
          </sapn>
        </div>
        <el-divider></el-divider>
        <div class="fpje">
          公司全称: <span>{{ruleFormlist.gongsi}}</span>
        </div>
        <div class="fplx">
          发票类型: <span>专票</span>
        </div>
        <div class="sqrq">
          税务登记号:<span>{{ ruleFormlist.shuiwu}}</span>
        </div>
        <div class="fptt">
          公司注册地址:<span>{{ ruleFormlist.address}}</span>
        </div>
        <div class="gfsh">
          开票电话:<span>{{ ruleFormlist.phone}}</span>
        </div>
        <div class="fpnr">
          基本开户银行:<span>{{ ruleFormlist.kaihu}}</span>
        </div>
        <div class="fpnr">
          基本开户银行账号:<span>{{ ruleFormlist.kaihunumber}}</span>
        </div>
        <div class="fpnr">
          基本开户名:<span>{{ ruleFormlist.name}}</span>
        </div>
        <div class="management_con_five">
          <div class="businesslicense"><!-- *营业执照***** -->
            <div class="namee">营业执照</div>
            <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
              :show-file-list="false">
              <img v-if="ruleFormlist.imageUrl" :src="ruleFormlist.imageUrl" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
          <div class="nashuishuoming"><!-- *一般纳税人证明****** -->
            <div class="namee">一般纳税人证明</div>
            <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
              :show-file-list="false">
              <img v-if="ruleFormlist.imageUrl1" :src="ruleFormlist.imageUrl1" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
          <div class="xukezheng"><!-- *银行开户许可证****** -->
            <div class="namee">银行开户许可证</div>
            <el-upload class="avatar-uploader" action="https://ymshopapi.xianmaxiong.com/api/index/ajax/upload"
              :show-file-list="false">
              <img v-if="ruleFormlist.imageUrl2" :src="ruleFormlist.imageUrl2" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
        </div>
        <div class="fpcz">
          发票操作:<span style="color: #0D68FC;" @click="xiugaishengqing()">修改申请</span>
        </div>
        <el-divider></el-divider>
        <div>
          您还可以：<span style="color: #0D68FC;" @click="lookhistory">查看申请历史</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //修改
        editid: 0,
        countdownDate: new Date().getTime() + 7 * 24 * 60 * 60 * 1000, // 倒计时结束时间
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        labelPosition: 'top',
        content: "<div>如果您在订单交易成功前提交发票申请，</div><div>我们将在交易成功后7天内完成开票;如</div><div>果您在订单交易成功后提交申请,我们将</div><div>在申请成功提交;</div><div>如果您在我们完成开票前，发起了退款、</div><div>或换货，则发票申请的处理时间请与客</div><div> 服具体协商。</div>",
        dialogVisible: true,
        dialogVisible1: false,
        dialogVisible2: false,
        activeName: 'first',
        ruleForm: {
          nashuiren: '',
          yinhang: '',
          kaihu: '',
          kaipiao: '',
          adress: '',
          shoupiao: '',
          raido: '1',
        },
        rules: {
          nashuiren: [{
            required: true,
            message: '请输入纳税人识别号',
            trigger: 'blur'
          }, ],
          yinhang: [{
            required: true,
            message: '请输入银行账号',
            trigger: 'blur'
          }],
          kaihu: [{
            required: true,
            message: '请输入开户银行',
            trigger: 'blur'
          }, ],
          kaipiao: [{
            required: true,
            message: '请输入开票电话',
            trigger: 'blur'
          }, ],
          adress: [{
            required: true,
            message: '请输入开户地址',
            trigger: 'blur'
          }]
        },
        ruleFormlist: {
          gongsi: '',
          shuiwu: "",
          address: '',
          phone: '',
          kaihu: '',
          kaihunumber: '',
          name: '',
          imageUrl: '',
          imageUrl1: '',
          imageUrl2: '',
        },
        ruleslist: {
          gongsi: [{
            required: true,
            message: '请输入公司全称',
            trigger: 'blur'
          }],
          shuiwu: [{
            required: true,
            message: '请输入税务登记号',
            trigger: 'blur'
          }],
          address: [{
            required: true,
            message: '请输入公司注册地址',
            trigger: 'blur'
          }],
          phone: [{
            required: true,
            message: '请输入开票电话',
            trigger: 'blur'
          }],
          kaihu: [{
            required: true,
            message: '请输入基本开户银行',
            trigger: 'blur'
          }],
          kaihunumber: [{
            required: true,
            message: '请输入基本开户银行账号',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '请输入基本开户名',
            trigger: 'blur'
          }],
        },
        invoiceinfo: [],
        type: 0, //专票 普票
      }
    },
    created() {
      this.invoicein_fo()
    },
    methods: {
      xiugaishengqing() {
        this.dialogVisible = true
        this.dialogVisible1 = false
        this.dialogVisible2 = false
      },
      lookhistory() {
        this.$router.push({
          path: '/my/Invoicing',
          query: {
            name: '这是开票记录',
          }
        })
      },
      handleAvatarSuccess(res, file) {
        this.ruleFormlist.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
      handleAvatarSuccess1(res, file) {
        this.ruleFormlist.imageUrl1 = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload1(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
      handleAvatarSuccess2(res, file) {
        this.ruleFormlist.imageUrl2 = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload2(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
      handleClick(tab, event) {
        if (tab.props.name == 'first') {
          // this.dataSelect1()
        }
        if (tab.props.name == 'senced') {
          // this.dataSelect2()
        }
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/add_invoice_info', {
              type: 1, //发票类型  1.普票  2.专票
              company_tax_id: this.ruleForm.nashuiren || this.ruleFormlist.shuiwu, //税务登记号
              bank_name: this.ruleForm.kaihu || this.ruleFormlist.kaihu, //开户银行
              bank_phone: this.ruleForm.kaipiao || this.ruleFormlist.phone, //开票电话
              bank_number: this.ruleForm.yinhang || this.ruleFormlist.kaihunumber, //银行账号
              bank_address: this.ruleForm.adress, //开户地址
              company_name: this.ruleFormlist.gongsi, //公司全称
              company_address: this.ruleFormlist.address, //公司注册地址
              open_account: this.ruleFormlist.name, //开户名
              business_license: this.ruleFormlist.imageUrl, //营业执照
              taxpayer_prove: this.ruleFormlist.imageUrl1, //纳税人证明
              acount_permit: this.ruleFormlist.imageUrl2, //开户许可证
            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "发票申请",
                  message: "发票申请成功",
                  duration: 2500,
                  offset: 50
                });
                this.dialogVisible = false
                this.dialogVisible1 = true
                this.dialogVisible2 = false
              } else {
                this.$notify.success({
                  title: "发票申请",
                  message: res.msg,
                  duration: 2500,
                  offset: 50
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitFormlist(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/add_invoice_info', {
              type: 2, //发票类型  1.普票  2.专票
              company_tax_id: this.ruleForm.nashuiren || this.ruleFormlist.shuiwu, //税务登记号
              bank_name: this.ruleForm.kaihu || this.ruleFormlist.kaihu, //开户银行
              bank_phone: this.ruleForm.kaipiao || this.ruleFormlist.phone, //开票电话
              bank_number: this.ruleForm.yinhang || this.ruleFormlist.kaihunumber, //银行账号
              bank_address: this.ruleForm.adress, //开户地址
              company_name: this.ruleFormlist.gongsi, //公司全称
              company_address: this.ruleFormlist.address, //公司注册地址
              open_account: this.ruleFormlist.name, //开户名
              business_license: this.ruleFormlist.imageUrl, //营业执照
              taxpayer_prove: this.ruleFormlist.imageUrl1, //纳税人证明
              acount_permit: this.ruleFormlist.imageUrl2, //开户许可证
            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "发票申请",
                  message: "发票申请成功",
                  duration: 2500,
                  offset: 50
                });
                this.dialogVisible = false
                this.dialogVisible1 = false
                this.dialogVisible2 = true
                this.countdown();
              } else {
                this.$notify.success({
                  title: "发票申请",
                  message: res.msg,
                  duration: 2500,
                  offset: 50
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      fanhui() {
        this.dialogVisible = true
        this.dialogVisible1 = false
        this.dialogVisible2 = false
      },
      countdown() {
        setInterval(() => {
          const now = new Date().getTime();
          const distance = this.countdownDate - now;
          this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
          this.hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        }, 1000);
      },
      submitFormxiugai(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/edit_invoice_info', {
              invoice_id: '',
              type: 1, //发票类型  1.普票  2.专票
              company_tax_id: this.ruleForm.nashuiren || this.ruleFormlist.shuiwu, //税务登记号
              bank_name: this.ruleForm.kaihu || this.ruleFormlist.kaihu, //开户银行
              bank_phone: this.ruleForm.kaipiao || this.ruleFormlist.phone, //开票电话
              bank_number: this.ruleForm.yinhang || this.ruleFormlist.kaihunumber, //银行账号
              bank_address: this.ruleForm.adress, //开户地址
              company_name: this.ruleFormlist.gongsi, //公司全称
              company_address: this.ruleFormlist.address, //公司注册地址
              open_account: this.ruleFormlist.name, //开户名
              business_license: this.ruleFormlist.imageUrl, //营业执照
              taxpayer_prove: this.ruleFormlist.imageUrl1, //纳税人证明
              acount_permit: this.ruleFormlist.imageUrl2, //开户许可证
            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "发票申请",
                  message: "发票申请成功",
                  duration: 2500,
                  offset: 50
                });
              }
              this.dialogVisible = false
              this.dialogVisible1 = true
              this.dialogVisible2 = false
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitFormlistxiugai(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/edit_invoice_info', {
              invoice_id: '',
              type: 2, //发票类型  1.普票  2.专票
              company_tax_id: this.ruleForm.nashuiren || this.ruleFormlist.shuiwu, //税务登记号
              bank_name: this.ruleForm.kaihu || this.ruleFormlist.kaihu, //开户银行
              bank_phone: this.ruleForm.kaipiao || this.ruleFormlist.phone, //开票电话
              bank_number: this.ruleForm.yinhang || this.ruleFormlist.kaihunumber, //银行账号
              bank_address: this.ruleForm.adress, //开户地址
              company_name: this.ruleFormlist.gongsi, //公司全称
              company_address: this.ruleFormlist.address, //公司注册地址
              open_account: this.ruleFormlist.name, //开户名
              business_license: this.ruleFormlist.imageUrl, //营业执照
              taxpayer_prove: this.ruleFormlist.imageUrl1, //纳税人证明
              acount_permit: this.ruleFormlist.imageUrl2, //开户许可证

            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "发票申请",
                  message: "发票申请成功",
                  duration: 2500,
                  offset: 50
                });
              }
              this.dialogVisible = false
              this.dialogVisible1 = false
              this.dialogVisible2 = true
              this.countdown();
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async invoicein_fo() {
        const {
          data: res
        } = await this.$http.post('index/user/invoice_info')
        if (res.code == 1) {
          this.type = res.data.type //发票类型 
          // 1.普票
          if (this.type == 1) {
            this.ruleForm.nashuiren = res.data.company_tax_id
            console.log(this.ruleForm.nashuiren, '11111111111111')
            this.ruleForm.yinhang = res.data.bank_number
            this.ruleForm.kaihu = res.data.bank_name
            this.ruleForm.kaipiao = res.data.bank_phone
            this.ruleForm.adress = res.data.bank_address
            // 2.专票
          } else if (this.type == 2) {
            this.ruleFormlist.gongsi = res.data.company_name
            this.ruleFormlist.shuiwu = res.data.company_tax_id
            this.ruleFormlist.address = res.data.company_address
            this.ruleFormlist.phone = res.data.bank_phone
            this.ruleFormlist.kaihu = res.data.bank_name
            this.ruleFormlist.kaihunumber = res.data.bank_number
            this.ruleFormlist.name = res.data.open_account
            this.ruleFormlist.imageUrl = res.data.business_license
            this.ruleFormlist.imageUrl1 = res.data.taxpayer_prove
            this.ruleFormlist.imageUrl2 = res.data.acount_permit
          }
        }
      }

    }
  }
</script>

<style scoped lang="less">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  /deep/ i {
    font-style: inherit;
  }

  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .invoice {
      width: calc(100% - 20px);
      min-height: 785px;
      background: #FFFFFF;
      border-radius: 20px;

      .invoice_invoice {
        width: 96%;
        height: 650px;
        margin: 0 auto;
        padding-top: 65px;

        .application {
          width: 100%;

          .application_top {
            width: 100%;
            height: 30px;
            margin-top: 20px;
            margin-left: 37px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            display: flex;

            .shuxian {
              height: 13px;
              border: 3px solid #2469FF;
              margin-right: 10px;
              margin-top: 2px;
              display: inline-block;
            }
          }

          .application_con {
            width: 100%;
            height: 560px;

            .application_con_first {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 32px;

              .nashuiren {
                width: 50%;
                margin-right: 10px;
              }

              .yinhang {
                width: 50%;
              }
            }

            .application_con_two {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-bottom: 32px;

              .kaihu {
                width: 50%;
                margin-right: 10px;
              }

              .kaipiao {
                width: 50%;
              }
            }

            .application_con_three {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-bottom: 32px;

              .adress {
                width: 100%;
                margin-right: 10px;
              }

            }

            .application_con_four {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 10px;

              .shoupiao {
                width: 100%;
                margin-right: 10px;
              }

            }

            .application_con_five {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 34px;
              margin-top: 10px;

              .youpiao {
                width: 100%;
                margin-right: 10px;
              }

            }
          }
        }

        .management {
          width: 100%;

          .management_top {
            width: 100%;
            height: 30px;
            margin-top: 20px;
            margin-left: 37px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 30px;

            .shuxian {
              height: 18px;
              border: 3px solid #2469FF;
              margin-right: 10px;
            }

            .biaoshi {
              width: 18px;
              height: 18px;
              background: #0D68FC;
              border: 3px solid #2469FF;
              border-radius: 50%;
              color: #FFFFFF;
              margin-left: 3px;
              display: inline-block;
              position: relative;
              top: 5px;

              .biaoshi_biaoshi {
                color: #FFFFFF;
                display: inline-block;
                margin-left: 7px;
                line-height: 18px;
                position: absolute;
              }

            }

            .text {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #0D68FC;
              line-height: 3px;
            }
          }

          .management_con {
            width: 100%;
            height: 560px;

            .management_con_first {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 44px;

              .gongsi {
                width: 50%;
                margin-right: 10px;
              }

              .shuiwu {
                width: 50%;
              }
            }

            .management_con_two {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 44px;

              .address {
                width: 50%;
                margin-right: 10px;
              }

              .phone {
                width: 50%;
              }
            }

            .management_con_three {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 44px;

              .kaihu {
                width: 50%;
                margin-right: 10px;
              }

              .kaihunumber {
                width: 50%;
              }
            }

            .management_con_four {
              width: 100%;
              height: 40px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 38px;

              .name {
                width: 100%;
              }
            }

            .management_con_five {
              width: 100%;
              height: 200px;
              display: flex;
              flex-wrap: nowrap;
              margin-left: 33px;
              margin-top: 20px;
              margin-bottom: 32px;

              .businesslicense {
                width: 300px;
                height: 172px;
                margin-right: 28px;

                .namee {
                  margin-bottom: 10px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                }

                .avatar-uploader {
                  width: 300px;
                  height: 172px;
                  border: 1px dashed #d9d9d9;
                  border-radius: 6px;
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                }

                .avatar-uploader:hover {
                  border-color: #409EFF;
                }

                .avatar-uploader-icon {
                  font-size: 28px;
                  color: #8c939d;
                  width: 300px;
                  /* height: 172px; */
                  line-height: 41px;
                  text-align: center;
                  margin-top: 61px;
                  display: block;
                }

                .avatar {
                  width: 300px;
                  height: 172px;
                  display: block;
                }

              }

              .nashuishuoming {
                width: 300px;
                height: 172px;
                margin-right: 28px;

                .namee {
                  margin-bottom: 10px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                }

                .avatar-uploader {
                  width: 300px;
                  height: 172px;
                  border: 1px dashed #d9d9d9;
                  border-radius: 6px;
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                }

                .avatar-uploader:hover {
                  border-color: #409EFF;
                }

                .avatar-uploader-icon {
                  font-size: 28px;
                  color: #8c939d;
                  width: 300px;
                  /* height: 172px; */
                  line-height: 41px;
                  text-align: center;
                  margin-top: 61px;
                  display: block;
                }

                .avatar {
                  width: 300px;
                  height: 172px;
                  display: block;
                }
              }

              .xukezheng {
                width: 300px;
                height: 172px;

                .namee {
                  margin-bottom: 10px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                }

                .avatar-uploader {
                  width: 300px;
                  height: 172px;
                  border: 1px dashed #d9d9d9;
                  border-radius: 6px;
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                }

                .avatar-uploader:hover {
                  border-color: #409EFF;
                }

                .avatar-uploader-icon {
                  font-size: 28px;
                  color: #8c939d;
                  width: 300px;
                  /* height: 172px; */
                  line-height: 41px;
                  text-align: center;
                  margin-top: 61px;
                  display: block;
                }

                .avatar {
                  width: 300px;
                  height: 172px;
                  display: block;
                }
              }
            }

          }
        }
      }

    }
  }

  .management_con_five {
    width: 100%;
    height: 200px;
    display: flex;
    flex-wrap: nowrap;
    margin-left: 33px;
    margin-top: 20px;
    margin-bottom: 32px;

    .businesslicense {
      width: 300px;
      height: 172px;
      margin-right: 28px;

      .namee {
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .avatar-uploader {
        width: 300px;
        height: 172px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        /* height: 172px; */
        line-height: 41px;
        text-align: center;
        margin-top: 61px;
        display: block;
      }

      .avatar {
        width: 300px;
        height: 172px;
        display: block;
      }

    }

    .nashuishuoming {
      width: 300px;
      height: 172px;
      margin-right: 28px;

      .namee {
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .avatar-uploader {
        width: 300px;
        height: 172px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        /* height: 172px; */
        line-height: 41px;
        text-align: center;
        margin-top: 61px;
        display: block;
      }

      .avatar {
        width: 300px;
        height: 172px;
        display: block;
      }
    }

    .xukezheng {
      width: 300px;
      height: 172px;

      .namee {
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .avatar-uploader {
        width: 300px;
        height: 172px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        /* height: 172px; */
        line-height: 41px;
        text-align: center;
        margin-top: 61px;
        display: block;
      }

      .avatar {
        width: 300px;
        height: 172px;
        display: block;
      }
    }
  }

  /deep/.el-icon svg {
    width: 32px;
    height: 32px;
    font-size: 45px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0D68FC;
    line-height: 3px;
  }

  /deep/.el-input__wrapper {
    background: rgba(232, 238, 253, 0.3);
    border: 1px solid #B8CEFF;
    border-radius: 4px;
  }

  /deep/.el-input__wrapper {
    background-color: none !important;
  }

  :deep(.el-input__inner) {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 200;
    color: #666;
  }

  .shengqingzhong {
    width: 100%;
    height: 30px;
    position: relative;

    .fanhui {
      cursor: pointer;
      margin-right: 10px;
      position: absolute;
      top: 14px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .shengqing {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-right: 10px;
      position: absolute;
      top: 10px;
      left: 30px;
    }

    .time {
      position: absolute;
      top: 12px;
      left: 105px;
    }

    .imig {
      position: absolute;
      top: 3px;
      left: 291px;
    }
  }

  .fpje {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }

  .fplx {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }

  .sqrq {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 9px;
    }
  }

  .fptt {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }

  .gfsh {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }

  .fpnr {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }

  .fpcz {
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      margin-left: 36px;
    }
  }
</style>