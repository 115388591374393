<template>
  <div class="sq">
    <div class="sqbox">
      <p>授权记录</p>
      <div class="rir">授权区域名和IP地址后，部署运行的系统内才可使用在线更新和更新包下载功能

      </div>
      <div class="tab">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#EEF0F6',color:'#333333'}"
          :border="false" empty-text="暂无数据">
          <el-table-column prop="date" label="产品名称" width="250">
            <template #default="scope">
              <div v-if="scope.row.goods_info">
                <img :src="scope.row.goods_info.image" alt="" srcset="" style="width: 100px;">
                <div>{{scope.row.goods_info.title}}</div>
              </div>
              <div v-else-if="scope.row.miaosha=='miaosha'">
                <img :src="scope.row.cover_img" alt="" srcset="" style="width: 100px;">
                <div>{{scope.row.title}}</div>
              </div>
              <div v-else>
                <img :src="scope.row.image" alt="" srcset="" style="width: 100px;">
                <div>{{scope.row.title}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格(元)" width="200">
            <template #default="scope">
              <div v-if="scope.row.goods_info">
                <span>￥{{scope.row.goods_info.money}}</span>
              </div>
              <div v-else-if="scope.row.miaosha=='miaosha'">
                <div>{{scope.row.member_price}}</div>
              </div>
              <div v-else>
                <span>￥{{scope.row.money}}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="publish_time" label="发布时间" width="250">
            <template #default="scope">
              <div v-if="scope.row.goods_info">
                <span>{{formatDate(scope.row.goods_info.publish_time)}}</span>
              </div>
              <div v-else-if="scope.row.miaosha=='miaosha'">
                <div>{{formatDate(scope.row.create_time)}}</div>
              </div>
              <div v-else>
                <span>{{formatDate(scope.row.publish_time)}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="产品数量" width="200" align="center" />
          <el-table-column prop="price" label="小计" align="center">
            <template #default="scope">
              <span>￥{{scope.row.price}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="tableData!=''">
        <div class="yhj">
          <div class="flex align-center justify-between">
            <div class="yhchose" style="display: flex;" @click="change()" v-if="hiddlenN">
              <img src="../../assets/image/baikuang.png" />
              <span style="margin-left: 5px; font-size: 14px; color: #ccc;">使用优惠劵</span>
            </div>
            <div class="yhchose" v-if="coupon_id" style="display: flex;" @click="changeN()">
              <img src="../../assets/image/lankuang.png" />
              <span style="margin-left: 5px; font-size: 14px; color: #0D68FC;">使用优惠劵</span>
            </div>
            <div class="yhjprice">
              <div class="num">共<text>{{shoppnum}}</text>件</div>
              <div class="heji">金额合计：<text>￥{{shoppmoney.toFixed(2)}}</text></div>
              <div class="heji">优惠抵扣：<text>￥{{shoppcoumoney}}</text></div>
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;">
          <div class="paytype">支付方式：<text>在线支付</text></div>
          <div class="paybox">
            <div class="payxbox" v-for="(item,index) in openList" @click="selectWay(item.id)"
              :class="openWay==item.id?'active':''">
              <img :src="item.imgae" style="width: 20px;height: 20px;" />
              <div class="name">{{item.name}}</div>
              <div class="xz" v-if="openWay==item.id">
                <img src="../../assets/image/payxz.png" style="width: 22px;height: 22px;" />
              </div>
            </div>
          </div>
          <div style="width: 100%;height: 1px;background: #F1F5FF;margin:25px 0"></div>
          <div class="paytype">配送方式：<text>邮箱统一发送</text></div>
          <div class="mailbox">
            <div>请再次核对您的邮箱地址：</div>
            <div class="bjbxo">
              <el-input v-model="user_email"></el-input>
              <img src="../../assets/image/binaji.png" style="" />
            </div>
          </div>
          <div style="width: 100%;height: 1px;background: #F1F5FF;margin:25px 0"></div>
          <div class="yhchose" style="display: flex;" @click="changee()" v-if="hiddlen">
            <img src="../../assets/image/baikuang.png" />
            <span style="margin-left: 5px; font-size: 14px; color: #ccc;">是否开发票信息</span>
          </div>
          <div class="yhchose" v-if="invoice_id" style="display: flex;" @click="changeee()">
            <img src="../../assets/image/lankuang.png" />
            <span style="margin-left: 5px; font-size: 14px; color: #0D68FC;">是否开发票信息</span>
          </div>
          <div class="fqtitle"><span>普通个人发票</span> <span style="color: #0D68FC;" @click="gochat">［需开专票请联系客服]</span>
          </div>
          <div style="width: 100%;height: 1px;background: #F1F5FF;margin:25px 0"></div>
        </div>
        <div class="taber">
          <div style="text-align: right;">
            <div class="xil">
              <img src="../../assets/image/zan.png" />
              已有<text>{{buynum}}</text>人购买此授权
            </div>
            <div class="price">实际支付：<text class="pp">￥{{totalMoneyy.toFixed(2)}}</text>
              <text class="gz" @click="guize()">优惠规则<text>
                  <img src="../../assets/my/bangzhu.png" style="width: 15px; height: 15px;" />
                </text></text>
            </div>
            <div class="btn">
              <div class="qian" @click="shopp" v-if="tableData==''">继续购物</div>
              <div class="shen" @click="pay()">立即支付</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; position: absolute; top: 40px;left: 777px;">
        <img src="../../assets/dhpbj.png" alt="" />
        <div class="button" style="margin: 62px 0 0 0; cursor: pointer;">
          <button class="butt" @click="shopp">
            <span>去商城购买商品</span>
          </button>
        </div>
      </div>
    </div>
    <!-- 微信支付弹框 -->
    <el-dialog v-model="dialogVisible" width="35%" :before-close="handleClose">
      <div class="popup" @click="goPay">
        <img :src="tu" style="width:260px;" />
        <div class="tit">支付成功后会自动关闭窗口</div>
        <div class="tit1">如遇支付问题，请联系客服解决</div>
        <div class="btn" @click="dialogVisible = false">取消支付</div>
      </div>
    </el-dialog>
    <!-- 优惠卷弹窗 -->
    <el-dialog v-model="dialogTableVisible" title="优惠劵" center>
      <el-table :data="gridData" empty-text='暂无数据'>
        <el-table-column type="index" width="50" />
        <el-table-column property="coupon_name" label="优惠劵名称" />
        <el-table-column property="less_money" label="优惠劵金额" />
        <el-table-column property="failure_time" label="优惠劵到期时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button @click="coupon(scope.row)">选取</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--发票列表 -->
    <el-dialog v-model="fapiaoshowlist" title="发票列表" center>
      <div class="demo-collapse">
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :title="item.company_name" v-for="(item,index) in fapiaolist" :key="index" :name="index+''"
            style="position: relative;">
            <div>
              银行名称： {{item.bank_name}}
            </div>
            <div>
              银行账号： {{item.company_tax_id}}
              <span @click="changefp(item)" style="color: #0D68FC; cursor: pointer;">【点击可查看发票详情】</span>
            </div>
            <div style="top:38px;position: absolute;right: 76px;">

              <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
                @click="printInvoiceInfo(item)">
                <span
                  style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;line-height: 47px;">选择</span>
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div style="margin-left: 32px;margin-top: 50px;text-align: center;">
        <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;" @click="addfapiao()">
          <span
            style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;line-height: 47px;">添加发票信息</span>
        </el-button>
      </div>
    </el-dialog>
    <!-- 发票弹窗 -->
    <el-dialog v-model="fapiaoshow" title="添加发票" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <div class="application_con_first" style="display: flex; justify-content: space-evenly;">
          <div class="qiyename" style="width: 45%;margin-left: 6px;"><!-- *企业名称 -->
            <el-form-item label="企业名称:" prop="qiyename">
              <el-input v-model="ruleForm.qiyename" style="width: 306px;"></el-input>
            </el-form-item>
          </div>
          <div class="shuihao" style="width:43%;margin-left: 18px;"><!-- *税号 -->
            <el-form-item label="税号:" prop="shuihao">
              <el-input v-model="ruleForm.shuihao" style="width: 311px;margin-left: 6px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_two" style="display: flex; justify-content: space-evenly;">
          <div class="kaihu" style="width:43%"><!-- *开户银行账号 -->
            <el-form-item label="开户银行:" prop="kaihu">
              <el-input v-model="ruleForm.kaihu" style="width: 319px;    margin-left: 4px;"></el-input>
            </el-form-item>
          </div>
          <div class="yhzhanghao" style="width:43%"><!-- *银行账号 -->
            <el-form-item label="银行账号:" prop="yhzhanghao">
              <el-input v-model="ruleForm.yhzhanghao" style="width: 520px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_three" style="margin-left: 46px;">
          <div class="adress"><!-- **企业地址 -->
            <el-form-item label="企业地址:" prop="adress">
              <el-input v-model="ruleForm.adress" style="width: 95%;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_four" style="display: flex; justify-content: space-evenly;">
          <div class="name" style="width:30%"><!-- **收票人姓名 -->
            <el-form-item label="收票人姓名:" prop="name">
              <el-input v-model="ruleForm.name" style="width:286px; margin-left: -4px; "></el-input>
            </el-form-item>
          </div>
          <div class="phone" style="width:30%"><!-- **收票人电话 -->
            <el-form-item label="收票人电话:" prop="phone">
              <el-input v-model="ruleForm.phone" style="width: 286px; "></el-input>
            </el-form-item>
          </div>
          <div class="email" style="width:30%"><!-- **收票人邮箱 -->
            <el-form-item label="收票人邮箱:" prop="email">
              <el-input v-model="ruleForm.email" style="width:286px; "></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div style="margin-left: 32px;margin-top: 50px;text-align: center;">
        <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
          @click="submitForm('ruleForm')">
          <span style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">提交</span>
        </el-button>
      </div>
    </el-dialog>
    <!--发票修改 -->
    <el-dialog v-model="xiugaifapiaoshow" title="修改发票" center>
      <el-form :model="ruleFormN" :rules="rules" ref="ruleFormN">
        <div class="application_con_first" style="display: flex; justify-content: space-evenly;">
          <div class="qiyename" style="width: 45%;margin-left: 6px;"><!-- *企业名称 -->
            <el-form-item label="企业名称:" prop="qiyename">
              <el-input v-model="ruleFormN.qiyename" style="width: 306px;"></el-input>
            </el-form-item>
          </div>
          <div class="shuihao" style="width:43%;margin-left: 18px;"><!-- *税号 -->
            <el-form-item label="税号:" prop="shuihao">
              <el-input v-model="ruleFormN.shuihao" style="width: 311px;margin-left: 6px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_two" style="display: flex; justify-content: space-evenly;">
          <div class="kaihu" style="width:43%"><!-- *开户银行账号 -->
            <el-form-item label="开户银行:" prop="kaihu">
              <el-input v-model="ruleFormN.kaihu" style="width: 319px;    margin-left: 4px;"></el-input>
            </el-form-item>
          </div>
          <div class="yhzhanghao" style="width:43%"><!-- *银行账号 -->
            <el-form-item label="银行账号:" prop="yhzhanghao">
              <el-input v-model="ruleFormN.yhzhanghao" style="width: 520px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_three" style="margin-left: 46px;">
          <div class="adress"><!-- **企业地址 -->
            <el-form-item label="企业地址:" prop="adress">
              <el-input v-model="ruleFormN.adress" style="width: 95%;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_four" style="display: flex; justify-content: space-evenly;">
          <div class="name" style="width:30%"><!-- **收票人姓名 -->
            <el-form-item label="收票人姓名:" prop="name">
              <el-input v-model="ruleFormN.name" style="width:286px; margin-left: -4px; "></el-input>
            </el-form-item>
          </div>
          <div class="phone" style="width:30%"><!-- **收票人电话 -->
            <el-form-item label="收票人电话:" prop="phone">
              <el-input v-model="ruleFormN.phone" style="width: 286px; "></el-input>
            </el-form-item>
          </div>
          <div class="email" style="width:30%"><!-- **收票人邮箱 -->
            <el-form-item label="收票人邮箱:" prop="email">
              <el-input v-model="ruleFormN.email" style="width:286px; "></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div style="margin-left: 32px;margin-top: 50px;text-align: center;">
        <el-button style="width: 139px;height: 47px;background: #0D68FC;border-radius: 6px;"
          @click="submitFormxiugai('ruleFormN')">
          <span style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">修改提交</span>
        </el-button>
      </div>
    </el-dialog>
    <!-- 优惠规则弹框 -->
    <el-dialog v-model="dialogVisibleyh" title="优惠规则" width="80%" :before-close="handleClose" center>
      <div v-html="guizii"></div>
    </el-dialog>
    <el-dialog v-model="czshow" :shape="shape" title="购买产品" center @close="closeBtn()"
      style="width: 300px;height: 275px;position: relative;top: 10%;">
      <div v-if="qrCodeUrll" style="width: 100%;text-align: center;">
        <img :src="qrCodeUrll" alt="">
      </div>
      <div class="flex align-center justify-center" style="margin-top: 5px;">
        <img src="../../assets/login/saosao.png" style="width: 20px;height: 19px;" />
        <div style="font-size: 15px;margin-left: 8px;">打开 微信 扫一扫充值</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import html2canvas from "html2canvas";
  import {
    ElMessage
  } from 'element-plus'
  export default {
    name: 'Authorization',
    data() {
      return {
        czshow: false,
        qrCodeUrll: '',
        intervalId: '',
        hiddlen: true,
        hiddlenN: true,
        dialogVisibleyh: false,
        activeNames: '1',
        payy: false,
        xiugaifapiaoshow: false,
        fapiaoshow: false, //发票弹窗
        fapiaoshowlist: false, //发票列表
        fapiaolist: [],
        isCheck: 0,
        type: 0,
        radio: 0,
        ruleForm: {
          qiyename: '',
          shuihao: '',
          kaihu: '',
          yhzhanghao: '',
          adress: '',
          name: '',
          phone: '',
          email: '',
        },
        ruleFormN: {
          qiyename: '',
          shuihao: '',
          kaihu: '',
          yhzhanghao: '',
          adress: '',
          name: '',
          phone: '',
          email: '',
        },
        rules: {
          name: [{
            required: true,
            message: '请输入收票人姓名',
            trigger: 'blur'
          }, ],
          phone: [{
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          }],
          email: [{
            required: true,
            message: '请输入电子邮箱',
            trigger: 'blur'
          }, ],
          qiyename: [{
            required: true,
            message: '请输入企业名称',
            trigger: 'blur'
          }, ],
          shuihao: [{
            required: true,
            message: '请输入税号',
            trigger: 'blur'
          }]
        },
        goods_id: '',
        user_email: '',
        shoppnum: 0,
        shoppmoney: 0,
        shoppcoumoney: 0,
        coupon_money: 0,
        totalMoney: 0,
        tototmoney: '',
        totalMoneyy: 0,
        buynum: 0,
        gridData: [], //优惠卷
        dialogTableVisible: false,
        num: 1,
        checked: false,
        checkedd: false,
        coupon_id: '',
        youhuicoupon_id: '',
        tableData: [],
        weixin: '',
        alipay: '',
        pay_type: 'lingqian',
        openList: [{
          id: 1,
          type: 'lingqian',
          name: '余额支付',
          imgae: require("../../assets/image/yue.png"),
        }, {
          id: 2,
          type: 'wechat',
          name: '微信支付',
          imgae: require("../../assets/image/wx.png"),
        }, {
          id: 3,
          type: 'alipay',
          name: '支付宝支付',
          imgae: require("../../assets/image/zfb.png"),
        }],
        openWay: 1,
        zhifu: 'web',
        dialogVisible: false,
        tu: 'https://img0.baidu.com/it/u=582752653,3386538207&fm=253&fmt=auto&app=138&f=GIF?w=500&h=500',
        buy_type: '',
        is_invoice: '',
        payUrl: '',
        invoice_id: '',
        typenumshuzi: '',
        fapiaoshowlist: false,
        activeNames: false,
        fapiaolist: [],
        guizii: '',
        randomNumber: '',
      }
    },
    watch: {
      invoice_id(newVal) {
        console.log(newVal, 'newVal')
        if (newVal) {
          this.is_invoice = 1;
        } else {
          this.checkedd = false;
          this.is_invoice = 2;
        }
      }
    },
    methods: {
      guize() {
        this.dialogVisibleyh = !this.dialogVisibleyh
        this.yhguiz()
      },
      yhguiz() {
        this.$http.get('/index/common/sys_common?type_id=245').then(res => {
          console.log(res)
          this.guizii = res.data.data.value
        });
      },
      formatDate(row) {
        let date = new Date(row * 1000);
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },
      changefp(e) {
        console.log(e)
        this.invoice_id = e.id
        this.fapiaoshowlist = false
        this.xiugaifapiaoshow = true
        this.invoicein_fo()
      },
      gochat() {
        this.$router.push({
          path: '/chatindex',
        })
      },
      addfapiao() {
        this.fapiaoshow = true
      },
      printInvoiceInfo(e) {
        console.log(e)
        this.invoice_id = e.id
        this.$notify.success({
          title: "发票提示",
          message: "你已选择了一张发票",
          duration: 1500,
          offset: 50
        });
        if (this.invoice_id) {
          this.is_invoice = 1
          this.hiddlen = false
        } else {
          this.checkedd = false
          this.is_invoice = 2
        }
        this.fapiaoshowlist = false
      },
      change() {
        if (this.hiddlenN = true) {
          this.byconpone()
          this.dialogTableVisible = true
        }
      },
      changeN() {
        this.hiddlenN = !this.hiddlenN
        this.coupon_id = ''
      },
      changee() {
        if (this.hiddlen = true) {
          this.fapiaoshowlist = true
          this.getfapiaolist()
        }
      },
      changeee() {
        this.hiddlen = !this.hiddlen
        this.invoice_id = ''
      },
      selectWay(index, type) {
        this.openWay = index
      },
      //转换为表单格式
      getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
          const value = object[key];
          if (Array.isArray(value)) {
            value.forEach((subValue, i) => {
              formData.append(key + `[${i}]`, subValue)
            })
          } else {
            formData.append(key, object[key])
          }
        })
        return formData;
      },
      async pay() {
        if (this.user_email === '') {
          this.$notify.error({
            title: "注册提示",
            message: "请输入注册邮箱",
            duration: 1500,
            offset: 50
          });
        } else {
          if (this.user_email !== '') {
            var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!reg.test(this.user_email)) {
              this.$notify.error({
                title: "注册提示",
                message: "请输入正确的注册邮箱",
                duration: 1500,
                offset: 50
              });
            }
          }
        }
        if (this.is_invoice == '') {
          this.is_invoice = 2
        }
        if (this.openWay == 1) {
          let formData = {
            buy_type: this.typenumshuzi, //1.普通购买  2.秒杀
            goods_id: this.goods_id, //商品id
            user_email: this.user_email, //用户邮箱地址
            money: this.tototmoney, //支付金额
            goods_num: this.shoppnum, //购买数量
            pay_type: 'lingqian', //支付类型  alipay  wechat  lingqian
            method: 'web', //支付方式:电脑支付:web,公众号支付:mp,H5支付:wap,APP支付:app,扫码支付:scan,小程序支付:miniapp
            coupon_id: this.coupon_id, //优惠券id
            is_invoice: this.is_invoice, //是否开票  1.是  2.否
            invoice_id: this.invoice_id,
            remarks: '', //备注
            pay_ident: '', //随机数
          }
          const {
            data: res
          } = await this.$http.post('index/order/user_buy_goods', this.getFormData(formData))
          if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          } else {
            ElMessage({
              message: '支付成功',
              type: 'success',
            })
            this.$router.push({
              name: 'order',
            })
            localStorage.removeItem('purchasedProducts');
            localStorage.removeItem('buy_type');
          }
        } else if (this.openWay == 2) {
          this.randomNumber = Math.floor(Math.random() * 100) + 1;
          let formData = {
            goods_id: this.goods_id, //商品id
            user_email: this.user_email, //用户邮箱地址
            money: this.tototmoney, //支付金额
            pay_type: 'wechat', //支付类型  alipay  wechat  lingqian
            method: 'scan', //支付方式:电脑支付:web,公众号支付:mp,H5支付:wap,APP支付:app,扫码支付:scan,小程序支付:miniapp
            coupon_id: this.coupon_id, //优惠券id
            buy_type: this.typenumshuzi, //1.普通购买  2.秒杀
            goods_num: this.shoppnum, //购买数量
            is_invoice: this.is_invoice, //是否开票  1.是  2.否
            invoice_id: this.invoice_id,
            remarks: '', //备注
            pay_ident: this.randomNumber, //随机数
          }
          const {
            data: res
          } = await this.$http.post('index/order/user_buy_goods', this.getFormData(formData))
          if (res.code == 1) {
            this.payUrl = res.data.code_url
            if (this.payUrl) {
              this.geterimgg()
            }

          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
        } else if (this.openWay == 3) {

          let formData = {
            goods_id: this.goods_id, //商品id
            user_email: this.user_email, //用户邮箱地址
            money: this.tototmoney, //支付金额
            pay_type: 'alipay', //支付类型  alipay  wechat  lingqian
            method: 'web', //支付方式:电脑支付:web,公众号支付:mp,H5支付:wap,APP支付:app,扫码支付:scan,小程序支付:miniapp
            coupon_id: this.coupon_id, //优惠券id
            buy_type: this.typenumshuzi, //1.普通购买  2.秒杀
            goods_num: this.shoppnum, //购买数量
            is_invoice: this.is_invoice, //是否开票  1.是  2.否
            invoice_id: this.invoice_id,
            remarks: '', //备注
            pay_ident: '', //随机数
          }
          const {
            data: res
          } = await this.$http.post('index/order/user_buy_goods', this.getFormData(formData))
          if (res.code == 1) {
            this.payUrl = res.data
            let routerData = this.$router.resolve({
              path: '/payGateWay',
              query: {
                htmls: this.payUrl
              }
            });
            window.open(routerData.href, '_blank');
            localStorage.removeItem('purchasedProducts');
            localStorage.removeItem('buy_type');
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
        }
      },
      geterimgg() {
        const qrCodeData = this.payUrl;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrll = url;
            if (this.qrCodeUrll) {
              this.czshow = true
              this.payy = false
              this.intervalId = setInterval(this.repeatedApiCall, 2000);
            }
          }
        });
      },
      repeatedApiCall() {
        this.$http.post('/index/cashout/pay_details_foreach', {
            type: 3,
            pay_ident: this.randomNumber
          })
          .then((res) => {
            if (res.data.code == 1) {
              clearInterval(this.intervalId);
              this.$notify.success({
                title: "提示",
                message: '购买成功',
                duration: 2500,
                offset: 50
              });
              this.czshow = false
              localStorage.removeItem('purchasedProducts');
              localStorage.removeItem('buy_type');
              this.$router.push({
                name: 'order',
              })
            }
          });
      },
      goPay() {
        this.$router.push({
          path: '/payment',
          query: {
            name: '这是支付完成页面',
          }
        })
      },
      shopp() {
        this.$router.push({
          path: '/products',
          query: {
            name: '这是开源产品页面',
          }
        })
      },
      //优惠卷
      async byconpone() {
        const {
          data: res
        } = await this.$http.post('index/coupon/my_coupon', {
          num: 10,
          page: 1,
          status: 1
        })
        if (res.code == 1) {
          this.gridData = res.data.coupon_list.data
        }

      },
      //获取优惠卷详情
      coupon(item) {
        this.coupon_money = item.less_money
        this.dialogTableVisible = false
        this.coupon_id = item.coupon_user_id
        if (this.coupon_id) {
          this.hiddlenN = false
        }
        //优惠卷抵扣
        this.shoppcoumoney = this.coupon_money
        this.totalMoneyy = this.totalMoney - this.shoppcoumoney
        this.tototmoney = this.totalMoneyy
      },
      //发票列表
      async getfapiaolist() {
        const {
          data: res
        } = await this.$http.post('index/user/user_invoice_list')
        if (res.code == 1) {
          this.fapiaolist = res.data
        }
      },
      //添加发票
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/add_invoice_info', {
              company_name: this.ruleForm.qiyename, //公司名称
              company_tax_id: this.ruleForm.shuihao, //税务登记号
              bank_name: this.ruleForm.kaihu, //开户银行
              bank_number: this.ruleForm.yhzhanghao, //银行账号
              company_address: this.ruleForm.adress, //公司地址
              open_account: this.ruleForm.name, //收票人姓名
              bank_phone: this.ruleForm.phone, //收票人电话
              bank_email: this.ruleForm.email, //收票人邮箱
            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "添加发票信息成功",
                  message: "添加发票信息成功",
                  duration: 2500,
                  offset: 50
                });
                this.radio = 0
                this.fapiaoshow = false
                this.getfapiaolist()
              } else {
                this.$notify.error({
                  title: "添加发票信息",
                  message: res.msg,
                  duration: 2500,
                  offset: 50
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //修改发票
      submitFormxiugai(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('index/user/edit_invoice_info', {
              invoice_id: this.invoice_id,
              company_name: this.ruleFormN.qiyename, //公司名称
              company_tax_id: this.ruleFormN.shuihao, //税务登记号
              bank_name: this.ruleFormN.kaihu, //开户银行
              bank_number: this.ruleFormN.yhzhanghao, //银行账号
              company_address: this.ruleFormN.adress, //公司地址
              open_account: this.ruleFormN.name, //收票人姓名
              bank_phone: this.ruleFormN.phone, //收票人电话
              bank_email: this.ruleFormN.email, //收票人邮箱
            }).then((res) => {
              if (res.data.code == 1) {
                this.$notify.success({
                  title: "发票修改",
                  message: "发票修改成功",
                  duration: 2500,
                  offset: 50
                });
                this.radio = 0
                this.xiugaifapiaoshow = false
                this.getfapiaolist()
              } else {
                this.$notify.error({
                  title: "发票申请",
                  message: res.msg,
                  duration: 2500,
                  offset: 50
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //发票详情
      async invoicein_fo() {
        const {
          data: res
        } = await this.$http.post('index/user/invoice_info', {
          invoice_id: this.invoice_id
        })
        if (res.code == 1) {
          this.ruleFormN.qiyename = res.data.company_name
          this.ruleFormN.shuihao = res.data.company_tax_id
          this.ruleFormN.kaihu = res.data.bank_name
          this.ruleFormN.yhzhanghao = res.data.bank_number
          this.ruleFormN.adress = res.data.company_address
          this.ruleFormN.name = res.data.open_account
          this.ruleFormN.phone = res.data.bank_phone
          this.ruleFormN.email = res.data.bank_email
        }
      },
	  // 关闭微信支付弹框
	  closeBtn(){
	  		   clearInterval(this.intervalId);
	  }
    },
    mounted() {
      this.user_email = this.$cookie.get('email')
      this.typenumshuzi = localStorage.getItem('buy_type')
      console.log(this.typenumshuzi)
      // this.byconpone()
      if (this.$route.query.buy_type) {
        //秒杀专区
        var miaosha = JSON.parse(this.$route.query.buy_type)
        var putong = JSON.parse(this.$route.query.buy_type)
        if (miaosha == 2) {
          var srry = []
          srry = localStorage.getItem('purchasedProducts')
          this.tableData = JSON.parse(srry)
          console.log(this.tableData, '秒杀')
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].miaosha = 'miaosha'
            this.tableData[i].num = 1
            this.tableData[i].price = this.num * this.tableData[i].member_price
          }
          //商品个数
          this.shoppnum = this.tableData.length
          //商品总价
          for (var i in this.tableData) {
            if (this.tableData[i].price) {
              this.shoppmoney = this.shoppmoney + Number(this.tableData[i].price)
              this.totalMoney = this.shoppmoney
              this.totalMoneyy = this.totalMoney
              this.tototmoney = this.totalMoneyy
            }
            //销量
            if (this.tableData[i].number) {
              this.buynum = Number(this.tableData[i].number)
            }
            //商品id
            if (this.tableData[i].goods_id) {
              this.goods_id = this.tableData[i].goods_id
            }
          }
        } else if (putong == 1) {
          //普通专区
          var srry = []
          srry = localStorage.getItem('purchasedProducts')
          this.tableData = JSON.parse(srry)
          console.log(this.tableData, '普通')
          if (this.tableData) {
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].num = 1
              this.tableData[i].price = this.num * this.tableData[i].money
              if (this.tableData[i].goods_info) {
                this.tableData[i].price = this.num * this.tableData[i].goods_info.money
              }
            }
            //商品个数
            this.shoppnum = this.tableData.length
          }
          //商品总价
          for (var i in this.tableData) {
            if (this.tableData[i].price) {
              this.shoppmoney = this.shoppmoney + Number(this.tableData[i].price)
              this.totalMoney = this.shoppmoney
              this.totalMoneyy = this.totalMoney
              this.tototmoney = this.totalMoneyy
            }
            //销量
            if (this.tableData[i].sales) {
              this.buynum = this.buynum + Number(this.tableData[i].sales)
            }
            if (this.tableData[i].goods_info) {
              this.buynum = this.buynum + Number(this.tableData[i].goods_info.sales)
            }
            //商品id
            if (this.tableData[i].id) {
              this.goods_id = this.tableData[i].id
            }
            if (this.tableData[i].goods_info) {
              this.goods_id = this.tableData[i].goods_info.id
            }
          }
        }
      } else {
        if (this.tableData) {
          let typenum = localStorage.getItem('buy_type')
          console.log(typenum)
          if (typenum == 2) {
            var srry = []
            srry = localStorage.getItem('purchasedProducts')
            this.tableData = JSON.parse(srry)
            console.log(this.tableData)
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].miaosha = 'miaosha'
              this.tableData[i].num = 1
              this.tableData[i].price = this.num * this.tableData[i].member_price
            }
            //商品个数
            this.shoppnum = this.tableData.length
            //商品总价
            for (var i in this.tableData) {
              if (this.tableData[i].price) {
                this.shoppmoney = this.shoppmoney + Number(this.tableData[i].price)
                this.totalMoney = this.shoppmoney
                this.totalMoneyy = this.totalMoney
                this.tototmoney = this.totalMoneyy
              }
              //销量
              if (this.tableData[i].number) {
                this.buynum = Number(this.tableData[i].number)
              }
              //商品id
              if (this.tableData[i].goods_id) {
                this.goods_id = this.tableData[i].goods_id
              }
            }
          } else if (typenum == 1) {
            //普通专区
            var srry = []
            srry = localStorage.getItem('purchasedProducts')
            this.tableData = JSON.parse(srry)
            console.log(this.tableData, '普通')
            if (this.tableData) {
              for (let i = 0; i < this.tableData.length; i++) {
                this.tableData[i].num = 1
                this.tableData[i].price = this.num * this.tableData[i].money
                if (this.tableData[i].goods_info) {
                  this.tableData[i].price = this.num * this.tableData[i].goods_info.money
                }
              }
              //商品个数
              this.shoppnum = this.tableData.length
            }
            //商品总价
            for (var i in this.tableData) {
              if (this.tableData[i].price) {
                this.shoppmoney = this.shoppmoney + Number(this.tableData[i].price)
                this.totalMoney = this.shoppmoney
                this.totalMoneyy = this.totalMoney
                this.tototmoney = this.totalMoneyy
              }
              //销量
              if (this.tableData[i].sales) {
                this.buynum = this.buynum + Number(this.tableData[i].sales)
              }
              if (this.tableData[i].goods_info) {
                this.buynum = this.buynum + Number(this.tableData[i].goods_info.sales)
              }
              //商品id
              if (this.tableData[i].id) {
                this.goods_id = this.tableData[i].id
              }
              if (this.tableData[i].goods_info) {
                this.goods_id = this.tableData[i].goods_info.id
              }
            }
          }
        }


      }
    },
  }
</script>

<style lang="less" scoped>
  .sqbox {
    margin: 40px 18px 30px;

    .shop {
      width: 300px;
      height: auto;
      text-align: center;
      float: right;
      color: #0D68FC;
      margin-bottom: 20px;
      cursor: pointer;
    }

    p {
      font-weight: bold;
      margin: 0;
    }

    .rir {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #777777;
      margin-top: 4px;
    }

    .tab {
      margin-top: 30px;
    }
  }

  /deep/.el-table {
    --el-table-border-color: r !important;
  }

  /deep/ .el-table tbody tr:hover>td {
    background-color: #FFFFFF !important //修改成自己想要的颜色即可
  }

  /deep/.el-table__empty-block {
    min-height: 120px;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }

  /deep/.el-table .el-table__cell {
    padding: 12px 0;
  }

  // 优惠劵
  .yhj {
    background: #F0F5FF;
    border-radius: 8px;
    padding: 20px 40px;

  }

  .yhjprice {
    .num {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;

      text {
        color: #0D68FC;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .heji {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;

      text {
        color: #0D68FC;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0D68FC;
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0D68FC;
    border-color: #0D68FC;
  }

  .paytype {
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;

    text {
      color: #0D68FC;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .paybox {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .payxbox {
      width: 162px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #C2D9FE;
      border-radius: 4px;
      margin-right: 30px;
      position: relative;

      .name {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #818285;
        margin-left: 12px;
      }

      .xz {
        position: absolute;
        top: -6px;
        right: -2px;
      }
    }

    .active {
      border: 1px solid #0D68FC;
    }
  }

  .mailbox {
    display: flex;
    margin-top: 20px;
    align-items: center;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;

    .bjbxo {
      border-radius: 3px;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-left: -17px;
        z-index: 99;
      }
    }
  }

  .fapiao {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    margin-top: 15px;

    text {
      color: #0D68FC;
      font-size: 14px;
    }
  }

  .taber {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;

    .xil {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #818183;

      img {
        width: 10px;
        height: 10px;
      }

      text {
        color: #0D68FC;
        font-size: 12px;
      }
    }
  }


  .price {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #74767A;
    margin-top: 10px;

    .pp {
      color: #0D68FC;
      font-size: 18px;
      font-weight: bold;
    }

    .gz {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      margin-left: 8px;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .qian {
      width: 120px;
      height: 42px;
      background: #DCE7FF;
      border-radius: 6px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0D68FC;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .shen {
      width: 120px;
      height: 42px;
      background: #0D68FC;
      border-radius: 6px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }

  // 微信支付弹框
  .popup {
    text-align: center;

    .tit {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .tit1 {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      margin-top: 20px;
      letter-spacing: 2px;
    }

    .btn {
      width: 125px;
      margin: 20px auto 0;
      height: 48px;
      background: #0D68FC;
      border-radius: 8px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
    }
  }

  .fqtitle {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  /deep/.el-form-item__label {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  .button {
    width: 100%;
    height: auto;
    text-align: center;

    .butt {
      width: 200px;
      border: navajowhite;
      height: 44px;
      background: #0D68FC;
      border-radius: 5px;

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
</style>