<template>
  <div class="content" v-show="$route.meta.showfater">
    <!-- l轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item,index) in Bnners" :key="index">
          <div class="swiper-item">
            <img :src="item" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 切换tab -->
    <div class="centent">
      <div class="centent_con">
        <div class="con_text">
          源码下载
        </div>
        <div class="con_more">
          <div class="con_more_left" @click="bank">
            全部系列
          </div>
          <div class="con_more_con">
            <div class="yinchang">
              <div :class="flag ? 'externalDiv': ''">
                <div ref="insideDiv">
                  <section class="sectionon" v-for="(item,index) in  tab" :key="index" :class="{'bgtext':isbg==index}"
                    :style="item.isActive == true ? { backgroundColor: '#EAF4FF' } : { backgroundColor: '#F7F7F7' }"
                    @click="tabchang(index,item)">
                    <span>{{item.name}}</span>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="con_more_right">
            <span v-if="flag" @click="flag = !flag" id="more">
              更多
              <img src="../../assets/kaiyuanwendan/downjian.png" width="15" height="15">
            </span>
            <span v-if="!flag" @click="flag = !flag" id="more">
              收起
              <img src="../../assets/kaiyuanwendan/topjian.png" width="15" height="15">
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="concard flex justify-center align-center">
      <div class="concard_card">
        <div class="concard_con">
          <div class="concard_con_con" v-for="(item,index) in yuanmacon " :key="index">
            <div class="yumaimg " @click="concardcon(index,item)">
              <img :src="item.image">
            </div>
            <div class="yumacon" :class="{'bg':isconbg==index}">
              <div class="yumacon_txt">
                {{item.title}}
              </div>
              <div class="yumacomplate">
                {{item.company_name}}
              </div>
              <div class="yumatab">
                <div v-for="itemN in item.goods_lable.split(',')" style="margin-left: 5px;">
                  <el-tag>{{itemN}}</el-tag>
                </div>
              </div>
              <div class="yanmadown">
                <div class="yanmabutton">
                  <a href="#" @click="joinxq(item)">点击下载</a>
                </div>
                <div class="yanmadown_down">
                  {{item.down_num}}下载
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" title="开源文档查看" width="60%" :before-close="handleClose" center>
      <div class="title_dialog">
        {{titlename}}<span style="font-size: 25px;">开源文档说明</span>
      </div>
      <div class="tiyan_dialog">
        全平台体验
      </div>
      <div class="button_dialog">
        <div class="button_dialog1">
          <el-button plain>管理后台演示</el-button>
        </div>
        <div class="button_dialog2">
          <el-button plain>商家端小程序演示</el-button>
        </div>
        <div class="button_dialog3">
          <el-button plain>接单端</el-button>
        </div>
        <div class="button_dialog4">
          <el-button plain>接单端小程序演示</el-button>
        </div>
      </div>
      <div class="jishu_dialog">
        <div class="textjishu">技术栈</div>
        <div class='tetx_jisuh'>后台服务 springboot+mybatisplus+mysql</div>
        <div class='tetx_jisuh'>用户端 uniapp（vue语法）</div>
        <div class='tetx_jisuh'>管理后台 vue+elementUi</div>
      </div>
      <div class="jishu_dialog1">
        <div class="textjishu">服务说明</div>
        <div class='tetx_jisuh'>销售价格均提供技术文档，资料准备文档，部署文档，业务咨询免费！本店保证保障源码可用性！</div>
      </div>
      <div class="jishu_dialog2">
        <div class="textjishu">授权源码</div>
        <div class='tetx_jisuh'>uni-app用户端（适配微信公众号+微信小程序+android+ios APP）、uni-app接单端（适配微信公众号+微信小程序+android+ios
          APP）、vue管理平台、java后台服务。</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="button_img" @click="dialogFormVisible = false">
            <img src="../../assets/kaiyuanwendan/img9.png" alt="" @click="joinvip()">
          </div>
          <div class="button_text">
            支付即视为您已阅读并同意<span style="color: #0D68FC ; font-size: 18px;">授权许可协议</span>
          </div>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="yzmVisible" width="30%" :before-close="handleClose" title="验证码" center style="color: black;">
      <el-row :gutter="20">
        <el-col :span="14">
          <el-input v-model="captcha" placeholder="验证码" style="height: 52px;">
          </el-input>
        </el-col>
        <el-col :span="10" class="login-captcha">
          <img :src="yzmimg" @click="getCaptcha()" alt="">
        </el-col>
      </el-row>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="down()" style="background-color: #0D68FC; ">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 右侧固钉 -->
    <affix></affix>
  </div>
  <div class="otherpage">
    <router-view> </router-view>
  </div>
</template>

<script>
  import affix from '../../components/affix.vue'
  export default {
    components: {
      affix,

    },
    data() {
      return {
        yzmVisible: false,
        dialogFormVisible: false,
        color: '',
        yzmimg: '',
        goods_source_id: '',
        captcha: '',
        isbg: -1,
        isconbg: -1,
        iscon: 0,
        CommentData: [],
        isTextChanging: false,
        swiperIndex: 0,
        width: 0, // 记录一行数据有多高
        flag: true, // 展示样式
        tab: [],
        titlename: '',
        yuanmacon: [],
        cateid: 'all',
        typevip: '',
        uuid: '',
		Bnners:[],
      }
    },
    methods: {
      //获取分类
      async byfenlie() {
        const {
          data: res
        } = await this.$http.post('index/product/get_goods_cate_list')
        if (res.code == 1) {
          var tab = res.data
          for (let i = 0; i < this.tab.length; i++) {
            this.tab[i].isActive = false
          }
          this.tab = tab
        }
      },
      //获取内容
      async byyuanmacon() {
        const {
          data: res
        } = await this.$http.post('index/product/down_source_list', {
          cate_id: this.cateid,
          num: 10,
          page: 1
        })
        if (res.code == 1) {
          this.yuanmacon = res.data.data
          for (let i = 0; i < this.yuanmacon.length; i++) {
            this.yuanmacon[i].isActive = false
          }
        }
      },
      bank() {
        this.cateid = 'all'
        this.isbg = -1
        this.byyuanmacon()
        this.byfenlie()
      },
      tabchang(index, item) {
        this.cateid = item.id
        this.byyuanmacon()
        //改变字的颜色
        this.isbg = index
        //改变背景色
        for (let i in this.tab) {
          if (index == i) {
            this.tab[i].isActive = true
          } else {
            this.tab[i].isActive = false;
          }
        }
      },
      concardcon(index, item) {
        console.log(index, item)
        this.isconbg = index
        event.stopPropagation();
        var itemcon = JSON.stringify(item.id)
		this.$router.push({
		  name: 'ProductIntroduction',
		  query: {
		    item: itemcon
		  }
		})
        // this.$router.push({
        //   name: 'freevip',
        //   query: {
        //     name: itemcon,
        //   }
        // })
      },
      joinxq(item) {
		  let token = window.sessionStorage.getItem('token')
		  if (token == '' || token == null) {
		    
			this.$message({
			          message: '请登录后操作',
			          type: 'warning'
			        });
		  } else{
			  this.yzmVisible = true
			  this.goods_source_id = item.id
		  }
        
      },
      async down() {
        let token = window.sessionStorage.getItem('token')
        if (token == '' || token == null) {
          this.$notify.info({
            title: "提示",
            message: '请登录后操作',
            duration: 5500,
            offset: 50
          });
        } else {
          this.$notify.info({
            title: "下载提示",
            message: '正在下载请稍后',
            duration: 5500,
            offset: 50
          });
          const {
            data: res
          } = await this.$http.post('/index/product/user_down_source', {
            goods_source_id: this.goods_source_id,
            captcha: this.captcha
          });
          if (res.code == 1) {
            this.$notify.success({
              title: "下载提示",
              message: '下载成功,请在邮箱中查收',
              duration: 3500,
              offset: 50
            });
            this.captcha = ''
            this.yzmVisible = false
          } else {
            this.$notify.error({
              title: "下载提示",
              message: res.msg,
              duration: 3500,
              offset: 50
            });
          }
        }

      },
      joinvip() {
        this.$router.push({
          name: 'vipchongzhi',
          query: {
            name: '',
          }
        })
      },
      uuidd() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";
        this.uuidA = s.join("");
      },
      yanzhenma() {
        this.$http.get('index/user/get_captcha?uuid=' + this.uuidA, {
          responseType: 'arraybuffer' // 最为关键
        }).then((res) => {
          this.yzmimg = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res.data)
        })
      },
      getCaptcha() {
        this.uuidd();
        this.$http.get('index/user/get_captcha?uuid=' + this.uuidA, {
          responseType: 'arraybuffer' // 最为关键
        }).then((res) => {
          this.yzmimg = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res.data)
        })
      },
      arrayBufferToBase64(buffer) {
        var binary = ''
        var bytes = new Uint8Array(buffer)
        var len = bytes.byteLength
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
      },
	  //头部组件
	  async byhead() {
	    const {
	      data: res
	    } = await this.$http.get('index/index/index_header')
	    if (res.code == 1) {
	  			if(res.data.top_navigation){
	  				this.Bnners = res.data.top_navigation[4].img.split(",")
	  			}
	      
	    }
	  }
    },
    mounted() {
		this.byhead()
      this.uuidd();
      this.yanzhenma();
      this.typevip = this.$cookie.get('is_vip_type')
      this.byfenlie()
      this.byyuanmacon()
      this.$nextTick(() => {
        this.width = this.$refs.insideDiv.clientHeight
      })
      window.onresize = () => (() => {
        this.width = this.$refs.insideDiv.clientHeight // 放大或者缩小, 对应的更多、收起是否显示
      })()
    },
  }
</script>

<style lang="less" scoped>
  /deep/.el-button>span {
    font-size: 14px !important;
    color: #fff !important;
    align-items: center;
  }

  .login-captcha {
    overflow: hidden;

    >img {
      width: 100%;
      cursor: pointer;
    }
  }

  .bg {
    background-color: #FFFFFF;
  }

  .bgtext {
    width: 129px;
    height: 50px;
    background-color: #EAF4FF;
    border-radius: 6px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0D68FC;
  }

  .content {
    background: #EFF7FF;
  }

  .externalDiv {
    height: 59px;
    overflow: hidden;
  }

  .swiper {
    width: 100%;
    height: 700px;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  .centent {
    width: 100%;
    background: #FFFFFF;

    .centent_con {
      width: 95%;
      height: 100%;
      margin: 0 auto;

      .con_text {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        text-align: center;
        padding-top: 82px;
      }

      .con_more {
        width: 100%;
        display: flex;

        .con_more_left {
          float: left;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          line-height: 100px;
        }

        .con_more_con {
          width: 85%;

          .yinchang {
            width: 100%;
            position: relative;
            margin-top: 26px;
            margin-left: 5px;

            .sectionon {
              display: inline-block;
              width: 170px;
              height: 50px;
              background-color: #F7F7F7;
              border-radius: 6px;
              text-align: center;
              margin-left: 10px;
              line-height: 50px;
              padding-left: 10px;
              margin-bottom: 10px
            }
          }
        }

        .con_more_right {
          margin-top: 25px;
          float: right;
          width: 124px;
          height: 50px;
          background: #F7F7F7;
          border-radius: 6px;

          #more {
            line-height: 50px;
            position: relative;
            margin-left: 36px;
            img {
              position: absolute;
              left: 38px;
              top: 2px;
            }
          }
        }
      }


    }
  }

  .concard {
    width: 100%;
    // height: 1000px;
    background-color: #F6F9FF;

    .concard_card {
      width: 90%;
      height: 85%;

      .concard_con {
        width: 100%;
        height: 1000px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .concard_con_con {
          width: 20%;
          height: 50%;
          margin: 40px 0;
          position: relative;

          .yumaimg {
            img {
              width: 300px;
              height: 213px;
            }
          }

          .yumavip {
            position: absolute;
            top: 0px;
            right: 46px;
          }

          .yumacon {
            width: 300px;
            height: 130px;
            border-radius: 0px 0px 8px 8px;
            padding-top: 10px;
            background-color: #fff;

            .yumacon_txt {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              margin: 0px 15px 12px 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 278px;
            }

            .yumacomplate {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 299px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
            }

            .yumatab {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              margin: 0px 0px 11px 7px;

              .yumatab_tab {
                width: 100px;
                height: 24px;
                padding-left: 10px;
                margin-right: 10px;
                text-align: center;
                line-height: 24px;
                background: #EBF0FF;
                border-radius: 4px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #0D68FC;
              }
            }

            .yanmadown {
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              margin: 0px 0px 20px 10px;

              .yanmabutton {
                width: 119px;
                height: 39px;
                background: linear-gradient(90deg, #0D68FC 0%, #2597FF 100%);
                border-radius: 20px;
                text-align: center;

                a {
                  text-decoration: none;
                  line-height: 39px;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }

              .yanmadown_down {
                width: 57px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                margin: 14px 15px 10px 10px;
              }
            }
          }
        }
      }
    }
  }

  .title_dialog {
    width: 600px;
    height: 29px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 2px;
    margin-bottom: 20px;
  }

  .tiyan_dialog {

    height: 27px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 2px;
  }

  .button_dialog {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .jishu_dialog {
    width: 100%;
    height: 160px;
    margin-top: 60px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  .jishu_dialog1 {
    width: 100%;
    height: 90px;
    margin-top: 20px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  .jishu_dialog2 {
    width: 100%;
    height: 90px;
    margin-top: 20px;

    .textjishu {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .tetx_jisuh {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
    }
  }

  // /deep/.el-overlay-dialog {
  //   position: fixed;
  //   top: 302px;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   overflow: auto;
  // }

  /deep/.el-dialog__title {
    color: #0D68FC;
    font-size: 25px;
    font-weight: 800;
  }

  /deep/.el-button.is-plain {
    width: 230px;
    height: 73px;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
  }

  /deep/.el-button>span {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }

  /deep/.el-dialog__footer {
    padding-top: 40px;
  }
</style>