<template>
  <div class="content">
    <div class="head" v-if="isHead">
      <Head />
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="foot" v-if="isHead">
      <Footer />
    </div>
  </div>
</template>

<script>
  import Head from '@/components/head.vue'
  import Footer from '@/components/Footer.vue'
  import axios from 'axios'
  import jsonp from 'axios-jsonp'
  export default {
    components: {
      Head,
      Footer
    },
    name: 'App',
    data() {
      return {
        isHead: true,
        latitude: null,
        longitude: null
      }
    },
    mounted() {

    },
    watch: {
      '$route': { // $route可以用引号，也可以不用引号  监听的对象
        handler(to, from) {
          // console.log('路由变化了')
          // console.log('当前页面路由：' + JSON.stringify(to.name));
          // console.log('上一个路由：' + from);
          let url = to.path + ''
          // console.log(url && url.indexOf('login') !== -1||url.indexOf('payment') !== -1)
          // return
          if (url && (url.indexOf('login') !== -1 || url.indexOf('payment') !== -1)) {
            this.isHead = false
          } else {
            this.isHead = true
          }
        },
        deep: true, // 深度观察监听 设置为 true
        immediate: true, // 第一次初始化渲染就可以监听到
      },
    },
    mounted() {
      this.getGeolocation()
    },
    methods: {
      getGeolocation() {
        let data = {
          key: "ZBABZ-ZWECU-UQTVV-4LYDR-COK3F-5SF75", //申请的密钥
          output: "jsonp",
        };
        let url = "https://apis.map.qq.com/ws/location/v1/ip"
        axios.get(url, {
          params: data,
          adapter: jsonp
        }).then(res => {
          // this.$notify.success({
          //   title: "位置提示",
          //   message: "获取位置信息成功",
          //   duration: 1500,
          //   offset: 50
          // });
          this.longitude = res.data.result.location.lng
          this.latitude = res.data.result.location.lat
          this.$cookie.set('latitude', this.latitude);
          this.$cookie.set('longitude', this.longitude);
        }).catch(err => {
          this.$notify.error({
            title: "位置提示",
            message: "获取位置信息失败: " + error.message,
            duration: 1500,
            offset: 50
          });
        })
      },
    },

  }
</script>

<style>
  @font-face {
      font-family: 'yanshi';
      src: url('./assets/font/YouSheBiaoTiHei-2.ttf');
  }
  body {
    margin: 0;
    padding: 0;
    /* width: 100%; */

  }

  .content {
    /* width: 100vw; */
    /* background-color: red; */
  }

  .flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-left {
    justify-content: left;
  }

  /*当页面大于 1200px 时，大屏幕，主要是 PC 端*/
  @media (min-width: 1200px) {}

  /*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
  @media (min-width: 992px) and (max-width: 1199px) {}

  /*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
  @media (min-width: 768px) and (max-width: 991px) {}

  /*在 480 和 767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {}

  /*在小于 480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {}
</style>