<template>
	<div class="top" style="
    width: 100%;
    height: 96px;
    background-color: black;
  "></div>
	<div class="content">

		<!-- 左边 -->
		<div class="paidanleft">
			<div class="paidanleft_content">
				<!-- 面包屑 -->
				<div class="head">
					<el-breadcrumb :separator-icon="ArrowRight">
						<el-breadcrumb-item :to="{ path: '/products/paidan' }"
							style="font-size: 22px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">派单池</el-breadcrumb-item>
						<el-breadcrumb-item
							style="font-size: 22px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">订单需求</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<!-- 头部 -->
				<div class="head_head">
					<div class="head_head_title">
						{{concentt.title}}
					</div>
					<div class="label">
						<div class="lab_lab">
							<div>
								<el-tag style="width: 140px;height:46px;border-radius: 6px;margin-right:21px;"
									v-for="(item, index) in lab" :key="index" effect="dark">
									{{ item }}
								</el-tag>
							</div>
						</div>
						<div class="lab_addrss">
							<div class="address">
								<img src="../../assets/kaiyuanchanping/paidanchi/address1.png">
								<span class="address1">{{concentt.city}}</span><span
									class="address2">{{concentt.distance/1000}}KM</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 轮播图 -->
				<div class="lunboimg">
					<el-carousel height="583px">
						<el-carousel-item style="height: 583px;width: 1049px;" v-for="(item,index) in prpimg"
							:key="index">
							<img :src="item" style="height: 583px;width: 1049px;">
						</el-carousel-item>
					</el-carousel>

				</div>
				<!-- 项目需求 -->
				<div class="projectrequier">
					<div class="projectrequier_heard">
						<span>|</span> <a>项目需求</a>
					</div>
					<div class="projectrequier_con">
						<ul>
							<li v-html="projectrequier"> </li>
						</ul>
					</div>
				</div>
				<!--需求明细 -->
				<div class="requiredet">
					<div class="requiredet_heard">
						<span>|</span> <a>需求明细</a>
					</div>
					<img :src="details" style="width: 1103px; height:1512px; margin-top: 50px;" />
				</div>
			</div>
		</div>
		<!-- 右边 -->
		<div class="paidanright">
			<div class="paidanright_content">
				<!-- 上部分 -->
				<div class="paidanright_content_top">
					<div class='paidanright_content_top1' @click="dianjichange(1)" :class="{'bg-blue': isBlue}">
						<span :class="{'text-red': isRed}">¥{{concentt.money}}起</span>
					</div>
					<div class='paidanright_content_top2' @click="dianjichange(2)"
						:class="{ 'text-red': isRed1 ,'bg-blue': isBlue1}">
						<span><img src="../../assets/kaiyuanchanping/paidanchi/fenxiang.png" alt=""
								style="color: #333333;"></span>
						<span style="margin-left: 10px font-size: 22px;font-family: Source Han Sans CN;font-weight: 400; position: relative;
    top: -12px;
    left: 16px;">点击分享</span>
					</div>
				</div>
				<!-- 下部分 -->
				<div class="paidanright_content_bottom">
					<div class="paidanright_content_bottom_top">
						<span>
							<img src="../../assets/kaiyuanchanping/paidanchi/dunpai.png" alt="">
						</span>
						<span class="paidanright_content_bottom_top_tetx">
							诚信合作，爽约必究！
						</span>
					</div>
					<div class="paidanright_content_bottom_bottom">
						<div style="width: 100%;position: relative;">
							<div :class="flag ? 'externalDiv': ''">
								<div ref="insideDiv">
									<section v-for="(item,index) in people_config" :key="index"
										style="display: flex;flex-wrap: wrap;padding-right: 40px ;width: 100%;">
										<div class="wenziright">
											{{item.name}}
										</div>
										<div class="wenzileft">
											{{item.people_num}}名
										</div>
									</section>
								</div>
							</div>
							<div class="morelook">
								<span class="morelook_text" v-if="flag" @click="flag = !flag">查看更多<i></i></span>
								<span class="morelook_text" v-if="!flag" @click="flag = !flag">收起<i></i></span>
							</div>
						</div>
					</div>
					<div class="paidanright_content_bottom_button">
						<button class="button1" @click="lianxi(1)"><span>微信联系</span></button>
						<button class="button2" @click="lianxi(2)"><span>电话呼叫</span></button>
					</div>
				</div>
			</div>
		</div>
		<!-- 微信弹窗 -->
		<el-dialog title="分享链接" v-model="shooew" width="30%" :before-close="handleClose" center>
			<div style="text-align: center;">
				<div class="img margin" style="word-break: break-all">
					分享链接：<div style="font-size: 18px;" v-clipboard:success="fuzhi">{{corder}}</div>
					<el-button size="mini" type="primary" plain @click="fuzhi()">复制</el-button>
					<!-- <img :src="corder" style="width: 300px; height: 300px;" /> -->
				</div>
				<div class="margin" style="font-size: 12px;font-family: PingFang SC;color: #666666;margin-top: 20px;">
					复制链接分享给好友
				</div>
			</div>
		</el-dialog>
		<!-- 微信弹窗 -->
		<el-dialog title="微信弹窗" v-model="shooewx" width="30%" :before-close="handleClose" center>
			<div style="text-align: center;">
				<div class="img margin" style="word-break: break-all">
					<!-- 分享链接：<div style="font-size: 18px;" v-clipboard:success="fuzhi">{{corder}}</div>
					<el-button size="mini" type="primary" plain @click="fuzhi()">复制</el-button> -->
					<img :src="corder" style="width: 300px; height: 300px;" />
				</div>
				<!-- <div class="margin" style="font-size: 12px;font-family: PingFang SC;color: #666666;margin-top: 20px;">
					复制链接分享给好友
				</div> -->
			</div>
		</el-dialog>
	</div>
	<affix></affix>
</template>

<script>
	import affix from '../../components/affix.vue'
	import clipboardy from 'clipboardy';
	export default {
		components: {
			affix
		},
		data() {
			return {
				concent: '', //接受传的参数
				concentt: [],
				//项目需求
				projectrequier: [],
				//需求明细
				backgroundimg: require('../../assets/kaiyuanchanping/paidanchi/juxinglan.png'),
				isRed: true,
				isBlue: true,
				isRed1: false,
				isBlue1: false,
				cun: 4,
				ckgddiv: false,
				width: 4, // 记录一行数据有多高
				flag: true, // 展示样式
				productinfo: [], //商品详情
				lab: [],
				prpimg: [],
				details: '',
				people_config: [],
				shooew: false,
				shooewx:false,
				corder: '',
				mingxi: require('../../assets/jiedan/img5.png'),
				longitude: '',
				latitude: '',
			}
		},

		methods: {

			async lianxi(item) {
				var typeF = item
				if(item==3){
					var type = 1
				}else{
					var type = item
				}
				
				const {
					data: res
				} = await this.$http.post('index/empower/look_contact_info', {
					'receive_tank_id': this.concent,
					'type': type
				})
				if (res.code == 1) {
					if (typeF == 2) {
						this.$alert(res.data.mobile, '电话联系', {
							confirmButtonText: '确定',
							callback: action => {}
						});
					} else if (typeF == 1) {
						this.shooewx = true
						this.corder = res.data.wechat_qrcode
					} else if(typeF == 3){
						this.shooew = true
						this.corder = 'https://ymshop.xianmaxiong.com/products/dispatchdetails?item='+ res.data.id
					}
				}
			},
			async byproduct() {

			},
			//接受传的参数
			async getParams() {
				this.concent = JSON.parse(this.$route.query.item)
				console.log(this.concent)
				const {
					data: res
				} = await this.$http.post('index/empower/receive_tank_info', {
					tank_id: this.concent,
					longitude: this.$cookie.get('longitude'),
					latitude: this.$cookie.get('latitude'),
				})
				if (res.code == 1) {
					this.concentt = res.data
					if (this.concentt.lable) {
						this.lab = this.concentt.lable.split(",")
					}
					if (this.concentt.content) {
						this.projectrequier = this.concentt.content
					}
					if (this.concentt.image) {
						let prpimgg = this.concentt.image
						this.prpimg.push(prpimgg)
					}
					//项目需求
					if (this.concentt.details) {
						this.details = this.concentt.details
					}
					//人员配置
					if (this.concentt.people_config) {
						this.people_config = this.concentt.people_config
					}
				}
			},
			//点击切换
			dianjichange(index) {
				if (index == 1) {
					this.isBlue = true;
					this.isRed = true;
					this.isBlue1 = false;
					this.isRed1 = false;
				} else if (index == 2) {
					this.isBlue = false;
					this.isRed = false;
					this.isBlue1 = true;
					this.isRed1 = true;
					this.lianxi(3)
				}
			},
			// 复制分享链接
			fuzhi() {
				const text = this.corder
				const textarea = document.createElement('textarea')
				textarea.value = text;
				document.body.appendChild(textarea)
				textarea.select();
				try {
					const success = document.execCommand('copy');
					if (success) {
						this.$notify({
							title: "提示",
							message: "复制成功",
							duration: 1500,
							type: 'success',
						});
					} else {
						this.$notify({
							title: "提示",
							message: "复制失败",
							duration: 1500,
							type: 'warning',
						});
					}
				} catch (e) {
					this.$notify({
						title: "提示",
						message: "复制失败",
						duration: 1500,
						type: 'warning',
					});
				}

				document.body.removeChild(textarea)

				return
				navigator.clipboard.writeText(text)
					.then(() => {
						this.$notify({
							title: "提示",
							message: "复制成功",
							duration: 1500,
							type: 'success',
						});
					})
					.catch(() => {
						this.$notify({
							title: "提示",
							message: "复制失败",
							duration: 1500,
							type: 'warning',
						});


					})
			},
		},
		created() {
			this.byproduct()
			this.getParams();
			this.$nextTick(() => {
				this.width = this.$refs.insideDiv.clientHeight
			})
			window.onresize = () => (() => {
				this.width = this.$refs.insideDiv.clientHeight // 放大或者缩小, 对应的更多、收起是否显示
			})
		},


	}
</script>

<style scoped lang="less">
	/deep/.el-tag__content {
		font-size: 22px;
		font-family: Source Han Sans CN;
		font-weight: 400;
	}

	.text-red {
		color: #FFFFFF;
	}

	.bg-blue {
		background-image: url('../../assets/kaiyuanchanping/paidanchi/juxinglan.png');
	}


	.externalDiv {
		height: 145px;
		overflow: hidden;
	}

	.content {
		background: #EFF7FF;
		width: 100%;
		height: 3000px;
		display: flex;



		.paidanleft {
			width: 65%;
			height: 100%;

			.paidanleft_content {
				width: 90%;
				height: 100%;
				margin: 0 auto;
				margin-top: 50px;

				.head_head {
					width: 100%;
					margin-top: 40px;
				}

				.head_head_title {
					font-size: 32px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #333333;
				}

				.label {
					width: 100%;
					margin-top: 15px;
					display: flex;

					.lab_lab {
						width: 70%;
						display: flex;


						.label1 {
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #FC701C;
							line-height: 46px;

						}

						.label2 {
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #0D68FC;
							line-height: 46px;
						}
					}

					.lab_addrss {
						width: 30%;
						display: flex;

						.address {
							img {
								width: 20px;
								height: 22px;
								margin-right: 10px;
							}

							.address1 {
								margin-right: 10px;
								font-size: 24px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #666666;
								line-height: 29px;
							}

							.address2 {
								margin-right: 10px;
								font-size: 24px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #666666;
								line-height: 29px;
							}
						}
					}
				}

				.lunboimg {
					width: 1062px;
					height: 587px;
					margin-top: 20px;
				}

				.projectrequier {
					width: 1027px;
					height: auto;
					margin-top: 100px;
					position: relative;

					.projectrequier_heard {
						span {
							width: 6px;
							height: 25px;
							background: #0D68FC;
							border-radius: 2px;
						}

						a {
							font-size: 24px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #333333;
							line-height: 2px;
						}
					}

					.projectrequier_con {
						ul {
							padding: 0;
							list-style-type: none
						}


						span {
							position: absolute;
							left: 7px;
							font-size: 24px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #666666;
						}

						li {
							font-size: 24px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #666666;
						}
					}
				}

				.requiredet {
					width: 1027px;
					height: 200px;
					margin-top: 100px;

					// position: relative;
					.requiredet_heard {
						span {
							width: 6px;
							height: 25px;
							background: #0D68FC;
							border-radius: 2px;
						}

						a {
							font-size: 24px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #333333;
							line-height: 2px;
						}
					}

					.requiredet_lable {

						.table1 {
							background-image: url('../../assets/kaiyuanchanping/paidanchi/tableimg.png');
							width: 1098px;
							height: 65px;
							margin-top: 21px;

							span {
								font-size: 22px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: black;
								line-height: 63px;
							}
						}

						.mytable tr td {
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #666666;

						}
					}

				}

			}
		}

		.paidanright {
			flex: 1;
			position: relative;

			.paidanright_content {
				width: 90%;
				position: sticky;
				top: 15px;
				/* 这里的 top 值可以根据你的需求进行调整 */
				margin: 0 auto;
				margin-top: 126px;
				display: flex;
				flex-wrap: wrap;
				background-color: #FFFFFF;

				.paidanright_content_top {
					width: 100%;
					height: 141px;
					display: flex;

					.paidanright_content_top1 {
						width: 50%;
						text-align: center;

						span {
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							// color: #0D68FC;
							line-height: 141px;
						}
					}

					.paidanright_content_top2 {
						width: 50%;
						text-align: center;

						span {
							line-height: 141px;
						}
					}
				}

				.paidanright_content_bottom {
					width: 100%;

					.paidanright_content_bottom_top {
						width: 100%;
						height: 71px;
						background-image: url('../../assets/kaiyuanchanping/paidanchi/juxing838.png');
						background-repeat: no-repeat;
						background-size: 100% 100%;
						position: relative;

						span>img {
							position: absolute;
							top: 17px;
							left: 40px;
							line-height: 71px;
						}

						.paidanright_content_bottom_top_tetx {
							font-size: 22px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #666666;
							line-height: 65px;
							margin-left: 82px;
						}
					}

					.paidanright_content_bottom_bottom {
						width: 100%;

						.wenziright {
							float: left;
							text-align: start;
							width: 50%;
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #999999;
							margin-top: 30px;
							margin-left: 53px;
						}

						.wenzileft {
							float: right;
							margin-top: 30px;
							text-align: right;
							width: 30%;
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #999999;
							margin-left: 3px;
						}
					}

					.morelook {
						.morelook_text {
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #0D68FC;
							margin-left: 53px;
							margin-top: 20px;
						}
					}

					.paidanright_content_bottom_button {
						width: 100%;
						height: 120px;
						display: flex;
						justify-content: space-around;

						.button1 {
							width: 220px;
							height: 60px;
							background: #0FCE69;
							border-radius: 35px;
							margin-top: 23px;
							border: none;

							span {
								font-size: 20px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 29px;
							}
						}

						.button2 {
							width: 220px;
							height: 60px;
							margin-top: 25px;
							background: #0D68FC;
							border-radius: 35px;
							border: none;

							span {
								font-size: 20px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 29px;
							}
						}
					}
				}
			}
		}
	}
</style>