import {
  createApp
} from 'vue'
import App from './App.vue'
//引入animate.css动画库
import 'animate.css';
//引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 在main.js中引入swiper样式，让所有的组件都能用swiper的样式
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'

//引入router.js
import router from './router.js'


//引入axios
import axios from 'axios'

import VueClipboard from 'vue-clipboard2';



const app = createApp(App)
app.config.globalProperties.$http = axios
axios.defaults.baseURL = 'https://ymshopapi.xianmaxiong.com/api/'
// axios.defaults.baseURL = 'http://192.168.0.120:8018/api/'

axios.interceptors.request.use(config => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})

// 全局配置cookie，组件调用方式：this.$cookies
import cookies from 'vue-cookies'
app.config.globalProperties.$cookie = cookies;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(router)
app.use(ElementPlus)
app.use(VueClipboard )

app.mount('#app')