<template>
  <div class="swiper-item-gg flex align-center">
    <img src="@/assets/index/gg.png" alt="">
    <div class="scroll-container">
      <div class="container">
        <div class="inner" v-for="(item,index) in texts">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        texts: ['购买请联系客服，领取优惠卷！客服电话：13895585204'],
      }
    },
  }
</script>

<style scoped lang="less">
  .swiper-item-gg {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85%;
    height: 70px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    padding: 0 50px;
  

    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    .scroll-container {
      width: 100%;
      line-height: 70px;
      display: flex;
      flex-wrap: nowrap;

      .container {
        width: 1046px;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
      }

      .inner {
        width: 54%;
        color: #0D68FC;
        font-size: 22px;
        animation: move 20s linear infinite;
      }

      @keyframes move {
        0% {
          transform: translatex(0px);
        }

        100% {
          transform: translatex(-100%);
        }
      }
    }
  }
</style>