<template>
  <div class="content">
    <div class="content_head">
      <swiper class="" :autoplay="true" interval="5000" duration="500" :circular="true"
        style="width: 100%;height: 710px;">
        <swiper-item>
          <img style="width: 100%;height: 100%;" src="@/assets/image/vip/banner.png" />
        </swiper-item>
      </swiper>
      <div class="tirle">
        <img src="../../assets/hy.png" alt="">
        <p style='font-size:50px;margin-top: 12px;'>欢迎来到省钱兄商城会员中心</p>
      </div>
    </div>
    <div class="firstcont">
      <div class="baben">
        <div class="babenbox">
          <el-row class="row-bg">
            <el-col :span="one" v-for="(item,index) in vip" :key='index'>
              <div class="row-bg1">
                <div class="line" :style="{background:colorList[index%3]}"></div>
                <div>
					<img :src="item.name_img" style="text-align: center;height: auto;width: 112px;"/>
				</div>
                <div class="tits">{{item.lable}}</div>
                <div class="rowcont"><span :style="{color:colorList[index%3]}">{{item.sum_discount}}</span></div>
                <ul v-for="(ite,ind) in item.pc_lable" :key='ind'>
                  <li style="width:161px">{{ite}}</li>
                </ul>
                <div v-if="is_vip==1">
                  <div class="btn" @click="chongzhi(item,index)">立即充值
                    <img src="../../assets/image/vip/right.png"
                      style="width:10px;height: 11px;margin-left: 6px;margin-top: 2px;" />
                  </div>
                </div>
                <div v-else>
                  <div class="btn" @click="chongzhi(item,index)">续费会员
                    <img src="../../assets/image/vip/right.png"
                      style="width:10px;height: 11px;margin-left: 6px;margin-top: 2px;" />
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="forlist">
      <el-row class="justify-center">
        <el-col :span="vipone">
          <div style="margin-top: -27px;line-height: 42px;">
            <div class="bigtlt">功能对比</div>
            <div v-for="(item,index) in listone">
              <div style="font-size: 20px;font-family: Source Han Sans CN;color: #666666;">{{item.name}}</div>
            </div>
          </div>
        </el-col>
        <!-- vip -->
        <el-col :span="viptwo" class="blurbox">
          <div class="blue">
            <div class="line"></div>
          </div>
          <div class="listhang" v-for="(item,index) in listone">
            <div class="bigtltsa">{{item.pc_compare}}</div>
          </div>
        </el-col>
        <!-- svip -->
        <el-col :span="viptwo" class="yellowbox">
          <div class="huang">
            <div class="line"></div>
          </div>
          <div class="listhang" v-for="(item,index) in listtwo">
            <div class="bigtltsa">{{item.pc_compare}}</div>

          </div>
        </el-col>
        <!-- 超级 -->
        <el-col :span="viptwo" class="originbox">
          <div class="origin">
            <div class="line"></div>
          </div>
          <div class="listhang" v-for="(item,index) in listthree">
            <div class="bigtltsa">{{item.pc_compare}}</div>

          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog v-model="czshow" :shape="shape" style=" height:840px; width: 1612px;">
      <div class="distributionN">
        <div class="distributiontop">
          <div class="title">
            <div class="title1">充值会员</div>
            <div class="title2">当前版本:
              <span v-if="is_vip==0">普通用户</span>
              <span v-if="is_vip==1">免费体验</span>
              <span v-if="is_vip==2">vip用户</span>
              <span v-if="is_vip==3">svip用户</span>
              <span v-if="is_vip==4">超级svip用户</span>
            </div>
          </div>
          <div class="close" @click="czshow=false" style="cursor: pointer;">
            <img src="../../assets/chacha.png" alt="" style="width: 31px; height: 31px;" />
          </div>
        </div>
        <div class="distributionbot">
          <div class="left">
            <div class="left_top">
              <div style="font-size: 20px;font-family: Source Han Sans CN;font-weight: 800;color: #333333;">
                {{vipzs.name}}
              </div>
              <div style="font-size: 17px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                {{vipzs.lable}}
              </div>
            </div>
            <div class="left_bottom">
              <div style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                <ul v-for="(ite,ind) in vipzs.pc_lable" :key='ind'>
                  <li>{{ite}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="rightone">
              <span>选择版本</span>
              <div class="banbenn">
                <div class="banben" v-for="(item,index) in viplistimg" :key="index" :class="curr==index?'active':''"
                  @click="selectWay(item,index)">
                  <div class="con_right">
                    <div class="con_right_text" v-if="curr!=index">
                      <img :src="item.img" alt="">
                    </div>
                    <div class="con_right_text" v-if="curr==index">
                      <img :src="item.img2" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="righttwo">
              <span>选择购买时长</span>
              <div v-if="viplist==''" class="righttwoqq">
                请选择要充值的会员版本
              </div>
              <div class="time" v-else>
                <div class="timemoney" v-for="(item,index) in viplist" :key="index" :class="currr==index?'active':''"
                  @click="selectWayy(item,index)">
                  <div class="con_right">
                    <div class="con_right_text" :style="{background:colorList[index%3]}">
                      {{(item.discount*10).toFixed(1)}}折
                    </div>
                    <div class="con_right_time">
                      <span v-if="item.type==1">月会员</span>
                      <span v-if="item.type==2">季会员</span>
                      <span v-if="item.type==3">年会员</span>
                    </div>
                    <div class="con_right_top">
                      <span v-if="item.type==1">￥{{item.money}}/月</span>
                      <span v-if="item.type==2">￥{{item.money}}/季</span>
                      <span v-if="item.type==3">￥{{item.money}}/年</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="righthree">
              <div class="recharge_bottom">
                <div>
                  <div class="paytype">支付方式</div>
                  <div class="paybox">
                    <div class="payxbox" v-for="(item,index) in openList" @click="selectWaylist(item.id)"
                      :class="openWay==item.id?'active':''">
                      <img :src="item.imgae" style="width: 40px;height: 40px;" />
                      <div class="name">{{item.name}}</div>
                      <div class="xz" v-if="openWay==item.id">
                        <img src="../../assets/image/payxz.png" style="width: 22px;height: 22px;" />
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%;height: 1px;background: #F1F5FF;margin:25px 0"></div>
                </div>

                <div class="taber">
                  <div style="text-align: right;">
                    <div class="price">实际支付：<text class="pp">￥{{toprice}}</text>
                    </div>
                    <div class="btnnn">
                      <div class="shen" @click="pay()">立即支付</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 支付宝扫码页面 -->
          <div ref="alipayWap" v-html="alipay" />
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="WXczshow" :shape="shape" title="购买会员" center @close="closeBtn()"
      style="width: 300px; height: 244px;position: relative; top:27%;line-height;padding:20px;">
      <div v-if="qrCodeUrll" style="width: 100%;text-align: center;">
        <img :src="qrCodeUrll" alt="">
      </div>
      <div class="flex align-center justify-center" style="margin-top: 5px;">
        <img src="../../assets/login/saosao.png" style="width: 20px;height: 19px;" />
        <div style="font-size: 15px;margin-left: 8px;">打开 微信 扫一扫充值</div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import html2canvas from "html2canvas";
  import myData from '../../components/Userdata.vue'
  import kj from '../../components/keji.vue'
  export default {
    components: {
      myData,
      kj
    },
    name: "vip",
    data() {
      return {
        randomNumber: '',
        WXczshow: false,
        checked: false,
        toprice: 0,
        colorList: ['#4189FF', '#FBCB49', '#FF670B'],
        viplistimg: [{
            img: require('../../assets/vip/vip.png'),
            img2: require('../../assets/vip/vip2.png'),
            text: 'VIP'
          },
          {
            img: require('../../assets/vip/svip.png'),
            img2: require('../../assets/vip/svip2.png'),
            text: 'SVIP'
          },
          {
            img: require('../../assets/vip/ssvip.png'),
            img2: require('../../assets/vip/ssvip2.png'),
            text: '超级SVIP'
          },
        ],
        curr: 0,
        currr: -1,
        czshow: false,
        shape: 'rounded',
        is_vip: '',
        one_vip: [],
        two_vip: [],
        three_vip: [],
        four_vip: [],
        one: 8,
        two: 8,
        three: 8,
        vipone: 5,
        viptwo: 3,
        vipthree: 4,
        listone: [],
        listtwo: [],
        listthree: [],
        viplist: [],
        vipzs: [],
        vip: [],
        vpname: 'VIP版',
        pay_type: 'lingqian',
        openList: [{
          id: 1,
          type: 'wechat',
          name: '微信支付',
          imgae: require("../../assets/image/wx.png"),
        }, {
          id: 2,
          type: 'alipay',
          name: '支付宝支付',
          imgae: require("../../assets/image/zfb.png"),
        }],
        openWay: 0,
        totalMoney: 0,
        vip_id: '',
        vip_type_id: '',
        qrCodeUrll: '',
        intervalId: '',
      }
    },
    mounted() {
      this.is_vip = window.sessionStorage.getItem('is_vip_type')
      this.byvip()
      if (window.innerWidth > Number(1200)) {
        this.one = 8
        this.two = 8
        this.three = 8

      } else if (window.innerWidth >= Number(992) && window.innerWidth <= Number(1200)) {
        this.one = 8
        this.two = 8
        this.three = 8

      } else if (window.innerWidth >= Number(768) && window.innerWidth < Number(992)) {
        this.one = 12
        this.two = 12
        this.three = 12
        this.vipone = 5
        this.viptwo = 4
        this.vipthree = 5
        this.vipfour = 5
        this.vipfive = 5
      } else if (window.innerWidth >= Number(480) || window.innerWidth < Number(768)) {
        this.one = 24
        this.two = 24
        this.three = 24
        this.vipone = 12
        this.viptwo = 12
        this.vipthree = 12
        this.vipfour = 12
        this.vipfive = 12
      } else if (window.innerWidth < Number(480)) {
        this.one = 12
        this.two = 12
        this.three = 12
        this.four = 12
      }
    },
    methods: {
      byvip() {
        this.$http.post('/index/user/vip_config_list').then((res) => {
          if (res.data.code == 1) {
            this.vip = res.data.data
            this.list = res.data.data.vip_compare_list
            //功能对比
            this.listone = res.data.data[0].vip_compare_list
            this.listtwo = res.data.data[1].vip_compare_list
            this.listthree = res.data.data[2].vip_compare_list
          }
        })
      },
      //选择版本
      selectWay(item, index) {
        this.curr = index
        this.vipzs = item
        let viptext = item.text
        if (viptext === 'VIP') {
          this.viplist = this.vip[0].vip_list
          this.vipzs = this.vip[0]
        } else if (viptext === 'SVIP') {
          this.viplist = this.vip[1].vip_list
          this.vipzs = this.vip[1]
        } else if (viptext === '超级SVIP') {
          this.viplist = this.vip[2].vip_list
          this.vipzs = this.vip[2]
        }
      },
      //购买时长
      selectWayy(item, index) {
        this.currr = index
        this.totalMoney = item.money
        this.zekou = item.discount
        this.vip_id = item.id
        this.toprice = this.totalMoney
      },
      mianfei() {
        this.$router.push({
          path: '/',
        })
      },
      //立即充值
      chongzhi(iten, index) {
        this.curr = index
        this.czshow = true
        this.viplist = iten.vip_list
        this.vipzs = iten
        this.vip_type_id = this.vipzs.id
      },
      // 支付方式
      selectWaylist(index, type) {
        this.openWay = index
      },
      //转换为表单格式
      getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
          const value = object[key];
          if (Array.isArray(value)) {
            value.forEach((subValue, i) => {
              formData.append(key + `[${i}]`, subValue)
            })
          } else {
            formData.append(key, object[key])
          }
        })
        return formData;
      },
      async pay() {
		  if(this.vip_id===''){
		  			  this.$notify({
		  			    title: "提示",
		  			    message: "请选择购买时长",
		  			    duration: 1500,
		  				type: 'warning',
		  			  });
		  			  return
		  }
		  if(this.openWay==0){
			  this.$notify({
			    title: "提示",
			    message: "请输选择支付方式",
			    duration: 1500,
				type: 'warning',
			  });
			  return
		  }
        if (this.openWay == 1) {
          this.randomNumber = Math.floor(Math.random() * 100) + 1;
          let formData = {
            vip_id: this.vip_id, //会员配置id
            vip_type_id: this.vip_type_id, //会员分类id
            pay_type: 'wechat', //支付类型  alipay  wechat
            method: 'scan', //支付方式:电脑支付:web,公众号支付:mp,H5支付:wap,APP支付:app,扫码支付:scan,小程序支付:miniapp
            pay_ident: this.randomNumber, //随机数
          }
          const {
            data: res
          } = await this.$http.post('index/user/user_open_member', this.getFormData(formData))
          if (res.code == 1) {
            this.payUrl = res.data.code_url
            if (this.payUrl) {
              this.geterimgg()
            }
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
        } else if (this.openWay == 2) {
          let formData = {
            vip_id: this.vip_id, //会员配置id
            pay_type: 'alipay', //支付类型  alipay  wechat
            method: 'web', //支付方式:电脑支付:web,公众号支付:mp,H5支付:wap,APP支付:app,扫码支付:scan,小程序支付:miniapp
            vip_type_id: this.vip_type_id, //会员分类id
            pay_ident: '',
          }
          const {
            data: res
          } = await this.$http.post('index/user/user_open_member', this.getFormData(formData))
          if (res.code == 1) {
            this.payUrl = res.data
            let routerData = this.$router.resolve({
              path: '/payGateWay',
              query: {
                htmls: this.payUrl
              }
            });
            window.open(routerData.href, '_blank');
            this.czshow = false
            this.currr = 0
            this.openWay = 0
            this.user()
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
        }
      },
      async user() {
        const {
          data: res
        } = await this.$http.post('index/user/user_center')
        console.log(res)
        //头像
        this.circleUrl = res.data.avatar
        this.$cookie.set('avatar', this.circleUrl)
        this.ruleForm.name = res.data.nickname
        this.ruleForm.sex = res.data.gender.toString()
        this.ruleForm.email = res.data.email
        this.ordernum = res.data.serial_number
        this.dengji = res.data.is_vip_type
        //是否vip
		window.sessionStorage.setItem('is_vip_type',res.data.is_vip_type)
        this.time = res.data.vip_end_time
        //是否vip
        if (this.time) {
          window.sessionStorage.setItem('is_vip_time', res.data.vip_end_time)
        }
      },
      geterimgg() {
        const qrCodeData = this.payUrl;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrll = url;
            if (this.qrCodeUrll) {
              this.czshow = false
              this.WXczshow = true
              this.intervalId = setInterval(this.repeatedApiCall, 2000);
            }
          }
        });
      },
      repeatedApiCall() {
        this.$http.post('/index/cashout/pay_details_foreach', {
            type: 2,
            pay_ident: this.randomNumber
          })
          .then((res) => {
			  var that = this
            if (res.data.code == 1) {
				
              clearInterval(that.intervalId);
              that.$notify.success({
                title: "提示",
                message: '会员充值成功',
                duration: 2500,
                offset: 50
              });
              that.WXczshow = false
              that.currr = 0
              that.openWay = 0
            }
          });
      },
	  // 关闭微信支付弹框
	  closeBtn(){
		   clearInterval(this.intervalId);
	  }
    }
  }
</script>

<style lang="less" scoped>
  .content {
    background: #FFFFFF;
  }

  .content_head {
    position: relative;
  }

  .tirle {
    color: #FFFFFF;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    z-index: 9;
    text-align: center;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }

  // 第一部分
  .firstcont {
    background: #FFFFFF;
    color: #333333;
    // height: 100vh;
    // position: relative;
    // top: 0px;
    // z-index: 9;
  }

  .baben {
    position: relative;
  }

  .babenbox {
    position: absolute;
    bottom: -60px;
    width: 100%;
  }

  .forlist {
    margin: 140px 150px 30px 150px;

  }

  // 第二部分
  .huibox {
    padding: 10px 0;
    position: relative;
    // margin-right: 10px;
    top: 0px;


    .line {
      width: 55px;
      height: 6px;
      background: #CCCCCC;
      border-radius: 44px;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
    }

    .huihui {
      border: 1px solid #999999;
      border-radius: 16px;
      height: 100%;
      position: absolute;
      top: -8px;
      left: 0;
      right: 20px;
    }
  }

  .blurbox {
    padding: 10px 0;
    position: relative;
    // margin-right: 10px;

    .line {
      width: 55px;
      height: 6px;
      background: #268BFB;
      border-radius: 44px;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
    }

    .blue {
      border: 1px solid #268BFB;
      border-radius: 16px;
      height: 100%;
      position: absolute;
      top: -8px;
      left: 0;
      right: 20px;
    }
  }

  .yellowbox {

    padding: 10px 0;
    position: relative;
    // margin-right: 10px;
    // padding-left: 50px;

    .line {
      width: 55px;
      height: 6px;
      background: #FBCB49;
      border-radius: 44px;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;

    }

    .huang {
      border: 1px solid #FBCB49;
      border-radius: 16px;
      height: 100%;
      position: absolute;
      top: -8px;
      left: 0;
      right: 20px;
    }
  }

  .originbox {
    padding: 10px 0;
    position: relative;

    .line {
      width: 55px;
      height: 6px;
      background: #FF670B;
      border-radius: 44px;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;

    }

    .origin {
      border: 1px solid #FF670B;
      border-radius: 16px;
      height: 100%;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0px;
    }
  }

  .listhang {
    text-align: center;
    font-size: 14px;
    color: #666666;
    line-height: 42px;
    // margin-right: 20px;
    padding-right: 20px;

  }

  // 奇数行样式
  .listhang:nth-child(odd) {
    background: #FBFBFB;
  }

  // 偶数行样式
  .listhang:nth-child(even) {
    background: #FFFFFF;
  }

  .bigtlts {
    color: #666666;
    font-size: 18px;
    position: relative;
    top: -11px;
  }

  .onehang {
    font-size: 14px;
    color: #666666;
    height: 42px;
    line-height: 42px;

  }

  .bigtlt {
    font-size: 25px;
    font-weight: bold;
    color: #333333;
    position: relative;
    top: -15px;
  }

  .bigtltsa {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  // 奇数行样式
  .onehang:nth-child(odd) {
    background: #FBFBFB;
  }

  // 偶数行样式
  .onehang:nth-child(even) {
    background: #FFFFFF;
  }


  // 第一部分


  .row-bg {
    background: #FFFFFF;
    box-shadow: 0px 4px 27px 0px rgba(13, 104, 252, 0.06);
    border-radius: 44px;
    margin: 0 110px;
    padding: 40px 0;
  }



  .row-bg1 {
    border-right: 1px solid #CCCCCC;
    text-align: center;

    .line {
      width: 67px;
      height: 6px;
      border-radius: 4px;
    }

    img {
      width: 60px;
      margin-top: 5px;
    }

    .tits {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }

    .rowcont {
      span {
        font-size: 30px;
        font-weight: bold;
      }

      margin: 20px 0;
      font-size: 25px;

      text {
        font-size: 13px;
      }
    }

    ul {
      width: 100%;
      text-align: center;
      padding-left: 221px;

      li {
        width: max-content;
        font-size: 15px;
      }

      li::marker {
        color: #666666;
      }
    }
  }





  .btn {
    width: 100px;
    height: 35px;
    margin: 0 auto;
    background: #FF670B;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 20px 0 20px;
    font-size: 13px;
  }

  /*当页面大于 1200px 时，大屏幕，主要是 PC 端*/
  @media (min-width: 1200px) {
    .row-bg {
      margin: 0 110px;
    }
  }

  /*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .row-bg {
      margin: 0 40px;
    }

    .tirle {
      position: absolute;
      top: 90px;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }

    }

    .babenbox {
      position: absolute;
      bottom: -60px;
      width: 100%;
    }

    .forlist {
      margin: 140px 80px 30px 80px;

    }
  }

  /*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
  @media (min-width: 768px) and (max-width: 991px) {
    .row-bg {
      margin: 0 40px;
    }

    .row-bg1 {
      margin-bottom: 60px;
    }

    .tirle {
      position: absolute;
      top: 90px;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }

    }

    .babenbox {
      position: absolute;
      bottom: -400px;
      width: 100%;
    }

    .forlist {
      margin: 490px 80px 30px 80px;

    }
  }

  /*在 480 和 767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {
    .row-bg {
      margin: 0 40px;
    }

    .row-bg1 {
      margin-bottom: 60px;
    }

    .row-bg2 {
      margin-bottom: 60px;
    }

    .row-bg3 {
      margin-bottom: 60px;
    }



    .tirle {
      position: absolute;
      top: 90px;

      h1 {
        font-size: 22px;
      }

      p {
        display: none !important;
      }

    }

    .babenbox {
      position: absolute;
      bottom: -1080px;
      width: 100%;
    }

    .forlist {
      margin: 1140px 50px 30px 50px;

    }

    .huibox {
      margin-bottom: 20px;
    }

    .blurbox {
      margin-bottom: 20px;
    }

    .yellowbox {
      margin-bottom: 20px;
    }
  }

  /*在小于 480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {
    .row-bg {
      margin: 0 40px;
    }

    .row-bg1 {
      margin-bottom: 60px;
    }

    .row-bg2 {
      margin-bottom: 60px;
    }

    .row-bg3 {
      margin-bottom: 60px;
    }

    .tirle {
      position: absolute;
      top: 90px;

      h1 {
        font-size: 18px;
      }

      p {
        display: none !important;
      }

    }

    .baben {
      // position: relative;
    }

    .babenbox {
      position: relative;
      bottom: 30px;
      width: 100%;
    }

    .forlist {
      margin: 40px 20px 30px 20px;
      // display: none;
    }

    .huibox {
      margin-bottom: 20px;
    }

    .blurbox {
      margin-bottom: 20px;
    }

    .yellowbox {
      margin-bottom: 20px;
    }
  }

  /deep/.el-dialog {
    border-radius: 24px;
  }

  /deep/.el-dialog__body {
    padding: 0;


  }

  /deep/.el-dialog__header {
    padding: 0;

  }

  .distributionN {
    width: 100%;
    height: 600px;
    position: relative;


    .distributiontop {
      width: 100%;
      height: 146px;
      background: #0D68FC;
      border-radius: 24px 24px 0 0;
      position: absolute;
      top: 0;

      .title {
        float: left;
        line-height: 39px;
        margin-top: 35px;
        margin-left: 36px;

        .title1 {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .close {
        float: right;
        line-height: 146px;
        margin-right: 36px;
      }
    }

    .distributionbot {
      width: 100%;
      height: 618px;
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      top: 180px;

      .left {
        width: 334px;
        height: 100%;
        background: #F9FAFB;
        margin-left: 37px;
        padding: 20px;
        border-radius: 10px;

        .left_top {
          width: 100%;
          height: 100px;
          border-bottom: 1px solid #E6E6E6;
          margin-bottom: 24px;
          line-height: 40px;
        }

        .left_bottom {
          line-height: 40px;
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-wrap: wrap;


        .rightone {
          width: 100%;
          height: 115px;

          span {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #333333;
            margin-left: 20px;
          }

          .banbenn {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;


            .banben {
              margin-top: 10px;
              width: 30%;
              height: 68px;
              border-radius: 10px;
              border: 1px solid #ccc;


              .con_right {
                width: 100%;
                text-align: center;
                margin-top: 10px;

                .con_right_text {
                  line-height: 68px;

                  img {
                    width: 117px;
                    height: 25px;
                  }
                }

                .con_right_top {
                  margin-top: 20px;
                  font-size: 20px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                }
              }
            }

            .active {
              border: 2px solid #0D68FC !important;
              color: #0D68FC !important;
            }
          }



        }

        .righttwo {
          width: 100%;
          height: 210px;

          .righttwoqq {
            width: 100%;
            text-align: center;
            line-height: 150px;
            font-size: 30px;
          }

          span {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #333333;
            margin-left: 20px;
          }

          .time {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;


            .timemoney {
              margin-top: 10px;
              width: 30%;
              height: 150px;
              border-radius: 15px;
              border: 1px solid #ccc;


              .con_right {
                width: 100%;
                text-align: center;

                .con_right_text {
                  width: 100%;
                  height: 52px;
                  line-height: 52px;
                  border-radius: 6px 6px 0 0;
                  font-size: 28px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                }

                .con_right_time {
                  margin-top: 15px;

                  span {
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #4189FF;
                  }
                }

                .con_right_top {
                  margin-top: 20px;

                  span {
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                  }


                }
              }
            }

            .active {
              border: 2px solid #0D68FC !important;
              color: #0D68FC !important;
            }
          }
        }

        .righthree {
          width: 100%;

          margin-left: 20px;

          .button {
            width: 210px;
            height: 58px;
            background: #0D68FC;
            border-radius: 8px;
            text-align: center;

            text {
              line-height: 58px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              // font-weight: 400;
              color: #FFFFFF;
            }
          }

        }

      }
    }
  }

  .recharge_bottom {
    width: 95%;
    height: 200px;

    .paytype {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #333333;
      margin-left: 20px;
    }

    .paybox {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .payxbox {
        width: 250px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #C2D9FE;
        border-radius: 4px;
        margin-right: 30px;
        position: relative;

        .name {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #818285;
          margin-left: 12px;
        }

        .xz {
          position: absolute;
          top: -3px;
          right: -4px;
        }
      }

      .active {
        border: 1px solid #0D68FC;
      }
    }

    .mailbox {
      display: flex;
      margin-top: 20px;
      align-items: center;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;

      .bjbxo {
        border-radius: 3px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-left: -17px;
          z-index: 99;
        }
      }
    }

    .fapiao {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      margin-top: 15px;

      text {
        color: #0D68FC;
        font-size: 14px;
      }
    }

    .taber {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 20px;

      .xil {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #818183;

        img {
          width: 10px;
          height: 10px;
        }

        text {
          color: #0D68FC;
          font-size: 12px;
        }
      }
    }


    .price {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #74767A;

      .pp {
        color: #0D68FC;
        font-size: 18px;
        font-weight: bold;
      }

      .gz {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-left: 8px;
      }
    }

    .btnnn {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .qian {
        width: 120px;
        height: 42px;
        background: #DCE7FF;
        border-radius: 6px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0D68FC;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .shen {
        width: 120px;
        height: 42px;
        background: #0D68FC;
        border-radius: 6px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
      }
    }
  }
</style>