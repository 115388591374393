<template>
	<div class="content">
		<div class="swiper">
			<el-carousel height="700px" indicator-position="none" arrow="never">
				<el-carousel-item v-for="item in 4" :key="item">
					<div class="swiper-item">
						<img src="../../assets/kaiyuanchanping/banabn.png" />
						<div class="imgg">
							<img src="../../assets/kaiyuanchanping/kaiyuan.png" />
							<div class="text">官方正版授权,享受1V1服务支持,上手更快,商用无忧</div>
						</div>
						<div class="butt1" @click="kefuzixun()">
							<img src="../../assets/kaiyuanchanping/buttonkf.png" />
						</div>
						<scrollwarp></scrollwarp>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="product flex justify-center align-center">
			<div class="allproduct">
				<!-- 级联选择 -->
				<div class="jilian">
					<div style="position: relative;display: inline-block;margin: 10px 0px;">
						<!-- @change="orderfenxi" -->
						<el-select :teleported="false" v-model="types" style="margin-left: 10px;" placeholder="最新上线"
							@change="shangxian">
							<el-option v-for="item in typeList" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</div>
					<div style="position: relative;display: inline-block;margin: 10px 0px;">
						<el-select v-model="types1" style="margin-left: 10px;" placeholder="产品价格" :teleported="false"
							@change="chanpinprice">
							<el-option v-for="item in typeList1" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</div>
					<div style="position: relative;display: inline-block;margin: 10px 0px;">
						<el-select v-model="types2" style="margin-left: 10px;" placeholder="全部系列" :teleported="false"
							@change="allxilei">
							<el-option v-for="item in typeList2" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div style="position: relative;display: inline-block;margin: 10px 0px;">
						<el-select v-model="types3" style="margin-left: 10px;" placeholder="发布时间" :teleported="false"
							@change="fabutime">
							<el-option v-for="item in typeList3" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</div>
					<el-button @click="clearBtn()" class="qingkong">清空选项</el-button>
				</div>
				<!-- 内容 -->
				<div class="productcentents">
					<div class="product1" v-for="(item,index) in product " :key="index">
						<div class="productimg">
							<img :src="item.image" alt="" @click="joinjieshao(item)">
						</div>
						<div class="productext" :style="colorDiv(item)" @click="divCLick(index)" style="z-index: 999;">
							<div class="producone">
								{{item.title}}
							</div>
							<div class="productwo">
								<div class="productwo_money">
									￥ <span style="font-size: 18px; font-weight: 600px;">{{item.money}}</span>
								</div>
								<div class="productwo_renqi">
									<span><img src="../../assets/kaiyuanchanping/hot.png" alt=""> {{item.sales}}</span>
								</div>
							</div>
							<div class="producthree">
								<div class='button1' @click="buyone(item)">
									<span>立即购买</span>
								</div>
								<div class='button2' @click="buytwo()">
									<span>产品演示</span>
								</div>
								<div class='button3' @click="buythree(item)">
									<span><img src="../../assets/kaiyuanchanping/kefu.png"
											style="margin-top: 5px; margin-right: 10px;">咨询</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pages">
					<el-pagination background :total="total" v-model:page-size="pageSize"
						@size-change="handleSizeChange" v-model:current-page="currentPage"
						@current-change="handleCurrentChange" layout=" prev, pager, next" />
				</div>
			</div>
		</div>
	</div>

	<!-- <affix></affix> -->
	<!-- 等录弹窗 -->
	<!-- <el-dialog v-model="dialogVisible" title="登录提示" width="30%" :before-close="handleClose">
    <span>尊敬的用户，你还未登录，请登录后在进行购买</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="gotologin()">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog> -->
</template>

<script>
	import affix from '../../components/affix.vue'
	import scrollwarp from '../../components/scrollwarp2.vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		components: {
			affix,
			scrollwarp
		},
		data() {
			return {
				total: '',
				pageSize: 20,
				currentPage: 1,
				dialogVisible: false,
				gg: '购买请联系客服，领取优惠卷！客服电话：13895585204',
				types: '',
				typeList: [{
					value: 'all',
					label: '最新'
				}, {
					value: 1,
					label: '热销'
				}, {
					value: 2,
					label: '好评'
				}],
				types1: '',
				typeList1: [{
					value: 'all',
					label: '全部'
				}, {
					value: 1,
					label: '5K以下'
				}, {
					value: 2,
					label: '5k-1w'
				}, {
					value: 3,
					label: '1w-2w'
				}, {
					value: 4,
					label: '2w-3w'
				}, {
					value: 5,
					label: '3w-4w'
				}, {
					value: 6,
					label: '4w以上'
				}],
				types2: '',
				typeList2: [],
				types3: '',
				typeList3: [{
						value: 'all',
						label: '全部'
					},
					{
						value: 1,
						label: '近一个月'
					}, {
						value: 2,
						label: '近三个月'
					}, {
						value: 3,
						label: '近六个月'
					}, {
						value: 4,
						label: '近一年'
					}
				],
				isActive: false,
				product: [],
				purchasedProducts: []
			}
		},
		methods: {
			shangxian(value) {
				this.types = value
				this.byallchanpin()
			},
			chanpinprice(value) {
				this.types1 = value
				this.byallchanpin()
			},
			allxilei(value) {
				this.types2 = value
				this.byallchanpin()
			},
			fabutime(value) {
				this.types3 = value
				this.byallchanpin()
			},
			// 清空选项
			clearBtn() {
				this.types = ''
				this.types1 = ''
				this.types2 = ''
				this.types3 = ''
				this.byallchanpin()
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.byallchanpin()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.byallchanpin()
			},
			async byshopplist() {
				const {
					data: res
				} = await this.$http.get('index/product/get_goods_cate_list')
				this.typeList2 = res.data
			},
			//全部产品
			async byallchanpin() {
				const {
					data: res
				} = await this.$http.get('index/product/procduct_list', {
					params: {
						search_new: this.types,
						product_money: this.types1,
						goods_cate: this.types2,
						create_time: this.types3,
						num: this.pageSize,
						page: this.currentPage
					}
				})
				if (res.code == 1) {
					//全部产品
					var chanpin = res.data.data
					this.total = res.data.total
					for (let i = 0; i < chanpin.length; i++) {
						chanpin[i].isActive = false
					}
					console.log(chanpin)
					this.product = chanpin
				}
			},
			kefuzixun() {
				this.$router.push({
					path: '/chatindex',
				})
			},
			//点击切换背景
			divCLick(index) {
				/** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
				for (let i in this.product) {
					if (index == i) {
						this.product[i].isActive = !this.product[i].isActive
					} else this.product[i].isActive = false;
				}
			},
			colorDiv(item) {
				if (item.isActive == true) {
					return {
						'background-color': '#FFFFFF'
					}
				} else {
					return {
						'background-color': '#FAFAFA'
					}
				}
			},
			gotologin() {
				this.$router.push({
					name: 'login',
				})
			},
			//进入产品介绍页面
			joinjieshao(item) {
				this.purchasedProducts.push(item)
				let jsonString = JSON.stringify(this.purchasedProducts);
				window.localStorage.setItem('purchasedProducts', jsonString)
				window.localStorage.setItem('buy_type', 1)
				this.$router.push({
					name: 'ProductIntroduction',
					query: {
						item: item.id
					}
				})
			},
			buyone(item) {
				let userId = window.sessionStorage.getItem('token');
				if (userId == '' || userId == null) {
					ElMessageBox.confirm(
						'当前未登录，是否登录。',
						'提示', {
							confirmButtonText: '去登录',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).then(() => {
						this.$router.push({
							path: 'login',
						})
					}).catch(() => {

					})
				} else {
					this.purchasedProducts.push(item);
					let jsonString = JSON.stringify(this.purchasedProducts);
					window.localStorage.setItem('purchasedProducts', jsonString)
					this.$router.push({
						name: 'empower',
						query: {
							buy_type: 1,
							name: '/my/empower'
						}
					})
				}
			},
			buytwo() {
				this.$router.push({
					path: '/demoCenter',
				})
			},
			buythree(item) {
				var itemcon = item.id
				this.$router.push({
					path: '/chatindex',
					query: {
						item: itemcon
					}
				})
			},
		},
		mounted() {
			this.byallchanpin()
			this.byshopplist()
		}
	}
</script>

<style lang="less" scoped>
	.content {
		background: #EFF7FF;
	}

	.swiper {
		width: 100%;
		height: 700px;

		.swiper-item {
			width: 100%;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.imgg {
				position: absolute;
				top: 200px;
				left: 100px;

				img {
					width: 913px;
					height: 96px;
				}
			}

			.text {
				margin-top: 40px;
				margin-left: 10px;
				font-size: 28px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #0060FB;
				line-height: 2px;
			}

			.butt1 {
				position: absolute;
				top: 473px;
				left: 132px;
			}

			.swiper-item-gg {
				position: absolute;
				bottom: 20px;
				left: 50%;
				transform: translate(-50%, 0);
				width: 80%;
				height: 80px;
				background: rgba(255, 255, 255, 0.7);
				border-radius: 16px;
				padding: 0 50px;
				color: #0D68FC;
				font-size: 22px;

				img {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
			}
		}
	}

	.product {
		width: 100%;
		margin-top: 34px;
		padding-bottom: 28px;
		background: #EFF7FF;

		.allproduct {
			width: 87%;
			height: 100%;
			margin: 0 auto;
		}

		.jilian {
			width: 680px;
			display: flex;
			justify-content: space-between;
		}

		.productcentents {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			.product1 {
				height: 100%;
				margin-top: 40px;
				margin-right: 63px;

				.productimg {
					width: 350px;
					height: 280px;

					img {
						width: 350px;
						height: 280px;
						object-fit: cover;
					}
				}

				.productext {
					width: 350px;
					height: 153px;
					background: #FFFFFF;
					border-radius: 0px 0px 16px 16px;

					.producone {
						padding-top: 10px;
						font-size: 23px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #000024;
						line-height: 40px;
						margin-left: 18px;
						white-space: nowrap;
						/* 禁止文字换行 */
						overflow: hidden;
						/* 隐藏超出容器部分的文字 */
						text-overflow: ellipsis;
						/* 当文字超出容器宽度时显示省略号 */

					}

					.productwo {
						margin-left: 18px;

						.productwo_money {
							float: left;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #FF011B;
							line-height: 32px;
						}

						.productwo_renqi {
							float: right;
							margin-right: 19px;

							span {
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #999999;
								line-height: 2px;
							}
						}
					}

					.producthree {
						margin-left: 18px;
						width: 100%;
						text-align: center;
						display: inline-flex;
						margin-top: 20px;

						.button1 {
							margin-right: 10px;
							width: 90px;
							height: 32px;
							background: linear-gradient(90deg, #0D68FC 0%, #2597FF 100%);

							span {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 32px;

							}
						}

						.button2 {
							margin-right: 65px;
							width: 90px;
							height: 32px;
							background: #EBF1FF;

							span {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #1A6AFF;
								line-height: 32px;
							}
						}

						.button3 {
							margin-top: 5px;
						}
					}
				}
			}
		}
	}

	.pages {
		margin-top: 20px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.qingkong {
		background: #e8ebee;
		height: 52px;
		position: relative;
		top: 10px;
		font-size: 18px;
		margin-left: 20px;
		line-height: 52px;
		
		border-radius: 10px;
		border: none;
		color: #999;
	}
</style>