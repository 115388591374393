<template>
  <div class="cont" ref="imgBox">
    <div class="touxing" style="margin:14px 0">
      <img :src="avatar?avatar:avatrr" alt="" srcset=""
        style="width: 56px;height: 56px;border-radius: 50%;;margin-right: 5px;">
    </div>
    <div class="neirong">
      <div class="num">邀请码：{{invitation_code}}</div>
      <div class="box_img"> <img :src="qrCodeUrl"> </div>
    </div>
    <div class="anniu">
      <div class="anniu1" @click="shareLink()" v-if="lianjie">
        <text>复制链接</text>
      </div>
      <div class="anniu2" @click="saveImage()" v-if="baoucn">
        <text>保存图片</text>
      </div>
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import html2canvas from "html2canvas";
  export default {
    data() {
      return {
        avatar: '',
        avatrr: 'https://tse2-mm.cn.bing.net/th/id/OIP-C.fNF8owgmIwYhU9KINmt2dAAAAA?w=186&h=186&c=7&r=0&o=5&pid=1.7',
        invitation_code: '',
        qrcode_url: '',
        qrCodeUrl: '',
        lianjie: true,
        baoucn: true,
      }
    },
    mounted() {
      this.code()
      this.avatar = this.$cookie.get('avatar')
    },
    methods: {
      async code() {
        const {
          data: res
        } = await this.$http.post('/index/user/test_qrcode')
        this.invitation_code = res.data.invitation_code
        this.qrcode_url = res.data.qrcode_url
        if (this.qrcode_url) {
          this.geterimg()
        }
      },
      geterimg() {
        const qrCodeData = this.qrcode_url;
        QRCode.toDataURL(qrCodeData, (err, url) => {
          if (err) {
            this.$notify.error({
              title: "提示",
              message: '二维码生成有误,请联系客服',
              duration: 2500,
              offset: 50
            });
          } else {
            this.qrCodeUrl = url;
          }
        });
      },
      shareLink() {
        this.$notify.success({
          title: "成功",
          message: '复制成功',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = this.qrcode_url
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input)
      },
      saveImage() {
        this.lianjie = false
        this.baoucn = false
        this.$nextTick((res) => {
          html2canvas(this.$refs.imgBox, {
            height: this.$refs.imgBox.scrollHeight,
            width: this.$refs.imgBox.scrollWidth,
          }).then((canvas) => {
            const link = document.createElement("a"); // 创建一个超链接对象实例
            link.download = "推广图.png"; // 设置要下载的图片的名称
            link.href = canvas.toDataURL(); // 将图片的URL设置到超链接的href中
            link.click(); // 触发超链接的点击事件
          });
        })
        setTimeout(res => {
          this.lianjie = true
          this.baoucn = true
        }, 2000)
      },
      changeToBlob(dataURL) {
        var arr = dataURL.split(","),
          type = arr[0].match(/:(.*?);/)[1], //获取MIME 类型，即image/png
          bstr = atob(arr[1]),
          count = bstr.length,
          u8arr = new Uint8Array(count);
        while (count--) {
          u8arr[count] = bstr.charCodeAt(count);
        }
        return new Blob([u8arr], {
          type: type,
        });
      },
    }
  }
</script>

<style scoped lang="less">
  .cont {
    height: 796px;
    width: 493px;
    position: fixed;
    top: 10%;
    left: 65%;
    z-index: 999;
    background-image: url('../../src/assets/tuiguang.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .touxing {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      position: absolute;
      top: 402px;
      left: 211px;
    }

    .neirong {
      width: 312px;
      height: 180px;
      position: absolute;
      top: 470px;
      left: 90px;
      align-items: center;

      .num {
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .box_img {
        text-align: center;

        img {
          width: 160px;
          height: 160px;
        }
      }
    }

    .anniu {
      width: 313px;
      height: 48px;
      justify-content: space-around;
      position: absolute;
      top: 715px;
      left: 90px;
      display: flex;
      flex-wrap: nowrap;

      .anniu1 {
        width: 110px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        text-align: center;

        text {
          line-height: 40px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FD4F4A;
        }
      }

      .anniu2 {
        width: 110px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        text-align: center;

        text {
          line-height: 40px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FD4F4A;
        }
      }
    }
  }
</style>