<template>
  <div class="cont">
    <div class="contlist" ref='contlist'>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="con">
            <img :src="item.image" alt="">
          </div>
        </li>
      </ul>
      <ul v-if="this.initLeft='-100px'">
        <li v-for="(item, index) in list" :key="index">
          <div class="con">
            <img :src="item.image" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        scrollW: 0, //记录滚动到哪了
      }
    },
    methods: {
      //循环滚动
      scroll() {
        var that = this;
        var target = this.$refs.contlist;
        var initLeft = 0;
        if (this.scrollW < 0) {
          initLeft = this.scrollW * (-1)
        }
        //定时器
        this.scrollTime = setInterval(function() {
          initLeft++;
          if (initLeft >= target.offsetWidth / 2) {
            initLeft = 0;
          }
          target.style.left = '-' + initLeft + 'px';
        }, 16)
      },
      async byteam() {
        const {
          data: res
        } = await this.$http.get('index/index/index_title_corporate_culture')
        if (res.code == 1) {
          this.list = res.data.index_content_list
        } else {
          this.$notify.error({
            title: "页面提示",
            message: "接口报错请重新登录",
            duration: 1500,
            offset: 50
          });
        }
      }
    },
    mounted() {
      this.byteam()
      this.$nextTick(function() {
        this.scroll() //获取焦点
      })
    }
  }
</script>
<style type="text/css" scoped>
  ul,
  li,
  em {
    margin: 0;
    padding: 0;
  }

  .cont {
    width: 100%;
    height: 330px;
    color: #fff;
    overflow: hidden;
    position: relative;
  }

  .contlist {
    position: absolute;
    display: flex;
  }

  .contlist ul {
    display: flex;
  }

  .contlist ul li {
    width: 322px;
    height: 330px;
  }

  .con img {
    width: 322px;
    height: 330px;
  }
</style>