<template>
  <div class="MyChat">
    <div class="my_user">
      <div class="time">{{ time }}</div>
      <div class="other_main">
        <div class="other_message">
          <div v-if="con.type===1">
            {{con.content}}
          </div>
          <div v-if="con.type===7">
            {{con.content}}
          </div>
          <div v-if="con.type===2">
            <img :src="con.content" alt="" style="width: 100px; height:100px" />
          </div>
          <div v-if="con.type === 4">
             <img mode="widthFix" :src="'https://pw.xianmxkj.com/emoji/'+con.content" />
          </div>

          <div v-if="con.type===5">
            <div class="shopping flex">
              <div class="shopping_img">
                <img :src="con.con.image" alt="">
              </div>
              <div class="shopping_textt">
                <div class="shopping_text">
                  {{con.con.title}}
                </div>
                <div class="shopping_tip">
                  <el-tag>{{con.con.goods_lable}}</el-tag>
                </div>
                <div class="shopping_money">
                  <span class="money1">￥{{con.con.money}}</span><span class="money2">￥{{con.con.old_money}}</span>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="avatar_warp">
          <el-avatar class="chatAvatar" :size="30" :src="avatar"></el-avatar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MyChat",
    props: {
      time: {
        type: String,
        default: "",
      },
      avatar: {
        type: String,
        default: "",
      },
      con: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style scoped>
  .time {
    
    text-align: center;
    color: #9a9a9a;
    padding-bottom: 20px;
  }

  /* 我的信息 */
  .my_user {
    padding-bottom: 30px;
  }

  .other_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .other_message {
    padding: 2px 25px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: black;
    line-height: 34px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: 60%;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    margin-right: 10px;
  }

  .shopping {
    /* width: 300px;
    height: 200px; */
    border-radius: 15%;

  }

  .shopping_img {
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 10px;
    float: left;
  }

  .shopping_img img {
    width: 100px;
    height: 150px;
  }

  .shopping_textt {
    margin-right: 10px;
    margin-top: 10px;
    float: right;
    color: black;
  }

  .shopping_text {
    font-size: 18px;
  }

  .shopping_money {
    margin-top: 20px;
  }

  .shopping_money .money1 {
    color: orange;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 600;
    margin-right: 20px;
  }

  .shopping_money .money2 {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #B3B3B3;
    text-decoration: line-through;
  }
</style>