<template>
  <div class="chatIcon">
    <el-popover placement="top" :width="300" trigger="click" v-model="visible" :offset="198">
      <div class="emotionList">
        <div v-for="(item,index) in faceList" :key="index" class="emotionItem">
          <div v-for="(em,eid) in item" :key="eid" @click="getEmo(em.url)">
            <img mode="widthFix" :src="'https://pw.xianmxkj.com/emoji/'+em.url" />
          </div>
        </div>
      </div>
      <template #reference>
        <el-button class="emotionSelect"><img src="../../src/assets/affix/emioy.png" alt=""></el-button>
      </template>
    </el-popover>

  </div>
</template>
<script>
  import {
    appData
  } from "../mockdata/emojiArr"
  export default {
    data() {
      return {
        faceList: [],
        emojiItem: null,
        visible: false,
      };
    },
    methods: {
      getEmo(index) {
        console.log(index)
        this.emojiItem = index;
        this.$emit("changeTextZ",this.emojiItem)
        this.visible = false
      }
    },
    mounted() {
      appData.map(item => {
        this.faceList.push(item)
      })
      console.log(this.faceList)
    },
  }
</script>
<style scoped>
  .emotionSelect {
    width: 40px;
    height: 40px;
    border: none;
    margin-left: -20px;
    margin-top: -82px;
  }

  .emotionSelect img {
    width: 25px;
    height: 25px;
  }

  .chatIcon {
    padding: 0 10px;
    font-size: 25px;
  }

  .emotionList {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    height: 200px;
    overflow: scroll;
  }

  .emotionItem {
    width: 20%;
  }
</style>