//模拟评论数据
const comment = {
  status: "成功",
  code: 200,
  data: [{
      id: 'comment0001', //主键id
      date: '2023-09-01 ', //评论时间
      ownerId: 'talents100020', //文章的id
      fromId: 'errhefe232213', //评论者id
      fromName: '犀利的评论家', //评论者昵称
      fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg!1', //评论者头像
      content: '非常靠谱的程序员', //评论内容
      address: '西安',
      vip: 1,
      rate:5,
      reply: [ //回复，或子评论
        {
          id: '34523244545', //主键id
          commentId: 'comment0001', //父评论id，即父亲的id
          fromId: 'observer223432', //评论者id
          fromName: '省钱兄官方客服', //评论者昵称
          fromAvatar: 'https://wx4.sinaimg.cn/mw690/69e273f8gy1ft1541dmb7j215o0qv7wh.jpg', //评论者头像
          toId: 'errhefe232213', //被评论者id
          toName: '犀利的评论家', //被评论者昵称
          toAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg', //被评论者头像
          content: '赞同，很靠谱，水平很高', //评论内容
          date: '2018-07-07 ' //评论时间
        },
      ]
    },
    {
      id: 'comment0002',
      date: '2018-07-05 ',
      ownerId: 'talents100020',
      fromId: 'errhefe232213',
      fromName: '毒蛇郭德纲',
      fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg',
      likeNum: 0,
      content: '从没见过这么优秀的人',
      vip: 0,
      address:'北京',
      rate:5,
    },
    {
        id: 'comment0001', //主键id
        date: '2018-07-08 ', //评论时间
        ownerId: 'talents100020', //文章的id
        fromId: 'errhefe232213', //评论者id
        fromName: '犀利的评论家', //评论者昵称
        fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg!1', //评论者头像
        content: '非常靠谱的程序员', //评论内容
        address: '西安',
        vip: 1,
        rate:5,
        reply: [ //回复，或子评论
          {
            id: '34523244545', //主键id
            commentId: 'comment0001', //父评论id，即父亲的id
            fromId: 'observer223432', //评论者id
            fromName: '省钱兄官方客服', //评论者昵称
            fromAvatar: 'https://wx4.sinaimg.cn/mw690/69e273f8gy1ft1541dmb7j215o0qv7wh.jpg', //评论者头像
            toId: 'errhefe232213', //被评论者id
            toName: '犀利的评论家', //被评论者昵称
            toAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg', //被评论者头像
            content: '赞同，很靠谱，水平很高', //评论内容
            date: '2018-07-08' //评论时间
          },
        ]
      },
      {
        id: 'comment0002',
        date: '2018-07-05 ',
        ownerId: 'talents100020',
        fromId: 'errhefe232213',
        fromName: '毒蛇郭德纲',
        fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg',
        likeNum: 0,
        content: '从没见过这么优秀的人',
        vip: 0,
        address:'北京',
        rate:5,
      },
      {
          id: 'comment0001', //主键id
          date: '2018-07-06 ', //评论时间
          ownerId: 'talents100020', //文章的id
          fromId: 'errhefe232213', //评论者id
          fromName: '犀利的评论家', //评论者昵称
          fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg!1', //评论者头像
          content: '非常靠谱的程序员', //评论内容
          address: '西安',
          vip: 1,
          rate:5,
          reply: [ //回复，或子评论
            {
              id: '34523244545', //主键id
              commentId: 'comment0001', //父评论id，即父亲的id
              fromId: 'observer223432', //评论者id
              fromName: '省钱兄官方客服', //评论者昵称
              fromAvatar: 'https://wx4.sinaimg.cn/mw690/69e273f8gy1ft1541dmb7j215o0qv7wh.jpg', //评论者头像
              toId: 'errhefe232213', //被评论者id
              toName: '犀利的评论家', //被评论者昵称
              toAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg', //被评论者头像
              content: '赞同，很靠谱，水平很高', //评论内容
              date: '2019-07-05 ' //评论时间
            },
          ]
        },
        {
          id: 'comment0002',
          date: '2018-08-03',
          ownerId: 'talents100020',
          fromId: 'errhefe232213',
          fromName: '毒蛇郭德纲',
          fromAvatar: 'https://profile-avatar.csdnimg.cn/3f06e64daeca4a7cb7a046ce98f53b8b_qq_45652428.jpg',
          likeNum: 0,
          content: '从没见过这么优秀的人',
          vip: 0,
          address:'北京',
          rate:5,
        }
  ]
};

export {
  comment
}