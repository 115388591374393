<template>
	<div>
		<div v-for="(item, index) in images" :class="[...item.className]" @click="handleClick(item.type,index)"
			:key="`info-item-${index}`">
			<div class="content">
				<div class="img">
					<img :src="item.active ? item.caiImg : item.huiImg" />
				</div>
				<div class="text" :style="item.active ? {color: caiColor} : {color: textColor}">
					{{item.text}}
				</div>
			</div>
		</div>
		<!-- <div class="affixbutton" @click="ruzhu()">
      <div class="content">
        <div class="img">
          <img src="../../src/assets/affix/join.png">
        </div>
        <div class="text" :style="{color: textColor}">
          我要入驻
        </div>
      </div>
    </div>
    <div class="affixbutton1" @click="tuiguang()">
      <div class="content">
        <div class="img">
          <img src="../../src/assets/affix/join_biue.png">
        </div>
        <div class="text" :style="{color: textColor}">
          我要推广
        </div>
      </div>
    </div>
    <div class="affixbutton2" @click="kefu()">
      <el-badge :value="value" class="item">
        <div class="content">
          <div class="img">
            <img src="../../src/assets/affix/kefu.png">
          </div>
          <div class="text" :style="{color: textColor}">
            联系客服
          </div>
        </div>
      </el-badge>
    </div>
    <div class="affixbutton3" @click="top()">
      <div class="content">
        <div class="img">
          <img src="../../src/assets/affix/top.png">
        </div>
        <div class="text" :style="{color: textColor3}">
          返回顶部
        </div>
      </div>
    </div> -->
		<chat v-if="chatapp"></chat>
		<tuikuang v-if="tkshow"></tuikuang>
		<entryRules v-if="gzshow"></entryRules>
		
		
	</div>
</template>

<script>
	import chat from "./chat.vue"
	import tuikuang from "./tuikuang.vue"
	import entryRules from "./entryRules.vue"
	
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import ruzhuHImg from "../../src/assets/affix/ruzhu_hui.png" //入駐灰色
	import ruzhuLImg from "../../src/assets/affix/join.png" //入駐彩色
	import tuiguangHImg from "../../src/assets/affix/tuiguang_hui.png" //推廣灰色
	import tuiguangLImg from "../../src/assets/affix/join_biue.png" //推廣彩色
	import kefuHImg from "../../src/assets/affix/kefu_hui.png" //客服灰色
	import kefuLImg from "../../src/assets/affix/kefu.png" //客服彩色
	import topImg from "../../src/assets/affix/top.png" //返回顶部
	export default {
		components: {
			chat,
			tuikuang,
			entryRules,
		},
		data() {
			return {
				value: '',
				chatapp: true,
				tkshow: false,
				gzshow:false,
				textColor: '#666666',
				caiColor: '#0D68FC',
				ruzhuHImg: ruzhuHImg,
				tuiguangHImg: tuiguangHImg,
				kefuHImg: kefuHImg,
				topImg: topImg,
				images: [{
						text: '我要入驻',
						'huiImg': ruzhuHImg,
						'caiImg': ruzhuLImg,
						className: ['affixbutton'],
						type: 'ruzhu',
						active: false
					},
					{
						text: '我要推广',
						'huiImg': tuiguangHImg,
						'caiImg': tuiguangLImg,
						className: ['affixbutton1'],
						type: 'tuiguang',
						active: false
					},
					{
						text: '联系客服',
						'huiImg': kefuHImg,
						'caiImg': kefuLImg,
						className: ['affixbutton2'],
						type: 'kefu',
						active: true
					},
					{
						text: '返回顶部',
						'huiImg': topImg,
						'caiImg': topImg,
						className: ['affixbutton3'],
						type: 'top',
						active: false
					}
				],
				dialogVisible: false,
				fuwutioakuamg: '',
			}
		},
		methods: {
			handleClick(type, index) {
				let objFun = {
					"ruzhu": this.ruzhu,
					"tuiguang": this.tuiguang,
					'kefu': this.kefu,
					'top': this.top
				}
				// 调用函数
				objFun[type] && objFun[type](index)
			},
			clickImg(index) {
				console.log('--------index--------', index)
				this.images.map((item, ind) => {
					if (index != ind) {
						item.active = false
					} else {
						item.active = !item.active
					}
				})
			},
			ruzhu(index) {
				// this.dialogVisible = true
				this.clickImg(index)
				this.gzshow = !this.gzshow
				this.chatapp = false
				// this.ruzu()

				// console.log('this.dialogVisible', this.dialogVisible)

			},
			ruzhiBtn() {
				let isLogin = window.sessionStorage.getItem('token');
				if (!isLogin) {
					ElMessageBox.confirm(
						'当前未登录，是否登录。',
						'提示', {
							confirmButtonText: '去登录',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).then(() => {
						this.$router.push({
							path: 'login',
						})
					}).catch(() => {
						this.images[0].active = false
					})
				} else {
					this.$router.push({
						name: 'Settlein',
						query: {
							name: '这是入驻页面',
						}
					})
				}
			},
			tuiguang(index) {
				this.clickImg(index)
				this.tkshow = !this.tkshow
				this.chatapp = false
			},
			kefu(index) {
				this.clickImg(index)
				this.chatapp = !this.chatapp
				this.tkshow = false
			},
			top(index) {
				this.clickImg(index)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 0) {
					const timeTop = setInterval(() => {
							document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50;
							if (scrollTop <= 0) {
								clearInterval(timeTop);
							}
						},
						10);
				}
			},
			weidu() {
				let isLogin = window.sessionStorage.getItem('token');
				this.$http.post('index/chatroom/weidu_num', {
					token: isLogin,
					rand_num: localStorage.getItem('randNum')
				}).then(res => {
					if (res.code == 1) {
						this.value = res.data
					} else {
						this.value = 0
					}
				})
			},
			// 获取入驻规则
			ruzu() {
				this.$http.get('/index/common/sys_common?type_id=246').then(res => {

					this.fuwutioakuamg = res.data.data.value
					
				});
			},
		},
		mounted() {
			let chatid = window.sessionStorage.getItem('chat_id')
			console.log('chatid---', chatid)
			let randNum = Math.floor(Math.random() * 10) + 1 + '' + new Date().getTime()
			let is_rand = localStorage.getItem('randNum');
			if (!is_rand) {
				localStorage.setItem('randNum', randNum);
			}
			if (chatid) {
				this.weidu()
			}
		}
	}
</script>

<style scoped lang="less">
	.affixbutton {
		width: 90px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
		border-radius: 14px 0px 0px 14px;
		position: fixed;
		bottom: 420px;
		right: 0px;
		z-index: 999;

		.content {
			text-align: center;
			margin-top: 10px;

			.img {
				margin-bottom: 10px;

				img {
					width: 45px;
					height: 45px;
				}
			}

			.text {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}

	}

	.affixbutton1 {
		width: 90px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
		border-radius: 14px 0px 0px 14px;
		position: fixed;
		bottom: 295px;
		right: 0px;
		z-index: 999;
		text-align: center;

		.content {
			text-align: center;
			margin-top: 10px;

			.img {
				margin-bottom: 10px;

				img {
					width: 45px;
					height: 45px;
				}
			}

			.text {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}
	}

	.affixbutton2 {
		width: 90px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
		border-radius: 14px 0px 0px 14px;
		position: fixed;
		bottom: 170px;
		right: 0px;
		z-index: 999;
		text-align: center;

		.content {
			text-align: center;
			margin-top: 10px;

			.img {
				margin-bottom: 10px;

				img {
					width: 45px;
					height: 45px;
				}
			}

			.text {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}
	}

	.affixbutton3 {
		width: 90px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
		border-radius: 14px 0px 0px 14px;
		position: fixed;
		bottom: 40px;
		right: 0px;
		z-index: 999;
		text-align: center;

		.content {
			text-align: center;
			margin-top: 10px;

			.img {
				margin-bottom: 10px;

				img {
					width: 45px;
					height: 45px;
				}
			}

			.text {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 2px;
			}
		}
	}

	.item {}
</style>