<template>
  <div class="content">
    <div class="distribution">

      <!-- 上部分 -->
      <div class="distribution_top">
        <div class="distribution_top1">
          <div class="distribution_top1_left">
            <img src="../../assets/my/shouyi.png" alt="">
          </div>
          <div class="distribution_top1_right">
            <div class="money">
              <div style="margin-top: -5px;">
                <span class="jinqian">{{invitation_money}}</span>
                <span class="jinqiantext">元</span>
              </div>
              <div class="text" style="margin-top: 11px;">今日收益</div>
            </div>
          </div>
        </div>
        <div class="distribution_top2">
          <div class="distribution_top2_left">
            <img src="../../assets/my/tuiguang.png" alt="">
          </div>
          <div class="distribution_top2_right">
            <div class="money">
              <div style="margin-top: -5px;">
                <span class="jinqian">{{invitation_num}}</span>
                <span class="jinqiantext">人</span>
              </div>
              <div class="text" style="margin-top: 11px;">今日推广人数</div>
            </div>
          </div>
        </div>
        <div class="distribution_top3">
          <div class="distribution_top3_left">
            <img src="../../assets/my/yue.png" alt="">
          </div>
          <div class="distribution_top3_right">
            <div class="money">
              <div style="margin-top: -5px;">
                <span class="jinqian">{{user_money}}</span>
                <span class="jinqiantext">元</span>
              </div>
              <div class="text" style="margin-top:11px;">账户余额</div>
            </div>
          </div>
        </div>
      </div>
      <div class="balance_bottom">
        <div class="balance_bottomtitle">
          <div style="float: left; display: flex;font-size: 16px;font-family: Source Han Sans CN;color: #999999; ">
            <div style="margin-right:29px;">
              <div :class="current==1?'select':''" @click="confirm(1)">明细</div>
              <div :class="current==1?'hengxian':''"></div>
            </div>
          </div>
        </div>
        <div class="balance_bottomcon">
          <el-table :data="tableData" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
            <el-table-column prop="id" label="序号">
            </el-table-column>
            <el-table-column prop="lower_user_name" label="邀请用户昵称">
            </el-table-column>
            <el-table-column prop="commission_money" label="收益金额">
            </el-table-column>
            <el-table-column prop="create_at" label="邀请时间">
            </el-table-column>
          </el-table>


          <div class="pages">
            <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
              v-model:current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
          </div>
        </div>

      </div>
    </div>


  </div>

</template>

<script>
  export default {
    data() {
      return {
        invitation_money: '',
        invitation_num: '',
        user_money: '',
        tableData: [],
        current: 1,
        total: 6,
        pageSize: 6,
        currentPage: 1,
      }
    },
    methods: {
      confirm(e) {
        this.page = 1
        this.current = e;

      },
      handleSizeChange(val) {
        this.pageSize = val
        this.withdrawal_info()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.withdrawal_info()
      },
      //分销
      //提现记录
      async withdrawal_info() {
        const {
          data: res
        } = await this.$http.post('index/user/user_withdrawal_info')
        if (res.code == 1) {
          this.invitation_money = res.data.invitation_money
          this.invitation_num = res.data.invitation_money
          this.max_tixian_money = res.data.max_tixian_money
          this.min_tixian_money = res.data.min_tixian_money
          this.tixian_money = res.data.tixian_money
          this.user_money = res.data.user_money
          this.tableData = res.data.order_relation_list.data
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      },
    },
    created() {
      this.withdrawal_info()
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-table__empty-text {
    display: block;
    width: 200px;
    height: 371px;
    position: relative;
    padding-top: 148px;
    top: 80px;
    background: url('../../assets/dhpbjj.png') no-repeat;
    color: #909399;
    box-sizing: border-box;
  }

  /deep/.el-table tr {
    height: 50px;
  }

  /deep/.el-table__header th {
    height: 50px;
    background: #EEF0F6;
    border-radius: 0px 0px 0px 0px;
  }

  .select {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  .hengxian {
    width: 13px;
    height: 3px;
    background: #0D68FC;
    border-radius: 2px;
    position: absolute;
    top: 26px;
    left: 10px;
  }

  .content {
    width: 100%;
    min-height: 820px;
    background-color: #F6F9FF;

    .distribution {
      width: calc(100% - 20px);
      min-height: 790px;
      background: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 35px;

      .distribution_top {
        width: 100%;
        height: 165px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        padding-top: 31px;

        .distribution_top1 {
          width: 504px;
          height: 164px;
          background: #EDE9FF;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .distribution_top1_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 43%;
              right: 8px;
            }
          }

          .distribution_top1_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              position: absolute;
              top: 48%;
              left: 3px;

              .jinqian {
                font-size: 34px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #8461F0;
                line-height: 2px;
              }

              .jinqiantext {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #8461F0;
                line-height: 2px;
              }

              .text {
                margin-top: 5px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #8461F0;
                line-height: 2px;
              }
            }
          }
        }

        .distribution_top2 {
          width: 504px;
          height: 164px;
          background: #E8F1FF;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .distribution_top2_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 43%;
              right: 8px;
            }
          }

          .distribution_top2_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              position: absolute;
              top: 48%;
              left: 3px;

              .jinqian {
                font-size: 34px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #0D68FC;
                line-height: 2px;
              }

              .jinqiantext {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #0D68FC;
                line-height: 2px;
              }

              .text {
                margin-top: 5px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #0D68FC;
                line-height: 2px;
              }
            }
          }
        }

        .distribution_top3 {
          width: 504px;
          height: 164px;
          background: #E8FFFD;
          border-radius: 12px;
          display: flex;
          flex-wrap: nowrap;

          .distribution_top3_left {
            width: 50%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 43%;
              right: 8px;
            }
          }

          .distribution_top3_right {
            width: 50%;
            height: 100%;
            position: relative;

            .money {
              position: absolute;
              top: 48%;
              left: 3px;

              .jinqian {
                font-size: 34px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #42C1B5;
                line-height: 2px;
              }

              .jinqiantext {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #42C1B5;
                line-height: 2px;
              }

              .text {
                margin-top: 5px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #42C1B5;
                line-height: 2px;
              }
            }
          }
        }
      }
    }
  }

  .balance_bottom {
    width: calc(100% - 20px);
    min-height: 523px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 30px;

    .balance_bottomtitle {
      width: 100%;
      height: 30px;
      position: relative;
      left: 50px;
      top: 52px;

      .budeg {
        position: absolute;
        top: 39px;
        left: 32px;
        height: 13px;
        border: 3px solid #0D68FC;
      }

      .budegtitle {
        position: absolute;
        top: 48px;
        left: 52px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 900;
        color: #333333;
        line-height: 2px;
      }

      .authot_score {
        margin-top: -2px;
        margin-right: 133px;

        .Searchqq {
          width: 155px;
          height: 30px;
          border: 1px solid #2469FF;
          opacity: 0.3;
          margin: 0;
          padding: 0;
          padding-left: 15px;
          border-radius: 4px 0px 0px 4px;

        }

        .button1 {
          position: absolute;
          top: -2px;
          width: 32px;
          height: 31.6px;
          background: #0D68FC;
          border-radius: 0px 4px 4px 0px;
          border: none;

          img {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 23px;
            height: 23px;

          }
        }
      }
    }

    .balance_bottomcon {
      width: 94%;
      margin: 0 auto;
      margin-top: 69px;
      height: 368px;
    }
  }

  .pages {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>