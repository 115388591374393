<template>
  <div class='content'>
    <div class="coupon">
      <!-- 上半部分 -->
      <div class="coupon_toptitle">
        <span class="budeg"></span>
        <span class="budegtitle">优惠券</span>
        <span class="chongzhi" @click="conpon()">
          <img src="../../assets/my/coupon.png" alt="">
          <b class="tixian">优惠券使用规则</b>
        </span>
      </div>
      <!-- 下半部分 -->
      <div class="coupon_bottom">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane name="first">
            <template #label>
              <span>未使用({{num1}})</span>
            </template>
            <div class='weiused'>
              <div class="weiused_coupon" v-for="(item,indx) in weishiyong" :key="index">
                <div class="weiused_coupon_top">
                  <div class="guoqi" v-if="item.time==1">
                    <img src="../../assets/my/guoqiimg.png" alt="">
                  </div>
                  <div class="coupon_con">
                    <div style="padding-top: 38px;">
                      <span class="bugdeg">￥</span>
                      <span class='money'>{{item.less_money}}</span>
                    </div>
                    <div>
                      <span class="couponmoney">【满{{item.min_money}}可用】</span>
                    </div>
                    <div>
                      <span class="time">{{item.create_time}}-{{item.failure_time}}</span>
                    </div>
                  </div>
                </div>
                <div class="weiused_coupon_bottom">
                  <div class="bottom1">
                    适用品类:
                    <span v-if="item.goods_ids==''">省钱兄商城所有商品</span>
                    <span v-else>{{item.goods_ids}}</span>
                  </div>
                  <div class="bottom2">
                    适用平台:
                    <span v-if="item.type==1">全平台</span>
                    <span v-else>各别平台</span>
                  </div>
                  <div class="bottom3">
                    抵用券编号:{{item.user_id}}
                  </div>
                  <div class="bottom4" @click="shopdeletes(item)">
                    <button class="button"><span>立即使用</span></button>
                  </div>
                </div>
              </div>
              <el-empty description="暂无数据" v-if="!weishiyong.length" :image-size="200"
                style="margin: 0 auto;"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane name="senced">
            <template #label>
              <span>已使用({{num2}})</span>
            </template>
            <div class='yiused'>
              <div class="yiused_coupon" v-for="(item,indx) in weishiyong" :key="index">
                <div class="yiused_coupon_top">
                  <div class="coupon_con">
                    <div style="padding-top: 38px;">
                      <span class="bugdeg">￥</span>
                      <span class='money'>{{item.less_money}}</span>
                    </div>
                    <div>
                      <span class="couponmoney">【满{{item.min_money}}可用】</span>
                    </div>
                    <div>
                      <span class="time">{{item.create_time}}-{{item.failure_time}}</span>
                    </div>
                  </div>
                </div>
                <div class="yiused_coupon_bottom" style="position: relative;">
                  <div style="position: absolute; left: 14%;top: 20px;">
                    <img src="../../assets/my/yishiyong.png" alt="">
                  </div>
                  <div class="bottom1">
                    适用品类:
                    <span v-if="item.goods_ids==''">省钱兄商城所有商品</span>
                    <span v-else>{{item.goods_ids}}</span>
                  </div>
                  <div class="bottom2">
                    适用平台:
                    <span v-if="item.type==1">全平台</span>
                    <span v-else>各别平台</span>
                  </div>
                  <div class="bottom3">
                    抵用券编号:{{item.user_id}}
                  </div>
                </div>
              </div>
              <el-empty description="暂无数据" v-if="!weishiyong.length" :image-size="200"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane name="three">
            <template #label>
              <span>已过期({{num3}})</span>
            </template>
            <div class='yiguoqi'>
              <div class="yiguoqi_coupon" v-for="(item,indx) in weishiyong" :key="index">
                <div class="yiguoqi_coupon_top">
                  <div class="coupon_con">
                    <div style="padding-top: 38px;">
                      <span class="bugdeg">￥</span>
                      <span class='money'>{{item.less_money}}</span>
                    </div>
                    <div>
                      <span class="couponmoney">【满{{item.min_money}}可用】</span>
                    </div>
                    <div>
                      <span class="time">{{item.create_time}}-{{item.failure_time}}</span>
                    </div>
                  </div>
                </div>
                <div class="yiguoqi_coupon_bottom" style="position: relative;">
                  <div style="position: absolute; left: 14%;top: 20px;">
                    <img src="../../assets/my/yishiyong.png" alt="">
                  </div>
                  <div class="bottom1">
                    适用品类:
                    <span v-if="item.goods_ids==''">省钱兄商城所有商品</span>
                    <span v-else>{{item.goods_ids}}</span>
                  </div>
                  <div class="bottom2">
                    适用平台:
                    <span v-if="item.type==1">全平台</span>
                    <span v-else>各别平台</span>
                  </div>
                  <div class="bottom3">
                    抵用券编号:{{item.user_id}}
                  </div>
                </div>
              </div>
            </div>
            <el-empty description="暂无数据" v-if="!weishiyong.length" :image-size="200"></el-empty>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog v-model="dialogVisible" title="优惠券使用规则" center width="30%" :before-close="handleClose">
        <div> {{youhiujuan}} </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">我知道了</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        time: '',
        activeName: 'first',
        num1: 0,
        num2: 0,
        num3: 0,
        weishiyong: [],
        yishiyong: [],
        yiguoqi: [],
        dialogVisible: false,
        status: 1,
        youhiujuan:'',
      }
    },
    methods: {
      handleClick(tab, event) {
        if (tab.props.name == 'first') {
          this.status = 1
          this.dataSelect1()
        }

        if (tab.props.name == 'senced') {
          this.status = 2
          this.dataSelect1()
        }
        if (tab.props.name == 'three') {
          this.status = 3
          this.dataSelect1()
        }
      },
      async dataSelect1() {
		  this.weishiyong = []
        const {
          data: res
        } = await this.$http.post('index/coupon/my_coupon', {
          status: this.status,
          num: 10,
          page: 1
        })
        if (res.code == 1) {
			
          //未使用
          this.weishiyong = res.data.coupon_list.data
          this.num1 = res.data.count_num.not_used_num
          for (let i = 0; i < this.weishiyong.length; i++) {
            if (this.weishiyong.create_time < this.weishiyong.failure_time) {
              this.weishiyong[i].time = 0
            } else {
              this.weishiyong[i].time = 1
            }
          }
          //已使用
          // this.yishiyong = res.data.coupon_list.data
          this.num2 = res.data.count_num.is_used_num
          //已过期
          // this.yiguoqi = res.data.coupon_list.data
          this.num3 = res.data.count_num.is_expired_num
        } else {
          this.$notify.info({
            title: "消息提示",
            message: res.msg,
            duration: 2500,
            offset: 50
          });
          this.$router.push({
            name: 'login',
          })
        }
      },
      shopdeletes(row) {
        this.$confirm(`确定使用这张优惠券?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          window.localStorage.setItem('youhuijuna', row.less_money)
          window.localStorage.setItem('youhuijunaid', row.coupon_user_id)
          this.$router.push({
            name: 'empower',
          })
        })

      },
      conpon() {
		  this.conponlist()
         this.dialogVisible = true
        
      },
      conponlist(){
        this.$http.get('/index/common/sys_common?type_id=248').then(res => {
          // if(res.code==1){
			   this.youhiujuan = res.data.data.value
			   console.log(res.data, this.youhiujuan)
			  
		  // }
           
          
        });
      },
    },
    mounted() {
      this.dataSelect1()
    }
  }
</script>

<style scoped lang="less">
  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .coupon {
      width: calc(100% - 20px);
      min-height: 680px;
      background: #FFFFFF;
      border-radius: 20px;

      .coupon_toptitle {
        width: 100%;
        height: 30px;
        position: relative;
        top: 50px;
        left: 16px;
        display: flex;

        .budeg {
          position: absolute;
          top: 13px;
          left: 38px;
          height: 15px;
          width: 4px;
          border-radius: 2px;
          background-color: #0D68FC;
        }

        .budegtitle {
          position: absolute;
          top: 20px;
          left: 52px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 900;
          color: #333333;
          line-height: 2px;
        }

        .chongzhi {
          cursor: pointer;

          img {
            position: absolute;
            top: 14px;
            left: 109px;
            width: 13px;
            height: 14px;
          }

          .tixian {
            position: absolute;
            top: 11px;
            left: 125px;
            height: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
          }
        }
      }

      .coupon_bottom {
        width: 94%;
        margin: 72px auto;

        .weiused {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .weiused_coupon {
            width: 213px;
            height: auto;
            margin-top: 20px;
            margin-left: 10px;

            .weiused_coupon_top {
              width: 211px;
              height: 120px;
              background-image: url('../../assets/my/couponimg.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;

              .guoqi {
                position: absolute;
                right: 0px;
                top: -2px;
              }

              .coupon_con {
                width: 100%;
                height: 100%;
                text-align: center;

                .bugdeg {
                  font-size: 15px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .money {
                  font-size: 37px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .couponmoney {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .time {
                  font-size: 11px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }
              }
            }

            .weiused_coupon_bottom {
              width: 211px;
              height: 165px;
              background: #F2F6FF;

              .bottom1 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom2 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom3 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom4 {
                width: 100%;
                height: auto;
                text-align: center;

                .button {
                  width: 106px;
                  height: 29px;
                  border: 1px solid #2469FF;
                  border-radius: 14px;

                  span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #2469FF;
                    line-height: 2px;
                  }
                }
              }
            }
          }
        }

        .yiused {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .yiused_coupon {
            width: 213px;
            height: auto;
            margin-top: 20px;
            margin-left: 10px;

            .yiused_coupon_top {
              width: 211px;
              height: 120px;
              background-image: url('../../assets/my/couponimig1.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;

              .guoqi {
                position: absolute;
                right: 0px;
                top: -2px;
              }

              .coupon_con {
                width: 100%;
                height: 100%;
                text-align: center;

                .bugdeg {
                  font-size: 15px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .money {
                  font-size: 37px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .couponmoney {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .time {
                  font-size: 11px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }
              }
            }

            .yiused_coupon_bottom {
              width: 211px;
              height: 165px;
              background: #FAFAFA;

              .bottom1 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom2 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom3 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }


            }
          }
        }

        .yiguoqi {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .yiguoqi_coupon {
            width: 213px;
            height: auto;
            margin-top: 20px;
            margin-left: 10px;

            .yiguoqi_coupon_top {
              width: 211px;
              height: 120px;
              background-image: url('../../assets/my/couponimig1.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;

              .guoqi {
                position: absolute;
                right: 0px;
                top: -2px;
              }

              .coupon_con {
                width: 100%;
                height: 100%;
                text-align: center;

                .bugdeg {
                  font-size: 15px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .money {
                  font-size: 37px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .couponmoney {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }

                .time {
                  font-size: 11px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 2px;
                }
              }
            }

            .yiguoqi_coupon_bottom {
              width: 211px;
              height: 165px;
              background: #FAFAFA;

              .bottom1 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom2 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }

              .bottom3 {
                margin-left: 14px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
              }


            }
          }
        }
      }
    }
  }
</style>