<template>
  <!-- <div class="swiper-item-gg flex align-center">
    <img src="@/assets/index/gg.png" alt="">
    <div class="textBox">
      {{text.val}}
      <transition name="slide">
        <p class="text" :key="text.id" @mouseenter="mouseenter" @mouseleave="mouseleave">{{text.val}}1111</p>
      </transition>
    </div>
  </div> -->
  <div>
    <div class="textBox1">
    <img src="@/assets/index/gg.png" alt="" style="position: relative;bottom: 20px;">
    <el-carousel height="71px" indicator-position="none" arrow="never" autoplay :interval="5000" style="line-height:71px;display: inline-block;width:90%;margin-left:3%">
      <el-carousel-item v-for="(item,index) in textArr" :key="index" style="width:100%">
        <div class="small justify-center" text="2xl"  @mouseenter="mouseenter" @mouseleave="mouseleave">{{text.val}}</div>
      </el-carousel-item>
    </el-carousel>
  </div>
  </div>
  
</template>

<script>
  export default {
    data() {
      return {
        textArr: [],
        number: 0,
        timer: null,
        arr: []
      }
    },
    computed: {
      text() {
        return {
          id: this.number,
          val: this.textArr[this.number]
        }
      }
    },
    mounted() {
      this.startMove()
      this.bytexts()
    },
    methods: {
      mouseenter() {
        clearInterval(this.timer)
      },
      mouseleave() {
        this.startMove()
      },
      startMove() {
        this.timer = setTimeout(() => {
          if (this.number === 2) {
            this.number = 0;
          } else {
            this.number += 1;
          }
          this.startMove();
        }, 2000)
      },

      async bytexts() {
        const {
          data: res
        } = await this.$http.get('index/index/index_header')
        if (res.code == 1) {
          let wenzu = res.data.notice
          let tempArr = []
          wenzu.forEach(w => {
            tempArr.push(w.description)
            
          })
          this.textArr = tempArr
          
        }
      }
    }
  }
</script>

<style scoped lang="less">
.textBox1 {
    //   height: 71px;
    // line-height: 71px;
        position: relative;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85%;
    height: 70px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    padding: 0 50px;
    color: #0D68FC;
    font-size: 22px;
}
  .textBox {
    width: 100%;
    height: 71px;
    line-height: 71px;
    font-size: 16px;
    top: 1px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: left;
  }

  .text {
    position: absolute;
    bottom: 1px;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s linear;
  }

  .slide-enter {
    transform: translateY(20px) scale(1);
    opacity: 1;
  }

  .slide-leave-to {
    transform: translateY(-20px) scale(0.8);
    opacity: 0;
  }

  .swiper-item-gg {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85%;
    height: 70px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    padding: 0 50px;


    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    .scroll-container {
      width: 100%;
      line-height: 70px;
      display: flex;
      flex-wrap: nowrap;

      .container {
        width: 1046px;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
      }

      .inner {

        width: auto;
        color: #0D68FC;
        font-size: 22px;
        animation: move 10s linear infinite;
      }

      @keyframes move {
        0% {
          transform: translatex(0px);
        }

        100% {
          transform: translatex(-100%);
        }
      }
    }
  }
</style>