<template>
  <div class="content">
    <el-row class="headTop" :class="headbg?'':'headbg'">
      <el-col :span="pcleft">
        <div class="headleft">
          <div class="leftone" @click="goIndex">
            <div v-if="headbg">
              <img src="@/assets/logos.png" alt="" srcset="">
            </div>
            <div v-else>
              <img src="@/assets/logo.png" alt="" srcset="">
            </div>
            <div class="tiit" :style="headbg?'color:#0D68FC':'color:#FFFFFF'">移动端领域多端技术 <br />源码服务解决商</div>
          </div>
          <div class="lefttwo leftwoHover">
            <div  :style="headbg&&tabIndex==-1?'color:#0D68FC;cursor: pointer; font-size: 16px;margin-top: -4px;':'cursor: pointer; font-size: 16px;margin-top: -4px;'"     @click="TabChangee(item.id)"
              v-for="(item,index) in heard" :key="item.id">
              {{item.name}}
              <div :class="headbg?'linee linee2':'linee'"  v-if="tabIndexx==item.id"></div>
            </div>
            <div class="leftwo leftwoHover" v-for="(item,index) in tablist" @click="TabChange(index)" :key="index">
              <div :style="headbg&&tabIndex==index?'color:#0D68FC;margin-bottom: 5px;':'margin-bottom: 5px;'">{{item.name}}</div>
              <div :class="headbg?'line linee2': 'line'" v-if="tabIndex==index"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="pcright" style="margin-top: 10px;">
        
           <score></score>
        
       
      </el-col>
      <el-col :span="pcright" style="margin-top: 10px;">
        <div class="headright" >
          <!-- 小屏幕 全部 -->
          <el-dropdown trigger="click">
            <div class="all" v-if="headbg&&isvip">
              <img src="@/assets/index/allan.png" alt="" srcset="">
            </div>
            <div class="all" v-else>
              <img src="@/assets/index/all.png" alt="" srcset="">
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="gotoRouterr(5)">
                  <span>首页</span>
                </el-dropdown-item>
                <el-dropdown-item v-for="(item,index) in tablist" @click="gotoRouter(item)" :key="index">
                  {{item.name}}
                </el-dropdown-item>
                <el-dropdown-item @click="goVip" v-if="isShow"
                  style="font-size: 12px;">开通会员&nbsp;限时特惠</el-dropdown-item>
                <el-dropdown-item @click="logoutHandle()" v-if="this.isLoggedIn==true">退出登录</el-dropdown-item>
                <el-dropdown-item @click="gotoUser" v-if="isShow">个人中心</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <!-- <div class="btn butt" @click="gotoLogin" v-if="this.isLoggedIn==false"> 登录/注册</div>
          <div class="btn butt" @click="gotoLogin" v-if="this.isLoggedIn==true"> 退出登录</div> -->
          <div class="btn">
            <div class="flex align-center justify-center " v-if="token!=''" >
              <div class="vip" v-if="isvip" @click="goVip">
                <img src="../assets/index/cip.png" alt="" srcset="" style="width: 23px;margin-right: 5px;">
                <div class="" v-if="is_type_num==null||is_type_num==1" style="font-size: 12px;">
                  开通会员&nbsp;限时特惠</div>
                <div class="" v-if="is_type_num==2 ||is_type_num==3||is_type_num==4">
                  <span v-if="is_type_num==2">VIP</span>
                  <span v-if="is_type_num==3">超级VIP</span>
                  <span v-if="is_type_num==4">超级SVIP</span>
                </div>
              </div>
              <div class="flex align-center justify-center" style="margin-left: 30px;cursor: pointer;"
                @click="gotoUser">
                <img :src="avatr?avatr:avatr" alt="" srcset=""
                  style="width: 35px;height: 35px;border-radius: 50%;;margin-right: 5px;">
                <div>
                  <el-dropdown :placement='bottom'>
                    <span class="el-dropdown-link" style="white-space: nowrap;  margin-top: 6px; border: none;"
                      :style="headbg?'color:#0D68FC':'color:#FFFFFF'">
                      个人中心
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="join()">我的订单</el-dropdown-item>
                        <el-dropdown-item @click="join1()">个人资料</el-dropdown-item>
                        <el-dropdown-item @click="join2()">授权记录</el-dropdown-item>
                        <el-dropdown-item @click="logoutHandle()" v-if="this.isLoggedIn==true">退出登录</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
			<div v-else>
				<el-button type="primary" style="background: #0D68FC;border: #0D68FC;" @click="gotoLogin">登录/注册</el-button>
			</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import score from './score.vue'
  export default {
    components: {
      score,
    },
    name: 'haed',
    props: {
      msg: String,
    },
    data() {
      return {
        isLoggedIn: false, // 初始化时默认为未登录状态
        isShow: true,
        heard: [{
          name: '首页',
          id: 5
        }],
        tablist: ['首页', '开源产品', '演示中心', '开源文档', '授权查询', '源码下载'],
        tabIndex: -1,
        tabIndexx: 5,
        headbg: false,
        isvip: false,
        is_type_num: '',
        isScroll: false,
        isStyle: false,
        avatr: '',
        pcleft: 8,
        pcright: 8,
        isNoSwiper: true, //是否有轮播图
		token:'',
      }
    },
    mounted() {
      this.is_type_num = window.sessionStorage.getItem('is_vip_type')
      var token = window.sessionStorage.getItem('token')
      if (token == null || token == '') {
		  this.token = ''
        this.isLoggedIn = false
        this.isShow = true
      } else {
		  this.token = token
        this.isLoggedIn = true
        this.isShow = false
      }
	  if(window.sessionStorage.getItem('avatar')){
		  this.avatr = window.sessionStorage.getItem('avatar') ? window.sessionStorage.getItem('avatar') : require(
		    '../assets/heard.png')
	  }else{
		  this.avatr = '../assets/heard.png'
	  }
	  	  
      
      this.byhead()
      if (this.isScroll) {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
      } else {
        this.headbg = false
      }
      if (window.innerWidth > Number(1200)) {
        this.pcleft = 12
        this.pcright = 6
        this.isShow = false
      } else if (window.innerWidth >= Number(992) && window.innerWidth <= Number(1200)) {
        this.pcleft = 12
        this.pcright = 6
        this.isShow = false
      } else if (window.innerWidth >= Number(768) && window.innerWidth < Number(992)) {
        this.pcleft = 12
        this.pcright = 6
        this.isShow = true
      } else if (window.innerWidth >= Number(480) || window.innerWidth < Number(768)) {
        this.pcleft = 12
        this.pcright = 6
        this.isShow = true

      } else if (window.innerWidth < Number(480)) {
        this.pcleft = 12
        this.pcright = 6
        this.isShow = true
      }
    },
    watch: {
      '$route': { // $route可以用引号，也可以不用引号  监听的对象
        handler(to, from) {
          // console.log('路由变化了')
          // console.log('当前页面路由：' + JSON.stringify(to.name));
          // console.log('上一个路由：' + JSON.stringify(from));
          console.log(to.path, '333333')
		  
          let url = to.path + ''
          // console.log(url,'----',url.indexOf('/my') !== -1)
          // return
          if (url && url.indexOf('my') !== -1) {
            this.tabIndex = -1
            this.tabIndexx = -1
            this.isScroll = false
            this.headbg = true
            this.isvip = true
            this.isNoSwiper = false
          } else {
            this.isScroll = true
            this.headbg = false
            this.isvip = true
            this.isNoSwiper = true
          }
		  if(this.$route.query.indexNun){
			  
			 this.TabChange(JSON.parse(this.$route.query.indexNun))
		  }
        },
        deep: true, // 深度观察监听 设置为 true
        immediate: true, // 第一次初始化渲染就可以监听到
      },
    },
    methods: {
      join() {
        this.$router.push({
          path: '/my/order',
          query: {
            name: '/my/order',
          }
        })
      },
      join1() {
        this.$router.push({
          path: '/my/user',
          query: {
            name: '/my/user',
          }
        })
      },
      join2() {
        this.$router.push({
          path: '/my/Authorization',
          query: {
            name: '/my/Authorization',
          }
        })
      },
      logoutHandle() {
        this.$confirm(`确定进行[退出]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$cookie.set('token', '')
          this.$cookie.set('userid', '')
          this.$cookie.set('avatar', '')
          this.$cookie.set('is_vip_type', '')
          this.$cookie.set('phone', '')
          this.$cookie.set('gender', '')
          this.$cookie.set('username', '')
          this.$cookie.set('email', '')
          sessionStorage.clear()
          localStorage.clear()
          this.isLoggedIn = false;
          this.$router.push({
            name: 'login'
          })
        }).catch(() => {})
      },
      goVip() { //跳转会员页面
        let token = window.sessionStorage.getItem('token')
        if (token == '' || token == null) {
          this.$router.push({
            path: '/login',
          })
        } else {
          this.$router.push({
            path: '/hy',
          })
        }
      },
      //去首页
      goIndex() {
        this.tabIndexx = 5
        this.tabIndex = -2
        this.$router.push({
          path: '/',
        })
      },
      gotoLogin() {
        let token = window.sessionStorage.getItem('token')
        console.log(token)
        if (token == '' || token == null) {
          this.$router.push({
            path: '/login',
            query: {
              name: '这是登录页面',
            }
          })
        } else {
          this.logoutHandle()
        }
      },
      gotoUser() {
        let token = window.sessionStorage.getItem('token')
        console.log(token)
        if (token == '' || token == null) {
          this.$confirm(`请先进行【登录】操作?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              name: 'login'
            })
          }).catch(() => {})
        } else {
          this.$router.push({
            path: '/my/user',
            query: {
              name: '/my/user',
            }
          })
        }
      },
      // 获取页面滚动距离
      handleScroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (this.isNoSwiper == false) {
          this.headbg = true
        } else {
          if (scrollTop != 0) {
            this.headbg = true
          } else {
            this.headbg = false
          }
        }
      },
      TabChangee(index) {
        this.tabIndexx = index
        this.tabIndex = -1
        switch (index) {
          case 5:
            this.$router.push({
              path: '/',
            })
            break;
        }
      },
      TabChange(index) {
        this.tabIndexx = 0
        this.tabIndex = index
		console.log('-------------',this.tabIndexx,'----index',index)
        switch (index) {
          case 0:
            this.$router.push({
              path: '/products',
            })
            break;
          case 1:
            this.$router.push({
              path: '/demoCenter',
            })
            break;
          case 2:
            this.$router.push({
              path: '/kiayuandocumentation',
            })
            break;
          case 3:
            this.$router.push({
              path: '/authorizationquery',
            })
            break;
          case 4:
            this.$router.push({
              path: '/yumaxiazai',
            })
            break;
        }
      },
      gotoRouterr(item) {
        if (item = 5) {
          this.$router.push({
            path: '/',
          })
        }
      },
      gotoRouter(item) {
        console.log(item)
        if (item.id == 7) {
          this.$router.push({
            path: '/products',
          })
        } else if (item.id == 8) {
          this.$router.push({
            path: '/demoCenter',
          })
        } else if (item.id == 9) {
          this.$router.push({
            path: '/kiayuandocumentation',
          })
        } else if (item.id == 10) {
          this.$router.push({
            path: '/authorizationquery',
          })
        } else if (item.id == 11) {
          this.$router.push({
            path: '/yumaxiazai',
          })
        }
      },
      //头部组件
      async byhead() {
        const {
          data: res
        } = await this.$http.get('index/index/index_header')
        if (res.code == 1) {
          this.tablist = res.data.top_navigation
        }
      }
    },
  }
</script>
<style lang="less" scoped>
  .linee {
    width: 20px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px auto;
    // margin-top: 5px;
  }
  

  :deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
  }

  .content {
    width: 100vw;
    background: #EFF7FF;
  }

  .headTop {
    width: 100%;
    height: 96px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 26px 88px;
    // color: #333333;
    background: #FFFFFF;
  }

  .headbg {
    background: rgba(225, 225, 225, 0.0);
    color: #FFFFFF;
  }

  .butt {
    width: 100px;
    height: 36px;
    background: #0D68FC;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 30px;

    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }

  /*当页面大于 1200px 时，大屏幕，主要是 PC 端*/
  @media (min-width: 1200px) {
    .all {
      display: none !important;
    }

    .btn {
      display: block !important;

    }
  }

  /*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .all {
      display: block !important;
    }
	
    .lefttwo {
      display: none !important;
    }

    .btn {
      display: block !important;
    }

  }

  /*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
  @media (min-width: 768px) and (max-width: 991px) {
    .all {
      display: block !important;
    }

    .lefttwo {
      display: none !important;
    }

    .btn {
      display: block !important;
    }
  }

  /*在 480 和 767 像素之间的屏幕里，超小屏幕，主要是手机*/
  @media (min-width: 480px) and (max-width: 767px) {
    .all {
      display: block !important;
    }

    .lefttwo {
      display: none !important;
    }

    .btn {
      display: none !important;
    }

  }

  /*在小于 480 像素的屏幕，微小屏幕，更低分辨率的手机*/
  @media (max-width: 479px) {
    .headTop {
      height: 70px;
      padding: 20px 30px !important;
    }

    .all {
      display: block !important;
    }

    .lefttwo {
      // display: none !important;
    }

    .btn {
      display: none !important;

    }

  }

  .headleft {
    display: flex;
    align-items: center;


    .leftone {
      cursor: pointer;

      img {
        width: 114px;
        height: 18px;
      }

      .tiit {
        font-size: 12px;
        margin-left: 0px;
        text-align: center;
      }
    }
  }

  .lefttwo {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .leftwo {
      margin-left: 40px;
      font-size: 16px;
      cursor: pointer;

      .line {
        width: 20px;
        height: 3px;
        background: #FFFFFF;
        border-radius: 2px;
        margin: -2px auto;
      }
	  .linee2{
	  	  background: #0D68FC;
	  }

    }
  }

  .headright {
    display: flex;
    align-items: center;
    justify-content: end;

    .butt {
      width: 100px;
      height: 36px;
      background: #0D68FC;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-left: 30px;
    }

    .all {
      display: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

  }

  .vip {
    width: 150px;
    height: 36px;
    background: linear-gradient(256deg, #FFE8C6 0%, #FBEEDA 100%);
    border-radius: 5px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #8C4C26;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 30px;
  }
  .linee2{
  	  background: #0D68FC;
  }
</style>