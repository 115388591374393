import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routerHistory = createWebHistory()

// 引入页面
import index from './views/index/index.vue'
import login from './views/login/login.vue'
import my from './views/my/index.vue'
import hy from './views/my/vip.vue'
import payment from '@/views/my/payment.vue' //支付成功
import products from "./views/sourceproducts/sourceproducts.vue" //开源产品
import demoCenter from "./views/demoCenter/demoCenter.vue" //演示中心
import kiayuandocumentation from "./views/kaiyuanwendang/kiayuandocumentation.vue" //开源文档
import yumaxiazai from "./views/yuanmaxiazai/yumaxiazai.vue" //源码下载
import Settlein from "./components/Settlein.vue" //推广页面
import authorizationquery from "./views/shouquanchaxun/authorizationquery.vue" //授权查询
import chatindex from "./components/chatindex.vue" //客服
// 导入 my 子路由
import Authorization from '@/views/my/Authorization.vue' //产品授权
import empower from '@/views/my/empower.vue' //购买授权
import order from '@/views/my/order.vue' //我的订单
import OrderDetails from '@/views/my/OrderDetails.vue' // 订单详情
import mymoney from '@/views/my/mymoney.vue' //我的订单
import coupon from '@/views/my/coupon.vue' //我的优惠卷
import distribution from '@/views/my/distribution.vue' //分销管理
import team from '@/views/my/team.vue' //分销管理
import user from '@/views/my/user.vue' //user用户信息
import invoice from '@/views/my/invoice.vue' //invoice发票管
import Invoicing from '@/views/my/Invoicing.vue' //Invoicing开票记录

//导入开源产品子路由
import flashKill from "./views/sourceproducts/flashKill.vue" //秒杀专区
import paidan from "./views/sourceproducts/paidan.vue" //派单专区
import dispatchdetails from "./views/sourceproducts/dispatchdetails.vue" //派单商品详情
import ProductIntroduction from "./views/sourceproducts/ProductIntroduction.vue" //产品介绍
import skillProductIntroduction from "./views/sourceproducts/skillProductIntroduction.vue" //秒杀产品介绍
import moreshop from "./views/sourceproducts/moreshop.vue" //商品铺
//导入开源文档子路由
import Vipfree from "./views/kaiyuanwendang/vipfree.vue" //非会员页面
import vipmoney from './views/kaiyuanwendang/vip.vue' //充值页面
//导入源码下载子路由
import freevip from "./views/yuanmaxiazai/freevip.vue" //非会员页面
import vipchongzhi from './views/yuanmaxiazai/vip.vue' //充值页面
import kaiyaunxiangqing from './views/yuanmaxiazai/kaiyaunxiangqing.vue' //商品详情

import payGateWay from './components/payGateWay.vue' //payGateWay
//定义路由
const routes = [{
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  }, {
    path: '/my',
    name: 'my',
    redirect: '/my/Authorization',
    component: () => import("@/views/my/index.vue"),
    children: [{
        path: 'Authorization',
        name: 'Authorization',
        component: () => import("@/views/my/Authorization.vue")
      }, {
        path: 'empower',
        name: 'empower',
        component: () => import("@/views/my/empower.vue")
      }, {
        path: 'order',
        name: 'order',
        component: () => import("@/views/my/order.vue")
      },
      {
        path: 'mymoney',
        name: 'mymoney',
        component: () => import("@/views/my/mymoney.vue")
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import("@/views/my/coupon.vue")
      },
      {
        path: 'distribution',
        name: 'distribution',
        component: () => import("@/views/my/distribution.vue")
      },
      {
        path: 'team',
        name: 'team',
        component: () => import("@/views/my/team.vue")
      },
      {
        path: 'user',
        name: 'user',
        component: () => import("@/views/my/user.vue")
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: () => import("@/views/my/invoice.vue")
      },
      {
        path: 'Invoicing',
        name: 'Invoicing',
        component: () => import("@/views/my/Invoicing.vue")
      },
      {
        path: 'OrderDetails',
        name: 'OrderDetails',
        component: () => import("@/views/my/OrderDetails.vue")
      },
      //payGateWay

    ]
  }, {
    path: '/hy',
    name: 'hy',
    component: hy
  }, {
    path: '/payment',
    name: 'payment',
    component: payment
  },
  //开源产品
  {
    path: '/products',
    name: 'products',
    component: products,
    meta: {
      showfater: true //隐藏内容
    },
    children: [{
        path: 'flashKill',
        name: 'flashKill',
        component: flashKill,
        meta: {
          showfater: false
        }
      }, {
        path: 'paidan',
        name: 'paidan',
        component: paidan,
        meta: {
          showfater: false
        },
      },
      //派单池详情页面
      {
        path: 'dispatchdetails',
        name: 'dispatchdetails',
        component: dispatchdetails,
        meta: {
          showfater: false
        },
      },
      //产品介绍页面
      {
        path: 'ProductIntroduction',
        name: 'ProductIntroduction',
        component: ProductIntroduction,
        meta: {
          showfater: false
        },
      },
      {
        path: 'skillProductIntroduction',
        name: 'skillProductIntroduction',
        component: skillProductIntroduction,
        meta: {
          showfater: false
        },
      },
      {
        path: 'moreshop',
        name: 'moreshop',
        component: moreshop,
        meta: {
          showfater: false
        },
      }
    ]
  },
  //演示中心
  {
    path: '/demoCenter',
    name: 'demoCenter',
    component: demoCenter
  },
  //开源文档
  {
    path: '/kiayuandocumentation',
    name: 'kiayuandocumentation',
    component: kiayuandocumentation,
    meta: {
      showfater: true //隐藏内容
    },
    children: [{
      path: '/Vipfree',
      name: 'Vipfree',
      component: Vipfree,
      meta: {
        showfater: false //隐藏内容
      },
    }, {
      path: '/vipmoney',
      name: 'vipmoney',
      component: vipmoney,
      meta: {
        showfater: false //隐藏内容
      },
    }]
  },
  //源码下载
  {
    path: '/yumaxiazai',
    name: 'yumaxiazai',
    component: yumaxiazai,
    meta: {
      showfater: true //隐藏内容
    },
    children: [{
        path: '/freevip',
        name: 'freevip',
        component: freevip,
        meta: {
          showfater: false //隐藏内容
        }
      },
      {
        path: '/vipchongzhi',
        name: 'vipchongzhi',
        component: vipchongzhi,
        meta: {
          showfater: false //隐藏内容
        },
      },
      {
        path: '/kaiyaunxiangqing',
        name: 'kaiyaunxiangqing',
        component: kaiyaunxiangqing,
        meta: {
          showfater: false //隐藏内容
        },
      },
    ]
  },
  //授权查询
  {
    path: '/authorizationquery',
    name: 'authorizationquery',
    component: authorizationquery,
    meta: {
      showfater: true //隐藏内容
    },
  },
  //入驻页面
  {
    path: '/Settlein',
    name: 'Settlein',
    component: Settlein
  },
  {
    path: '/chatindex',
    name: 'chatindex',
    component: chatindex
  },
  {
    path: '/payGateWay',
    name: 'payGateWay',
    component: payGateWay
  },
]

// 创建路由器
const router = createRouter({
  history: routerHistory,
  routes: routes,
  //返回顶部
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition
    } else {
      return {
        left: 0,
        top: 0
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next();
  } else {
    let token = window.sessionStorage.getItem('token')
    console.log(token, 'tokentokentoken')
    if (token == null || token == '') {
      next();
    } else {
      next();
    }
  }
});

export default router