<template>
  <div class="content">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px" indicator-position="none" arrow="never">
        <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
        <div class="swiper-item">
          <!-- 轮播图 -->
          <img src="../../assets/kaiyuanchanping/chanpinjieshao/banner.png" alt="">
          <!-- 标题 -->
          <div class="killtitle">
            <div class="killtitle_left">
              <img :src="products.image" alt="">
            </div>
            <div class="killtitle_right">
              {{products.title}}
              <div class="killtitle1">
                <span>结合APP数据完美同步跳转官方小程序，自购分享更方便</span>
                <div class="hx"></div>
                <div class="butt">
                  <div class="button1" @click="buyzixun()">
                    <span>购买咨询</span>
                  </div>
                  <div class="button2" @click="lijibuy()">
                    <span>立即购买</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </el-carousel-item> -->
      </el-carousel>
    </div>
    <!-- 主体内容 -->
    <div class="product">
      <div class="product_content">
        <!-- table 切换 -->
        <div class="product_lable">
          <div class="product_lablechange">
            <ul>
              <li @click="but" data-id="1">产品介绍</li>
              <li @click="but" data-id="2" class="chanpin">产品中心
                <div class="hovertab">
                  <!-- v-if="hovershow" -->
                  <div class="page flex-col">
                    <div class="section_1 flex-col">
                      <div class="section_2 flex-row justify-between">
                        <div class="block_1 flex-row" @click="bianse1()" :style="{ backgroundColor: backgroundColor }">
                          <img class="label_3" referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/55d2ca89f6d28c6db3e20f4bec08f9d0" />
                          <div class="text-wrapper_3 flex-col justify-between">
                            <span class="text_1" :style="{ color: textColor}">APP端</span>
                            <span class="text_2" :style="{ color: textColor}">AI智能问答工具App</span>
                          </div>
                        </div>
                        <div class="block_3 flex-row" @click="bianse2()" :style="{ backgroundColor: backgroundColor2 }">
                          <img class="label_4" referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/acc4fadb7915a22de151a0d949ac33ee" />
                          <div class="text-wrapper_4 flex-col justify-between">
                            <span class="text_3" :style="{ color: textColor2}">小程序版</span>
                            <span class="text_4" :style="{ color: textColor2}">打造专属的小程序</span>
                          </div>
                        </div>
                      </div>
                      <div class="section_3 flex-row">
                        <div class="box_3 flex-row" @click="bianse3()" :style="{ backgroundColor: backgroundColor3 }">
                          <img class="label_5" referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/0da6a93333f5ce9a3c192af3088ac102" />
                          <div class="text-group_2 flex-col justify-between">
                            <span class="text_5" :style="{ color: textColor3}">公众号版</span>
                            <span class="text_6" :style="{ color: textColor3}">微信公众号</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li @click="but" data-id="3">特色功能</li>
              <li @click="but" data-id="4">客户案例</li>
            </ul>
          </div>
          <div class="product_lablecontent">
            <div style="margin-top: 10px;"></div>
            <div v-show="tab==1" class="tapS product_con1" v-if="corecom_list">
              <!--自营一站式解决方案  -->
              <div class="product_lablecontentheard">
                <div class="product_lablecontentheard_one">
                  {{corecom_listtitleone}}
                </div>
                <div class="product_lablecontentheard_two">
                  {{ corecom_listsubtitleone}}
                </div>
              </div>
              <div class="productjux">
                <ul>
                  <li @click="butt(1)" data-id="1" :class="{'bg-blue': isBlue,'text':istop}">
                    <span class="numm">01</span><span class="text">{{tabnameone}}</span>
                  </li>
                  <li @click="butt(2)" data-id="2" :class="{'bg-blue': isBlue1,'text':istop1}">
                    <span class="numm">02</span><span class="text">{{tabnametwo}}</span>
                  </li>
                  <li @click="butt(3)" data-id="3" :class="{'bg-blue': isBlue2,'text':istop2}">
                    <span class="numm">03</span><span class="text">{{tabnamethree}}</span>
                  </li>
                </ul>
              </div>
              <div class="productjuxchange">
                <div v-show="tabb==1">
                  <div class="productjuxchange1">
                    <div class="productjuxchange1_text">
                      <span>
                        {{zhinengquestion.content}}
                      </span>
                    </div>
                    <div class="productjuxchange1_img">
                      <div class="img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/phone.png">
                      </div>
                      <div class="phone1">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">

                          语义精准理解
                        </div>
                      </div>
                      <div class="phone2">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          智能扩写相似问题
                        </div>
                      </div>
                      <div class="phone3">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          依据现有知识问答
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="tabb==2" class="productjuxchange2">
                  <div class="productjuxchange1">
                    <div class="productjuxchange1_text">
                      <span>
                        {{zhinengquestion1.content}}
                      </span>
                    </div>
                    <div class="productjuxchange1_img">
                      <div class="img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/phone.png">
                      </div>
                      <div class="phone1">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          可以选择好友聊天
                        </div>
                      </div>
                      <div class="phone2">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          可以选择不同语种聊天方式
                        </div>
                      </div>
                      <div class="phone3">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          解决您所遇到的问题
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="tabb==3" class="productjuxchange3">
                  <div class="productjuxchange1">
                    <div class="productjuxchange1_text">
                      <span>
                        {{zhinengquestion2.content}}
                      </span>
                    </div>
                    <div class="productjuxchange1_img">
                      <div class="img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/phone.png">
                      </div>
                      <div class="phone1">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          分层的团队架构
                        </div>
                      </div>
                      <div class="phone2">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          属于自己的分销团队
                        </div>
                      </div>
                      <div class="phone3">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
                        <div class="phone1_img">
                          <img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png" alt="">
                        </div>
                        <div class="phone1_text">
                          随时随地提取自己的佣金
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 产品营销距库 -->
              <div class="yingxiao">
                <div class="product_lablecontentheard">
                  <div class="product_lablecontentheard_one">
                    {{corecom_listtitletwo}}

                  </div>
                  <div class="product_lablecontentheard_two">
                    {{ corecom_listsubtitletwo}}
                  </div>
                </div>
                <div class="yingxiao_content">
                  <div class="yingxiao_contenttop">
                    <div class="yingxiao_content1">
                      <img src="../../assets/kaiyuanchanping/chanpinjieshao/img1.png" alt="">
                    </div>
                    <div class="yingxiao_content2">
                      <img src="../../assets/kaiyuanchanping/chanpinjieshao/img2.png" alt="">
                    </div>
                    <div class="yingxiao_content3">
                      <img src="../../assets/kaiyuanchanping/chanpinjieshao/img3.png" alt="">
                    </div>
                    <div class="yingxiao_content4">
                      <img src="../../assets/kaiyuanchanping/chanpinjieshao/img4.png" alt="">
                    </div>
                  </div>
                  <span class="line"></span>
                  <div class="yingxiao_contentbottoma">
                    <div class="yingxiao_content1" v-for="(item, index) in  xingxiaoku ">
                      <div class="radius"></div>
                      <div class="text">{{item.name}}</div>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 助您获益的六大核心能力 -->
              <div class="nengli">
                <div class="product_lablecontentheard">
                  <div class="product_lablecontentheard_one">
                    {{corecom_listtitlethree}}

                  </div>
                  <div class="product_lablecontentheard_two">
                    {{ corecom_listsubtitlethree}}
                  </div>
                </div>
                <div class="nengli_cotent">
                  <el-tabs v-model="activeName" class="demo-tabs" stretch>
                    <div v-for="(item,indx) in heixin" :key="index">
                      <el-tab-pane :label="item.name" :name="item.id" class="demo-tabs_first">
                        <div class="demo-tabs_first1">
                          <img :src="item.image" alt="">
                        </div>
                        <div class="demo-tabs_first2">
                          <div class="title_demo1">
                            {{item.name}}
                          </div>
                          <div class="title_demo2">
                            {{item.content}}
                          </div>
                        </div>
                      </el-tab-pane>
                    </div>
                  </el-tabs>
                </div>
              </div>
              <!-- 高效稳定的系统技术部署 -->
              <div class="xitongbushu">
                <div class="product_lablecontentheard">
                  <div class="product_lablecontentheard_one">
                    高效稳定的系统技术部署
                  </div>
                  <div class="product_lablecontentheard_two">
                    安全稳定的防护部署助您快速增长
                  </div>
                </div>
                <div class="xitongbushu_con">
                  <div class='xitongbushu_con1'>
                    <div class="contentt">
                      <div class="content_img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/img7.png" alt="">
                      </div>
                      <div class="content_text">
                        <div class="type-item-txt1">
                          HTTPS安全访问
                        </div>
                        <div class="type-item-txt2">
                          HTTPS SSL认证&nbsp;&nbsp;&nbsp;&nbsp;前端访问设置黑名单
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='xitongbushu_con2'>
                    <div class="content">
                      <div class="content_img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/img8.png" alt="">
                      </div>
                      <div class="content_text">
                        <div class="type-item-txt1">
                          HTTPS安全访问
                        </div>
                        <div class="type-item-txt2">
                          HTTPS SSL认证&nbsp;&nbsp;&nbsp;&nbsp;前端访问设置黑名单
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='xitongbushu_con3'>
                    <div class="content">
                      <div class="content_img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/img9.png" alt="">
                      </div>
                      <div class="content_text">
                        <div class="type-item-txt1">
                          HTTPS安全访问
                        </div>
                        <div class="type-item-txt2">
                          HTTPS SSL认证&nbsp;&nbsp;&nbsp;&nbsp;前端访问设置黑名单
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='xitongbushu_con4'>
                    <div class="content">
                      <div class="content_img">
                        <img src="../../assets/kaiyuanchanping/chanpinjieshao/img10.png" alt="">
                      </div>
                      <div class="content_text">
                        <div class="type-item-txt1">
                          HTTPS安全访问
                        </div>
                        <div class="type-item-txt2">
                          HTTPS SSL认证&nbsp;&nbsp;&nbsp;&nbsp;前端访问设置黑名单
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 用户评价框 -->
              <div class="pingjia">
                <!-- 评论组件 -->
                <div class="pingjia_hera">
                  <div class="pingjia_hera1">
                    <span class="shuxian"></span>
                    <span class="user">用户评价</span>
                  </div>
                </div>
                <div class="pingjiacomments">
                  <comment :comments="commentData" :goosid="goosid"></comment>
                </div>
              </div>
            </div>
            <div v-show="tab==2" class="tapS product_con2">
              <div class="appcon" v-if="appshow">
                <div class="box_40 flex-row justify-between">
                  <div class="section_16 flex-col justify-between">
                    <div class="text-group_20 flex-col justify-between">
                      <div class="text_17">{{appshownameone}}</div>
                      <div class="text_18">{{appshowsubtitleone}}</div>
                    </div>
                    <img class="image_11" referrerpolicy="no-referrer" :src="appshowimage" />
                  </div>
                </div>
                <div class="block_11 flex-col">
                  <div class="text-wrapper_53 flex-row">
                    <div class="text_30">{{appshownametwo}}</div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-md-6">
                      <ul class="preview_content">
                        <li v-for="(item, index) in chanpinlist" :key="index" @mouseover="addClass(index)"
                          :class="[currentIndex === index ? 'active' : '']">
                          <span>0{{index+1}}</span>
                          <span>{{item.name}}</span>
                          <div class="zhanshiimg" v-if="selectedItem === index" @mouseover="change()"
                            @mouseleave="removeClass()">
                            <img :src="item.image" v-if='imgshow' />
                            <el-carousel indicator-position="outside" v-if='imgshow1'
                              style="width: 303px; height: 660px;">
                              <el-carousel-item v-for="(ite,inde) in item.chanpinimg" :key="inde">
                                <img :src="ite" />
                              </el-carousel-item>
                            </el-carousel>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tese">
                  <div class="text-group_21 flex-col justify-between">
                    <div class="text_19">{{appshownamethree}}</div>
                    <div class="text_20">{{appshowsubtitlethree}}</div>
                  </div>
                  <div class="box_41 flex-row">
                    <div class="block_5 flex-col" v-for="(item,index) in tese" :key='index'>
                      <div class="block_5_1">
                        <div class="image_12">
                          <img :src="item.image" alt="">
                        </div>
                        <div class="text-group_22 flex-col justify-between">
                          <div class="text_21">{{item.name}}</div>
                          <div class="text_22">{{item.description}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="group_17 flex-col">
                  <span class="text_49">{{appshownamefour}}</span>
                  <div class="group_37 flex-row justify-between">
                    <div class="box_13 flex-col justify-center" v-for="(item,index) in  chuxiao" :key="index">
                      <div class="block_12 flex-col">
                        <div class='img'>
                          <img class="image_18" referrerpolicy="no-referrer" :src="item.image" />
                        </div>
                        <div class="text_50">{{item.name}}</div>
                        <div class="text_51">{{item.description}}</div>
                      </div>
                      <div class="box_14 flex-col"></div>
                    </div>
                  </div>
                </div>
                <div class="box_43 flex-col">
                  <span class="text_58">{{appshownamefive}}</span>
                  <span class="text_59">{{appshowsubtitlefive}}</span>
                  <div class="image-wrapper_4 flex-row">
                    <div class="swiper-container">
                      <div class="swiper-wrapper" style="margin-left: 58px;">
                        <div class="swiper-slide" v-for="item in aizhineng" :key="item">
                          <!-- //image -->
                          {{item}}
                          <img :src="item" alt="" width="230" height="496">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="weixincon" v-if="weixinshow">
                <div class="weixin_first">
                  <div class="weixin_first_top">
                    <div class="weixin_first_left">
                      <img :src="appshowimgsix" alt="">
                    </div>
                    <div class="weixin_first_right">
                      <div class="text">
                        {{appshownamesix}}
                      </div>
                      <div class='text2'>
                        {{appshowsubtitlesix}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="weixin_twice">
                  <div class="weixin_twice_top">
                    <div class="title_textxt"> {{appshownameseven}}</div>
                    <div class='contab'>
                      <div class="contab_con" v-for="(item,index) in contab" :key="index">
                        <div class="pictur">
                          <img :src="item.image" alt="">
                        </div>
                        <div class='contab_text'>{{item.name}}</div>
                        <div class='contab_main'>{{item.description}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="weixin_three">
                  <div class="weixin_three_top">
                    <div class="img">
                      <img :src="wxnameeightimg" alt="">
                    </div>
                    <div class="text">
                      <div class='title'>{{wxnameeight}}</div>
                      <div class='main'>{{wxnameeightname}}</div>
                    </div>
                  </div>
                  <div class="weixin_three_bottom">
                    <div class="text">
                      <div class="title">{{wxnameninth}}</div>
                      <div class="main">{{wxnameninthname}}</div>
                    </div>
                    <div class="img">
                      <img :src="wxnameninthimg" alt="">
                    </div>
                  </div>
                </div>
                <div class="weixin_four">
                  <div class="weixin_four_top">
                    <div class="top_title">{{appshownameten}}</div>
                    <div class="top_title2">{{appshowsubtitleten}}</div>
                  </div>
                  <div class="confouer">
                    <div class="confouer_con" v-for="(item,index) in wxyycj" :key="index">
                      <div class="img">
                        <img :src="item.image" alt="">
                      </div>
                      <div class="text">
                        <div class="title">{{item.name}}</div>
                        <div class="main">{{item.description}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gzhcon" v-if="gzhshow">
                <div class="gzh_first">
                  <div class="gzh_first_top">
                    <div class="top_title">{{gzhshownameone}}</div>
                    <div class="top_title2">{{gzhshowsubtitleone}}</div>
                  </div>
                  <div class="gzh_con" v-for="(item,index) in gzhcon" :key="index">
                    <div class="img">
                      <img :src="item.image" alt="">
                    </div>
                    <div class="text">
                      <div class="title">{{item.name}}</div>
                      <div class="main">{{item.description}}</div>
                    </div>
                  </div>
                </div>
                <div class="block_11 flex-col">
                  <div class="text-wrapper_53 flex-row">
                    <div class="text_30">{{appshownametwo}}</div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-md-6">
                      <ul class="preview_content">
                        <li v-for="(item, index) in chanpinlist" :key="index" @mouseover="addClass(index)"
                          :class="[currentIndex === index ? 'active' : '']">
                          <span>{{index+1}}</span>
                          <span>{{item.name}}</span>
                          <div class="zhanshiimg" v-if="selectedItem === index" @mouseover="change()"
                            @mouseleave="removeClass()">
                            <img :src="item.img1" v-if='imgshow' />
                            <el-carousel indicator-position="outside" v-if='imgshow1'
                              style="width: 303px; height: 660px;">
                              <el-carousel-item v-for="(ite,inde) in item.chanpinimg" :key="inde">
                                <img :src="ite" />
                              </el-carousel-item>
                            </el-carousel>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="gzh_bottom">
                  <div class="gzh_bottom_title">
                    <div class="tetx">{{appshownamethree}}</div>
                    <div class='main'>{{gzhshowsubtitlthree}}</div>
                  </div>
                  <div class="gzh_bottom_con">
                    <div class="container">
                      <div class="nav">
                        <div class="btn" :class="{ activee: itemIndex == index }" v-for="(item, index) in btnList"
                          :key="index" @click="btnClick(index)">
                          <div class="btn_name" :class="{ btn_namee: itemIndex == index }">{{ item.name }}</div>
                          <div class="btn_mian" :class="{ btn_miane: itemIndex == index }">{{item.description}}</div>
                        </div>

                      </div>
                      <div class="detail" v-for="(item,index) in btnList " v-show="itemIndex == index">
                        <img :src="item.image" alt="">
                        <!-- <div v-for="(ite,ind) in item.nameContent " :key="ind" style="margin-left: 20px;">
                          <img :src="ite.image" alt="">
                        </div> -->

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="tab==3" class="tapS product_con3">
              <div class="tese_first">
                <div class="tese">
                  <div class="text-group_21 flex-col justify-between">
                    <div class="text_19">{{tesenameone}}</div>
                    <div class="text_20">{{tesesubtitleone}}</div>
                  </div>
                  <div class="box_41 flex-row">
                    <div class="block_5 flex-col" v-for="(item,index) in tesewzh" :key='index'>
                      <div class="block_5_1">
                        <div class="image_12">
                          <img :src="item.image" alt="">
                        </div>
                        <div class="text-group_22 flex-col justify-between">
                          <div class="text_21">{{item.name}}</div>
                          <div class="text_22">{{item.description}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tese_sence">
                <div class="box_43 flex-col">
                  <span class="text_58">{{tesenametwo}}</span>
                  <span class="text_59">{{tesesubtitletwo}}</span>
                  <div class="image-wrapper_4 flex-row">
                    <div class="swiper-container">
                      <div class="swiper-wrapper" style="margin-left: 58px;">
                        <div class="swiper-slide" v-for="item in aizhineng" :key="item">
                          <img :src="item.imglist" alt="" width="230" height="496">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tese_three">
                <div class='title'>{{tesenamethree}}</div>
                <div class='box_table'>
                  <div class="box_table1">
                    <div class='img'>
                      <img :src="tesenamethreeimage" alt="">
                    </div>
                  </div>
                  <div class="box_table2">
                    <div class="box_con" v-for="(item,index) in teliaotian " :key="index">
                      <div class="box_img">
                        <img :src="item.description" alt="">
                      </div>
                      <div class="box_txt">
                        {{item.name}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="tab==4" class="tapS product_con4 ">
              <div class="product_lablecontentheard">
                <div class="product_lablecontentheard_one">
                  客户案例
                </div>
                <div class="product_lablecontentheard_two">
                  欢迎加入我们，助您您获得收益
                </div>
              </div>
              <div class="kehuanli">
                <div class="nengli_cotent">
                  <el-tabs v-model="activeNamee" class="demo-tabs" stretch>
                    <div v-for="(item,index) in kehuanli" :key="index">
                      <el-tab-pane :label="item.name" :name="item.id" class="demo-tabs_first">
                        <div class="demo-tabs_first1">
                          <img :src="item.image" alt="">
                        </div>
                        <div class="demo-tabs_first2">
                          <div class="title_demo">
                            <img :src="item.icon" alt="">
                          </div>
                          <div class="title_demo1">
                            {{item.title}}
                          </div>
                          <div class="title_demo2">
                            {{item.content}}
                          </div>
                          <div class="title_demo3">
                            <div class="title_demo3_1">
                              <div class="title_demo3_1_num">
                                {{item.dis_promotion}}%↑
                              </div>
                              <div class="title_demo3_1_text">
                                分销推厂
                              </div>
                            </div>
                            <div class="title_demo3_2">
                              <div class="title_demo3_2_num">
                                {{item.overall_income}}%↑
                              </div>
                              <div class="title_demo3_2_text">
                                整体收益
                              </div>
                            </div>
                            <div class="title_demo3_3">
                              <div class="title_demo3_3_num">
                                {{item.user_traffic}}%↑
                              </div>
                              <div class="title_demo3_3_text">
                                用户流量
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </div>


                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- 右侧固钉 -->
  <affix></affix>
</template>

<script>
  import * as CommentData from '../../mockdata/mockdata.js'
  import comment from '../../components/comment.vue'
  import affix from '../../components/affix.vue'
  import Swiper from "swiper";

  export default {
    components: {
      comment,
      affix
    },
    data() {
      return {
        products: [],
        itemIndex: 1,
        teliaotian: [{
            img: require('../../assets/kaiyuan/teseimg2.png'),
            text: '用户端版本'
          },
          {
            img: require('../../assets/kaiyuan/teseimg3.png'),
            text: '公众号版本'
          },
          {
            img: require('../../assets/kaiyuan/teseimg4.png'),
            text: '微信小程序'
          },
        ],
        btnList: [{
            name: "团队收益",
            mian: '分销推广赚取收益',
            nameContent: [{
                img: require('../../assets/kaiyuan/gzhimg5.png')
              },
              {
                img: require('../../assets/kaiyuan/gzhimg6.png')
              },
            ]
          },
          {
            name: "AI聊天",
            mian: 'AI 助理回答您所有问题',
            nameContent: [{
                img: require('../../assets/kaiyuan/gzhimg5.png')
              },
              {
                img: require('../../assets/kaiyuan/gzhimg6.png')
              },
              {
                img: require('../../assets/kaiyuan/gzhimg7.png')
              }
            ],
          },
          {
            name: "分销提现",
            mian: '随时结算，自由操控',
          },
        ],
        currentIndex: 0,
        hovershow: false,
        imgshow: true,
        imgshow1: false,
        appshow: true,
        weixinshow: false,
        gzhshow: false,
        tabshow2: false,
        backgroundColor: "white",
        textColor: "black",
        backgroundColor2: "white",
        textColor2: "black",
        backgroundColor3: "white",
        textColor3: "black",
        commentData: [],
        activeName: 2,
        activeNamee: 1,
        selectedItem: 0,
        corecom_listtitleone: '',
        corecom_listsubtitleone: '',
        tabnameone: '',
        tabnametwo: '',
        tabnamethree: '',
        contab: [{
            img: require('../../assets/kaiyuan/wximg2.png'),
            text: '更多流量 快速吸粉',
            main: '得益于小程序得天独厚的流量优势 快速吸粉转化为APP粉丝'
          }, {
            img: require('../../assets/kaiyuan/wximg3.png'),
            text: '无需下载 更多流量',
            main: '有微信即可快速进入小程序，一站 式快速分享、聊天'
          },
          {
            img: require('../../assets/kaiyuan/wximg4.png'),
            text: '快速聊天 智能问答',
            main: '为更多用户，提供贴心 的问答服务'
          },
          {
            img: require('../../assets/kaiyuan/wximg5.png'),
            text: '安全稳定 官方平台',
            main: '依托微信官方平台 入口稳定、服务体验佳'
          },
        ],
        gzhcon: [{
            img: require('../../assets/kaiyuan/gzhimg1.png'),
            text: '自定义装修 不拘一格',
            main: '依托微信官方平台 入口稳定、服务体验佳'
          },
          {
            img: require('../../assets/kaiyuan/gzhimg2.png'),
            text: '数据打通 实时同步',
            main: '与APP数据完全打通，订单、收益报 表、服务任务全部保持实时更新'
          },
          {
            img: require('../../assets/kaiyuan/gzhimg3.png'),
            text: '统一管理 去繁从简',
            main: '免去繁琐推广，操作便捷，跑腿后台 统一管理，快速设置和传达信息'
          },
          {
            img: require('../../assets/kaiyuan/gzhimg4.png'),
            text: '模版消息 精准推送',
            main: '注册成功、提现结算、统一推送 公告等，系统会自动推送至用户'
          },
        ],
        chanpinlist: [],
        tese: [],
        tesewzh: [],
        chuxiao: [],
        wxyycj: [{
            img: require('../../assets/kaiyuan/wximg8.png'),
            text: 'AI全场景聊天',
            main: 'AI智能聊天回答 您所有问题'
          },
          {
            img: require('../../assets/kaiyuan/wximg9.png'),
            text: '选择AI聊天',
            main: '选择AI为你提供贴心服务'
          },
          {
            img: require('../../assets/kaiyuan/wximg10.png'),
            text: '公众号关联',
            main: '在公众号关联小程序， 快速引流'
          },
          {
            img: require('../../assets/kaiyuan/wximg11.png'),
            text: '分销分享',
            main: '分销推广 赚取收益提现'
          },
          {
            img: require('../../assets/kaiyuan/wximg12.png'),
            text: '微信分享群',
            main: '分享小程序到微信群，批量 增加平台粉丝'
          },
          {
            img: require('../../assets/kaiyuan/wximg13.png'),
            text: '收益提现',
            main: '收益快速提现到账'
          },
          {
            img: require('../../assets/kaiyuan/wximg14.png'),
            text: '附近的小程序',
            main: '设置小程序位置 可搜索附近5公里内小程序'
          },
          {
            img: require('../../assets/kaiyuan/wximg15.png'),
            text: '更多精彩',
            main: '省钱兄小程序持续优化中'
          },
        ],
        zhinengquestion: {},
        zhinengquestion1: {},
        zhinengquestion2: {},
        xingxiaoku: {

        },
        concent: [], //接受传的参数
        tab: 1,
        tabb: 1,
        isBlue: true,
        isBlue1: false,
        isBlue2: false,
        istop: true,
        istop1: false,
        istop2: false,
        isRedd: false,
        isRed: false,
        purchasedProducts: [],
        goods_id: '',
        icon_type: 1,
        aizhineng: [{
            imglist: require('../../assets/kaiyuan/app1.png')
          },
          {
            imglist: require('../../assets/kaiyuan/app2.png')
          },
          {
            imglist: require('../../assets/kaiyuan/app3.png')
          },
          {
            imglist: require('../../assets/kaiyuan/app4.png')
          },
          {
            imglist: require('../../assets/kaiyuan/app5.png')
          },
        ],
        kehuanli: [],
        corecom_list: [], //产品介绍
        goods_center_list: [], //产品中心
        goods_feature_list: [], //特色功能
        heixin: [],
        appshownameone: '',
        appshowsubtitleone: '',
        appshowimage: '',
        appshownametwo: '',
        appshownamethree: '',
        appshowsubtitlethree: '',
        appshownamefour: '',
        appshownamefive: '',
        appshowsubtitlefive: '',
        appshownamesix: '',
        appshowsubtitlesix: '',
        appshowimgsix: '',
        appshownameseven: '',
        wxnameeightimg: '',
        wxnameeight: '',
        wxnameeightname: '',
        wxnameninthimg: '',
        wxnameninth: '',
        wxnameninthname: '',
        appshownameten: '',
        appshowsubtitleten: '',
        gzhshownameone: '',
        gzhshowsubtitleone: '',
        appshownamethree: '',
        gzhshowsubtitlthree: '',
        tesenameone: '',
        tesesubtitleone: '',
        tesenametwo: '',
        tesesubtitletwo: '',
        tesenamethree: "",
        tesenamethreeimage: '',
        goosid: '',
      }
    },
    methods: {
      btnClick(index) {
        this.itemIndex = index
      },
      //接受传的参数
      getParams() {
        this.concent = JSON.parse(this.$route.query.item)
        if (this.concent.goods_id) {
          this.goods_id = this.concent.goods_id
          this.goosid = this.concent.goods_id
        }
        if (this.concent.id) {
          this.goods_id = this.concent.id
          this.goosid = this.concent.id
        }

      },
      //商品详情
      async bykaiyaunxaingqing() {
        const {
          data: res
        } = await this.$http.post('/index/product/product_info', {
          goods_id: this.goods_id,
          icon_type: this.icon_type //1.app  2.小程序  3.公众号  4.H5
        })
        this.products = res.data
        //产品介绍
        this.corecom_list = res.data.corecom_list
        this.corecom_listtitleone = this.corecom_list[1].title
        this.corecom_listsubtitleone = this.corecom_list[1].subtitle
        this.tabnameone = this.corecom_list[1].children_list[0].name
        this.tabnametwo = this.corecom_list[1].children_list[1].name
        this.tabnamethree = this.corecom_list[1].children_list[2].name
        this.zhinengquestion = this.corecom_list[1].children_list[0]
        this.zhinengquestion1 = this.corecom_list[1].children_list[1]
        this.zhinengquestion2 = this.corecom_list[1].children_list[2]
        this.corecom_listtitletwo = this.corecom_list[2].title
        this.corecom_listsubtitletwo = this.corecom_list[2].subtitle
        this.xingxiaoku = this.corecom_list[2].children_list
        this.corecom_listtitlethree = this.corecom_list[0].title
        this.corecom_listsubtitlethree = this.corecom_list[0].subtitle
        this.heixin = this.corecom_list[0].children_list
        if (this.icon_type == 1) {
          //产品中心 app端
          this.goods_center_list = res.data.goods_center_list
          //app
          this.appshownameone = this.goods_center_list[0].icon_subname
          this.appshowsubtitleone = this.goods_center_list[0].subtitle
          this.appshowimage = this.goods_center_list[0].image
          //界面预览
          this.appshownametwo = this.goods_center_list[1].title
          var jiemian = this.goods_center_list.slice(1, 10)
          this.chanpinlist = jiemian
          var chanpinimgarry = []
          this.chanpinlist.forEach((item) => {
            chanpinimgarry.push(item.image)
          })
          var chanpinimg = chanpinimgarry
          for (let i = 0; i < this.chanpinlist.length; i++) {
            this.chanpinlist[i].chanpinimg = chanpinimg
          }
          //特色
          this.appshownamethree = this.goods_center_list[11].title
          this.appshowsubtitlethree = this.goods_center_list[11].subtitle
          this.tese = this.goods_center_list.slice(11, 17)
          this.appshownamefour = this.goods_center_list[18].title
          this.chuxiao = this.goods_center_list.slice(17, 20)
          //轮播
          this.appshownamefive = this.goods_center_list[21].title
          this.appshowsubtitlefive = this.goods_center_list[21].subtitle
          var aizhinengimg = this.goods_center_list.slice(21, 22)
          this.aizhineng = aizhinengimg[0].image.split(",")
        }
        if (this.icon_type == 2) {
          //产品中心 小程序
          this.goods_center_list = res.data.goods_center_list
          this.appshownamesix = this.goods_center_list[0].title
          this.appshowsubtitlesix = this.goods_center_list[0].subtitle
          this.appshowimgsix = this.goods_center_list[0].image
          this.appshownameseven = this.goods_center_list[1].title
          this.contab = this.goods_center_list.slice(1, 5)
          this.wxnameeightimg = this.goods_center_list[5].image
          this.wxnameeight = this.goods_center_list[5].name
          this.wxnameeightname = this.goods_center_list[5].description
          this.wxnameninthimg = this.goods_center_list[6].image
          this.wxnameninth = this.goods_center_list[6].name
          this.wxnameninthname = this.goods_center_list[6].description
          this.appshownameten = this.goods_center_list[7].title
          this.appshowsubtitleten = this.goods_center_list[7].subtitle
          this.wxyycj = this.goods_center_list.slice(7, 15)
        }
        if (this.icon_type == 3) {
          //产品中心 小程序
          this.goods_center_list = res.data.goods_center_list
          this.gzhshownameone = this.goods_center_list[0].title
          this.gzhshowsubtitleone = this.goods_center_list[0].subtitle
          this.gzhcon = this.goods_center_list.slice(0, 3)
          this.appshownametwo = this.goods_center_list[4].title
          var jiemian = this.goods_center_list.slice(4, 15)
          this.chanpinlist = jiemian
          var chanpinimgarry = []
          this.chanpinlist.forEach((item) => {
            chanpinimgarry.push(item.image)
          })
          var chanpinimg = chanpinimgarry
          for (let i = 0; i < this.chanpinlist.length; i++) {
            this.chanpinlist[i].chanpinimg = chanpinimg
          }
          this.appshownamethree = this.goods_center_list[15].title
          this.gzhshowsubtitlthree = this.goods_center_list[15].subtitle
          this.btnList = this.goods_center_list.slice(15, 18)
        }
        //特色功能
        this.goods_feature_list = res.data.goods_feature_list
        this.tesenameone = this.goods_feature_list[0].name
        this.tesesubtitleone = this.goods_feature_list[0].subtitle
        this.tesewzh = this.goods_feature_list.slice(0, 6)
        this.tesenametwo = this.goods_feature_list[6].name
        this.tesesubtitletwo = this.goods_feature_list[6].title
        var aizhinengimg = this.goods_feature_list.slice(6, 7)
        this.aizhineng = aizhinengimg[0].image.split(",")
        this.tesenamethree = this.goods_feature_list[7].title
        this.tesenamethreeimage = this.goods_feature_list[7].image
        this.teliaotian = this.goods_feature_list.slice(7, 10)
      },
      //客户案例
      async customer_list() {
        const {
          data: res
        } = await this.$http.post('index/product/customer_list', {
          goods_id: this.goods_id,
        })
        if (res.code == 1) {
          this.kehuanli = res.data
        }
      },
      but: function(e) {
        let tabid = e.target.dataset.id
        this.tab = tabid
        if (this.tab == 4) {
          this.customer_list()
        }
      },
      dianjichange(index) {
        if (index == 1) {
          this.isRed = true
          this.isRedd = false
        } else if (index == 2) {
          this.isRedd = true
          this.isRed = false
        }
      },
      butt(e) {
        if (e == 1) {
          this.isBlue = true
          this.isBlue1 = false
          this.isBlue2 = false
          this.istop = true
          this.istop1 = false
          this.istop2 = false
        } else if (e == 2) {
          this.isBlue = false
          this.isBlue1 = true
          this.isBlue2 = false
          this.istop = false
          this.istop1 = true
          this.istop2 = false
        } else if (e == 3) {
          this.isBlue = false
          this.isBlue1 = false
          this.isBlue2 = true
          this.istop = false
          this.istop1 = false
          this.istop2 = true
        }
        let tabid = e
        this.tabb = tabid
      },
      bianse1() {
        event.stopPropagation();
        this.icon_type = 1
        this.bykaiyaunxaingqing()
        this.tab = 2
        this.appshow = true
        this.weixinshow = false
        this.gzhshow = false
        const newBackgroundColor = "rgba(224, 236, 255, 1)"
        const newTextColor = "#5295FF";
        this.backgroundColor = newBackgroundColor;
        this.textColor = newTextColor;
        this.backgroundColor2 = "#fff";
        this.textColor2 = "black";
        this.backgroundColor3 = "#fff";
        this.textColor3 = "black";
      },
      bianse2() {
        event.stopPropagation();
        this.icon_type = 2
        this.bykaiyaunxaingqing()
        this.tab = 2
        this.appshow = false
        this.weixinshow = true
        this.gzhshow = false
        const newBackgroundColor = "rgba(224, 236, 255, 1)"
        const newTextColor = "#5295FF";
        this.backgroundColor2 = newBackgroundColor;
        this.textColor2 = newTextColor;
        this.backgroundColor = "#fff";
        this.textColor = "black";
        this.backgroundColor3 = "#fff";
        this.textColor3 = "black";
      },
      bianse3() {
        event.stopPropagation();
        this.icon_type = 3
        this.bykaiyaunxaingqing()
        this.tab = 2
        this.appshow = false
        this.weixinshow = false
        this.gzhshow = true
        const newBackgroundColor = "rgba(224, 236, 255, 1)"
        const newTextColor = "#5295FF";
        this.backgroundColor3 = newBackgroundColor;
        this.textColor3 = newTextColor;
        this.backgroundColor = "#fff";
        this.textColor = "black";
        this.backgroundColor2 = "#fff";
        this.textColor2 = "black";
      },
      buyzixun() {
        var itemcon = JSON.stringify(this.concent)
        this.$router.push({
          path: '/chatindex',
          query: {
            item: itemcon
          }
        })
      },
      lijibuy() {
        this.purchasedProducts.push(this.concent);
        let jsonString = JSON.stringify(this.purchasedProducts);
        window.localStorage.setItem('purchasedProducts', jsonString)
        this.$router.push({
          name: 'empower',
        })
      },
      addClass(index) {
        this.currentIndex = index
        this.selectedItem = index;
      },
      change() {
        this.imgshow = false
        this.imgshow1 = true
      },
      removeClass() {
        this.imgshow1 = false
        this.imgshow = true
      }
    },
    created() {
      this.getParams();
      this.bykaiyaunxaingqing();
      this.commentData = JSON.parse(JSON.stringify(CommentData.comment.data));
      // console.log(this.commentData, '111')
    },
    mounted() {
      new Swiper(".swiper-container", {
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: false,
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 5,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
    }
  }
</script>

<style lang="less" scoped>
  /deep/.el-carousel__container {
    height: 660px;
  }

  .active {
    background-color: #f5f7fa;
    border-right: 8px solid #0274FB;
    color: #0274FB;
  }

  .activee {
    background-image: url('../../assets/kaiyuan/gzhchagge.png');
    background-repeat: no-repeat;
    // background-size: 100% 110%;
    border: none;
  }

  .btn_namee {
    color: #fff;
  }

  .btn_miane {
    color: #fff;
  }

  .bg-blue {
    background-image: url('../../assets/kaiyuanchanping/chanpinjieshao/dibuimg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 13px;
    margin-left: -39px;
  }

  .text-red {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 42px;
    background: linear-gradient(-90deg, #0055FF 0.634765625%, #3E81FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text {
    .numm {
      position: relative;
      top: 31px;
      font-size: 70px;
      font-family: DIN;
      font-weight: bold;
      color: white;
      line-height: 2px;
    }

    .text {
      position: relative;
      top: 18px;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: white;
      line-height: 2px;
    }
  }

  .content {
    background: #EFF7FF;

  }

  .swiper {
    width: 100%;
    height: 700px;
    position: relative;

    .swiper-item {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .swiper-item-gg {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        height: 80px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        padding: 0 50px;
        color: #0D68FC;
        font-size: 22px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .killtitle {
        width: 100%;
        display: flex;

        .killtitle_left {
          width: 50%;
          position: absolute;
          top: 130px;
          left: 254px;

          img {
            width: 500px;
            height: 512px;
          }
        }

        .killtitle_right {
          width: 50%;
          position: absolute;
          top: 234px;
          right: 17px;
          font-size: 45px;
          font-weight: 900;
          font-family: 'Source Han Sans', sans-serif;
          color: white;


        }

        .killtitle1 {
          span {
            display: inline-block;
            margin-top: 20px;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }

          .hx {
            margin-top: 37px;
            width: 78px;
            height: 8px;
            background: #FFFFFF;
          }

          .butt {
            width: 100%;
            height: 71px;
            margin-top: 107px;
            display: flex;

            .button1 {
              width: 229px;
              height: 80px;
              background: #FFFFFF;
              border: 1px solid #63FFFD;
              border-radius: 40px;
              text-align: center;
              margin-right: 50px;
              border: none;

              span {
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #0D68FC;
                line-height: 44px;
              }
            }

            .button2 {
              width: 229px;
              height: 80px;
              background: #FFFFFF;
              border: 1px solid #63FFFD;
              border-radius: 40px;
              text-align: center;
              border: none;

              span {
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #0D68FC;
                line-height: 44px;
              }
            }
          }
        }
      }
    }
  }

  .product {
    width: 100%;
    overflow: hidden;
    // height: 5000px;
    background-color: #FFFFFF;


    .product_content {
      width: 90%;
      height: 100%;
      margin: 0 auto;

      .product_lable {
        width: 100%;
        height: 100%;

        .product_lablechange {
          ul {
            width: 700px;
            display: flex;
            justify-content: space-between;
            margin-left: 451px;
            margin-top: 0px;
          }

          li {
            width: 158px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0D68FC;
            text-align: center;
            display: inline-block;
            margin-top: 80px;
          }

          .chanpin:hover .hovertab {
            display: block;
          }

          .hovertab {
            position: absolute;
            display: none;

            .page {
              position: relative;
              left: -147px;
            }

            .section_1 {
              background-color: rgba(242, 246, 253, 1);
              border-radius: 24px;
              height: 205px;
              width: 477px;
              justify-content: flex-center;
              margin: 16px 0 0 9px;
              display: flex;
            }

            .section_2 {
              width: 407px;
              height: 70px;
              margin: 22px 0 0 25px;
            }

            .block_1 {
              // background-color: rgba(224, 236, 255, 1);
              background-color: #FFFFFF;
              border-radius: 10px;
              width: 194px;
              height: 70px;
              border: 1px solid rgba(13, 104, 252, 1);
              display: flex
            }

            .label_3 {
              width: 30px;
              height: 30px;
              margin: 19px 0 0 13px;
            }

            .text-wrapper_3 {
              width: 122px;
              height: 39px;
              margin: 15px 17px 0 12px;
            }

            .text_1 {
              width: 52px;
              height: 16px;
              overflow-wrap: break-word;
              // color: rgba(13, 104, 252, 1);
              color: #000000;
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 18px;
            }

            .text_2 {
              width: 122px;
              height: 15px;
              overflow-wrap: break-word;
              // color: rgba(82, 149, 255, 1);
              color: #000000;
              font-size: 14px;
              font-family: SourceHanSansCN-Normal;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 14px;
              margin-top: 8px;
            }

            .block_3 {
              background-color: rgba(255, 255, 255, 1);
              border-radius: 10px;
              width: 194px;
              height: 70px;
              border: 1px solid rgba(13, 104, 252, 1);
              display: flex;
              margin-top: 10px;
            }

            .label_4 {
              width: 30px;
              height: 30px;
              margin: 22px 0 0 13px;
            }

            .text-wrapper_4 {
              width: 113px;
              height: 38px;
              margin: 17px 27px 0 11px;
            }

            .text_3 {
              width: 71px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 18px;
            }

            .text_4 {
              width: 112px;
              height: 15px;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              font-family: SourceHanSansCN-Normal;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 14px;
              margin: 6px 0 0 1px;
            }

            .section_3 {
              width: 194px;
              height: 70px;
              margin: 21px 33px 22px 25px;

            }

            .box_3 {
              background-color: rgba(255, 255, 255, 1);
              border-radius: 10px;
              width: 194px;
              height: 70px;
              border: 1px solid rgba(13, 104, 252, 1);
              display: flex;
            }

            .label_5 {
              width: 30px;
              height: 30px;
              margin: 19px 0 0 13px;
            }

            .text-group_2 {
              width: 122px;
              height: 39px;
              margin: 15px 17px 0 12px;
            }

            .text_5 {
              width: 71px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 18px;
            }

            .text_6 {
              width: 70px;
              height: 14px;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              font-family: SourceHanSansCN-Normal;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 14px;
              margin-top: 7px;
            }
          }

          li:hover {
            border-bottom: 2px solid #0D68FC;
          }


        }

        .product_lablecontent {
          width: 100%;

          .product_lablecontentheard {
            width: 100%;
            height: 150px;
            text-align: center;

            .product_lablecontentheard_one {
              font-size: 34px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              margin-top: 50px;
            }

            .product_lablecontentheard_two {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              margin-top: 30px;
            }
          }

          .product_con1 {
            width: 100%;
            // height: 803px;
            overflow: hidden;

            .productjux {
              width: 100%;
              height: 116px;
              background: #0D68FC;
              opacity: 0.5;
              border-radius: 0px 6px 18px 0px;
              position: relative;

              ul {
                width: 100%;
                display: flex;
                position: absolute;
                top: -18px;
              }

              li {
                width: 33.3%;
                list-style-type: none;
                height: 116px;
                text-align: center;

                .numm {
                  font-size: 70px;
                  font-family: DIN;
                  font-weight: bold;
                  color: white;
                  margin-top: 10px;
                  display: inline-block;
                }

                .text {
                  font-size: 26px;
                  height: 116px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: white;
                  margin-top: 10px;
                  display: inline-block;
                }
              }
            }

            .productjuxchange {
              width: 100%;
              height: 680px;
              background: #F6F9FF;

              .productjuxchange1 {
                width: 100%;
                height: 100%;
                display: flex;
                position: relative;

                .productjuxchange1_text {
                  width: 50%;
                  height: 100%;

                  span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    // line-height: 300px;
                    margin-left: 83px;
                    margin-top: 300px;
                    display: inline-block;
                  }
                }

                .productjuxchange1_img {
                  width: 50%;
                  height: 100%;
                  margin: 0 auto;
                  position: relative;

                  .img {
                    position: absolute;
                    top: 98px;
                    left: 275px;
                  }

                  .phone1 {
                    position: absolute;
                    top: 173px;
                    left: 120px;

                    .phone1_img {
                      position: absolute;
                      top: 26px;
                      left: 110px;
                    }

                    .phone1_text {
                      width: auto;
                      text-align: center;
                      position: absolute;
                      top: 24px;
                      left: -20px;
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #666666;
                    }
                  }

                  .phone2 {
                    position: absolute;
                    top: 358px;
                    left: 120px;

                    .phone1_img {
                      position: absolute;
                      top: 26px;
                      left: 110px;
                    }

                    .phone1_text {
                      width: 100px;
                      text-align: center;
                      position: absolute;
                      top: 15px;
                      left: 9px;
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #666666;
                    }
                  }

                  .phone3 {
                    position: absolute;
                    top: 173px;
                    left: 586px;

                    .phone1_img {
                      position: absolute;
                      top: 26px;
                      left: 10px;
                    }

                    .phone1_text {
                      width: auto;
                      text-align: center;
                      position: absolute;
                      top: 20px;
                      left: 38px;
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #666666;
                    }
                  }
                }
              }
            }
          }

          .yingxiao {
            width: 100%;
            height: 374px;

            .yingxiao_content {
              position: absolute;
              left: -20px;
              width: 100%;
              height: 374px;
              background-color: #333333;
              display: flex;
              flex-wrap: wrap;

              .yingxiao_contenttop {
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: space-evenly;

                .yingxiao_content1 {
                  margin-top: 50px;
                }

                .yingxiao_content2 {
                  margin-top: 50px;
                }

                .yingxiao_content3 {
                  margin-top: 50px;
                }

                .yingxiao_content4 {
                  margin-top: 50px;
                }

              }

              .line {
                width: 100%;
                height: 2px;
                border: 2px solid #FFFFFF;
                background-color: #FFFFFF;
              }

              .yingxiao_contentbottoma {
                width: 100%;
                // height: 50%;
                display: flex;
                justify-content: space-evenly;

                .yingxiao_content1 {
                  .radius {
                    width: 15px;
                    height: 15px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    margin: 20px 0 20px 58px;
                  }

                  .text {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    width: 135px;
                    text-align: center;
                  }

                  .line {
                    width: 35px;
                    height: 4px;
                    border: 4px solid #FFFFFF;
                    margin: 20px 0 20px 44px;
                  }
                }

                .yingxiao_content2 {
                  .radius {
                    width: 15px;
                    height: 15px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    margin: 20px 0 20px 58px;

                  }

                  .text {
                    width: 128px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                  }

                  .line {
                    width: 35px;
                    height: 4px;
                    border: 4px solid #FFFFFF;
                    margin: 20px 0 20px 44px;
                  }
                }

                .yingxiao_content3 {
                  .radius {
                    width: 15px;
                    height: 15px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    margin: 20px 0 20px 58px;
                  }

                  .text {
                    width: 128px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                  }

                  .line {
                    width: 35px;
                    height: 4px;
                    border: 4px solid #FFFFFF;
                    margin: 20px 0 20px 44px;
                  }
                }

                .yingxiao_content4 {
                  .radius {
                    width: 15px;
                    height: 15px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    margin: 20px 0 20px 58px;
                  }

                  .text {
                    width: 128px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                  }

                  .line {
                    width: 35px;
                    height: 4px;
                    border: 4px solid #FFFFFF;
                    margin: 20px 0 20px 44px;
                  }
                }


              }
            }
          }

          .nengli {
            width: 100%;
            height: 500px;
            margin-top: 200px;

            .nengli_cotent {
              width: 100%;
              height: 100%;

              .demo-tabs_first {
                width: 100%;
                height: 100%;
                display: flex;

                .demo-tabs_first1 {
                  width: 50%;
                  height: 100%;
                  margin-top: 163px;
                  margin-left: 3px;
                }

                .demo-tabs_first2 {
                  width: 50%;
                  height: 100%;
                  margin-top: 200px;

                  .title_demo1 {
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                  }

                  .title_demo2 {
                    margin: 20px 0 20px 0;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;

                  }

                  .title_demo3 {
                    margin: 20px 0 20px 0;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                  }

                  .title_demo4 {
                    margin: 20px 0 20px 0;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                  }
                }
              }
            }
          }

          .xitongbushu {
            width: 100%;
            height: 500px;
            margin-top: 250px;

            .xitongbushu_con {
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;

              .xitongbushu_con1 {
                width: 50%;
                height: 50%;

                .contentt {
                  width: 80%;
                  height: 80%;
                  margin: 0 auto;
                  display: flex;
                  justify-content: space-between;
                  border: 1px solid #B5CCFF;
                  border-radius: 16px;
                  background-color: #B5CCFF;

                  .content_img {
                    width: 30%;

                    img {
                      margin-top: 65px;
                      margin-left: 65px;
                    }
                  }

                  .content_text {
                    width: 70%;
                    margin-top: 55px;

                    .type-item-txt1 {
                      font-size: 32px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #0D68FC;
                    }

                    .type-item-txt2 {
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #0D68FC;
                      margin-top: 20px;
                    }
                  }
                }


              }

              .xitongbushu_con2 {
                width: 50%;
                height: 50%;

                .content {
                  width: 80%;
                  height: 80%;
                  margin: 0 auto;
                  display: flex;
                  background: #EAD1FF;
                  justify-content: space-between;
                  border: 1px solid #EAD1FF;
                  border-radius: 16px;

                  .content_img {
                    width: 30%;

                    img {
                      margin-top: 65px;
                      margin-left: 65px;
                    }
                  }

                  .content_text {
                    width: 70%;
                    margin-top: 55px;

                    .type-item-txt1 {
                      font-size: 32px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #9E64FB;
                    }

                    .type-item-txt2 {
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #9E64FB;
                      margin-top: 20px;
                    }
                  }
                }
              }

              .xitongbushu_con3 {
                width: 50%;
                height: 50%;

                .content {
                  width: 80%;
                  height: 80%;
                  margin: 0 auto;
                  display: flex;
                  justify-content: space-between;
                  background: #D0FFF2;
                  border: 1px solid #D0FFF2;
                  border-radius: 16px;

                  .content_img {
                    width: 30%;

                    img {
                      margin-top: 65px;
                      margin-left: 65px;
                    }
                  }

                  .content_text {
                    width: 70%;
                    margin-top: 55px;

                    .type-item-txt1 {
                      font-size: 32px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #42C1B5;
                    }

                    .type-item-txt2 {
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #42C1B5;
                      margin-top: 20px;
                    }
                  }
                }
              }

              .xitongbushu_con4 {
                width: 50%;
                height: 50%;

                .content {
                  width: 80%;
                  height: 80%;
                  margin: 0 auto;
                  display: flex;
                  justify-content: space-between;
                  background-color: #FFE7D2;
                  border: 1px solid #FFE7D2;
                  border-radius: 16px;

                  .content_img {
                    width: 30%;

                    img {
                      margin-top: 65px;
                      margin-left: 65px;
                    }
                  }

                  .content_text {
                    width: 70%;
                    margin-top: 55px;

                    .type-item-txt1 {
                      font-size: 32px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #FFA876;
                    }

                    .type-item-txt2 {
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #FFA876;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
          }

          .pingjia {
            width: 100%;
            margin-top: 190px;
            background: #FAFAFA;
            border-radius: 12px;

            .pingjia_hera {
              width: 100%;
              height: 50px;

              .pingjia_hera1 {
                float: left;
                position: relative;

                .shuxian {
                  margin-left: 34px;
                  display: inline-block;
                  width: 8px;
                  height: 34px;
                  margin-top: 18px;
                  background: #0D68FC;
                }

                .user {
                  position: absolute;
                  top: 15px;
                  left: 53px;
                  width: 200px;
                  font-size: 28px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;

                }
              }

              .pingjia_hera2 {
                float: right;
                margin-top: 18px;

                .moren {
                  width: 111px;
                  height: 27px;
                  font-size: 28px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 42px;
                }

                .shu {
                  display: inline-block;
                  width: 2px;
                  height: 18px;
                  color: #B3B3B3;
                  padding: 0 20px;
                }

                .zuixin {
                  width: 111px;
                  height: 27px;
                  font-size: 28px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 42px;
                  margin-right: 51px;
                }
              }
            }

            .pingjiacomments {
              width: 95%;
              height: 100%;
              margin: 0 auto;
            }
          }
        }

        .product_con2 {
          width: 100%;


          .box_40 {
            width: 1506px;
            height: 715px;
            margin: 0 auto;

            .section_16 {
              width: 100%;
              height: 715px;
              text-align: center;
              margin: 84px 0;

              .text-group_20 {
                width: 100%;
                height: 70px;
                text-align: center;

                .text_17 {

                  height: 32px;
                  overflow-wrap: break-word;
                  color: rgba(51, 51, 51, 1);
                  font-size: 34px;
                  font-family: SourceHanSansCN-Regular;
                  font-weight: NaN;
                  text-align: center;
                  white-space: nowrap;
                  line-height: 34px;
                }

                .text_18 {

                  height: 19px;
                  overflow-wrap: break-word;
                  color: rgba(153, 153, 153, 1);
                  font-size: 18px;
                  font-family: SourceHanSansCN-Regular;
                  font-weight: NaN;
                  text-align: center;
                  white-space: nowrap;
                  line-height: 27px;
                  margin-top: 19px;
                }
              }

              .image_11 {
                width: 1188px;
                height: 568px;
                margin-top: 77px;
              }
            }

            .section_17 {
              width: 101px;
              height: 472px;
              margin-top: 66px;

              .section_5 {
                box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
                background-color: rgba(255, 255, 255, 1);
                border-radius: 14px;
                width: 100px;
                height: 100px;

                .image-text_24 {
                  width: 64px;
                  height: 52px;
                  margin: 25px 0 0 18px;

                  .label_2 {
                    width: 33px;
                    height: 28px;
                    margin-left: 19px;
                  }

                  .text-group_2 {
                    width: 64px;
                    height: 16px;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: NaN;
                    text-align: left;
                    line-height: 2px;
                    margin-top: 8px;
                  }
                }
              }

              .section_6 {
                box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
                background-color: rgba(255, 255, 255, 1);
                border-radius: 14px;
                width: 98px;
                height: 100px;
                margin: 25px 0 0 3px;

                .image-text_25 {
                  width: 63px;
                  height: 53px;
                  margin: 24px 0 0 19px;

                  .label_3 {
                    width: 30px;
                    height: 30px;
                    margin-left: 16px;
                  }

                  .text-group_3 {
                    width: 63px;
                    height: 16px;
                    overflow-wrap: break-word;
                    color: rgba(13, 104, 252, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: NaN;
                    text-align: left;
                    line-height: 2px;
                    margin-top: 7px;
                  }
                }
              }

              .section_7 {
                box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
                background-color: rgba(255, 255, 255, 1);
                border-radius: 14px;
                width: 98px;
                height: 100px;
                margin: 24px 0 0 3px;

                .image-text_26 {
                  width: 64px;
                  height: 52px;
                  margin: 26px 0 0 18px;

                  .label_4 {
                    width: 30px;
                    height: 33px;
                    margin-left: 17px;
                  }

                  .text-group_4 {
                    width: 64px;
                    height: 16px;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: NaN;
                    text-align: left;
                    line-height: 2px;
                    margin-top: 3px;
                  }
                }
              }

              .section_8 {
                box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
                background-color: rgba(255, 255, 255, 1);
                border-radius: 14px;
                width: 98px;
                height: 100px;
                margin: 23px 0 0 3px;

                .image-text_27 {
                  width: 63px;
                  height: 52px;
                  margin: 26px 0 0 18px;

                  .label_5 {
                    width: 31px;
                    height: 31px;
                    margin-left: 17px;
                  }

                  .text-group_5 {
                    width: 63px;
                    height: 15px;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: NaN;
                    text-align: left;
                    line-height: 2px;
                    margin-top: 6px;
                  }
                }
              }
            }
          }

          .block_11 {
            width: 111%;
            margin-left: -96px;
            height: 985px;
            background-image: url("../../assets/kaiyuan/product_bg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-top: 98px;

            .text-wrapper_53 {
              width: 100%;
              height: 32px;
              padding-top: 96px;

              .text_30 {
                width: 100%;
                height: 32px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 34px;
                font-family: SourceHanSansCN-Regular;
                font-weight: NaN;
                text-align: center;
                white-space: nowrap;
                line-height: 34px;
              }
            }

            .row {
              margin-right: -15px;
              margin-left: -15px;

              .col-xs-6 {
                width: 50%;
                position: relative;

                .zhanshiimg {
                  position: absolute;
                  top: 17px;
                  left: 1059px;

                  img {
                    width: 307px;
                    height: 660px;
                  }
                }

                ul {
                  list-style: none;
                  color: #fff;
                }

                ul.preview_content {
                  padding-left: 50%;
                  padding-top: 85px;
                }

                .preview_content li {
                  width: 280px;
                }

                .preview_content li {
                  width: 280px;
                  height: 70px;
                  line-height: 70px;

                  font-size: 20px;
                  padding: 0 40px;
                  text-decoration: none;
                }

                .preview_content li span {
                  margin-right: 15px;
                }




              }
            }

          }

          .text-group_21 {
            width: 100%;
            height: 100%;
            text-align: center;
            margin-top: 78px;
            margin-bottom: 42px;

            .text_19 {

              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 34px;
              font-family: SourceHanSansCN-Bold;
              font-weight: 700;
              text-align: center;
              white-space: nowrap;
              line-height: 34px;
              margin-left: 52px;
            }

            .text_20 {

              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              text-align: center;
              white-space: nowrap;
              line-height: 18px;
              margin-top: 18px;
            }
          }

          .box_41 {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .block_5 {

              background-color: rgba(255, 255, 255, 1);
              width: 30%;
              height: 261px;

              .block_5_1 {
                width: 88%;
                /* height: 100%; */
                /* padding: 15px; */
                margin: 33px auto;

                .image_12 {
                  width: 100%;
                  text-align: center;

                  img {
                    width: 65px;
                    height: 65px;
                  }
                }

                .text-group_22 {
                  width: 100%;
                  height: 48px;
                  text-align: center;

                  .text_21 {

                    overflow-wrap: break-word;
                    // color: rgba(51, 51, 51, 1);
                    font-size: 22px;
                    font-family: SourceHanSansCN-Medium;
                    font-weight: 500;
                    text-align: center;
                    white-space: nowrap;
                    line-height: 22px;
                  }

                  .text_22 {

                    overflow-wrap: break-word;
                    color: #999;
                    font-size: 18px;
                    font-family: SourceHanSansCN-Light;
                    font-weight: 300;
                    white-space: nowrap;
                    line-height: 24px;
                    margin-top: 9px;
                  }
                }

              }
            }
          }

          .block_5:hover {
            height: 201px;
            background: #FFFFFF;
            border: 1px solid #0274FB;
            box-shadow: 4px 19px 20px 0px rgba(235, 238, 255, 1);
          }

          .group_17 {
            background-color: #f5f7fa;
            position: relative;
            width: 111%;
            height: 986px;
            margin-top: -1px;
            margin-left: -94px;

            .text_49 {
              display: block;
              width: 100%;
              text-align: center;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 34px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              text-align: center;
              white-space: nowrap;
              padding-top: 20px+80px;
            }

            .group_37 {
              width: 90%;
              /* height: 340px; */
              margin: 62px auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;

              .box_13 {
                background-color: #0274fb;
                border-radius: 10px;
                height: 321px;
                width: 680px;
                margin-bottom: 54px;

                .block_12 {
                  background-color: rgba(255, 255, 255, 1);
                  width: 680px;
                  height: 303px;

                  .image_18 {
                    width: 95px;
                    height: 95px;
                    margin: 29px 0 0 38px;
                  }

                  .text_50 {
                    width: 476px;
                    height: 24px;
                    overflow-wrap: break-word;
                    color: #333333;
                    font-size: 24px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: 400;
                    white-space: nowrap;
                    line-height: 35px;
                    margin: 23px 0 0 34px;
                  }

                  .text_51 {
                    width: 593px;
                    height: 42px;
                    overflow-wrap: break-word;
                    color: rgba(153, 153, 153, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: NaN;
                    text-align: left;
                    line-height: 23px;
                    margin: 19px 0 71px 40px;
                  }
                }
              }

              .box_14 {
                width: 680px;
                height: 13px;
                background: #0274FB;
                border-radius: 10px;
              }
            }
          }

          .box_43 {
            position: relative;
            width: 100%;
            height: auto;
            margin-bottom: 1px;

            .text_58 {
              margin-top: 45px;
              width: 100%;
              display: block;
              text-align: center;
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 34px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              white-space: nowrap;
              line-height: 34px;
            }

            .text_59 {
              margin-top: 51px;
              width: 100%;
              display: block;
              margin: 21px 0 0 0;
              text-align: center;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: NaN;
              white-space: nowrap;
              line-height: 18px;
            }

            .image-wrapper_4 {
              width: 100%;
              height: 536px;
              margin-top: 20px;

              .swiper-container {
                position: relative;
                width: 100%;
                height: 500px;
              }

              .swiper-slide {
                width: 20% !important;
                height: 150px;
                font-size: 14px;
                line-height: 80px;
                border-radius: 8px;
                position: relative;
                align-items: center;
                transition: 300ms;
                transform: scale(0.8);

              }

              .swiper-slide-active,
              .swiper-slide-duplicate-active {
                transform: scale(1);
              }
            }

          }

          .weixincon {
            width: 100%;
            height: auto;

            .weixin_first {
              width: 100%;
              height: auto;
              margin-top: 83px;

              .weixin_first_top {
                width: 100%;
                height: 541px;

                .weixin_first_left {
                  float: left;
                  margin-left: 385px;

                  img {
                    width: 549px;
                    height: 541px;
                  }
                }

                .weixin_first_right {
                  float: left;
                  margin-left: 75px;
                  margin-top: 131px;

                  .text {
                    font-size: 34px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 36px;
                  }

                  .text2 {
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                  }
                }
              }


            }

            .weixin_twice {
              width: 100%;
              height: 400px;

              .weixin_twice_top {
                width: 100%;
                height: 300px;
                position: relative;

                .title_textxt {
                  width: 100%;
                  text-align: center;
                  position: absolute;
                  font-size: 34px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  margin: 30px 0;
                }

                .contab {
                  position: absolute;
                  top: 140px;
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-wrap: nowrap;
                  justify-content: space-between;

                  .contab_con {
                    width: 25%;
                    height: auto;
                    text-align: center;
                    margin: 0 100px;

                    .contab_text {

                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 500;
                      color: #333333;
                      margin: 20px 0;
                    }

                    .contab_main {

                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #999999;
                      line-height: 24px;
                    }
                  }
                }
              }
            }

            .weixin_three {
              width: 111%;
              height: 1559px;
              background: #F5F7FA;
              margin-left: -94px;

              .weixin_three_top {
                width: 100%;
                height: 50%;

                .text {
                  float: left;
                  margin: 74px+258px 0 0 99px;

                  .title {
                    font-size: 34px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 36px;
                  }

                  .main {
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                  }
                }

                .img {
                  float: left;
                  margin: 74px 0 0 419px;
                }


              }

              .weixin_three_bottom {
                width: 100%;
                height: 50%;

                .text {
                  float: left;
                  margin: 20px -547px 0;
                  padding-top: 259px;

                  .title {
                    font-size: 34px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 36px;
                  }

                  .main {
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                  }
                }

                .img {
                  float: right;
                  margin-right: 399px;
                }

              }
            }

            .weixin_four {
              width: 100%;
              height: 800px;

              .weixin_four_top {
                width: 100%;
                height: 100px;
                margin-top: 100px;

                .top_title {
                  text-align: center;
                  width: 100%;
                  font-size: 34px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;


                }

                .top_title2 {
                  margin-top: 19px;
                  text-align: center;
                  width: 100%;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }
              }

              .confouer {
                width: 100%;
                margin-top: 100px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .confouer_con {
                  width: 25%;
                  text-align: center;
                  margin-bottom: 50px;

                  .text {
                    .title {
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 500;
                      color: #333333;
                      margin: 14px 0 19px 0;
                    }

                    .main {
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #999999;
                      line-height: 24px;
                    }
                  }
                }
              }

            }
          }

          .gzhcon {
            width: 100%;
            height: auto;

            .gzh_first {
              width: 100%;
              height: 306px;
              margin-top: 85px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .gzh_first_top {
                width: 100%;
                height: 100px;
                margin-bottom: 50px;

                .top_title {
                  text-align: center;
                  width: 100%;
                  font-size: 34px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;


                }

                .top_title2 {
                  margin-top: 19px;
                  text-align: center;
                  width: 100%;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }
              }

              .gzh_con {
                width: 25%;
                text-align: center;
                margin-bottom: 50px;

                .text {
                  .title {
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    margin: 14px 0 19px 0;
                  }

                  .main {
                    width: 239px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                    margin-left: 102px;
                  }
                }
              }
            }

            .block_11 {
              width: 111%;
              margin-left: -96px;
              height: 985px;
              background-image: url("../../assets/kaiyuan/product_bg.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-top: 98px;

              .text-wrapper_53 {
                width: 100%;
                height: 32px;
                padding-top: 96px;

                .text_30 {
                  width: 100%;
                  height: 32px;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 34px;
                  font-family: SourceHanSansCN-Regular;
                  font-weight: NaN;
                  text-align: center;
                  white-space: nowrap;
                  line-height: 34px;
                }
              }

              .row {
                margin-right: -15px;
                margin-left: -15px;

                .col-xs-6 {
                  width: 50%;
                  position: relative;

                  .zhanshiimg {
                    position: absolute;
                    top: 17px;
                    left: 1059px;

                    img {
                      width: 307px;
                      height: 660px;
                    }
                  }

                  ul {
                    list-style: none;
                    color: #fff;
                  }

                  ul.preview_content {
                    padding-left: 50%;
                    padding-top: 85px;
                  }

                  .preview_content li {
                    width: 280px;
                  }

                  .preview_content li {
                    width: 280px;
                    height: 70px;
                    line-height: 70px;

                    font-size: 20px;
                    padding: 0 40px;
                    text-decoration: none;
                  }

                  .preview_content li span {
                    margin-right: 15px;
                  }




                }
              }

            }

            .gzh_bottom {
              width: 100%;
              height: auto;

              .gzh_bottom_title {
                width: 100%;
                height: auto;
                text-align: center;
                margin-top: 120px;

                .tetx {
                  font-size: 34px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                }

                .main {
                  margin-top: 19px;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                }
              }

              .gzh_bottom_con {
                width: 100%;
                height: auto;

                .container {
                  width: 100%;

                  .nav {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    margin-top: 59px;

                    .btn {
                      width: 330px;
                      height: 140px;
                      background-image: url("../../assets/kaiyuan/kuang.png");
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      text-align: center;

                      .btn_name {
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;

                        margin: 43px 0 0 0;
                      }

                      .btn_mian {
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;

                        line-height: 24px;
                      }
                    }

                    .activee {
                      background-image: url('../../assets/kaiyuan/gzhchagge.png');
                      background-repeat: no-repeat;
                      // background-size: 100% 110%;
                      border: none;
                    }

                    .btn_namee {
                      color: #fff;
                    }

                    .btn_miane {
                      color: #fff;
                    }

                  }

                  .detail {
                    width: 81%;
                    height: 905px;
                    display: flex;
                    flex-wrap: nowrap;
                    margin-left: 160px;
                  }
                }
              }
            }
          }
        }

        .product_con3 {
          width: 100%;
          height: auto;

          .tese_first {
            width: 100%;
            height: auto;

            .text-group_21 {
              width: 100%;
              height: 100%;
              text-align: center;
              margin-top: 78px;
              margin-bottom: 42px;

              .text_19 {

                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 34px;
                font-family: SourceHanSansCN-Bold;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                line-height: 34px;
                margin-left: 52px;
              }

              .text_20 {

                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 18px;
                font-family: SourceHanSansCN-Regular;
                font-weight: NaN;
                text-align: center;
                white-space: nowrap;
                line-height: 18px;
                margin-top: 18px;
              }
            }

            .box_41 {
              width: 100%;
              height: auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;

              .block_5 {

                background-color: rgba(255, 255, 255, 1);
                width: 30%;
                height: 261px;

                .block_5_1 {
                  width: 88%;
                  /* height: 100%; */
                  /* padding: 15px; */
                  margin: 33px auto;

                  .image_12 {
                    width: 100%;
                    text-align: center;

                    img {
                      width: 65px;
                      height: 65px;
                    }
                  }

                  .text-group_22 {
                    width: 100%;
                    height: 48px;
                    text-align: center;

                    .text_21 {

                      overflow-wrap: break-word;
                      // color: rgba(51, 51, 51, 1);
                      font-size: 22px;
                      font-family: SourceHanSansCN-Medium;
                      font-weight: 500;
                      text-align: center;
                      white-space: nowrap;
                      line-height: 22px;
                    }

                    .text_22 {

                      overflow-wrap: break-word;
                      color: #999;
                      font-size: 18px;
                      font-family: SourceHanSansCN-Light;
                      font-weight: 300;
                      white-space: nowrap;
                      line-height: 24px;
                      margin-top: 9px;
                    }
                  }

                }
              }
            }

            .block_5:hover {
              height: 201px;
              background: #FFFFFF;
              border: 1px solid #0274FB;
              box-shadow: 4px 19px 20px 0px rgba(235, 238, 255, 1);
            }
          }

          .tese_sence {
            width: 100%;
            height: 838px;
            background: #F5F5F5;

            .box_43 {
              position: relative;
              width: 100%;
              height: auto;
              margin-bottom: 1px;

              .text_58 {
                padding-top: 90px;
                width: 100%;
                display: block;
                text-align: center;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 34px;
                font-family: SourceHanSansCN-Regular;
                font-weight: NaN;
                white-space: nowrap;
                line-height: 34px;
              }

              .text_59 {
                margin-top: 51px;
                width: 100%;
                display: block;
                margin: 21px 0 51px 0;
                text-align: center;
                overflow-wrap: break-word;
                color: rgba(153, 153, 153, 1);
                font-size: 18px;
                font-family: SourceHanSansCN-Regular;
                font-weight: NaN;
                white-space: nowrap;
              }

              .image-wrapper_4 {
                width: 100%;
                height: 536px;
                margin-top: 20px;

                .swiper-container {
                  position: relative;
                  width: 100%;
                  height: 500px;
                }

                .swiper-slide {
                  width: 20% !important;
                  height: 150px;
                  font-size: 14px;
                  line-height: 80px;
                  border-radius: 8px;
                  position: relative;
                  align-items: center;
                  transition: 300ms;
                  transform: scale(0.8);

                }

                .swiper-slide-active,
                .swiper-slide-duplicate-active {
                  transform: scale(1);
                }

              }

            }
          }

          .tese_three {
            width: 100%;
            height: auto;

            .title {
              width: 100%;
              height: 41px;
              font-size: 42px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              text-align: center;
              padding-top: 109px;
            }

            .box_table {
              width: 100%;
              height: auto;
              margin: 81px 0;
              display: flex;
              flex-wrap: nowrap;

              .box_table1 {
                width: 50%;

                .img {
                  padding-left: 360px;
                }
              }

              .box_table2 {
                width: 30%;
                height: 500px;
                display: flex;
                flex-wrap: wrap;
                margin-left: 60px;
                padding-top: 123px;

                .box_con {
                  width: 50%;
                  text-align: center;

                  .box_txt {
                    margin: 20px 0;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                  }

                }
              }

            }
          }
        }

        .product_con4 {
          width: 100%;
          overflow: hidden;

          .kehuanli {
            width: 100%;
            height: 700px;
            margin-top: 10px;
            margin-bottom: 50px;

            .demo-tabs_first {
              width: 100%;
              height: 100%;
              display: flex;

              .demo-tabs_first1 {
                width: 34%;
                height: 100%;
                margin-top: 40px;
                margin-left: 177px;
              }

              .demo-tabs_first2 {
                width: 50%;
                height: 100%;

                .title_demo {
                  margin-top: 65px;
                  margin-bottom: 20px;
                }

                .title_demo1 {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #333333;
                }

                .title_demo2 {
                  margin: 20px 0 20px 0;
                  font-size: 22px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #666666;

                }

                .title_demo3 {
                  margin: 50px 0 0 0;
                  width: 50%;
                  height: 150px;
                  display: flex;

                  .title_demo3_1 {
                    width: 33.3%;
                    height: 100%;

                    .title_demo3_1_num {
                      font-size: 40px;
                      font-family: DIN;
                      font-weight: bold;
                      color: #FF0017;
                      line-height: 33px;
                    }

                    .title_demo3_1_text {
                      text-align: center;
                      width: 120px;
                      margin-top: 10px;
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #999999;
                    }

                  }

                  .title_demo3_2 {
                    width: 33.3%;
                    height: 100%;

                    .title_demo3_2_num {
                      font-size: 40px;
                      font-family: DIN;
                      font-weight: bold;
                      color: #FF0017;
                      line-height: 33px;
                    }

                    .title_demo3_2_text {
                      text-align: center;
                      width: 120px;
                      margin-top: 10px;
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #999999;
                      line-height: 33px;
                    }
                  }

                  .title_demo3_3 {
                    width: 33.3%;
                    height: 100%;

                    .title_demo3_3_num {
                      font-size: 40px;
                      font-family: DIN;
                      font-weight: bold;
                      color: #FF0017;
                      line-height: 33px;
                    }

                    .title_demo3_3_text {
                      text-align: center;
                      width: 120px;
                      margin-top: 10px;
                      font-size: 22px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #999999;
                      line-height: 33px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /deep/ .el-tabs__item {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }

  /deep/ .el-tabs__content {
    height: 600px;
  }

  /deep/.el-carousel__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /deep/.el-carousel__item {
    flex: 1;
    text-align: center;
    transition: transform 0.3s ease;
  }

  /deep/.el-carousel__item img {
    width: 230px;
    height: 496px;
  }
</style>