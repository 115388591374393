<template>
	<div class="content">
		<!-- 轮播图 -->
		<div class="swiper">
			<el-carousel height="700px" indicator-position="none" arrow="never">
				<!-- <el-carousel-item v-for="item in 4" :key="item"> -->
				<div class="swiper-item">
					<!-- 轮播图 -->
					<img src="../../assets/kaiyuanchanping/chanpinjieshao/banner.png" alt="">
					<!-- 标题 -->
					<div class="killtitle">
						<div class="killtitle_left">
							<img :src="ruanzhuimg" alt="" style="position: relative;z-index: 2;left: -100px;">
						</div>
						<div class="killtitle_right">
							{{products.title}}
							<div class="killtitle1">
								<span>结合APP数据完美同步跳转官方小程序，自购分享更方便</span>
								<div class="hx"></div>
								<div class="butt">
									<div class="button1" @click="buyzixun()">
										<span>购买咨询</span>
									</div>
									<div class="button2" @click="lijibuy()">
										<span>立即购买</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- </el-carousel-item> -->
			</el-carousel>
		</div>
		<!-- 主体内容 -->
		<div class="product">
			<div class="product_content">
				<!-- table 切换 -->
				<div class="product_lable">
					<div class="product_lablechange">
						<ul>
							<li @click="but" data-id="1">产品介绍</li>
							<li @click="but" data-id="2" class="chanpin">{{titlepro}}
								<div class="hovertab" style="z-index: 999;">
									<div class="page flex-col">
										<div class="section_1 flex-col">
											<div class="section_2">
												<div class="block_1" @click="bianse1(item)"
													v-for="(item,index) in changpingfenlei" :key="index">
													<div class="imgg_center">
														<img :src="item.icon" alt="" />
													</div>

													<div class="text-wrapper_3">
														<span class="text_1"
															:class="openWay==item.id?'activeNN':''">{{item.name}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li @click="but" data-id="3">特色功能</li>
							<li @click="but" data-id="4">客户案例</li>
						</ul>
					</div>
					<div class="product_lablecontent">
						<div style="margin-top: 10px;"></div>
						<div v-show="tab==1" class="tapS product_con1" v-if="corecom_list">
							<!--自营一站式解决方案  -->
							<div class="product_lablecontentheard">
								<div class="product_lablecontentheard_one">
									{{corecom_listtitleone}}
								</div>
								<div class="product_lablecontentheard_two">
									{{ corecom_listsubtitleone}}
								</div>
							</div>
							<div class="productjux">
								<ul>

									<li @click="butt(1)" data-id="1" :class="{'bg-blue': isBlue}">
										<span class="numm">01</span><span class="text">{{tabnameone}}</span>
									</li>
									<li @click="butt(2)" data-id="2" :class="{'bg-blue': isBlue1}">
										<span class="numm">02</span><span class="text">{{tabnametwo}}</span>
									</li>
									<li @click="butt(3)" data-id="3" :class="{'bg-blue': isBlue2}">
										<span class="numm">03</span><span class="text">{{tabnamethree}}</span>
									</li>
								</ul>
							</div>
							<div class="productjuxchange">
								<div v-show="tabb==1">
									<div class="productjuxchange1">
										<div class="productjuxchange1_text">
											<span v-html="zhinengquestion.content">
											</span>
										</div>
										<div class="productjuxchange1_img">
											<div class="img">
												<img :src="ziyingimg1.image" style="width: 280px; height: 474px;"
													mode="widthFix">
											</div>
											<div class="phone1">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img" v-if="oneN">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">

													{{oneN}}

												</div>
											</div>
											<div class="phone2" v-if="oneNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{oneNN}}

												</div>
											</div>
											<div class="phone3" v-if="oneNNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ oneNNN}}

												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-show="tabb==2" class="productjuxchange2">
									<div class="productjuxchange1">
										<div class="productjuxchange1_text">
											<span v-html="zhinengquestion1.content">
											</span>
										</div>
										<div class="productjuxchange1_img">
											<div class="img">
												<img :src="ziyingimg2.image" style="width: 280px; height: 474px;">
											</div>
											<div class="phone1" v-if="twoN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ twoN}}

												</div>
											</div>
											<div class="phone2" v-if="twoNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ twoNN}}

												</div>
											</div>
											<div class="phone3" v-if="twoNNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ twoNNN}}

												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-show="tabb==3" class="productjuxchange3">
									<div class="productjuxchange1">
										<div class="productjuxchange1_text">
											<span v-html="zhinengquestion2.content">
											</span>
										</div>
										<div class="productjuxchange1_img">
											<div class="img">
												<img :src="ziyingimg3.image" style="width: 280px; height: 474px;">
											</div>
											<div class="phone1" v-if="threeN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ threeN}}

												</div>
											</div>
											<div class="phone2" v-if="threeNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{ threeNN}}

												</div>
											</div>
											<div class="phone3" v-if="threeNNN">
												<img src="../../assets/kaiyuanchanping/chanpinjieshao/line2.png" alt="">
												<div class="phone1_img">
													<img src="../../assets/kaiyuanchanping/chanpinjieshao/boimg.png"
														alt="">
												</div>
												<div class="phone1_text">
													{{threeNNN}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 产品营销距库 -->
							<div class="yingxiao">
								<div class="product_lablecontentheard">
									<div class="product_lablecontentheard_one">
										{{corecom_listtitletwo}}

									</div>
									<div class="product_lablecontentheard_two">
										{{ corecom_listsubtitletwo}}
									</div>
								</div>
								<div class="yingxiao_content">
									<div class="yingxiao_contenttop">
										<div class="yingxiao_content1" v-for="(item, index) in  xingxiaoku ">
											<img :src="item.image" alt="">
										</div>
									</div>
									<span class="line"></span>
									<div class="yingxiao_contentbottoma">
										<div class="yingxiao_content1" v-for="(item, index) in  xingxiaoku ">
											<div class="radius"></div>
											<div class="text">{{item.name}}</div>
											<div class="line"></div>
										</div>
									</div>
								</div>
							</div>
							<!-- 助您获益的六大核心能力 -->
							<div class="nengli">
								<div class="product_lablecontentheard">
									<div class="product_lablecontentheard_one">
										{{corecom_listtitlethree}}

									</div>
									<div class="product_lablecontentheard_two">
										{{ corecom_listsubtitlethree}}
									</div>
								</div>
								<div class="nengli_cotent">
									<el-tabs v-model="activeName" class="demo-tabs" stretch>
										<div v-for="(item,indx) in heixin" :key="indx">
											<el-tab-pane :label="item.name" :name="indx" class="demo-tabs_first">
												<div class="demo-tabs_first1">
													<img :src="item.image" alt="">
												</div>
												<div class="demo-tabs_first2">
													<div class="title_demo1">
														{{item.name}}
													</div>
													<div class="title_demo2" :innerHTML='item.content'>
														<!-- {{item.content}} -->
													</div>
												</div>
											</el-tab-pane>
										</div>
									</el-tabs>
								</div>
							</div>
							<!-- 高效稳定的系统技术部署 -->
							<div class="xitongbushu">
								<div class="product_lablecontentheard">
									<div class="product_lablecontentheard_one">
										{{bushuname}}

									</div>
									<div class="product_lablecontentheard_two">
										{{bushutitle}}
									</div>
								</div>
								<div class="xitongbushu_con">
									<div class='xitongbushu_con1' v-for="(item,index) in bushu " :key="index"
										:style="{background:colorList[index%4]}">
										<div class="contentt">
											<div class="content_img">

												<img :src="item.image" style="width: 100px; height: 100px;" />
											</div>
											<div class="content_text">
												<div class="type-item-txt1">
													{{item.name}}
												</div>
												<div class="type-item-txt2" :innerHTML='item.content'>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 用户评价框 -->
							<div class="pingjia">
								<!-- 评论组件 -->
								<div class="pingjia_hera">
									<div class="pingjia_hera1">
										<span class="shuxian"></span>
										<span class="user">用户评价</span>
									</div>
								</div>
								<div class="pingjiacomments" style="margin-bottom: 20px;">
									<comment :goosid="goosid"></comment>
								</div>
							</div>
						</div>
						<div v-show="tab==2" class="tapS product_con2">
							<div class="appcon" v-show="appshow">
								<div class="box_40 flex-row justify-between"
									v-if="appshownameone && appshownameone.length > 0">
									<div class="section_16 flex-col justify-between">
										<div class="text-group_20 flex-col justify-between">
											<div class="text_17">{{appshownameone}}</div>
											<div class="text_18">{{appshowsubtitleone}}</div>
										</div>
										<img class="image_11" referrerpolicy="no-referrer" :src="appshowimage" />
									</div>
								</div>
								<div class="block_11 flex-col" v-if="appshownametwo  && appshownametwo.length > 0">
									<div class="text-wrapper_53 flex-row">
										<div class="text_30">{{appshownametwo}}</div>
									</div>
									<div class="row">
										<div class="col-xs-6 col-md-6">
											<ul class="preview_content">
												<li v-for="(item, index) in chanpinlist" :key="index"
													@mouseover="addClass(index)"
													:class="[currentIndex === index ? 'active' : '']">
													<span>{{item.title}}</span>
													<div class="zhanshiimg" v-if="selectedItem === index"
														@mouseover="change()" @mouseleave="removeClass()">
														<img :src="item.image" v-if='imgshow' />
														<el-carousel indicator-position="outside" v-if='imgshow1'
															style="width: 303px; height: 660px;">
															<el-carousel-item v-for="(ite,inde) in item.chanpinimg"
																:key="inde">
																<img :src="ite" />
															</el-carousel-item>
														</el-carousel>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="tese" v-if="appshownamethree  && appshownamethree.length > 0">
									<div class="text-group_21 flex-col justify-between">
										<div class="text_19">{{appshownamethree}}</div>
										<div class="text_20">{{appshowsubtitlethree}}</div>
									</div>
									<div class="box_41 flex-row">
										<div class="block_5 flex-col" v-for="(item,index) in tese" :key='index'>
											<div class="block_5_1">
												<div class="image_12">
													<img :src="item.image" alt="">
												</div>
												<div class="text-group_22 flex-col justify-between"
													style="margin-top: 10px;">
													<div class="text_21">{{item.title}}</div>
													<div class="text_22">{{item.subtitle}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="group_17 flex-col" v-if="appshownamefour && appshownamefour.length > 0">
									<span class="text_49">{{appshownamefour}}</span>
									<div class="group_37 flex-row justify-between">
										<div class="box_13 flex-col justify-center" v-for="(item,index) in  chuxiao"
											:key="index">
											<div class="block_12 flex-col">
												<div class='img'>
													<img class="image_18" referrerpolicy="no-referrer"
														:src="item.image" />
												</div>
												<div class="text_50">{{item.title}}</div>
												<div class="text_51">{{item.subtitle}}</div>
											</div>
											<div class="box_14 flex-col"></div>
										</div>
									</div>
								</div>
								<div class="box_43 flex-col" v-if="appshownamefive && appshownamefive.length > 0">
									<span class="text_58">{{appshownamefive}}</span>
									<span class="text_59">{{appshowsubtitlefive}}</span>
									<div class="image-wrapper_4 flex-row" v-if="aizhineng">
										<div class="swiper-container">
											<div class="swiper-wrapper">
												<div class="swiper-slide" v-for="item in aizhineng" :key="item"
													style="justify-content: space-between;">
													<img :src="item.image" alt="" width="230" height="496">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="weixincon" v-show="weixinshow">
								<div class="weixin_first" v-if="appshowimgsix  && appshowimgsix.length > 0">
									<div class="weixin_first_top">
										<div class="weixin_first_left">
											<img :src="appshowimgsix" alt="">
										</div>
										<div class="weixin_first_right">
											<div class="text">
												{{appshownamesix}}
											</div>
											<div class='text2'>
												{{appshowsubtitlesix}}
											</div>
										</div>
									</div>
								</div>
								<div class="weixin_twice" v-if="appshownameseven && appshownameseven.length > 0">
									<div class="weixin_twice_top">
										<div class="title_textxt"> {{appshownameseven}}</div>
										<div class='contab'>
											<div class="contab_con" v-for="(item,index) in contab" :key="index">
												<div class="pictur">
													<img :src="item.image" alt="">
												</div>
												<div class='contab_text'>{{item.title}}</div>
												<div class='contab_main'>{{item.subtitle}}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="weixin_three" v-if="wxnameeightimg && wxnameeightimg.length > 0">
									<div class="weixin_three_top">
										<div class="img">
											<img :src="wxnameeightimg" alt="">
										</div>
										<div class="text">
											<div class='title'>{{wxnameeight}}</div>
											<div class='main'>{{wxnameeightname}}</div>
										</div>
									</div>
									<div class="weixin_three_bottom" v-if="wxnameninth">
										<div class="text">
											<div class="title">{{wxnameninth}}</div>
											<div class="main">{{wxnameninthname}}</div>
										</div>
										<div class="img">
											<img :src="wxnameninthimg" alt="">
										</div>
									</div>
								</div>
								<div class="weixin_four" v-if="appshownameten && appshownameten.length > 0 ">
									<div class="weixin_four_top">
										<div class="top_title">{{appshownameten}}</div>
										<div class="top_title2">{{appshowsubtitleten}}</div>
									</div>
									<div class="confouer">
										<div class="confouer_con" v-for="(item,index) in wxyycj" :key="index">
											<div class="img">
												<img :src="item.image" alt="">
											</div>
											<div class="text">
												<div class="title">{{item.title}}</div>
												<div class="main">{{item.subtitle}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="gzhcon" v-show="gzhshow">
								<div class="gzh_first" v-if="gzhshownameone && gzhshownameone.length > 0">
									<div class="gzh_first_top">
										<div class="top_title">{{gzhshownameone}}</div>
										<div class="top_title2">{{gzhshowsubtitleone}}</div>
									</div>
									<div class="gzh_con" v-for="(item,index) in gzhcon" :key="index">
										<div class="img">
											<img :src="item.image" alt="">
										</div>
										<div class="text">
											<div class="title">{{item.title}}</div>
											<div class="main">{{item.subtitle}}</div>
										</div>
									</div>
								</div>
								<div class="block_11 flex-col" v-if="appshownametwo && appshownametwo.length > 0">
									<div class="text-wrapper_53 flex-row">
										<div class="text_30">{{appshownametwo}}</div>
									</div>
									<div class="row">
										<div class="col-xs-6 col-md-6">
											<ul class="preview_content">
												<li v-for="(item, index) in chanpinlist" :key="index"
													@mouseover="addClass(index)"
													:class="[currentIndex === index ? 'active' : '']">
													<span>{{item.title}}</span>
													<div class="zhanshiimg" v-if="selectedItem === index"
														@mouseover="change()" @mouseleave="removeClass()">
														<img :src="item.image" v-if='imgshow' />
														<el-carousel indicator-position="outside" v-if='imgshow1'
															style="width: 303px; height: 660px;">
															<el-carousel-item v-for="(ite,inde) in item.chanpinimg"
																:key="inde">
																<img :src="ite" />
															</el-carousel-item>
														</el-carousel>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="gzh_bottom" v-if="appshownamethree && appshownamethree.length > 0">
									<div class="gzh_bottom_title">
										<div class="tetx">{{appshownamethree}}</div>
										<div class='main'>{{gzhshowsubtitlthree}}</div>
									</div>
									<div class="gzh_bottom_con">
										<div class="container">
											<div class="nav">
												<div class="btn" :class="{ activee: itemIndex == index }"
													v-for="(item, index) in btnList" :key="index"
													@click="btnClick(index)">
													<div class="btn_name" :class="{ btn_namee: itemIndex == index }">
														{{ item.title }}</div>
													<div class="btn_mian" :class="{ btn_miane: itemIndex == index }">
														{{item.subtitle}}</div>
												</div>
											</div>
											<div class="detail" v-for="(item,index) in btnList "
												v-show="itemIndex == index"
												style="height: 650px; align-items: center;text-align: center;">
												<img :src="item.image" style="width: 1000px; height:600px;">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="gzhcon" v-show="hfivehshow">
								<div class="gzh_first" v-if="gzhshownameone && gzhshownameone.length > 0">
									<div class="gzh_first_top">
										<div class="top_title">{{gzhshownameone}}</div>
										<div class="top_title2">{{gzhshowsubtitleone}}</div>
									</div>
									<div class="gzh_con" v-for="(item,index) in gzhcon" :key="index">
										<div class="img">
											<img :src="item.image" alt="">
										</div>
										<div class="text">
											<div class="title">{{item.title}}</div>
											<div class="main">{{item.subtitle}}</div>
										</div>
									</div>
								</div>
								<div class="block_11 flex-col" v-if="appshownametwo && appshownametwo.length > 0">
									<div class="text-wrapper_53 flex-row">
										<div class="text_30">{{appshownametwo}}</div>
									</div>
									<div class="row">
										<div class="col-xs-6 col-md-6">
											<ul class="preview_content">
												<li v-for="(item, index) in chanpinlist" :key="index"
													@mouseover="addClass(index)"
													:class="[currentIndex === index ? 'active' : '']">
													<span>{{item.title}}</span>
													<div class="zhanshiimg" v-if="selectedItem === index"
														@mouseover="change()" @mouseleave="removeClass()">
														<img :src="item.image" v-if='imgshow' />
														<el-carousel indicator-position="outside" v-if='imgshow1'
															style="width: 303px; height: 660px;">
															<el-carousel-item v-for="(ite,inde) in item.chanpinimg"
																:key="inde">
																<img :src="ite" />
															</el-carousel-item>
														</el-carousel>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="gzh_bottom" v-if="appshownamethree && appshownamethree.length > 0">
									<div class="gzh_bottom_title">
										<div class="tetx">{{appshownamethree}}</div>
										<div class='main'>{{gzhshowsubtitlthree}}</div>
									</div>
									<div class="gzh_bottom_con">
										<div class="container">
											<div class="nav">
												<div class="btn" :class="{ activee: itemIndex == index }"
													v-for="(item, index) in btnList" :key="index"
													@click="btnClick(index)">
													<div class="btn_name" :class="{ btn_namee: itemIndex == index }">
														{{ item.title }}</div>
													<div class="btn_mian" :class="{ btn_miane: itemIndex == index }">
														{{item.subtitle}}</div>
												</div>
											</div>
											<div class="detail" v-for="(item,index) in btnList "
												v-show="itemIndex == index"
												style="height: 650px; align-items: center;text-align: center;">
												<img :src="item.image" style="width: 1000px; height:600px;">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-show="tab==3" class="tapS product_con3">
							<div class="tese_first">
								<div class="tese">
									<div class="text-group_21 flex-col justify-between">
										<div class="text_19">{{tesenameone}}</div>
										<div class="text_20">{{tesesubtitleone}}</div>
									</div>
									<div class="box_41 flex-row">
										<div class="block_5 flex-col" v-for="(item,index) in tesewzh" :key='index'>
											<div class="block_5_1">
												<div class="image_12">
													<img :src="item.image" alt="">
												</div>
												<div class="text-group_22 flex-col justify-between">
													<div class="text_21">{{item.title}}</div>
													<div class="text_22">
														{{item.subtitle}}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tese_sence">
								<div class="box_43 flex-col">
									<span class="text_58">{{tesenametwo}}</span>
									<span class="text_59">{{tesesubtitletwo}}</span>
									<div class="image-wrapper_4 flex-row" v-if="aizhineng">
										<div class="swiper-container">
											<div class="swiper-wrapper"
												style="transition-duration: 0ms; transform: translate3d(63.1625px, 0px, 0px);">
												<div class="swiper-slide " v-for="(item,index) in aizhineng" :key="item"
													data-id="3">
													<img :src="item.image" style="width: 230px; height: 496px;">

												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							<div class="tese_three">
								<div class='title'>{{tesenamethree}}</div>
								<div class='box_table'>
									<div class="box_table1">
										<div class='img'>
											<img :src="tesenamethreeimage" alt="">
										</div>
									</div>
									<div class="box_table2">
										<div class="box_con" v-for="(item,index) in teliaotian " :key="index">
											<div class="box_img">
												<img :src="item.image" alt="">
											</div>
											<div class="box_txt">
												{{item.title}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-show="tab==4" class="tapS product_con4 ">
							<div class="product_lablecontentheard">
								<div class="product_lablecontentheard_one">
									{{khtitle}}
								</div>
								<div class="product_lablecontentheard_two">
									{{khtitleN}}
								</div>
							</div>
							<div class="kehuanli">
								<div class="nengli_cotent">
									<el-tabs v-model="activeNamee" class="demo-tabs" stretch>
										<div v-for="(item,index) in kehuanli" :key="index">
											<el-tab-pane :label="item.name" :name="index" class="demo-tabs_first">
												<div class="demo-tabs_first1">
													<img :src="item.image" style="width: 405px; height: 502px;">
												</div>
												<div class="demo-tabs_first2">
													<div class="title_demo">
														<img :src="item.icon" style="width: 80px; height: 77px;">
													</div>
													<div class="title_demo1">
														{{item.title}}
													</div>
													<div class="title_demo2" v-html="item.content">
													</div>
													<div class="title_demo3">
														<div class="title_demo3_1">
															<div class="title_demo3_1_num">
																{{item.dis_promotion}}%↑
															</div>
															<div class="title_demo3_1_text">
																分销推厂
															</div>
														</div>
														<div class="title_demo3_2">
															<div class="title_demo3_2_num">
																{{item.overall_income}}%↑
															</div>
															<div class="title_demo3_2_text">
																整体收益
															</div>
														</div>
														<div class="title_demo3_3">
															<div class="title_demo3_3_num">
																{{item.user_traffic}}%↑
															</div>
															<div class="title_demo3_3_text">
																用户流量
															</div>
														</div>
													</div>
												</div>
											</el-tab-pane>
										</div>


									</el-tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 登录弹窗 -->
		<el-dialog v-model="dialogVisibleN" title="登录提示" width="30%" :before-close="handleClose">
			<span>尊敬的用户，你还为登录，请登录后在进行操作</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisibleN = false">取消</el-button>
					<el-button type="primary" @click="gotologin()">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
	<!-- 右侧固钉 -->
	<affix></affix>
</template>

<script>
	import * as CommentData from '../../mockdata/mockdata.js'
	import comment from '../../components/comment.vue'
	import affix from '../../components/affix.vue'
	import Swiper from "swiper";

	export default {
		components: {
			comment,
			affix
		},
		data() {
			return {
				titlepro: '产品中心',
				dialogVisibleN: false,
				products: {},
				productsimg: '',
				itemIndex: 1,
				teliaotian: [{
						img: require('../../assets/kaiyuan/teseimg2.png'),
						text: '用户端版本'
					},
					{
						img: require('../../assets/kaiyuan/teseimg3.png'),
						text: '公众号版本'
					},
					{
						img: require('../../assets/kaiyuan/teseimg4.png'),
						text: '微信小程序'
					},
				],
				btnList: [{
						name: "团队收益",
						mian: '分销推广赚取收益',
						nameContent: [{
								img: require('../../assets/kaiyuan/gzhimg5.png')
							},
							{
								img: require('../../assets/kaiyuan/gzhimg6.png')
							},
						]
					},
					{
						name: "AI聊天",
						mian: 'AI 助理回答您所有问题',
						nameContent: [{
								img: require('../../assets/kaiyuan/gzhimg5.png')
							},
							{
								img: require('../../assets/kaiyuan/gzhimg6.png')
							},
							{
								img: require('../../assets/kaiyuan/gzhimg7.png')
							}
						],
					},
					{
						name: "分销提现",
						mian: '随时结算，自由操控',
					},
				],
				currentIndex: 0,
				hovershow: false,
				imgshow: true,
				imgshow1: false,
				appshow: true,
				weixinshow: false,
				gzhshow: false,
				tabshow2: false,
				backgroundColor: "white",
				textColor: "black",
				backgroundColor2: "white",
				textColor2: "black",
				backgroundColor3: "white",
				textColor3: "black",
				commentData: [],
				activeName: 2,
				activeNamee: 1,
				selectedItem: 0,
				corecom_listtitleone: '',
				corecom_listsubtitleone: '',
				tabnameone: '',
				tabnametwo: '',
				tabnamethree: '',
				contab: [{
						img: require('../../assets/kaiyuan/wximg2.png'),
						text: '更多流量 快速吸粉',
						main: '得益于小程序得天独厚的流量优势 快速吸粉转化为APP粉丝'
					}, {
						img: require('../../assets/kaiyuan/wximg3.png'),
						text: '无需下载 更多流量',
						main: '有微信即可快速进入小程序，一站 式快速分享、聊天'
					},
					{
						img: require('../../assets/kaiyuan/wximg4.png'),
						text: '快速聊天 智能问答',
						main: '为更多用户，提供贴心 的问答服务'
					},
					{
						img: require('../../assets/kaiyuan/wximg5.png'),
						text: '安全稳定 官方平台',
						main: '依托微信官方平台 入口稳定、服务体验佳'
					},
				],
				gzhcon: [{
						img: require('../../assets/kaiyuan/gzhimg1.png'),
						text: '自定义装修 不拘一格',
						main: '依托微信官方平台 入口稳定、服务体验佳'
					},
					{
						img: require('../../assets/kaiyuan/gzhimg2.png'),
						text: '数据打通 实时同步',
						main: '与APP数据完全打通，订单、收益报 表、服务任务全部保持实时更新'
					},
					{
						img: require('../../assets/kaiyuan/gzhimg3.png'),
						text: '统一管理 去繁从简',
						main: '免去繁琐推广，操作便捷，跑腿后台 统一管理，快速设置和传达信息'
					},
					{
						img: require('../../assets/kaiyuan/gzhimg4.png'),
						text: '模版消息 精准推送',
						main: '注册成功、提现结算、统一推送 公告等，系统会自动推送至用户'
					},
				],
				chanpinlist: [],
				tese: [],
				tesewzh: [],
				chuxiao: [],
				wxyycj: [{
						img: require('../../assets/kaiyuan/wximg8.png'),
						text: 'AI全场景聊天',
						main: 'AI智能聊天回答 您所有问题'
					},
					{
						img: require('../../assets/kaiyuan/wximg9.png'),
						text: '选择AI聊天',
						main: '选择AI为你提供贴心服务'
					},
					{
						img: require('../../assets/kaiyuan/wximg10.png'),
						text: '公众号关联',
						main: '在公众号关联小程序， 快速引流'
					},
					{
						img: require('../../assets/kaiyuan/wximg11.png'),
						text: '分销分享',
						main: '分销推广 赚取收益提现'
					},
					{
						img: require('../../assets/kaiyuan/wximg12.png'),
						text: '微信分享群',
						main: '分享小程序到微信群，批量 增加平台粉丝'
					},
					{
						img: require('../../assets/kaiyuan/wximg13.png'),
						text: '收益提现',
						main: '收益快速提现到账'
					},
					{
						img: require('../../assets/kaiyuan/wximg14.png'),
						text: '附近的小程序',
						main: '设置小程序位置 可搜索附近5公里内小程序'
					},
					{
						img: require('../../assets/kaiyuan/wximg15.png'),
						text: '更多精彩',
						main: '省钱兄小程序持续优化中'
					},
				],
				zhinengquestion: {},
				zhinengquestion1: {},
				zhinengquestion2: {},
				xingxiaoku: {

				},
				concent: [], //接受传的参数
				tab: 1,
				tabb: 1,
				isBlue: true,
				isBlue1: false,
				isBlue2: false,
				istop: true,
				istop1: false,
				istop2: false,
				isRedd: false,
				isRed: false,
				purchasedProducts: [],
				goods_id: '',
				icon_type: 1,
				aizhineng: [{
						imglist: require('../../assets/kaiyuan/app1.png')
					},
					{
						imglist: require('../../assets/kaiyuan/app2.png')
					},
					{
						imglist: require('../../assets/kaiyuan/app3.png')
					},
					{
						imglist: require('../../assets/kaiyuan/app4.png')
					},
					{
						imglist: require('../../assets/kaiyuan/app5.png')
					},
				],
				kehuanli: [],
				corecom_list: [], //产品介绍
				goods_center_list: [], //产品中心
				goods_feature_list: [], //特色功能
				heixin: [],
				bushuname: '',
				bushutitle: '',
				bushu: [],
				colorList: ['#F2F6FF', '#F6EDFE', '#EFFBF8', '#FFF3EB'],
				ruanzhuimg: '',
				appshownameone: '',
				appshowsubtitleone: '',
				appshowimage: '',
				appshownametwo: '',
				appshownamethree: '',
				appshowsubtitlethree: '',
				appshownamefour: '',
				appshownamefive: '',
				appshowsubtitlefive: '',
				appshownamesix: '',
				appshowsubtitlesix: '',
				appshowimgsix: '',
				appshownameseven: '',
				wxnameeightimg: '',
				wxnameeight: '',
				wxnameeightname: '',
				wxnameninthimg: '',
				wxnameninth: '',
				wxnameninthname: '',
				appshownameten: '',
				appshowsubtitleten: '',
				gzhshownameone: '',
				gzhshowsubtitleone: '',
				appshownamethree: '',
				gzhshowsubtitlthree: '',
				tesenameone: '',
				tesesubtitleone: '',
				tesenametwo: '',
				tesesubtitletwo: '',
				tesenamethree: "",
				tesenamethreeimage: '',
				goosid: '',
				openWay: -1,
				lableone: [],
				labletwo: [],
				lablethree: [],
				oneN: '',
				oneNN: '',
				oneNNN: '',
				twoN: '',
				twoNN: '',
				twoNNN: '',
				threeN: '',
				threeNN: '',
				threeNNN: '',
				ziyingimg1: {},
				ziyingimg2: {},
				ziyingimg3: {},
				corecom_listtitletwo: '',
				corecom_listsubtitletwo: '',
				corecom_listtitlethree: '',
				corecom_listsubtitlethree: '',
			}
		},
		methods: {
			btnClick(index) {
				this.itemIndex = index
			},
			//接受传的参数
			getParams() {
				if (this.$route.query.item) {
					this.concent = JSON.parse(this.$route.query.item)
				} else {
					this.concent = ''
				}
				// this.concent = JSON.parse(this.$route.query.item)
				console.log(this.concent)
				this.goods_id = this.concent
				this.goosid = this.concent
			},
			//商品详情
			async bykaiyaunxaingqing() {
				const {
					data: res
				} = await this.$http.post('/index/product/kill_product_info', {
					sec_kill_id: this.goods_id,
					icon_type: this.icon_type //1.app  2.小程序  3.公众号  4.H5
				})
				this.products = res.data
				this.ruanzhuimg = this.products.soft_image
				this.changpingfenlei = res.data.adaptation_port
				//产品介绍
				if (res.data && res.data.goods_info.corecom_list) {
					this.corecom_list = res.data.goods_info.corecom_list

					if (this.corecom_list && this.corecom_list.length > 0) {
						if (this.corecom_list[0].length > 0) {
							this.corecom_listtitleone = this.corecom_list[0].title
							this.corecom_listsubtitleone = this.corecom_list[0].subtitle
							if (this.corecom_list[0].children_list.length > 0) {
								this.zhinengquestion = this.corecom_list[0].children_list[0]
								this.tabnameone = this.corecom_list[0].children_list[0].name
								if (this.corecom_list[0].children_list[0].lable) {
									this.lableone = this.corecom_list[0].children_list[0].lable.split(',')
									this.oneN = this.lableone[0]
									this.oneNN = this.lableone[1]
									this.oneNNN = this.lableone[2]
								}
								this.ziyingimg1 = this.corecom_list[0].children_list[0]


							}
							if (this.corecom_list[0].children_list.length > 1) {
								this.zhinengquestion1 = this.corecom_list[0].children_list[1]
								this.tabnametwo = this.corecom_list[0].children_list[1].name
								if (this.corecom_list[0].children_list[1].lable) {
									this.labletwo = this.corecom_list[0].children_list[1].lable.split(',')
									this.twoN = this.labletwo[0]
									this.twoNN = this.labletwo[1]
									this.twoNNN = this.labletwo[2]
								}
								this.ziyingimg2 = this.corecom_list[0].children_list[1]


							}
							if (this.corecom_list[0].children_list.length > 2) {
								this.zhinengquestion2 = this.corecom_list[0].children_list[2]
								this.tabnamethree = this.corecom_list[0].children_list[2].name
								if (this.corecom_list[0].children_list[2].lable) {
									this.lablethree = this.corecom_list[0].children_list[2].lable.split(',')
									this.threeN = this.lablethree[0]
									this.threeNN = this.lablethree[1]
									this.threeNNN = this.lablethree[2]
								}
								this.ziyingimg3 = this.corecom_list[0].children_list[2]

							}

						}
						if (this.corecom_list.length > 1) {
							this.corecom_listtitletwo = this.corecom_list[1].title
							this.corecom_listsubtitletwo = this.corecom_list[1].subtitle
							this.xingxiaoku = this.corecom_list[1].children_list
						}
						if (this.corecom_list.length > 2) {
							this.corecom_listtitlethree = this.corecom_list[2].title
							this.corecom_listsubtitlethree = this.corecom_list[2].subtitle
							this.heixin = this.corecom_list[2].children_list
						}
						if (this.corecom_list.length > 3) {
							this.bushuname = this.corecom_list[3].title
							this.bushutitle = this.corecom_list[3].subtitle
							this.bushu = this.corecom_list[3].children_list
						}


					}
				}
				//产品中心 app端
				if (res.data && res.data.goods_info.goods_center_list.length > 0) {
					this.goods_center_list = res.data.goods_info.goods_center_list
					if (this.goods_center_list.length > 0) {
						if (this.icon_type == 1) {
							//app
							this.appshownameone = this.goods_center_list[0].title
							this.appshowsubtitleone = this.goods_center_list[0].subtitle
							this.appshowimage = this.goods_center_list[0].icon
							//界面预览
							if (this.goods_center_list.length > 1) {
								this.appshownametwo = this.goods_center_list[1].title
								var jiemian = this.goods_center_list[1].children_list
								this.chanpinlist = jiemian
								var chanpinimgarry = []
								this.chanpinlist.forEach((item) => {
									chanpinimgarry.push(item.image)
								})
								var chanpinimg = chanpinimgarry
								for (let i = 0; i < this.chanpinlist.length; i++) {
									this.chanpinlist[i].chanpinimg = chanpinimg
								}
							}

							//特色
							if (this.goods_center_list.length > 2) {
								this.appshownamethree = this.goods_center_list[2].title
								this.appshowsubtitlethree = this.goods_center_list[2].subtitle
								this.tese = this.goods_center_list[2].children_list
							}
							if (this.goods_center_list.length > 3) {
								this.appshownamefour = this.goods_center_list[3].title
								this.chuxiao = this.goods_center_list[3].children_list
							}

							//轮播
							if (this.goods_center_list.length > 4) {
								this.appshownamefive = this.goods_center_list[4].title
								this.appshowsubtitlefive = this.goods_center_list[4].subtitle
								this.aizhineng = this.goods_center_list[4].children_list
							}

						}
						if (this.icon_type == 2) {
							//产品中心 小程序
							this.goods_center_list = res.data.goods_center_list
							if (this.goods_center_list.length > 0) {
								this.appshownamesix = this.goods_center_list[0].title
								this.appshowsubtitlesix = this.goods_center_list[0].subtitle
								this.appshowimgsix = this.goods_center_list[0].icon
							}
							if (this.goods_center_list.length > 1) {
								this.appshownameseven = this.goods_center_list[1].title
								this.contab = this.goods_center_list[1].children_list
							}
							if (this.goods_center_list.length > 2) {
								this.wxnameeightimg = this.goods_center_list[2].icon
								this.wxnameeight = this.goods_center_list[2].title
								this.wxnameeightname = this.goods_center_list[2].subtitle
							}
							if (this.goods_center_list.length > 3) {
								this.wxnameninthimg = this.goods_center_list[3].icon
								this.wxnameninth = this.goods_center_list[3].title
								this.wxnameninthname = this.goods_center_list[3].subtitle
							}
							if (this.goods_center_list.length > 4) {
								this.appshownameten = this.goods_center_list[4].title
								this.appshowsubtitleten = this.goods_center_list[4].subtitle
								this.wxyycj = this.goods_center_list[4].children_list
							}

						}
						if (this.icon_type == 3) {
							//产品中心 小程序
							this.goods_center_list = res.data.goods_center_list

							if (this.goods_center_list.length > 0) {
								this.gzhshownameone = this.goods_center_list[0].title
								this.gzhshowsubtitleone = this.goods_center_list[0].subtitle
								this.gzhcon = this.goods_center_list[0].children_list
							}
							//界面预览
							if (this.goods_center_list.length > 1) {
								this.appshownametwo = this.goods_center_list[1].title
								var jiemian = this.goods_center_list[1].children_list
								this.chanpinlist = jiemian
								var chanpinimgarry = []
								this.chanpinlist.forEach((item) => {
									chanpinimgarry.push(item.image)
								})
								var chanpinimg = chanpinimgarry
								for (let i = 0; i < this.chanpinlist.length; i++) {
									this.chanpinlist[i].chanpinimg = chanpinimg
								}
							}

							if (this.goods_center_list.length > 2) {
								this.appshownamethree = this.goods_center_list[2].title
								this.gzhshowsubtitlthree = this.goods_center_list[2].subtitle
								this.btnList = this.goods_center_list[2].children_list
							}

						}
					}
				}
				//特色功能
				if (res.data && res.data.goods_info.goods_feature_list) {
					this.goods_feature_list = res.data.goods_info.goods_feature_list
					if (this.goods_feature_list) {
						if (this.goods_center_list.length > 0) {
							this.tesenameone = this.goods_feature_list[0].title
							this.tesesubtitleone = this.goods_feature_list[0].subtitle
							this.tesewzh = this.goods_feature_list[0].children_list
						}
						if (this.goods_center_list.length > 1) {
							this.tesenametwo = this.goods_feature_list[1].title
							this.tesesubtitletwo = this.goods_feature_list[1].subtitle
							this.aizhineng = this.goods_feature_list[1].children_list
						}
						if (this.goods_center_list.length > 2) {
							this.tesenamethree = this.goods_feature_list[2].title
							this.tesenamethreeimage = this.goods_feature_list[2].icon
							this.teliaotian = this.goods_feature_list[2].children_list
						}



					}
				}
			},
			//客户案例
			async customer_list() {
				const {
					data: res
				} = await this.$http.post('index/product/customer_list', {
					goods_id: this.goods_id,
				})
				if (res.code == 1) {
					this.kehuanli = res.data
				}
			},
			but: function(e) {
				let tabid = e.target.dataset.id
				this.tab = tabid
				if (this.tab == 4) {
					this.customer_list()
				}
			},
			dianjichange(index) {
				if (index == 1) {
					this.isRed = true
					this.isRedd = false
				} else if (index == 2) {
					this.isRedd = true
					this.isRed = false
				}
			},
			butt(e) {
				if (e == 1) {
					this.isBlue = true
					this.isBlue1 = false
					this.isBlue2 = false

				} else if (e == 2) {
					this.isBlue = false
					this.isBlue1 = true
					this.isBlue2 = false

				} else if (e == 3) {
					this.isBlue = false
					this.isBlue1 = false
					this.isBlue2 = true
				}
				let tabid = e
				this.tabb = tabid
			},
			bianse1(item) {
				event.stopPropagation();
				this.openWay = item.id
				this.icon_type = item.id
				this.titlepro = item.name
				this.tab = 2
				this.bykaiyaunxaingqing()
				// 1.app  2.小程序  3.公众号  4.H5
				if (this.openWay == 1) {
					this.appshow = true
					this.weixinshow = false
					this.gzhshow = false
					this.hfivehshow = false
				} else if (this.openWay == 2) {
					this.weixinshow = true
					this.appshow = false
					this.gzhshow = false
					this.hfivehshow = false
				} else if (this.openWay == 3) {
					this.gzhshow = true
					this.appshow = false
					this.weixinshow = false
					this.hfivehshow = false
				} else if (this.openWay == 4) {
					this.gzhshow = false
					this.appshow = false
					this.weixinshow = false
					this.hfivehshow = true
				}
			},
			buyzixun() {
				var itemcon = JSON.stringify(this.concent)
				this.$router.push({
					path: '/chatindex',
					query: {
						item: itemcon
					}
				})
			},
			gotologin() {
				this.$router.push({
					name: 'login',
				})
			},
			lijibuy() {
				let userId = window.sessionStorage.getItem('token');
				if (userId == '' || userId == null) {
					this.$confirm(
						'当前未登录，是否登录。',
						'提示', {
							confirmButtonText: '去登录',
							cancelButtonText: '取消',
							type: 'warning',
						}
					).then(() => {
						this.$router.push({
							path: 'login',
						})
					}).catch(() => {

					})
				} else {
					this.$router.push({
						name: 'empower',
						query: {
							buy_type: 2,
						}
					})
				}
			},
			addClass(index) {
				this.currentIndex = index
				this.selectedItem = index;
			},
			change() {
				this.imgshow = false
				this.imgshow1 = true
			},
			removeClass() {
				this.imgshow1 = false
				this.imgshow = true
			}

		},
		created() {
			this.getParams();
			this.bykaiyaunxaingqing();
		},
		mounted() {
			new Swiper(".swiper-container", {
				direction: "horizontal",
				speed: 600,
				loop: true,
				autoplay: false,
				observer: true,
				observeParents: true,
				effect: "coverflow",
				slidesPerView: "auto",
				centeredSlides: true,
				coverflowEffect: {
					rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
					stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
					depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
					modifier: 5,
					slideShadows: false, //开启slide阴影。默认 true。
				},
			});
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-carousel__container {
		height: 660px;
	}

	.active {
		background-color: #f5f7fa;
		border-right: 8px solid #0274FB;
		color: #0274FB;
	}

	.activee {
		background-image: url('../../assets/kaiyuan/gzhchagge.png');
		background-repeat: no-repeat;
		// background-size: 100% 110%;
		border: none;
	}

	.btn_namee {
		color: #fff;
	}

	.btn_miane {
		color: #fff;
	}



	.text {
		.numm {
			position: relative;
			top: 31px;
			font-size: 70px;
			font-family: DIN;
			font-weight: bold;
			color: white;
			line-height: 2px;
		}

		.text {
			position: relative;
			top: 18px;
			font-size: 26px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: white;
			line-height: 2px;
		}
	}

	.content {
		background: #EFF7FF;

	}

	.swiper {
		width: 100%;
		height: 700px;
		position: relative;

		.swiper-item {
			width: 100%;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.swiper-item-gg {
				position: absolute;
				bottom: 20px;
				left: 50%;
				transform: translate(-50%, 0);
				width: 80%;
				height: 80px;
				background: rgba(255, 255, 255, 0.7);
				border-radius: 16px;
				padding: 0 50px;
				color: #0D68FC;
				font-size: 22px;

				img {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
			}

			.killtitle {
				width: 100%;
				display: flex;

				.killtitle_left {
					width: 50%;
					position: absolute;
					top: 130px;
					left: 254px;

					img {
						width: 530px;
						height: 745px;
					}
				}

				.killtitle_right {
					width: 50%;
					position: absolute;
					top: 234px;
					right: 100px;
					font-size: 45px;
					font-weight: 900;
					font-family: 'Source Han Sans', sans-serif;
					color: white;


				}

				.killtitle1 {
					span {
						display: inline-block;
						margin-top: 20px;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
					}

					.hx {
						margin-top: 37px;
						width: 78px;
						height: 8px;
						background: #FFFFFF;
					}

					.butt {
						width: 100%;
						height: 71px;
						margin-top: 107px;
						display: flex;

						.button1 {
							width: 229px;
							height: 80px;
							background: #FFFFFF;
							border: 1px solid #63FFFD;
							border-radius: 40px;
							text-align: center;
							margin-right: 50px;
							border: none;

							span {
								font-size: 26px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #0D68FC;
								line-height: 44px;
							}
						}

						.button2 {
							width: 229px;
							height: 80px;
							background: #FFFFFF;
							border: 1px solid #63FFFD;
							border-radius: 40px;
							text-align: center;
							border: none;

							span {
								font-size: 26px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #0D68FC;
								line-height: 44px;
							}
						}
					}
				}
			}
		}
	}

	.product {
		width: 100%;
		overflow: hidden;
		// height: 5000px;
		background-color: #FFFFFF;


		.product_content {
			width: 90%;
			height: 100%;
			margin: 0 auto;

			.product_lable {
				width: 100%;
				height: 100%;

				.product_lablechange {
					ul {
						width: 700px;
						display: flex;
						justify-content: space-between;
						margin-left: 451px;
						margin-top: 0px;
					}

					li {
						width: 158px;
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #0D68FC;
						text-align: center;
						display: inline-block;
						margin-top: 80px;
					}

					.chanpin:hover .hovertab {
						display: block;
					}

					.hovertab {
						position: absolute;
						display: none;

						.page {
							position: relative;
							left: -147px;
						}

						.section_1 {
							margin: 16px 0 0 9px;
						}

						.section_2 {
							background-color: #f2f6fd;
							border-radius: 24px;
							height: 156px;
							width: 424px;
							padding: 20px;
							flex-wrap: wrap;
							display: flex;
							justify-content: space-evenly;
						}

						.block_1 {
							background-color: #FFFFFF;
							border-radius: 10px;
							width: 194px;
							height: 70px;
							border: 1px solid rgba(13, 104, 252, 1);
							display: flex;
							align-items: center;

							.imgg_center {
								width: 50px;
								height: 50px;
								background-color: #0D68FC;
								border-radius: 100%;
								margin-left: 20px;
							}
						}

						.label_3 {
							width: 30px;
							height: 30px;
							margin: 19px 0 0 13px;
						}

						.text-wrapper_3 {
							width: 122px;
							height: 39px;
							margin: 15px 17px 0 12px;
						}

						.text_1 {
							width: 52px;
							height: 16px;
							overflow-wrap: break-word;
							// color: rgba(13, 104, 252, 1);
							color: #000000;
							font-size: 18px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 18px;
						}

						.text_2 {
							width: 122px;
							height: 15px;
							overflow-wrap: break-word;
							// color: rgba(82, 149, 255, 1);
							color: #000000;
							font-size: 14px;
							font-family: SourceHanSansCN-Normal;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 14px;
							margin-top: 8px;
						}

						.block_3 {
							background-color: rgba(255, 255, 255, 1);
							border-radius: 10px;
							width: 194px;
							height: 70px;
							border: 1px solid rgba(13, 104, 252, 1);
							display: flex;
							margin-top: 10px;
						}

						.label_4 {
							width: 30px;
							height: 30px;
							margin: 22px 0 0 13px;
						}

						.text-wrapper_4 {
							width: 113px;
							height: 38px;
							margin: 17px 27px 0 11px;
						}

						.text_3 {
							width: 71px;
							height: 17px;
							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 18px;
						}

						.text_4 {
							width: 112px;
							height: 15px;
							overflow-wrap: break-word;
							color: rgba(153, 153, 153, 1);
							font-size: 14px;
							font-family: SourceHanSansCN-Normal;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 14px;
							margin: 6px 0 0 1px;
						}

						.section_3 {
							width: 194px;
							height: 70px;
							margin: 21px 33px 22px 25px;

						}

						.box_3 {
							background-color: rgba(255, 255, 255, 1);
							border-radius: 10px;
							width: 194px;
							height: 70px;
							border: 1px solid rgba(13, 104, 252, 1);
							display: flex;
						}

						.label_5 {
							width: 30px;
							height: 30px;
							margin: 19px 0 0 13px;
						}

						.text-group_2 {
							width: 122px;
							height: 39px;
							margin: 15px 17px 0 12px;
						}

						.text_5 {
							width: 71px;
							height: 17px;
							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 18px;
						}

						.text_6 {
							width: 70px;
							height: 14px;
							overflow-wrap: break-word;
							color: rgba(153, 153, 153, 1);
							font-size: 14px;
							font-family: SourceHanSansCN-Normal;
							font-weight: NaN;
							text-align: left;
							white-space: nowrap;
							line-height: 14px;
							margin-top: 7px;
						}
					}

					li:hover {
						border-bottom: 2px solid #0D68FC;
					}


				}

				.product_lablecontent {
					width: 100%;

					.product_lablecontentheard {
						width: 100%;
						height: 150px;
						text-align: center;

						.product_lablecontentheard_one {
							font-size: 34px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #333333;
							margin-top: 50px;
						}

						.product_lablecontentheard_two {
							font-size: 18px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							margin-top: 30px;
						}
					}

					.product_con1 {
						width: 100%;
						overflow: hidden;

						.productjux {
							width: 100%;
							height: 103px;
							background: #81B0FD;
							border-radius: 0px 6px 18px 18px;

							.bg-blue {
								background-image: url('../../assets/kaiyuanchanping/chanpinjieshao/dibuimg.png');
								background-repeat: no-repeat;
								background-size: 100% 100%;
							}

							ul {
								margin: 0;
								padding: 0;
								width: 100%;
								display: flex;

							}

							li {
								width: 33.3%;
								list-style-type: none;
								height: 116px;
								line-height: 106px;
								text-align: center;

								.numm {
									font-size: 70px;
									font-family: DIN;
									font-weight: bold;
									color: white;
								}

								.text {
									font-size: 26px;
									height: 116px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: white;
								}
							}
						}

						.productjuxchange {
							width: 100%;
							height: 680px;
							background: #F6F9FF;

							.productjuxchange1 {
								width: 100%;
								height: 100%;
								display: flex;
								position: relative;

								.productjuxchange1_text {
									width: 50%;
									height: 100%;

									span {
										font-size: 24px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #333333;
										// line-height: 300px;
										margin-left: 83px;
										margin-top: 300px;
										display: inline-block;
									}
								}

								.productjuxchange1_img {
									width: 50%;
									height: 100%;
									margin: 0 auto;
									position: relative;

									.img {
										position: absolute;
										top: 98px;
										left: 275px;
									}

									.phone1 {
										position: absolute;
										top: 173px;
										left: 120px;

										.phone1_img {
											position: absolute;
											top: 26px;
											left: 110px;
										}

										.phone1_text {
											width: 128px;
											text-align: center;
											position: absolute;
											top: 24px;
											left: -20px;
											font-size: 16px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #666666;
										}
									}

									.phone2 {
										position: absolute;
										top: 358px;
										left: 120px;

										.phone1_img {
											position: absolute;
											top: 26px;
											left: 110px;
										}

										.phone1_text {
											width: 128px;
											text-align: center;
											position: absolute;
											top: 15px;
											left: -14px;
											font-size: 16px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #666666;
										}
									}

									.phone3 {
										position: absolute;
										top: 173px;
										left: 586px;

										.phone1_img {
											position: absolute;
											top: 26px;
											left: 10px;
										}

										.phone1_text {
											width: 128px;
											text-align: center;
											position: absolute;
											top: 20px;
											left: 38px;
											font-size: 16px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #666666;
										}
									}
								}
							}
						}
					}

					.yingxiao {
						width: 100%;
						height: 374px;


						.yingxiao_content {
							position: absolute;
							left: -20px;
							width: 100%;
							height: 374px;
							// background-color: #333333;
							background-image: url('../../assets/bj.png');
							background-size: 100%;
							background-repeat: no-repeat;
							display: flex;
							flex-wrap: wrap;

							.yingxiao_contenttop {
								width: 100%;
								height: 50%;
								display: flex;
								justify-content: space-evenly;

								.yingxiao_content1 {
									margin-top: 50px;
								}

								.yingxiao_content2 {
									margin-top: 50px;
								}

								.yingxiao_content3 {
									margin-top: 50px;
								}

								.yingxiao_content4 {
									margin-top: 50px;
								}

							}

							.line {
								width: 100%;
								border: 0px solid #FFFFFF;
								background-color: #FFFFFF;
							}

							.yingxiao_contentbottoma {
								width: 100%;
								// height: 50%;
								display: flex;
								justify-content: space-evenly;

								.yingxiao_content1 {
									.radius {
										width: 15px;
										height: 15px;
										background: #FFFFFF;
										border-radius: 50%;
										margin: 20px 0 20px 58px;
									}

									.text {
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #FFFFFF;
										width: 135px;
										text-align: center;
									}

									.line {
										width: 35px;
										// height: 4px;
										border: 0px solid #FFFFFF;
										margin: 20px 0 20px 44px;
									}
								}

								.yingxiao_content2 {
									.radius {
										width: 15px;
										height: 15px;
										background: #FFFFFF;
										border-radius: 50%;
										margin: 20px 0 20px 58px;

									}

									.text {
										width: 128px;
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #FFFFFF;
										text-align: center;
									}

									.line {
										width: 35px;
										height: 4px;
										border: 4px solid #FFFFFF;
										margin: 20px 0 20px 44px;
									}
								}

								.yingxiao_content3 {
									.radius {
										width: 15px;
										height: 15px;
										background: #FFFFFF;
										border-radius: 50%;
										margin: 20px 0 20px 58px;
									}

									.text {
										width: 128px;
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #FFFFFF;
										text-align: center;
									}

									.line {
										width: 35px;
										height: 4px;
										border: 4px solid #FFFFFF;
										margin: 20px 0 20px 44px;
									}
								}

								.yingxiao_content4 {
									.radius {
										width: 15px;
										height: 15px;
										background: #FFFFFF;
										border-radius: 50%;
										margin: 20px 0 20px 58px;
									}

									.text {
										width: 128px;
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #FFFFFF;
										text-align: center;
									}

									.line {
										width: 35px;
										height: 4px;
										border: 4px solid #FFFFFF;
										margin: 20px 0 20px 44px;
									}
								}


							}
						}
					}

					.nengli {
						width: 100%;
						height: 500px;
						margin-top: 200px;

						.nengli_cotent {
							width: 100%;
							height: 100%;

							.demo-tabs_first {
								width: 100%;
								height: 100%;
								display: flex;

								.demo-tabs_first1 {
									width: 50%;
									height: 100%;
									margin-top: 100px;
									margin-left: 3px;
								}

								.demo-tabs_first2 {
									width: 50%;
									height: 100%;
									margin-top: 100px;

									.title_demo1 {
										font-size: 32px;
										font-family: Source Han Sans CN;
										font-weight: bold;
										color: #333333;
									}

									.title_demo2 {
										margin: 20px 0 20px 0;
										font-size: 22px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #666666;

									}

									.title_demo3 {
										margin: 20px 0 20px 0;
										font-size: 22px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #666666;
									}

									.title_demo4 {
										margin: 20px 0 20px 0;
										font-size: 22px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #666666;
									}
								}
							}
						}
					}

					.xitongbushu {
						width: 100%;
						height: 500px;
						margin-top: 250px;

						.xitongbushu_con {
							width: 100%;
							height: 100%;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-evenly;

							.xitongbushu_con1 {
								width: 45%;
								height: 40%;
								margin-bottom: 50px;
								border-radius: 16px;

								.contentt {
									width: 80%;
									height: 80%;
									margin: 0 auto;
									display: flex;
									justify-content: space-between;

									.content_img {
										width: 30%;

										img {
											margin-top: 54px;
											margin-left: 17px;
										}
									}

									.content_text {
										width: 70%;
										margin-top: 44px;

										.type-item-txt1 {
											font-size: 32px;
											font-family: Source Han Sans CN;
											font-weight: bold;
										}

										.type-item-txt2 {
											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											margin-top: 20px;
										}
									}
								}
							}
						}
					}

					.pingjia {
						width: 100%;
						margin-top: 190px;
						background: #FAFAFA;
						border-radius: 12px;

						.pingjia_hera {
							width: 100%;
							height: 50px;

							.pingjia_hera1 {
								float: left;
								position: relative;

								.shuxian {
									margin-left: 34px;
									display: inline-block;
									width: 8px;
									height: 34px;
									margin-top: 18px;
									background: #0D68FC;
								}

								.user {
									position: absolute;
									top: 15px;
									left: 53px;
									width: 200px;
									font-size: 28px;
									font-family: Source Han Sans CN;
									font-weight: 500;
									color: #333333;

								}
							}

							.pingjia_hera2 {
								float: right;
								margin-top: 18px;

								.moren {
									width: 111px;
									height: 27px;
									font-size: 28px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #999999;
									line-height: 42px;
								}

								.shu {
									display: inline-block;
									width: 2px;
									height: 18px;
									color: #B3B3B3;
									padding: 0 20px;
								}

								.zuixin {
									width: 111px;
									height: 27px;
									font-size: 28px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #999999;
									line-height: 42px;
									margin-right: 51px;
								}
							}
						}

						.pingjiacomments {
							width: 95%;
							height: 100%;
							margin: 0 auto;
						}
					}
				}

				.product_con2 {
					width: 100%;


					.box_40 {
						width: 1506px;
						height: 715px;
						margin: 0 auto;

						.section_16 {
							width: 100%;
							height: 715px;
							text-align: center;
							margin: 84px 0;

							.text-group_20 {
								width: 100%;
								height: 70px;
								text-align: center;

								.text_17 {

									height: 32px;
									overflow-wrap: break-word;
									color: rgba(51, 51, 51, 1);
									font-size: 34px;
									font-family: SourceHanSansCN-Regular;
									font-weight: NaN;
									text-align: center;
									white-space: nowrap;
									line-height: 34px;
								}

								.text_18 {

									height: 19px;
									overflow-wrap: break-word;
									color: rgba(153, 153, 153, 1);
									font-size: 18px;
									font-family: SourceHanSansCN-Regular;
									font-weight: NaN;
									text-align: center;
									white-space: nowrap;
									line-height: 27px;
									margin-top: 19px;
								}
							}

							.image_11 {
								width: 1188px;
								height: 568px;
								margin-top: 77px;
							}
						}

						.section_17 {
							width: 101px;
							height: 472px;
							margin-top: 66px;

							.section_5 {
								box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
								background-color: rgba(255, 255, 255, 1);
								border-radius: 14px;
								width: 100px;
								height: 100px;

								.image-text_24 {
									width: 64px;
									height: 52px;
									margin: 25px 0 0 18px;

									.label_2 {
										width: 33px;
										height: 28px;
										margin-left: 19px;
									}

									.text-group_2 {
										width: 64px;
										height: 16px;
										overflow-wrap: break-word;
										color: rgba(102, 102, 102, 1);
										font-size: 16px;
										font-family: SourceHanSansCN-Regular;
										font-weight: NaN;
										text-align: left;
										line-height: 2px;
										margin-top: 8px;
									}
								}
							}

							.section_6 {
								box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
								background-color: rgba(255, 255, 255, 1);
								border-radius: 14px;
								width: 98px;
								height: 100px;
								margin: 25px 0 0 3px;

								.image-text_25 {
									width: 63px;
									height: 53px;
									margin: 24px 0 0 19px;

									.label_3 {
										width: 30px;
										height: 30px;
										margin-left: 16px;
									}

									.text-group_3 {
										width: 63px;
										height: 16px;
										overflow-wrap: break-word;
										color: rgba(13, 104, 252, 1);
										font-size: 16px;
										font-family: SourceHanSansCN-Regular;
										font-weight: NaN;
										text-align: left;
										line-height: 2px;
										margin-top: 7px;
									}
								}
							}

							.section_7 {
								box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
								background-color: rgba(255, 255, 255, 1);
								border-radius: 14px;
								width: 98px;
								height: 100px;
								margin: 24px 0 0 3px;

								.image-text_26 {
									width: 64px;
									height: 52px;
									margin: 26px 0 0 18px;

									.label_4 {
										width: 30px;
										height: 33px;
										margin-left: 17px;
									}

									.text-group_4 {
										width: 64px;
										height: 16px;
										overflow-wrap: break-word;
										color: rgba(102, 102, 102, 1);
										font-size: 16px;
										font-family: SourceHanSansCN-Regular;
										font-weight: NaN;
										text-align: left;
										line-height: 2px;
										margin-top: 3px;
									}
								}
							}

							.section_8 {
								box-shadow: 0px 2px 10px 0px rgba(4, 86, 202, 0.08);
								background-color: rgba(255, 255, 255, 1);
								border-radius: 14px;
								width: 98px;
								height: 100px;
								margin: 23px 0 0 3px;

								.image-text_27 {
									width: 63px;
									height: 52px;
									margin: 26px 0 0 18px;

									.label_5 {
										width: 31px;
										height: 31px;
										margin-left: 17px;
									}

									.text-group_5 {
										width: 63px;
										height: 15px;
										overflow-wrap: break-word;
										color: rgba(102, 102, 102, 1);
										font-size: 16px;
										font-family: SourceHanSansCN-Regular;
										font-weight: NaN;
										text-align: left;
										line-height: 2px;
										margin-top: 6px;
									}
								}
							}
						}
					}

					.block_11 {
						width: 111%;
						margin-left: -96px;
						height: 985px;
						background-image: url("../../assets/kaiyuan/product_bg.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
						margin-top: 98px;

						.text-wrapper_53 {
							width: 100%;
							height: 32px;
							padding-top: 96px;

							.text_30 {
								width: 100%;
								height: 32px;
								overflow-wrap: break-word;
								color: rgba(255, 255, 255, 1);
								font-size: 34px;
								font-family: SourceHanSansCN-Regular;
								font-weight: NaN;
								text-align: center;
								white-space: nowrap;
								line-height: 34px;
							}
						}

						.row {
							margin-right: -15px;
							margin-left: -15px;

							.col-xs-6 {
								width: 50%;
								position: relative;

								.zhanshiimg {
									position: absolute;
									top: 17px;
									left: 1059px;

									img {
										width: 307px;
										height: 660px;
									}
								}

								ul {
									list-style: none;
									color: #fff;
								}

								ul.preview_content {
									padding-left: 50%;
									padding-top: 85px;
								}

								.preview_content li {
									width: 280px;
								}

								.preview_content li {
									width: 280px;
									height: 70px;
									line-height: 70px;

									font-size: 20px;
									padding: 0 40px;
									text-decoration: none;
								}

								.preview_content li span {
									margin-right: 15px;
								}




							}
						}

					}

					.text-group_21 {
						width: 100%;
						height: 100%;
						text-align: center;
						margin-top: 78px;
						margin-bottom: 42px;

						.text_19 {

							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 34px;
							font-family: SourceHanSansCN-Bold;
							font-weight: 700;
							text-align: center;
							white-space: nowrap;
							line-height: 34px;
							margin-left: 52px;
						}

						.text_20 {

							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							text-align: center;
							white-space: nowrap;
							line-height: 18px;
							margin-top: 18px;
						}
					}

					.box_41 {
						width: 100%;
						height: auto;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-evenly;

						.block_5 {

							background-color: rgba(255, 255, 255, 1);
							width: 30%;
							height: 261px;

							.block_5_1 {
								width: 88%;
								/* height: 100%; */
								/* padding: 15px; */
								margin: 33px auto;

								.image_12 {
									width: 100%;
									text-align: center;

									img {
										width: 65px;
										height: 65px;
									}
								}

								.text-group_22 {
									width: 100%;
									height: 48px;
									text-align: center;

									.text_21 {

										overflow-wrap: break-word;
										// color: rgba(51, 51, 51, 1);
										font-size: 22px;
										font-family: SourceHanSansCN-Medium;
										font-weight: 500;
										text-align: center;
										white-space: nowrap;
										line-height: 22px;
									}

									.text_22 {

										overflow-wrap: break-word;
										color: #999;
										font-size: 18px;
										font-family: SourceHanSansCN-Light;
										font-weight: 300;
										white-space: nowrap;
										line-height: 24px;
										margin-top: 9px;
									}
								}

							}
						}
					}

					.block_5:hover {
						height: 201px;
						background: #FFFFFF;
						border: 1px solid #0274FB;
						box-shadow: 4px 19px 20px 0px rgba(235, 238, 255, 1);
					}

					.group_17 {
						background-color: #f5f7fa;
						position: relative;
						width: 111%;
						height: 986px;
						margin-top: -1px;
						margin-left: -94px;

						.text_49 {
							display: block;
							width: 100%;
							text-align: center;
							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 34px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							text-align: center;
							white-space: nowrap;
							padding-top: 20px+80px;
						}

						.group_37 {
							width: 90%;
							/* height: 340px; */
							margin: 62px auto;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-evenly;

							.box_13 {
								background-color: #0274fb;
								border-radius: 10px;
								height: 300px;
								width: 680px;
								margin-bottom: 54px;

								.block_12 {
									background-color: rgba(255, 255, 255, 1);
									width: 680px;
									height: 303px;

									.image_18 {
										width: 95px;
										height: 95px;
										margin: 29px 0 0 24px;
									}

									.text_50 {
										width: 476px;
										height: 24px;
										overflow-wrap: break-word;
										color: #333333;
										font-size: 24px;
										font-family: SourceHanSansCN-Regular;
										font-weight: 400;
										white-space: nowrap;
										line-height: 35px;
										margin: 23px 0 0 34px;
									}

									.text_51 {
										width: 593px;
										height: 42px;
										overflow-wrap: break-word;
										color: rgba(153, 153, 153, 1);
										font-size: 16px;
										font-family: SourceHanSansCN-Regular;
										text-align: left;
										line-height: 23px;
										margin: 19px 0 71px 34px;
									}
								}
							}

							.box_14 {
								width: 680px;
								height: 8px;
								background: #E6E6E6;
								border-radius: 10px;
							}
						}
					}

					.box_43 {
						position: relative;
						width: 100%;
						height: auto;
						margin-bottom: 1px;

						.text_58 {
							margin-top: 45px;
							width: 100%;
							display: block;
							text-align: center;
							overflow-wrap: break-word;
							color: rgba(51, 51, 51, 1);
							font-size: 34px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							white-space: nowrap;
							line-height: 34px;
						}

						.text_59 {
							margin-top: 51px;
							width: 100%;
							display: block;
							margin: 21px 0 0 0;
							text-align: center;
							overflow-wrap: break-word;
							color: rgba(153, 153, 153, 1);
							font-size: 18px;
							font-family: SourceHanSansCN-Regular;
							font-weight: NaN;
							white-space: nowrap;
							line-height: 18px;
						}

						.image-wrapper_4 {
							width: 100%;
							height: 536px;
							margin-top: 20px;
							margin-left: 6px;

							.swiper-container {
								position: relative;
								width: 75%;
								height: 500px;
							}

							.swiper-slide {
								width: 20% !important;
								height: 150px;
								font-size: 14px;
								line-height: 80px;
								border-radius: 8px;
								position: relative;
								align-items: center;
								transition: 300ms;
								transform: scale(0.8);

							}

							.swiper-slide-active,
							.swiper-slide-duplicate-active {
								transform: scale(1);
							}
						}

					}

					.weixincon {
						width: 100%;
						height: auto;

						.weixin_first {
							width: 100%;
							height: auto;
							margin-top: 83px;

							.weixin_first_top {
								width: 100%;
								height: 541px;

								.weixin_first_left {
									float: left;
									margin-left: 385px;

									img {
										width: 549px;
										height: 541px;
									}
								}

								.weixin_first_right {
									float: left;
									margin-left: 75px;
									margin-top: 217px;

									.text {
										font-size: 34px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #333333;
										line-height: 36px;
										margin-bottom: 10px;
									}

									.text2 {
										font-size: 18px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #999999;
										line-height: 24px;
									}
								}
							}


						}

						.weixin_twice {
							width: 100%;
							height: 400px;

							.weixin_twice_top {
								width: 100%;
								height: 300px;
								position: relative;

								.title_textxt {
									width: 100%;
									text-align: center;
									position: absolute;
									font-size: 34px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #333333;
									margin: 30px 0;
								}

								.contab {
									position: absolute;
									top: 140px;
									width: 100%;
									height: auto;
									display: flex;
									flex-wrap: nowrap;
									justify-content: space-between;

									.contab_con {
										width: 25%;
										height: auto;
										text-align: center;
										margin: 0 100px;

										.contab_text {

											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 500;
											color: #333333;
											margin: 20px 0;
										}

										.contab_main {

											font-size: 16px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #999999;
											line-height: 24px;
										}
									}
								}
							}
						}

						.weixin_three {
							width: 111%;
							height: 1559px;
							background: #F5F7FA;
							margin-left: -94px;

							.weixin_three_top {
								width: 100%;
								height: 50%;

								.text {
									float: left;
									margin: 74px+258px 0 0 99px;

									.title {
										font-size: 34px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #333333;
										line-height: 36px;
									}

									.main {
										font-size: 18px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #999999;
										line-height: 24px;
										margin-top: 10px;
										width: 400px;
									}
								}

								.img {
									float: left;
									margin: 74px 0 0 419px;
								}


							}

							.weixin_three_bottom {
								width: 100%;
								height: 50%;

								.text {
									float: left;
									margin: 20px -547px 0;
									padding-top: 259px;

									.title {
										font-size: 34px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #333333;
										line-height: 36px;
									}

									.main {
										font-size: 18px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #999999;
										line-height: 24px;
										margin-top: 10px;
										width: 400px;
									}
								}

								.img {
									float: right;
									margin-right: 399px;
								}

							}
						}

						.weixin_four {
							width: 100%;
							height: 800px;

							.weixin_four_top {
								width: 100%;
								height: 100px;
								margin-top: 100px;

								.top_title {
									text-align: center;
									width: 100%;
									font-size: 34px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #333333;


								}

								.top_title2 {
									margin-top: 19px;
									text-align: center;
									width: 100%;
									font-size: 18px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #999999;
								}
							}

							.confouer {
								width: 100%;
								margin-top: 100px;
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;

								.confouer_con {
									width: 25%;
									text-align: center;
									margin-bottom: 50px;

									.text {
										.title {
											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 500;
											color: #333333;
											margin: 14px 0 19px 0;
										}

										.main {
											font-size: 16px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #999999;
											line-height: 24px;
										}
									}
								}
							}

						}
					}

					.gzhcon {
						width: 100%;
						height: auto;

						.gzh_first {
							width: 100%;
							height: 306px;
							margin-top: 85px;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;

							.gzh_first_top {
								width: 100%;
								height: 100px;
								margin-bottom: 50px;

								.top_title {
									text-align: center;
									width: 100%;
									font-size: 34px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #333333;


								}

								.top_title2 {
									margin-top: 19px;
									text-align: center;
									width: 100%;
									font-size: 18px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #999999;
								}
							}

							.gzh_con {
								width: 25%;
								text-align: center;
								margin-bottom: 50px;

								.text {
									.title {
										font-size: 22px;
										font-family: Source Han Sans CN;
										font-weight: 500;
										color: #333333;
										margin: 14px 0 19px 0;
									}

									.main {
										width: 239px;
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #999999;
										line-height: 24px;
										margin-left: 102px;
									}
								}
							}
						}

						.block_11 {
							width: 111%;
							margin-left: -96px;
							height: 985px;
							background-image: url("../../assets/kaiyuan/product_bg.png");
							background-size: 100% 100%;
							background-repeat: no-repeat;
							margin-top: 98px;

							.text-wrapper_53 {
								width: 100%;
								height: 32px;
								padding-top: 96px;

								.text_30 {
									width: 100%;
									height: 32px;
									overflow-wrap: break-word;
									color: rgba(255, 255, 255, 1);
									font-size: 34px;
									font-family: SourceHanSansCN-Regular;
									font-weight: NaN;
									text-align: center;
									white-space: nowrap;
									line-height: 34px;
								}
							}

							.row {
								margin-right: -15px;
								margin-left: -15px;

								.col-xs-6 {
									width: 50%;
									position: relative;

									.zhanshiimg {
										position: absolute;
										top: 17px;
										left: 1059px;

										img {
											width: 307px;
											height: 660px;
										}
									}

									ul {
										list-style: none;
										color: #fff;
									}

									ul.preview_content {
										padding-left: 50%;
										padding-top: 85px;
									}

									.preview_content li {
										width: 280px;
									}

									.preview_content li {
										width: 280px;
										height: 70px;
										line-height: 70px;

										font-size: 20px;
										padding: 0 40px;
										text-decoration: none;
									}

									.preview_content li span {
										margin-right: 15px;
									}




								}
							}

						}

						.gzh_bottom {
							width: 100%;
							height: auto;

							.gzh_bottom_title {
								width: 100%;
								height: auto;
								text-align: center;
								margin-top: 120px;

								.tetx {
									font-size: 34px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #333333;
								}

								.main {
									margin-top: 19px;
									font-size: 18px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #999999;
								}
							}

							.gzh_bottom_con {
								width: 100%;
								height: auto;

								.container {
									width: 100%;

									.nav {
										width: 100%;
										height: 180px;
										display: flex;
										flex-wrap: nowrap;
										justify-content: space-around;
										margin-top: 59px;

										.btn {
											width: 330px;
											height: 140px;
											background-image: url("../../assets/kaiyuan/kuang.png");
											background-size: 100% 100%;
											background-repeat: no-repeat;
											text-align: center;

											.btn_name {
												font-size: 24px;
												font-family: Source Han Sans CN;
												font-weight: 400;

												margin: 43px 0 0 0;
											}

											.btn_mian {
												font-size: 16px;
												font-family: Source Han Sans CN;
												font-weight: 400;

												line-height: 24px;
											}
										}

										.activee {
											background-image: url('../../assets/kaiyuan/gzhchagge.png');
											background-repeat: no-repeat;
											border: none;
										}

										.btn_namee {
											color: #fff;
										}

										.btn_miane {
											color: #fff;
										}

									}

									.detail {
										width: 81%;
										height: 905px;
										margin-left: 160px;
									}
								}
							}
						}
					}
				}

				.product_con3 {
					width: 100%;
					height: auto;

					.tese_first {
						width: 100%;
						height: auto;

						.text-group_21 {
							width: 100%;
							height: 100%;
							text-align: center;
							margin-top: 78px;
							margin-bottom: 42px;

							.text_19 {

								overflow-wrap: break-word;
								color: rgba(51, 51, 51, 1);
								font-size: 34px;
								font-family: SourceHanSansCN-Bold;
								font-weight: 700;
								text-align: center;
								white-space: nowrap;
								line-height: 34px;
								margin-left: 52px;
							}

							.text_20 {

								overflow-wrap: break-word;
								color: rgba(51, 51, 51, 1);
								font-size: 18px;
								font-family: SourceHanSansCN-Regular;
								font-weight: NaN;
								text-align: center;
								white-space: nowrap;
								line-height: 18px;
								margin-top: 18px;
							}
						}

						.box_41 {
							width: 100%;
							height: auto;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-evenly;

							.block_5 {

								background-color: rgba(255, 255, 255, 1);
								width: 30%;
								height: 261px;

								.block_5_1 {
									width: 88%;
									/* height: 100%; */
									/* padding: 15px; */
									margin: 33px auto;

									.image_12 {
										width: 100%;
										text-align: center;

										img {
											width: 65px;
											height: 65px;
										}
									}

									.text-group_22 {
										width: 100%;
										height: 48px;
										text-align: center;

										.text_21 {

											overflow-wrap: break-word;
											// color: rgba(51, 51, 51, 1);
											font-size: 22px;
											font-family: SourceHanSansCN-Medium;
											font-weight: 500;
											text-align: center;
											white-space: nowrap;
											line-height: 22px;
										}

										.text_22 {

											overflow-wrap: break-word;
											color: #999;
											font-size: 18px;
											font-family: SourceHanSansCN-Light;
											font-weight: 300;
											white-space: nowrap;
											line-height: 24px;
											margin-top: 9px;
										}
									}

								}
							}
						}

						.block_5:hover {
							height: 201px;
							background: #FFFFFF;
							border: 1px solid #0274FB;
							box-shadow: 4px 19px 20px 0px rgba(235, 238, 255, 1);
						}
					}

					.tese_sence {
						width: 100%;
						height: 838px;
						background: #F5F5F5;

						.box_43 {
							position: relative;
							width: 100%;
							height: auto;
							margin-bottom: 1px;

							.text_58 {
								padding-top: 90px;
								width: 100%;
								display: block;
								text-align: center;
								overflow-wrap: break-word;
								color: rgba(51, 51, 51, 1);
								font-size: 34px;
								font-family: SourceHanSansCN-Regular;
								font-weight: NaN;
								white-space: nowrap;
								line-height: 34px;
							}

							.text_59 {
								margin-top: 51px;
								width: 100%;
								display: block;
								margin: 21px 0 51px 0;
								text-align: center;
								overflow-wrap: break-word;
								color: rgba(153, 153, 153, 1);
								font-size: 18px;
								font-family: SourceHanSansCN-Regular;
								font-weight: NaN;
								white-space: nowrap;
							}

							.image-wrapper_4 {
								width: 100%;
								height: 536px;
								margin-top: 20px;
								margin-left: 59px;

								.swiper-container {
									position: relative;
									width: 100%;
									height: 500px;
								}

								.swiper-slide {
									width: 20% !important;
									height: 150px;
									font-size: 14px;
									line-height: 80px;
									border-radius: 8px;
									position: relative;
									align-items: center;
									transition: 300ms;
									transform: scale(0.8);

								}

								.swiper-slide-active,
								.swiper-slide-duplicate-active {
									transform: scale(1);
								}

							}

						}
					}

					.tese_three {
						width: 100%;
						height: auto;

						.title {
							width: 100%;
							height: 41px;
							font-size: 42px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #333333;
							text-align: center;
							padding-top: 109px;
						}

						.box_table {
							width: 100%;
							height: auto;
							margin: 81px 0;
							display: flex;
							flex-wrap: nowrap;

							.box_table1 {
								width: 50%;

								.img {
									padding-left: 360px;
								}
							}

							.box_table2 {
								width: 30%;
								height: 500px;
								display: flex;
								flex-wrap: wrap;
								margin-left: 60px;
								padding-top: 123px;

								.box_con {
									width: 50%;
									text-align: center;

									.box_txt {
										margin: 20px 0;
										font-size: 22px;
										font-family: Source Han Sans CN;
										font-weight: 500;
										color: #333333;
									}

								}
							}

						}
					}
				}

				.product_con4 {
					width: 100%;
					overflow: hidden;

					.kehuanli {
						width: 100%;
						height: 700px;
						margin-top: 10px;
						margin-bottom: 50px;

						.demo-tabs_first {
							width: 100%;
							height: 100%;
							display: flex;

							.demo-tabs_first1 {
								width: 34%;
								height: 100%;
								margin-top: 40px;
								margin-left: 177px;
							}

							.demo-tabs_first2 {
								width: 50%;
								height: 100%;

								.title_demo {
									margin-top: 65px;
									margin-bottom: 20px;
								}

								.title_demo1 {
									font-size: 32px;
									font-family: Source Han Sans CN;
									font-weight: bold;
									color: #333333;
								}

								.title_demo2 {
									margin: 20px 0 20px 0;
									font-size: 22px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #666666;

								}

								.title_demo3 {
									margin: 50px 0 0 0;
									width: 50%;
									height: 150px;
									display: flex;

									.title_demo3_1 {
										width: 33.3%;
										height: 100%;

										.title_demo3_1_num {
											font-size: 40px;
											font-family: DIN;
											font-weight: bold;
											color: #FF0017;
											line-height: 33px;
										}

										.title_demo3_1_text {
											text-align: center;
											width: 120px;
											margin-top: 10px;
											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #999999;
										}

									}

									.title_demo3_2 {
										width: 33.3%;
										height: 100%;

										.title_demo3_2_num {
											font-size: 40px;
											font-family: DIN;
											font-weight: bold;
											color: #FF0017;
											line-height: 33px;
										}

										.title_demo3_2_text {
											text-align: center;
											width: 120px;
											margin-top: 10px;
											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #999999;
											line-height: 33px;
										}
									}

									.title_demo3_3 {
										width: 33.3%;
										height: 100%;

										.title_demo3_3_num {
											font-size: 40px;
											font-family: DIN;
											font-weight: bold;
											color: #FF0017;
											line-height: 33px;
										}

										.title_demo3_3_text {
											text-align: center;
											width: 120px;
											margin-top: 10px;
											font-size: 22px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #999999;
											line-height: 33px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	/deep/ .el-tabs__item {
		font-size: 22px;
		font-family: Source Han Sans CN;
		font-weight: 400;
	}

	/deep/ .el-tabs__content {
		height: 600px;
	}

	/deep/.el-carousel__container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/deep/.el-carousel__item {
		flex: 1;
		text-align: center;
		transition: transform 0.3s ease;
	}

	/deep/.el-carousel__item img {
		width: 230px;
		height: 496px;
	}
</style>