<template>
	<div class="userbox">
		<el-row>
			<el-col :span="one">
				<div class="box1">
					<div class="one">交付项目</div>
					<div class="two"> complete projects</div>
					<div class="three">2000+</div>
				</div>
			</el-col>
			<el-col :span="one">
				<div class="box1">
					<div class="one">获得奖项</div>
					<div class="two"> Awards Received</div>
					<div class="three">50+</div>
				</div>
			</el-col>
			<el-col :span="one">
				<div class="box1">
					<div class="one">快乐的客户 </div>
					<div class="two">Customer satisfaction</div>
					<div class="three">2000次+</div>
				</div>
			</el-col>
			<el-col :span="one">
				<div class="box1">
					<div class="one">员工工作 </div>
					<div class="two"> Number of employees</div>
					<div class="three">20+</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: 'user',
		data() {
			return {
				one: 6
			}
		},
		mounted() {
			if (window.innerWidth > Number(1200)) {
				this.one = 6
			} else if (window.innerWidth >= Number(992) && window.innerWidth <= Number(1200)) {
				this.one = 6
			} else if (window.innerWidth >= Number(768) && window.innerWidth < Number(992)) {
				this.one = 12
			} else if (window.innerWidth >= Number(480) || window.innerWidth < Number(768)) {
				this.one = 12
			} else if (window.innerWidth < Number(480)) {
				this.one = 24
			}
		},
		methods: {

		}
	}
</script>

<style lang="less" scoped>
	.userbox {
		background: #FFFFFF;
		padding: 55px 50px;
		color: #0D68FC;
	}

	.box1 {
		text-align: center;

		.one {
			font-size: 22px;
		}

		.two {
			font-size: 12px;
		}

		.three {
			font-size: 50px;
		}
	}

	/*在 992 和 1199 像素之间的屏幕里，中等屏幕，分辨率低的 PC*/
	@media (min-width: 992px) and (max-width: 1199px) {
		.box1 {
			text-align: center;
			padding-bottom: 50px;

			.one {
				font-size: 19px;
			}

			.two {
				font-size: 10px;
			}

			.three {
				font-size: 44px;
			}
		}

	}

	/*在 768 和 991 像素之间的屏幕里，小屏幕，主要是 PAD*/
	@media (min-width: 768px) and (max-width: 991px) {
		.box1 {
			text-align: center;
			padding-bottom: 50px;

			.one {
				font-size: 19px;
			}

			.two {
				font-size: 10px;
			}

			.three {
				font-size: 44px;
			}
		}

	}

	/*在 480 和 767 像素之间的屏幕里，超小屏幕，主要是手机*/
	@media (min-width: 480px) and (max-width: 767px) {
		.box1 {
			text-align: center;
			padding-bottom: 40px;

			.one {
				font-size: 19px;
			}

			.two {
				font-size: 10px;
			}

			.three {
				font-size: 44px;
			}
		}
	}

	/*在小于 480 像素的屏幕，微小屏幕，更低分辨率的手机*/
	@media (max-width: 479px) {
		.box1 {
			text-align: center;
			padding-bottom: 30px;

			.one {
				font-size: 16px;
			}

			.two {
				font-size: 10px;
			}

			.three {
				font-size: 32px;
			}
		}
	}
</style>