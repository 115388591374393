<template>
  <div class="OtherChat">
    <div class="other_user">
      <div class="time">{{ time }}</div>
      <div class="user_main">
        <div class="avatar_warp">
          <el-avatar class="chatAvatar" :size="30" :src="avatar"></el-avatar>
        </div>
        <div class="user_message">
          {{ con }}
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  export default {
    name: "OtherChat",
    props: {
      time: {
        type: String,
        default: "",
      },
      avatar: {
        type: String,
        default: "",
      },
      con: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        conlust: [],
        chat_id: '',
        messageList:[],
      }
    },
    mounted() {
      
      
    },
    methods: {
      
      
      
    }
  };
</script>

<style scoped>
  .time {
    font-size: 14px;
    text-align: center;
    color: #9a9a9a;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .other_user {
    padding-bottom: 30px;
  }

  .user_main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .user_message {
    padding: 10px 25px;
    background: #FBFCFF;
    border: 1px solid #D7E4FB;
    border-radius: 10px;
    max-width: 60%;
    line-height: 34px;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    margin-left: 10px;
  }
</style>