<template>
  <div class="content">
    <div class="order">
      <div class="order_order">
        <div class="order_heard">
          <el-page-header @back="goBack" title='返回'>
            <template #content>
              <span class="text-large font-600 mr-3">
                <h4>商品详情</h4>
              </span>
            </template>
          </el-page-header>
        </div>
        <div class="order_con">
          <!-- 收货人信息 -->
          <div class="people_xin">
            <div class="shouhuoren">
              <div class="shouhuoren_title">收货人信息</div>
              <div class="shouhuoren_name"> 收货邮箱:&nbsp;&nbsp;&nbsp;{{tableData.user_email}}</div>
            </div>
            <div class="fukuang">
              <div class="fukuang_title">付款信息</div>
              <div class="fukuang_fangshi">实付金额:&nbsp;&nbsp;&nbsp;{{tableData.pay_money}}</div>
              <div class="fukuang_time">付款方式:&nbsp;&nbsp;&nbsp;
                <span v-if='tableData.pay_type="lingqina"'>零钱</span>
                <span v-else-if="tableData.pay_type='alipay'">支付宝</span>
                <span v-else-if="tableData.pay_type='wechat'">微信</span>
              </div>
              <div class="fukuang_time">付款时间:&nbsp;&nbsp;&nbsp;{{tableData.create_time}}</div>
            </div>
            <div class="maijia">
              <div class="maijia_title">买家信息</div>
              <div class="maijia_fangshi">是否含有发票:&nbsp;&nbsp;&nbsp;
                <span v-if="tableData.is_invoice==1">是</span>
                <span v-if="tableData.is_invoice==2">否</span>
                <span style="margin-left: 10px;" v-if="tableData.is_invoice==1"> <el-button size="mini" type="info"
                    @click="chakna()">发票信息</el-button></span>
              </div>
            </div>
          </div>
          <div>
            <el-row>
              <el-col :span="24">
                <div class="tables">

                  <div class="tables-head">
                    <div class="tables-head-item" v-for="(item, index) in tables" :key="index">
                      {{ item }}
                    </div>
                  </div>
                  <div class="tables-list">
                    <div class="tables-list-item">
                      <div class="tables-list-itemt">
                        <div class="tables-list-itemt-l">
                          <text>{{tableData.create_time}}</text>
                          <text>订单号：{{tableData.order_num}}</text>
                          <text style="margin-left: 5px;">
                            <div class="fuzhuanniu" @click="fuzhiii(tableData.order_num)">
                              <img src="../../assets/fz.png" alt="" />
                            </div>
                          </text>
                        </div>
                      </div>
                      <div class="tables-list-itemb">
                        <div class="tables-list-items">
                          <div class="tables-list-items-l" v-if="tableData.goods_info">
                            <img :src="tableData.goods_info.image" />
                          </div>
                          <div class="tables-list-items-r">
                            <div class="tables-list-items-r-t" v-if="tableData.goods_info">

                              {{tableData.goods_info.title}}
                            </div>
                            <div class="tables-list-items-r-n" v-if="tableData.goods_info">
                              月销量 {{tableData.goods_info.sales}}
                            </div>
                          </div>
                        </div>

                        <div class="tables-list-items">
                          {{tableData.goods_money}}
                        </div>

                        <div class="tables-list-items">
                          {{tableData.goods_num}}
                        </div>

                        <div class="tables-list-items" style="font-weight: bold;">
                          ¥{{tableData.pay_money}}
                        </div>
                        <div v-if="tableData.goods_info" class="tables-list-items"
                          style="display: flex; flex-wrap: wrap;">
                          <div v-for="itemN in tableData.goods_info.goods_lable.split(',')"
                            style="margin-right:0 5px 5px  0">
                            <el-tag>{{itemN}}</el-tag>
                          </div>

                        </div>
                        <div class="tables-list-items">
                          <div class="tables-list-items-status">
                            <span v-if="tableData.status==1">待付款</span>
                            <span v-if="tableData.status==2">已付款</span>
                            <span v-if="tableData.status==3">已发货</span>
                            <span v-if="tableData.status==4">待评价</span>
                            <span v-if="tableData.status==5">已评价</span>
                            <span v-if="tableData.status==6">退款中</span>
                            <span v-if="tableData.status==7">已退款</span>
                            <span v-if="tableData.status==8">拒绝退款</span>
                            <span v-if="tableData.status==9">已取消</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="order_bottom">
            <div style="line-height: 50px;">
              <div>
                <div>商品总价:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ￥{{tableData.goods_money}}</div>
                <div>支付编号:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{tableData.pay_order}}</div>
                <div>优惠劵金额:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    ￥{{tableData.coupon_money}}
                  </span>
                </div>
              </div>
              <div>实付金额:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  style="color: red; font-weight: 900;">￥{{tableData.pay_money}}</span> </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="xiugaifapiaoshow" title="发票信息" center empty-text="暂无数据">
      <el-form :model="ruleFormN" :rules="rules" ref="ruleFormN">
        <div class="application_con_first" style="display: flex; justify-content: space-evenly;">
          <div class="qiyename" style="width: 45%;margin-left: 6px;"><!-- *企业名称 -->
            <el-form-item label="企业名称:" prop="qiyename">
              <el-input v-model="ruleFormN.qiyename" style="width: 306px;"></el-input>
            </el-form-item>
          </div>
          <div class="shuihao" style="width:43%;margin-left: 18px;"><!-- *税号 -->
            <el-form-item label="税号:" prop="shuihao">
              <el-input v-model="ruleFormN.shuihao" style="width: 311px;margin-left: 6px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_two" style="display: flex; justify-content: space-evenly;">
          <div class="kaihu" style="width:43%;margin-left: -20px;"><!-- *开户银行账号 -->
            <el-form-item label="开户银行:" prop="kaihu">
              <el-input v-model="ruleFormN.kaihu" style="width: 319px;    margin-left: 4px;"></el-input>
            </el-form-item>
          </div>
          <div class="yhzhanghao" style="width:43%"><!-- *银行账号 -->
            <el-form-item label="银行账号:" prop="yhzhanghao">
              <el-input v-model="ruleFormN.yhzhanghao" style="width: 520px;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_three" style="margin-left: 46px;">
          <div class="adress" style="margin-left: -20px;"><!-- **企业地址 -->
            <el-form-item label="企业地址:" prop="adress">
              <el-input v-model="ruleFormN.adress" style="width: 95%;"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="application_con_four" style="display: flex; justify-content: space-evenly;">
          <div class="name" style="width:30%"><!-- **收票人姓名 -->
            <el-form-item label="收票人姓名:" prop="name">
              <el-input v-model="ruleFormN.name" style="width:286px; margin-left: -4px; "></el-input>
            </el-form-item>
          </div>
          <div class="phone" style="width:30%"><!-- **收票人电话 -->
            <el-form-item label="收票人电话:" prop="phone">
              <el-input v-model="ruleFormN.phone" style="width: 286px; "></el-input>
            </el-form-item>
          </div>
          <div class="email" style="width:30%"><!-- **收票人邮箱 -->
            <el-form-item label="收票人邮箱:" prop="email">
              <el-input v-model="ruleFormN.email" style="width:286px; "></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tables: ['订单详情', '产品价格(元)', '数量', '实付款', '商品标签', '交易状态'],
        tableData: [],
        invoice_id: '',
        xiugaifapiaoshow: false,
        ruleFormN: {
          qiyename: '',
          shuihao: '',
          kaihu: '',
          yhzhanghao: '',
          adress: '',
          name: '',
          phone: '',
          email: '',
        },
      }
    },
    methods: {
      //接受传的参数
      async getParams() {
        let shopid = JSON.parse(this.$route.query.item)
        const {
          data: res
        } = await this.$http.post('/index/order/mine_order_info', {
          order_id: shopid
        })
        if (res.code == 1) {
          this.tableData = res.data
        }
      },
      chakna() {
        this.xiugaifapiaoshow = true
        this.ruleFormN.qiyename = this.tableData.invoice_info.company_name
        this.ruleFormN.shuihao = this.tableData.invoice_info.company_tax_id
        this.ruleFormN.kaihu = this.tableData.invoice_info.bank_name
        this.ruleFormN.yhzhanghao = this.tableData.invoice_info.bank_number
        this.ruleFormN.adress = this.tableData.invoice_info.company_address
        this.ruleFormN.name = this.tableData.invoice_info.open_account
        this.ruleFormN.phone = this.tableData.invoice_info.bank_phone
        this.ruleFormN.email = this.tableData.invoice_info.bank_email
      },
      async fuzhiii(item) {
        this.$notify.success({
          title: "成功",
          message: '复制成功',
          duration: 2500,
          offset: 50
        });
        let input = document.createElement("input");
        input.value = item;
        document.body.appendChild(input);
        input.select();
        try {
          await navigator.clipboard.writeText(item);
          document.body.removeChild(input);
          console.log("文本已复制到剪贴板");
        } catch (err) {
          console.log("复制失败", err);
        }
      },
      goBack() {
        this.$router.push({
          name: 'order',
        })
      }
    },
    mounted() {
      this.getParams()
    }
  }
</script>

<style lang="less" scoped>
  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .order {
      width: calc(100% - 20px);
      min-height: 770px;
      background: #FFFFFF;
      border-radius: 20px;

      .order_order {
        width: 95%;
        height: 100%;
        margin: 0 auto;

        .order_heard {
          width: 100%;
          height: 50px;
        }

        .order_con {
          width: 100%;
          height: 686px;
          margin-top: 20px;
        }

        .people_xin {
          width: 100%;
          height: 200px;
          background-color: rgba(128, 128, 128, 0.1);
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;

          .shouhuoren {
            width: 25%;
            height: 200px;
            line-height: 51px;
            padding-left: 10px;

            .shouhuoren_title {
              font-size: 18px;
              font-weight: 900;

            }

            .shouhuoren_name {
              font-size: 16px;

            }

            .shouhuoren_ten {
              font-size: 16px;

            }

            .shouhuoren_address {
              font-size: 16px;

            }
          }

          .peisong {
            width: 25%;
            height: 200px;
            line-height: 51px;
            padding-left: 10px;

            .peisong_title {
              font-size: 18px;
              font-weight: 900;
            }

            .peisong_fangshi {
              font-size: 16px;
            }

            .peisong_time {
              font-size: 16px;
            }
          }

          .fukuang {
            width: 25%;
            height: 200px;
            line-height: 51px;
            padding-left: 10px;

            .fukuang_title {
              font-size: 18px;
              font-weight: 900;
            }

            .fukuang_fangshi {
              font-size: 16px;
            }

            .fukuang_time {
              font-size: 16px;
            }

            .fukuang_time {
              font-size: 16px;
            }
          }

          .maijia {
            width: 40%;
            height: 200px;
            line-height: 51px;
            padding-left: 10px;

            .maijia_title {
              font-size: 18px;
              font-weight: 900;
            }

            .maijia_fangshi {
              font-size: 16px;
            }
          }
        }
      }

    }
  }

  .tables {
    width: 100%;
    margin-top: 20px;

    // border: 1px solid #ebeef5;
    .tables-head {
      width: 100%;
      height: 30px;
      background-color: #EEF0F6;
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      .tables-head-item {
        width: calc((100% - 500px) / 5);
      }

      .tables-head-item:nth-of-type(1) {
        width: 460px;
        padding: 0 20px;

      }

      .tables-head-item:nth-of-type(6) {
        // padding-right: 20px;
      }

    }

    .tables-list {
      width: 100%;

      .tables-list-item {
        width: 100%;
        border: 1px solid #ebeef5;
        margin-bottom: 20px;
      }

      .tables-list-itemt {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ebeef5;

        .tables-list-itemt-l {
          display: flex;
          align-items: center;
          line-height: 40px;

          text:nth-of-type(1) {
            padding-left: 20px;
            margin-right: 30px;
            color: #666666;
            font-size: 16px;
            font-weight: 500;
          }

          text:nth-of-type(2) {
            color: #666666;
            font-size: 14px;
          }
        }
      }

      .tables-list-itemb {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .tables-list-items {
        width: calc((100% - 500px) / 5);
        // padding: 10px 20px;
      }

      .tables-list-items-status {
        color: #0D68FC;
        font-size: 14px;
      }

      .tables-list-items-order {
        color: #333333;
        font-size: 14px;
        margin-top: 16px;
        cursor: pointer;
      }

      .tables-list-items:nth-of-type(1) {
        width: 460px;
        display: flex;
        align-items: flex-start;
        padding: 10px 20px;

        .tables-list-items-l {
          img {
            width: 100px;
            height: auto;
          }
        }

        .tables-list-items-r {
          margin-left: 10px;
        }

        .tables-list-items-r-t {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
        }

        .tables-list-items-r-n {
          color: #666666;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }
  }

  .order_bottom {
    width: 100%;
    height: 200px;
    border-radius: 0%;
    border-top: 1px silver solid;
    margin-top: 20px;
  }

  .fuzhuanniu {
    width: 30px;
    height: 20px;
    // border-radius: 8px;
    // border: 1px solid #ccc;
    text-align: center;

    img {
      margin-left: 5px;
      display: block;
      width: 20px;
      height: 20px;

    }
  }
</style>