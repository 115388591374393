<template>
  <div class="content">
    <div class="Invoicing">
      <div class="Invoicing_top">
        <span class="budeg"></span>
        <span class="Invoicing_top_left">发票记录</span>
        <div class="Invoicing_top_right">
          <span>申请时间</span>
          <el-date-picker v-model="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"></el-date-picker>

        </div>
      </div>
      <el-divider />
      <div class="Invoicing_bottom">
        <el-table :data="tableData" center style="width: 100%; margin-top: 20px;" empty-text="暂无数据">
          <el-table-column prop="name" label="产品名称" width="300" style="display: flex;">
            <template v-slot="scope">
              <span style="width: 40px; height: 30px; ">
                <img :src="scope.row.goods_info.image" width="40" height="30" />
              </span>
              <span
                style="font-size: 16px;margin-left: 9px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">{{scope.row.goods_info.title}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_num" label="订单编号">
          </el-table-column>
          <el-table-column prop="money" label="发票总额">
            <template v-slot="scope">
              <span>￥{{scope.row.invoice_money}}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="leixing" label="发票类型">
            <template v-slot="scope">
              <span>电子发票</span>
            </template>
          </el-table-column>
          <el-table-column prop="xingzhi" label="发票性质">
            <template v-slot="scope">
              <span>电子发票</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="收票地址">
            <template v-slot="scope">
              <span>{{scope.row.user_info.email}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template v-slot="scope">
              <span v-if="scope.row.status==1">审核中</span>
              <span v-if="scope.row.status==2">成功</span>
              <span v-if="scope.row.status==3">拒绝</span>
            </template>
          </el-table-column>
        </el-table>


        <div class="pages">
          <el-pagination background :total="total" v-model:page-size="pageSize" @size-change="handleSizeChange"
            :current-page="currentPage" @current-change="handleCurrentChange" layout=" prev, pager, next" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        date: '',
        total: 6,
        pageSize: 3,
        currentPage: 1,
        tableData: []
      }
    },
    methods: {
      handleSizeChange(val) {
        this.pageSize = val
        this.byInvoic()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.byInvoic()
      },
      dateChange(dates) {
        this.date = dates
        console.log(this.date)
      },
      async byInvoic() {
        const {
          data: res
        } = await this.$http.post('index/user/invoice_list', {
          num: this.pageSize,
          page: this.currentPage
        })
        if (res.code == 1) {
          this.tableData = res.data.data
          console.log(this.tableData)
          this.total = res.data.total
        }
      }
    },
    mounted() {
      this.byInvoic()
    }
  }
</script>

<style scoped lang="less">
  /deep/ .el-table__empty-text {
    display: block;
    width: 200px;
    height: 371px;
    position: relative;
    padding-top: 148px;
    top: 80px;
    background: url('../../assets/dhpbjj.png') no-repeat;
    color: #909399;
    box-sizing: border-box;
  }

  .budeg {
    position: absolute;
    top: 64px;
    left: -7px;
    height: 16px;
    width: 4px;
    border-radius: 2px;
    background-color: #0D68FC;
  }

  .content {
    width: 100%;
    min-height: 800px;
    background-color: #F6F9FF;

    .Invoicing {
      width: calc(100% - 20px);
      min-height: 750px;
      background: #FFFFFF;
      border-radius: 20px;

      .Invoicing_top {
        width: 96%;
        height: 100px;
        margin: 0 auto;
        position: relative;

        .Invoicing_top_left {
          float: left;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 900;
          color: #333333;
          padding-top: 60px;
        }

        .Invoicing_top_right {
          float: right;
          padding-top: 60px;

          span {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 900;
            color: #373A3C;
            margin-right: 10px;
          }
        }
      }

      .Invoicing_bottom {
        width: 96%;

        margin: 0 auto;
      }
    }
  }

  .pages {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/.el-table tr {
    height: 50px;
  }

  /deep/.el-table__header th {
    height: 50px;
    background: #EEF0F6;
    border-radius: 0px 0px 0px 0px;
  }
</style>